import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import { t } from 'i18next';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FieldEditorFooter from 'src/contents/profile-editor/FieldEditorFooter';
import { ImageFieldValue } from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import blobToBase64 from 'src/utility/blobToBase64';
import getExt from 'src/utility/getExt';
import { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FieldRenderEditorProp } from '../..';
import getMultiFieldValue from '../../_utils/getMultiFieldValue';

const CardActionAreaWrapper = styled(CardActionArea)(
  () => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .MuiTouchRipple-root {
      opacity: .3;
  }

  &:hover {
      .MuiCardActionArea-focusHighlight {
          opacity: .05;
      }
  }
`
);

const AvatarCloud = styled(Avatar)(
  ({ theme }) => `
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${'100%'};
    height: ${theme.spacing(7)};
`
);

export interface ImageSliderItem {
  url: string;
  title?: string;
  subTitle?: string;
  targetUrl?: string;
}
export interface ImageSliderProfileItem {
  active: boolean;
  profileId?: number;
  sliderTitle?: string;
}
export default function ImageSliderEditor(props: FieldRenderEditorProp) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const value = getMultiFieldValue<ImageFieldValue>(
    props,
    props.data.imageList
  );

  const defValue: ImageFieldValue = {
    orientation: 'Landscape',
    imageList: [],
    title: '',
    subTitle: ''
  };

  const [fieldValue, setFieldValue] = useState<ImageFieldValue>(
    value || defValue
  );

  const onSave = () => {
    if (!fieldValue.imageList.length) {
      enqueueSnackbar(t('Upload at-least one image'), { variant: 'error' });
      return;
    }

    const imageList = [...(props.data.imageList || [])];
    imageList[props.valueIndex] = { ...fieldValue };
    props.onSave({ ...props.data, imageList: imageList });
  };

  return (
    <Card
      sx={{ overflow: 'hidden', textAlign: 'left', border: 'solid 1px #ccc' }}
    >
      <CardHeader title={props.field.displayName} />
      <Grid container>
        <Grid xs={12} item>
          <Box sx={{ pl: 1 }}>
            <FormControlLabel
              checked={fieldValue.orientation == 'Portrait'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue({
                  ...value,
                  orientation: e.target.checked ? 'Portrait' : 'Landscape'
                });
              }}
              control={
                <Checkbox
                  icon={<CropLandscapeIcon />}
                  checkedIcon={<CropPortraitIcon />}
                />
              }
              label={`Orientation (${fieldValue.orientation})`}
            />
          </Box>
        </Grid>
      </Grid>

      <UploadImageForm
        fieldValue={value || defValue}
        onChange={(e) =>
          setFieldValue({
            ...fieldValue,
            imageList: e.imageList,
            title: e.title,
            subTitle: e.subTitle
          })
        }
      />

      <FieldEditorFooter
        onOk={onSave}
        onCancel={props.onCancel}
        onDelete={() => props.onDelete()}
      />
    </Card>
  );
}

function UploadImageForm(props: {
  fieldValue: ImageFieldValue;
  onChange: (e: ImageFieldValue) => void;
}) {
  const theme = useTheme();
  const [urls, setUrls] = useState<string[]>([]);

  const [fieldValue, setFieldValue] = useState<ImageFieldValue>(
    props.fieldValue
  );

  const onDropAccepted = async (files: File[]) => {
    fieldValue.imageList = [];

    files.forEach((f, i) => {
      const uniqueName = Date.now() + i + '.' + getExt(f.name);
      fieldValue.imageList.push({
        url: uniqueName
      });
      cdnService.blobHash[uniqueName] = f;
    });

    setUrls(fieldValue.imageList.map((o) => o.url));

    props.onChange(fieldValue);
  };

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 100,
    maxSize: 5242880,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg']
      //   'image/heic': ['.heic'],
      //   'image/heif': ['.heif']
    },
    onDropAccepted
  });

  const onRemoveFiles = () => {
    //  setAcceptedFiles([]);

    urls.forEach((o) => {
      cdnService.deleteBlob(o).then(() => {});
    });

    setUrls([]);

    const updatedModel = _.cloneDeep(fieldValue);
    updatedModel.imageList = [];

    setFieldValue(updatedModel);
    props.onChange(updatedModel);
  };

  return (
    <React.Fragment>
      {!fieldValue?.imageList?.length && urls.length == 0 && (
        <Box
          p={1}
          {...getRootProps()}
          component="div"
          className="feature-file-upload"
        >
          <input {...getInputProps()} />
          <label id="label-file-upload" htmlFor="input-file-upload">
            <div>
              <p>Drag and drop your file here or browse</p>
              <AvatarCloud variant="rounded">
                <CloudUploadTwoToneIcon />
              </AvatarCloud>
            </div>
          </label>
        </Box>
      )}
      <ImageSliderWrapper
        imageList={
          urls.length > 0
            ? urls.map((o) => {
                return { url: o };
              })
            : fieldValue.imageList
        }
        showRemove={true}
        onRemoveFiles={onRemoveFiles}
      />
      <Box m={1} mt={0} display="flex" flexDirection={'column'}>
        <Grid sx={{ padding: 0 }} container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              autoFocus={false}
              required={false}
              label={'Title'}
              fullWidth
              value={fieldValue.title || ''}
              onChange={(e) => {
                const val = { ...fieldValue, title: e.target.value };
                setFieldValue(val);
                props.onChange(val);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              autoFocus={false}
              required={false}
              label={'Subtitle'}
              fullWidth
              value={fieldValue.subTitle || ''}
              onChange={(e) => {
                const val = { ...fieldValue, subTitle: e.target.value };
                setFieldValue(val);
                props.onChange(val);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

function ImageSliderWrapper(props: {
  imageList: ImageSliderItem[];
  showRemove: boolean;
  onRemoveFiles: () => void;
}) {
  const theme = useTheme();
  const [urls, setUrls] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const _urls: string[] = [];

      const lst = props.imageList || [];

      for (let i = 0; i < lst.length; i++) {
        const blob = cdnService.blobHash[lst[i].url];
        if (blob) {
          const str = await blobToBase64(blob);
          _urls.push(str);
        } else {
          _urls.push(cdnService.toImageUrl(lst[i].url));
        }
      }
      setUrls(_urls);
    })();
  }, [props.imageList]);

  const renderSwiperSlide = (o: ImageSliderItem, i: number) => {
    return (
      <SwiperSlide key={o.url}>
        <Card
          sx={{
            mt: 2,
            textAlign: 'center',
            transition: `${theme.transitions.create([
              'box-shadow',
              'transform'
            ])}`,
            transform: 'translateY(0px)',

            '&:hover': {
              transform: 'translateY(-10px)',
              boxShadow: `0 2rem 8rem 0 ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}, 
                                0 0.6rem 1.6rem ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.2
                                )}, 
                                0 0.2rem 0.2rem ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.15
                                )}`
            }
          }}
        >
          <CardActionAreaWrapper>
            <CardMedia
              component="img"
              height="230"
              image={i < urls.length ? urls[i] : ''}
              alt="..."
              sx={{ objectFit: 'fill' }}
              onClick={
                o.targetUrl
                  ? () => window.open(o.targetUrl, '_blank')
                  : () => {}
              }
            />
          </CardActionAreaWrapper>
        </Card>
        {(o.title || o.subTitle) && (
          <Box
            sx={{
              px: { md: 2, lg: 1.5, xl: 3 },
              pt: 2,
              textAlign: 'center'
            }}
          >
            {o.title && (
              <Link
                lineHeight={1.5}
                target="_blank"
                href={o.targetUrl}
                sx={{
                  transition: `${theme.transitions.create(['color'])}`,
                  color: `${theme.colors.alpha.black[100]}`,

                  '&:hover': {
                    color: `${theme.colors.primary.main}`
                  }
                }}
                color="text.primary"
                variant="h3"
                underline="none"
              >
                {o.title}
              </Link>
            )}
            {o.subTitle && (
              <Typography
                variant="subtitle2"
                sx={{
                  pb: 2
                }}
              >
                {o.subTitle}
              </Typography>
            )}
          </Box>
        )}
      </SwiperSlide>
    );
  };

  return (
    <Box
      px={1}
      pb={2}
      sx={{
        '.swiper-pagination-bullets': {
          bottom: '0 !important'
        }
      }}
    >
      <Swiper
        spaceBetween={12}
        slidesPerView={1}
        loop
        navigation={{}}
        modules={[Navigation, Pagination, Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false
        }}
        pagination={{ dynamicBullets: true, clickable: true }}
      >
        {props.imageList?.map((o, i) => renderSwiperSlide(o, i))}
      </Swiper>
      {props.showRemove && props.imageList.length > 0 && (
        <Button
          onClick={props.onRemoveFiles}
          sx={{ mt: 1 }}
          size="small"
          variant="outlined"
          color="error"
        >
          Remove files ({props.imageList?.length})
        </Button>
      )}
    </Box>
  );
}
