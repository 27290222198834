import { Autocomplete, TextField, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { TeamMember } from 'src/services/apiService/response-models';
import { getTeamMembers } from 'src/services/query-client';

function MemberSelector(props: {
  onChange: (values: TeamMember) => void;
  selectedId?: string;
  disabled?: boolean;
  placeholder?: string;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [item, setItem] = useState<TeamMember | null>(null);

  const {
    data: { data: items = [] } = {},
    isError,
    error,
    isLoading
  } = useQuery('team-members', getTeamMembers);

  return (
    <Autocomplete
      id="member-select-comp"
      fullWidth
      multiple={false}
      limitTags={1}
      disabled={props.disabled}
      value={item}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      options={items.filter((o) => !(item?.id == o.id))}
      // @ts-ignore
      getOptionLabel={(option: TeamMember) =>
        option?.fullName ||
        option?.firstName + ' ' + option?.lastName ||
        option?.email ||
        ''
      }
      onChange={(event: any, newValues: TeamMember | null) => {
        if (newValues) {
          setItem(newValues);
        }
        props.onChange(newValues);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: ''
          }}
          fullWidth
          variant="outlined"
          // label={t('Member(s)')}
          placeholder={t(props.placeholder || 'Select Member...')}
        />
      )}
    />
  );
}

export default MemberSelector;
