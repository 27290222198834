import {
  Box,
  Card,
  Grid,
  IconButton,
  Skeleton,
  alpha,
  styled,
  useTheme
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DefaultAppThumb from 'src/components/default-app-thumb';
import { BusinessAppInstance } from 'src/services/apiService/response-models';

const CardActions = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};
    z-index: 7;
  `
);

const Label = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.success.main};
    color: ${theme.palette.success.contrastText};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(10)};
    font-weight: bold;
    line-height: 23px;
    height: 22px;
    padding: ${theme.spacing(0, 1.2)};
    border-radius: 50px;
  `
);

const BgComposed = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: ${theme.transitions.create(['opacity'])};
    z-index: 6;
    opacity: 1;
   
  `
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        transition: ${theme.transitions.create(['color', 'transform'])};
        border-radius: 100px;

        .MuiSvgIcon-root {
            transform: scale(1);
            transition: ${theme.transitions.create(['transform'])};
        }

        &:hover {
            .MuiSvgIcon-root {
                transform: scale(1.3);
            }
        }
  `
);

const CardWrapper = styled(Card)(
  ({ theme }) => `

    background: ${theme.colors.gradients.blue1};
    color:  ${theme.colors.alpha.trueWhite[100]};
    border-radius: ${theme.general.borderRadiusXl};
    padding: ${theme.spacing(1)};
    box-shadow: none;

    .MuiCardHeader-root {
      .MuiCardHeader-title {
          color: ${theme.colors.alpha.trueWhite[100]};;
          font-weight: normal;
          padding-bottom: ${theme.spacing(0.5)};
      }
    }
    
    .MuiCardHeader-subheader {
        color:  ${theme.colors.alpha.trueWhite[100]};;
    }

      .MuiActionButtons {
            background: ${alpha(theme.colors.alpha.white[100], 0.95)};
            border-radius: ${theme.general.borderRadius};
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
            top: 15%;
            margin-top: -${theme.spacing(3.5)};
            position: absolute;
            right: ${theme.spacing(1.5)};
            padding: ${theme.spacing(0.5, 1)};
            transition: ${theme.transitions.create(['visibility', 'opacity'])};
            z-index:100;
            .MuiIconButton-root {
                border-radius: 100px;
                width: ${theme.spacing(5)};
                height: ${theme.spacing(5)};
                margin: ${theme.spacing(0.5)};
            }
      }

      &:hover {
          .MuiActionButtons {
            visibility: visible;
            opacity: 1;
      }   
      }
  `
);

function CardGridBlock(props: {
  isLoading: boolean;
  items: BusinessAppInstance[];
  onClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const onClick = (e: BusinessAppInstance) => {
    navigate(
      '/app/business-apps/' +
        e.businessApp.name.toLowerCase() +
        '/' +
        e.id +
        '?ret=' +
        location.pathname
    );
  };
  return (
    <Grid container spacing={4}>
      {props.isLoading &&
        [1, 2, 3, 4, 5, 6].map((o) => (
          <Grid key={o} item xs={12} md={4}>
            <Skeleton variant="rectangular" width={'100%'} height={260} />
          </Grid>
        ))}

      {!props.isLoading &&
        props.items.map((o) => (
          <Grid key={o.id} item xs={12} md={4}>
            <DefaultAppThumb
              item={o}
              actionLabel={'Link'}
              showDelete={true}
              onDeleteClick={() => props.onDeleteClick(o.id)}
              onSelect={onClick}
            />
          </Grid>
        ))}
    </Grid>
  );
}

export default CardGridBlock;
