import axios, { AxiosResponse } from 'axios';
import { TeamDto } from '../dto';
import { ApiUrls } from '../endpoints';
import {
  CreateCardRequest,
  EmployeeAccountSetting,
  LogInRequest,
  PlanProduct
} from './request-models';
import {
  AccountSignUp,
  ActiveSubscription,
  CardApp,
  CardAppItem,
  CardFieldCategory,
  DigitalCard,
  DigitalCardAppItem,
  DigitalCardField,
  DigitalCardListItem,
  LogInResult,
  NfcCodeMap,
  Profile,
  ProfileTypes,
  Team,
  User,
  UserAccountEntity
} from './response-models';

// export const getIndividualProfiles = (): Promise<
//   AxiosResponse<Profile[]>
// > => {
//   const dataUrl =
//     ApiUrls.base + '/api/v1/profile/all';
//   return axios.get(dataUrl);
// };

//-------------------------------------OLD --------------------------------------------
export const getAllCardFieldCategories = (): Promise<
  AxiosResponse<CardFieldCategory[]>
> => {
  const dataUrl =
    ApiUrls.manageCardEndpoint + '/digital-card/field/category/all';
  return axios.get(dataUrl);
};

export const getAllCardFields = (): Promise<
  AxiosResponse<DigitalCardField[]>
> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/field/all';
  return axios.get(dataUrl);
};

export const getCardItems = (): Promise<
  AxiosResponse<DigitalCardListItem[]>
> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/list-view/all';
  return axios.get(dataUrl);
};

export const getCardAppItems = (
  includeFieldValues?: boolean
): Promise<AxiosResponse<DigitalCardAppItem[]>> => {
  const flag = includeFieldValues === true ? true : false;
  const dataUrl =
    ApiUrls.manageCardEndpoint +
    '/digital-card/card-app-items/all?fieldValue=' +
    flag;
  return axios.get(dataUrl);
};

export const getCardById = (
  cardId: number
): Promise<AxiosResponse<DigitalCard>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/id/' + cardId;
  return axios.get(dataUrl);
};

export const getUserAccount = (): Promise<AxiosResponse<UserAccountEntity>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/user/account';
  return axios.get(dataUrl);
};

export const login = (
  userName: string,
  password: string
): Promise<AxiosResponse<LogInResult>> => {
  const data: LogInRequest = {
    userName: userName,
    password: password
  };
  const dataUrl = ApiUrls.membershipEndpoint + '/login';
  return axios.post(dataUrl, data);
};

export const logout = (token: string): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/logout';
  return axios.post(dataUrl, { token: token });
};

export const forgotPassword = (
  email: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/forgot-password';
  return axios.post(dataUrl, { email: email });
};

export const deactivateAccount = (
  reason: string,
  token: string
): Promise<AxiosResponse> => {
  const dataUrl = ApiUrls.accountEndpoint + '/deactivate';
  return axios.post(
    dataUrl,
    {
      reasonCode: reason
    },
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
};

export const changePassword = (
  newPwd: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/change-password';
  return axios.post(dataUrl, { newPassword: newPwd });
};

export const resetPassword = (
  email: string,
  validationToken: string,
  password: string,
  newPassword: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/reset-password';
  return axios.post(dataUrl, { email, validationToken, password, newPassword });
};

export const generatePassword = (
  email: string,
  additionalEmail?: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/generate-password';
  return axios.post(dataUrl, { email, additionalEmail });
};

export const getTeamById = (id: string): Promise<AxiosResponse<Team>> => {
  const dataUrl = ApiUrls.base + '/api/v1/team/' + id;
  return axios.get(dataUrl);
};

export const getTeams = (): Promise<AxiosResponse<Team[]>> => {
  const dataUrl = ApiUrls.base + '/api/v1/team/all';
  return axios.get(dataUrl);
};

export const checkTeamExistsByName = (
  teamName: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.base + '/api/v1/team/exists/name/' + teamName;
  return axios.get(dataUrl);
};

export const saveTeam = (
  req: TeamDto,
  id?: string
): Promise<AxiosResponse<void>> => {
  const dataUrl = ApiUrls.base + '/api/v1/team';
  if (id && id.trim().length > 0) return axios.put(dataUrl, req);
  else return axios.post(dataUrl, req);
};

export const getUserByToken = (token: string): Promise<AxiosResponse<User>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/user/token/' + token;
  return axios.get(dataUrl);
};

//--
export const accountSignup = (
  req: AccountSignUp
): Promise<AxiosResponse<void>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/sign-up';
  return axios.post(dataUrl, req);
};

// export const businessAccountSignup = (
//   req: AccountSignUp
// ): Promise<AxiosResponse<void>> => {
//   req.businessPlan = 'Ki-Start';
//   req.accountStatus = 'Trial';
//   req.createdBy = 'Admin';
//   req.managedBy = 'Admin';
//   const dataUrl = ApiUrls.accountEndpoint + '/business-account';
//   return axios.post(dataUrl, req);
// };

export const deleteContactById = (
  id: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.base + '/api/v1/contact/' + id;
  return axios.delete(dataUrl);
};

export const updateContactAndLeadStatus = (
  ids: string[]
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.base + '/api/v1/contact/updateStatus';
  return axios.post(dataUrl, {
    contactIds: ids
  });
};

export const sentLeadsToContactForClose = (
  ids: string[]
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.base + '/api/v1/lead/updateStatusToClose';
  return axios.post(dataUrl, {
    leadIds: ids
  });
};

export const sentLeadsToContactForRevisit = (
  ids: string[]
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.base + '/api/v1/lead/updateStatusToClose';
  return axios.post(dataUrl, {
    leadIds: ids
  });
};

export const deleteProfileById = (
  id: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.base + '/api/v1/profile/' + id;
  return axios.delete(dataUrl);
};

export const cloneProfileById = (
  id: string,
  type: ProfileTypes,
  label?: string
): Promise<AxiosResponse<Profile>> => {
  const dataUrl = ApiUrls.base + '/api/v1/profile/clone/' + id;
  return axios.post(dataUrl, { label, type });
};
export const deleteTeamById = (id: number): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.base + '/api/v1/team/' + id;
  return axios.delete(dataUrl);
};

export const deleteEmployeeById = (
  id: number
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.base + '/api/v1/team-member/' + id;
  return axios.delete(dataUrl);
};

/////

export const getUsers = (): Promise<AxiosResponse<User[]>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/user/all';
  return axios.get(dataUrl);
};

export const getUserById = (id: number): Promise<AxiosResponse<User>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/user/id/' + id;
  return axios.get(dataUrl);
};

export const saveUser = (req: User): Promise<AxiosResponse<void>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/user';
  return axios.post(dataUrl, req);
};

export const deleteUserById = (id: number): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/user/' + id;
  return axios.delete(dataUrl);
};

export const checkAccountExistsByEmail = (
  email: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/user/exists/email/' + email;
  return axios.get(dataUrl);
};

export const checkMemberExistsByEmail = (
  email: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.base + '/api/v1/team-member/exists/email/' + email;
  return axios.get(dataUrl);
};

export const saveCard = (
  model: DigitalCard
): Promise<AxiosResponse<DigitalCard>> => {
  const req: CreateCardRequest = {
    card: model
  };
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card';
  if (req.card.id > 0) return axios.put(dataUrl, req);
  else return axios.post(dataUrl, req);
};

export const deleteCardById = (id: number): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/id/' + id;
  return axios.delete(dataUrl);
};

export const uploadContacts = (
  uploadedFile: any
): Promise<AxiosResponse<void>> => {
  const dataUrl = ApiUrls.base + '/api/v1/contact/upload';
  const form = new FormData();
  form.set('file', uploadedFile);
  return axios.post(dataUrl, form);
};

export const getCardApps = (): Promise<AxiosResponse<CardApp[]>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/card-apps/all';
  return axios.get(dataUrl);
};

export const addOrUpdateAppToCard = (
  model: CardAppItem
): Promise<AxiosResponse<CardAppItem>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/add-card-app';
  return axios.post(dataUrl, model);
};

export const removeCardApp = (
  cardId: number,
  appId: number
): Promise<AxiosResponse<CardAppItem>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/remove-card-app';
  return axios.post(dataUrl, { digitalCardId: cardId, cardAppId: appId });
};

export const saveEmployeeAccountSetting = (
  setting: EmployeeAccountSetting
): Promise<AxiosResponse<EmployeeAccountSetting>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/employee/account-setting';
  return axios.put(dataUrl, setting);
};

export const createBusinessCardForNewEmployee = (
  employeeId: number
): Promise<AxiosResponse<DigitalCard>> => {
  const dataUrl =
    ApiUrls.accountEndpoint + '/employee/create-card/new/' + employeeId;
  return axios.post(dataUrl);
};

export const keepAlive = (): Promise<AxiosResponse> => {
  const dataUrl = ApiUrls.accountEndpoint + '/keep-alive';
  return axios.get(dataUrl);
};

export const getNfcMaps = (): Promise<AxiosResponse<NfcCodeMap[]>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/my-cards';
  return axios.get(dataUrl);
};

export const resetNfcCard = (
  nfcCode: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/nfc-card/reset';
  return axios.post(dataUrl, { nfcCode: nfcCode });
};

export const getActiveSubscription = (
  token: string
): Promise<AxiosResponse<ActiveSubscription>> => {
  const dataUrl = ApiUrls.cardApi + '/subscription/active';
  return axios.get(dataUrl, {
    headers: {
      token
    }
  });
};

export const getPlanProducts = (): Promise<AxiosResponse<PlanProduct[]>> => {
  const dataUrl = ApiUrls.cardApi + '/subscription/all-products';
  return axios.get(dataUrl);
};
