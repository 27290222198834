const getExtFromDataUrl = (dataUrl: string): string => {
  if (!dataUrl || !dataUrl.startsWith('data:image'))
    return '';

  if (dataUrl.startsWith('data:image/jpeg;'))
    return '.jpg';
  else if (dataUrl.startsWith('data:image/png;'))
    return '.png';
  else if (dataUrl.startsWith('data:image/heif;'))
    return '.heic';
  else if (dataUrl.startsWith('data:image/webp'))
    return '.webp';

  return dataUrl.startsWith('data:image') ? '.png' : '';
};
export default getExtFromDataUrl;