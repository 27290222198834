import { useTheme } from '@mui/material';
import {
  AddressFieldValue,
  TitledFieldValue
} from 'src/services/apiService/response-models';
import { FieldRenderBlockProp } from '../..';
import getMultiFieldValue from '../../_utils/getMultiFieldValue';
import ListAvatarBlock from '../../_utils/list-avatar-block';

export default function AddressBlockRenderer(props: FieldRenderBlockProp) {
  const theme = useTheme();

  const value = getMultiFieldValue<TitledFieldValue<AddressFieldValue>>(
    props,
    props.data.addresses
  );

  const getAddressOrEmptyString = (address: AddressFieldValue) => {
    return address
      ? `${address.addressLine} ${address.addressLine2} ${address.city} ${address.state} ${address.zip} ${address.country}`
      : '';
  };
  return (
    <ListAvatarBlock
      title={value?.title}
      value={getAddressOrEmptyString(value?.value)}
      e={props}
    />
  );
}
