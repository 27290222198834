import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Slide,
  styled,
  Typography
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const AvatarInfo = styled(Avatar)(
  ({ theme }) => `
      background: ${theme.colors.info.lighter};
      color: ${theme.colors.info.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonInfo = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.info.main};
     color: ${theme.palette.info.contrastText};

     &:hover {
        background: ${theme.colors.info.dark};
     }
    `
);

function ConfirmDialog(props: {
  inProgress?: boolean;
  message?: string;
  id?: string;
  open: boolean;
  onClose: () => void;
  onConfirm: (id?: string) => void;
}) {
  const closeConfirmDelete = () => {
    props.onClose();
  };

  const handleDeleteCompleted = () => {
    props.onConfirm(props.id);
  };

  return (
    <DialogWrapper
      open={props.open}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={closeConfirmDelete}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        p={2}
      >
        <Typography
          align="center"
          sx={{
            py: 3,
            px: 5
          }}
          variant="h3"
        >
          {props.message}
        </Typography>

        <Box>
          <Button
            variant="text"
            size="large"
            sx={{
              mx: 1
            }}
            onClick={closeConfirmDelete}
          >
            {'No'}
          </Button>
          <ButtonInfo
            onClick={handleDeleteCompleted}
            disabled={props.inProgress === true}
            size="large"
            sx={{
              mx: 1,
              px: 3
            }}
            variant="contained"
            startIcon={
              props.inProgress === true ? (
                <CircularProgress size="1rem" />
              ) : null
            }
          >
            {'Yes'}
          </ButtonInfo>
        </Box>
      </Box>
    </DialogWrapper>
  );
}

export default ConfirmDialog;
