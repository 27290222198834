import axios, { AxiosResponse } from 'axios';
import { Note } from 'src/CRM/models/note';
import { Task } from 'src/CRM/models/task';
import PaginatedDataResult from '../apiService/response-models';
import { ApiUrls } from '../endpoints';

export interface TaskResponse extends Task {}

export interface NotesResponse {
  notes: Note;
}

export const createTask = (
  task: Task
): Promise<AxiosResponse<TaskResponse>> => {
  const dataUrl = ApiUrls.base + '/api/crm/v1/tasks';
  return axios.post(dataUrl, task);
};

export const updateTask = (
  task: Task
): Promise<AxiosResponse<TaskResponse>> => {
  const dataUrl = ApiUrls.base + '/api/crm/v1/tasks/' + task.id;
  return axios.put(dataUrl, task);
};

export const getAllAssociatedTask = (
  entityId: string,
  entityType: string
): Promise<AxiosResponse<PaginatedDataResult<Task>>> => {
  const dataUrl =
    ApiUrls.base + '/api/crm/v1/tasks/all/' + entityType + '/' + entityId;
  return axios.get(dataUrl);
};

export const createNote = (note: Note): Promise<AxiosResponse<Note>> => {
  const dataUrl = ApiUrls.base + '/api/crm/v1/notes';
  return axios.post(dataUrl, note);
};

export const updateNote = (note: Note): Promise<AxiosResponse<Note>> => {
  const dataUrl = ApiUrls.base + '/api/crm/v1/notes/' + note.id;
  return axios.put(dataUrl, note);
};

export const deleteNoteApi = (
  noteToDeleteId: string
): Promise<AxiosResponse<NotesResponse>> => {
  const dataUrl = ApiUrls.base + '/api/crm/v1/notes/' + noteToDeleteId;
  return axios.delete(dataUrl);
};

export const deleteTaskApi = (
  taskToDeleteId: string
): Promise<AxiosResponse<TaskResponse>> => {
  const dataUrl = ApiUrls.base + '/api/crm/v1/tasks/' + taskToDeleteId;
  return axios.delete(dataUrl);
};

export const getAllAssociatedNotes = (
  entityId: string,
  entityType: string
): Promise<AxiosResponse<PaginatedDataResult<Note>>> => {
  const dataUrl =
    ApiUrls.base + '/api/crm/v1/notes/all/' + entityType + '/' + entityId;
  return axios.get(dataUrl);
};

export const updateTaskStatus = (
  taskId: string,
  status: string
): Promise<AxiosResponse<TaskResponse>> => {
  const dataUrl = ApiUrls.base + '/api/crm/v1/tasks/updateTaskStatus/' + taskId;
  return axios.put(dataUrl, { status });
};
