import { Box, Dialog, Tab, Tabs, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getTeams } from 'src/services/apiService';
import { QKEY, getMembers } from 'src/services/query-client';
import MembersTab from './members-tab';
import TeamsTab from './teams-tab';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);

const Results = (props: {
  teamDataTriggerId?: string;
  openTeamEditDialogCallback: () => void;
  activeTab?: string;
}) => {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState<string>(
    props.activeTab || 'members'
  );
  const handleTabsChange = (_event: SyntheticEvent, tabsValue: string) => {
    setCurrentTab(tabsValue as any);
  };

  const tabs = [
    {
      value: 'members',
      label: t('Members')
    },
    {
      value: 'teams',
      label: t('All teams')
    }
  ];

  const {
    data: { data: teams = [] } = {},
    isError,
    error,
    refetch
  } = useQuery(QKEY.Teams, getTeams);

  const { data: { data: members = [] } = {}, isLoading } = useQuery(
    QKEY.Members,
    getMembers
  );

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >
        <TabsWrapper
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="secondary"
          value={currentTab}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </TabsWrapper>
        {/*<ToggleButtonGroup*/}
        {/*  sx={{*/}
        {/*    mt: { xs: 2, sm: 0 }*/}
        {/*  }}*/}
        {/*  value={toggleView}*/}
        {/*  exclusive*/}
        {/*  onChange={handleViewOrientation}*/}
        {/*>*/}
        {/*  <ToggleButton disableRipple value="table_view">*/}
        {/*    <TableRowsTwoToneIcon />*/}
        {/*  </ToggleButton>*/}
        {/*  <ToggleButton disableRipple value="grid_view">*/}
        {/*    <GridViewTwoToneIcon />*/}
        {/*  </ToggleButton>*/}
        {/*</ToggleButtonGroup>*/}
      </Box>
      {currentTab == 'members' && (
        <MembersTab isLoading={isLoading} employees={members} />
      )}
      {currentTab == 'teams' && (
        <TeamsTab
          isLoading={isLoading}
          teams={teams}
          onTeamEditCallback={props.openTeamEditDialogCallback}
        />
      )}
    </>
  );
};

export default Results;
