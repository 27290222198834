import { Dictionary } from 'lodash';
import { ReactElement } from 'react';
import {
  ProfileData,
  ProfileField,
  ProfileFieldNames
} from 'src/services/apiService/response-models';
import AddressBlockRenderer from './FieldRenderers/Addresses/block';
import AddressBlockEditorRenderer from './FieldRenderers/Addresses/editor';
import BusinessNameBlockRenderer from './FieldRenderers/BusinessName/block';
import BusinessNameEditorRenderer from './FieldRenderers/BusinessName/editor';
import CalendlyBlockRenderer from './FieldRenderers/Calendly/block';
import CalendlyEditorRenderer from './FieldRenderers/Calendly/editor';
import CompanyNameBlockRenderer from './FieldRenderers/CompanyName/block';
import CompanyNameEditorRenderer from './FieldRenderers/CompanyName/editor';
import ContactNumberBlockRenderer from './FieldRenderers/ContactNumber/block';
import ContactNumberEditorRenderer from './FieldRenderers/ContactNumber/editor';
import CoverPhotoBlock from './FieldRenderers/CoverPhoto/block';
import CoverPhotoEditorRenderer from './FieldRenderers/CoverPhoto/editor';
import DepartmentBlockRenderer from './FieldRenderers/Department/block';
import DepartmentEditorRenderer from './FieldRenderers/Department/editor';
import DiscordBlockRenderer from './FieldRenderers/Discord/block';
import DiscordEditorRenderer from './FieldRenderers/Discord/editor';
import EmailBlockRenderer from './FieldRenderers/Email/block';
import EmailEditorRenderer from './FieldRenderers/Email/editor';
import FacebookBlockRenderer from './FieldRenderers/Facebook/block';
import FacebookEditorRenderer from './FieldRenderers/Facebook/editor';
import FullNameBlockRenderer from './FieldRenderers/FullName/block';
import FullNameEditorRenderer from './FieldRenderers/FullName/editor';
import GoogleReviewBlockRenderer from './FieldRenderers/GoogleReview/block';
import GoogleReviewEditorRenderer from './FieldRenderers/GoogleReview/editor';
import HeadlineBlockRenderer from './FieldRenderers/Headline/block';
import HeadlineEditorRenderer from './FieldRenderers/Headline/editor';
import ImageSliderBlock from './FieldRenderers/ImageSliderOld/block';
import ImageSliderEditor from './FieldRenderers/ImageSliderOld/editor';
import InstagramBlockRenderer from './FieldRenderers/Instagram/block';
import InstagramEditorRenderer from './FieldRenderers/Instagram/editor';
import JobTitleBlockRenderer from './FieldRenderers/JobTitile/block';
import JobTitleEditorRenderer from './FieldRenderers/JobTitile/editor';
import LinkBlockRenderer from './FieldRenderers/Link/block';
import LinkEditorRenderer from './FieldRenderers/Link/editor';
import LinkedInBlockRenderer from './FieldRenderers/LinkedIn/block';
import LinkedInEditorRenderer from './FieldRenderers/LinkedIn/editor';
import LogoBlockRenderer from './FieldRenderers/Logo/block';
import LogoEditorRenderer from './FieldRenderers/Logo/editor';
import MediaPlayerBlock from './FieldRenderers/MediaPlayer/block';
import MediaPlayerEditor from './FieldRenderers/MediaPlayer/editor';
import ProfilePicBlockRenderer from './FieldRenderers/ProfilePic/block';
import ProfilePicEditorRenderer from './FieldRenderers/ProfilePic/editor';
import SignalBlockRenderer from './FieldRenderers/Signal/block';
import SignalEditorRenderer from './FieldRenderers/Signal/editor';
import SkypeBlockRenderer from './FieldRenderers/Skype/block';
import SkypeEditorRenderer from './FieldRenderers/Skype/editor';
import SnapChatBlockRenderer from './FieldRenderers/Snapchat/block';
import SnapChatEditorRenderer from './FieldRenderers/Snapchat/editor';
import TelegramBlockRenderer from './FieldRenderers/Telegram/block';
import TelegramEditorRenderer from './FieldRenderers/Telegram/editor';
import TwitchBlockRenderer from './FieldRenderers/Twitch/block';
import TwitchEditorRenderer from './FieldRenderers/Twitch/editor';
import TwitterBlockRenderer from './FieldRenderers/Twitter/block';
import TwitterEditorRenderer from './FieldRenderers/Twitter/editor';
import WebsiteBlockRenderer from './FieldRenderers/Website/block';
import WebsiteEditorRenderer from './FieldRenderers/Website/editor';
import WhatsAppBlockRenderer from './FieldRenderers/WhatsApp/block';
import WhatsAppEditorRenderer from './FieldRenderers/WhatsApp/editor';
import YoutubeBlockRenderer from './FieldRenderers/Youtube/block';
import YoutubeEditorRenderer from './FieldRenderers/Youtube/editor';
import fieldValueAt, { fieldValueAt2 } from './_utils/fieldValueAt';

export interface FieldRenderEditorProp {
  field: ProfileField;
  isReadOnly?: boolean;
  theme?: string;
  data: ProfileData;
  valueIndex?: number;
  onCancel: () => void;
  onDelete: () => void;
  onSave: (e: ProfileData) => void;
}
export interface FieldRenderBlockProp {
  field: ProfileField;
  isReadOnly?: boolean;
  valueIndex?: number;
  theme?: string;
  data: ProfileData;
}
export enum FieldBlockTypes {
  Fixed = 'Fixed',
  BtnGroup = 'BtnGroup',
  ListItem = 'ListItem',
  Image = ''
}
export interface FieldUtil {
  hasValue: (e: ProfileData, index?: number) => boolean;
  value: (e: ProfileData, index?: number) => any;
  clearValue: (e: ProfileData, index?: number) => void;
  fieldType: () => FieldBlockTypes;
}

interface FieldRendererBase {
  renderEditor: (props: FieldRenderEditorProp) => ReactElement;
  renderBlock: (props: FieldRenderBlockProp) => ReactElement;
  fieldUtil: FieldUtil;
}

const Build = (): Dictionary<FieldRendererBase> => {
  const registry: Dictionary<FieldRendererBase> = {};

  registry[ProfileFieldNames.FullName] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <FullNameEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <FullNameBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e) => e.fullName?.length > 0,
      value: (e) => e.fullName,
      clearValue: (e) => delete e.fullName,
      fieldType: () => FieldBlockTypes.Fixed
    }
  };

  registry[ProfileFieldNames.ProfilePic] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <ProfilePicEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <ProfilePicBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e) => e.profileImage?.length > 0,
      clearValue: (e) => delete e.profileImage,
      value: (e) => e.profileImage,
      fieldType: () => FieldBlockTypes.Fixed
    }
  };

  registry[ProfileFieldNames.Logo] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <LogoEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <LogoBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e) => e.logoImage?.length > 0,
      clearValue: (e) => delete e.logoImage,
      value: (e) => e.logoImage,
      fieldType: () => FieldBlockTypes.Fixed
    }
  };

  registry[ProfileFieldNames.CoverPhoto] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <CoverPhotoEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <CoverPhotoBlock {...props} />
    ),
    fieldUtil: {
      hasValue: (e) => e.coverImage?.length > 0,
      clearValue: (e) => delete e.coverImage,
      value: (e) => e.coverImage,
      fieldType: () => FieldBlockTypes.Fixed
    }
  };

  registry[ProfileFieldNames.JobTitle] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <JobTitleEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <JobTitleBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e) => e.jobTitle?.length > 0,
      clearValue: (e) => delete e.jobTitle,
      value: (e) => e.jobTitle,
      fieldType: () => FieldBlockTypes.Fixed
    }
  };

  registry[ProfileFieldNames.Department] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <DepartmentEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <DepartmentBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e) => e.department?.length > 0,
      value: (e) => e.department,
      clearValue: (e) => delete e.department,
      fieldType: () => FieldBlockTypes.Fixed
    }
  };

  registry[ProfileFieldNames.CompanyName] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <CompanyNameEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <CompanyNameBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e) => e.companyName?.length > 0,
      value: (e) => e.companyName,
      clearValue: (e) => delete e.companyName,
      fieldType: () => FieldBlockTypes.Fixed
    }
  };

  registry[ProfileFieldNames.BusinessName] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <BusinessNameEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <BusinessNameBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e) => e.businessName?.length > 0,
      value: (e) => e.businessName,
      clearValue: (e) => delete e.businessName,
      fieldType: () => FieldBlockTypes.Fixed
    }
  };

  registry[ProfileFieldNames.Headline] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <HeadlineEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <HeadlineBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => e.headline?.length > 0,
      value: (e) => e.headline,
      clearValue: (e) => delete e.headline,
      fieldType: () => FieldBlockTypes.Fixed
    }
  };

  registry[ProfileFieldNames.Email] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <EmailEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <EmailBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => Boolean(fieldValueAt(e.emails, i)),
      value: (e, i) => fieldValueAt(e.emails, i),
      clearValue: (e, i) => {
        const v = e.emails ? e.emails.splice(i, 1) : delete e.emails;
      },
      fieldType: () => FieldBlockTypes.ListItem
    }
  };

  registry[ProfileFieldNames.ContactNumber] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <ContactNumberEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <ContactNumberBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => Boolean(fieldValueAt(e.contactNumbers, i)),
      value: (e, i) => fieldValueAt(e.contactNumbers, i),
      clearValue: (e, i) => {
        const v = e.contactNumbers
          ? e.contactNumbers.splice(i, 1)
          : delete e.contactNumbers;
      },
      fieldType: () => FieldBlockTypes.ListItem
    }
  };

  registry[ProfileFieldNames.Address] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <AddressBlockEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <AddressBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => Boolean(fieldValueAt2(e.addresses, i)),
      value: (e, i) => fieldValueAt2(e.addresses, i),
      clearValue: (e, i) => {
        const v = e.addresses ? e.addresses.splice(i, 1) : delete e.addresses;
      },
      fieldType: () => FieldBlockTypes.ListItem
    }
  };
  registry[ProfileFieldNames.MediaPlayer] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <MediaPlayerEditor {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <MediaPlayerBlock {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => Boolean(fieldValueAt(e.mediaList, i)),
      value: (e, i) => fieldValueAt(e.mediaList, i),
      clearValue: (e, i) => {
        const v = e.mediaList ? e.mediaList.splice(i, 1) : delete e.mediaList;
      },
      fieldType: () => FieldBlockTypes.ListItem
    }
  };

  registry[ProfileFieldNames.ImageSlider] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <ImageSliderEditor {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <ImageSliderBlock {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => Boolean(fieldValueAt2(e.imageList, i)),
      value: (e, i) => fieldValueAt2(e.imageList, i),
      clearValue: (e, i) => {
        const v = e.imageList ? e.imageList.splice(i, 1) : delete e.imageList;
      },
      fieldType: () => FieldBlockTypes.ListItem
    }
  };

  registry[ProfileFieldNames.Website] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <WebsiteEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <WebsiteBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => Boolean(fieldValueAt(e.websites, i)),
      value: (e, i) => fieldValueAt(e.websites, i),
      clearValue: (e, i) => {
        const v = e.websites ? e.websites.splice(i, 1) : delete e.websites;
      },
      fieldType: () => FieldBlockTypes.ListItem
    }
  };

  registry[ProfileFieldNames.Link] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <LinkEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <LinkBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => Boolean(fieldValueAt(e.links, i)),
      value: (e, i) => fieldValueAt(e.links, i),
      clearValue: (e, i) => {
        const v = e.links ? e.links.splice(i, 1) : delete e.links;
      },
      fieldType: () => FieldBlockTypes.ListItem
    }
  };

  registry[ProfileFieldNames.GoogleReview] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <GoogleReviewEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <GoogleReviewBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => e.googleReview?.value?.length > 0,
      value: (e) => e.googleReview?.value,
      clearValue: (e) => delete e.googleReview,
      fieldType: () => FieldBlockTypes.ListItem
    }
  };

  registry[ProfileFieldNames.Calendly] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <CalendlyEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <CalendlyBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => e.calendly?.value?.length > 0,
      value: (e) => e.calendly?.value,
      clearValue: (e) => delete e.calendly,
      fieldType: () => FieldBlockTypes.ListItem
    }
  };

  registry[ProfileFieldNames.LinkedIn] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <LinkedInEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <LinkedInBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => e.linkedIn?.value?.length > 0,
      value: (e) => e.linkedIn?.value,
      clearValue: (e) => delete e.linkedIn,
      fieldType: () => FieldBlockTypes.BtnGroup
    }
  };

  registry[ProfileFieldNames.Twitter] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <TwitterEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <TwitterBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => e.twitter?.value?.length > 0,
      value: (e) => e.twitter?.value,
      clearValue: (e) => delete e.twitter,
      fieldType: () => FieldBlockTypes.BtnGroup
    }
  };

  registry[ProfileFieldNames.Instagram] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <InstagramEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <InstagramBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => e.instagram?.value?.length > 0,
      value: (e) => e.instagram?.value,
      clearValue: (e) => delete e.instagram,
      fieldType: () => FieldBlockTypes.BtnGroup
    }
  };

  registry[ProfileFieldNames.Facebook] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <FacebookEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <FacebookBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => e.facebook?.value?.length > 0,
      value: (e) => e.facebook?.value,
      clearValue: (e) => delete e.facebook,
      fieldType: () => FieldBlockTypes.BtnGroup
    }
  };

  registry[ProfileFieldNames.YouTube] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <YoutubeEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <YoutubeBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => e.youtube?.value?.length > 0,
      value: (e) => e.youtube?.value,
      clearValue: (e) => delete e.youtube,
      fieldType: () => FieldBlockTypes.BtnGroup
    }
  };

  registry[ProfileFieldNames.Twitch] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <TwitchEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <TwitchBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => e.twitch?.value?.length > 0,
      value: (e) => e.twitch?.value,
      clearValue: (e) => delete e.twitch,
      fieldType: () => FieldBlockTypes.BtnGroup
    }
  };

  registry[ProfileFieldNames.Snapchat] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <SnapChatEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <SnapChatBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => e.snapChat?.value?.length > 0,
      value: (e) => e.snapChat?.value,
      clearValue: (e) => delete e.snapChat,
      fieldType: () => FieldBlockTypes.BtnGroup
    }
  };

  registry[ProfileFieldNames.WhatsApp] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <WhatsAppEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <WhatsAppBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => e.whatsApp?.value?.length > 0,
      value: (e) => e.whatsApp?.value,
      clearValue: (e) => delete e.whatsApp,
      fieldType: () => FieldBlockTypes.ListItem
    }
  };

  registry[ProfileFieldNames.Telegram] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <TelegramEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <TelegramBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => e.telegram?.value?.length > 0,
      value: (e) => e.telegram?.value,
      clearValue: (e) => delete e.telegram,
      fieldType: () => FieldBlockTypes.ListItem
    }
  };

  registry[ProfileFieldNames.Signal] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <SignalEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <SignalBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => e.signal?.value?.length > 0,
      value: (e) => e.signal?.value,
      clearValue: (e) => delete e.signal,
      fieldType: () => FieldBlockTypes.ListItem
    }
  };

  registry[ProfileFieldNames.Discord] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <DiscordEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <DiscordBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => e.discord?.value?.length > 0,
      value: (e) => e.discord?.value,
      clearValue: (e) => delete e.discord,
      fieldType: () => FieldBlockTypes.ListItem
    }
  };

  registry[ProfileFieldNames.Skype] = {
    renderEditor: (props: FieldRenderEditorProp): ReactElement => (
      <SkypeEditorRenderer {...props} />
    ),
    renderBlock: (props: FieldRenderBlockProp): ReactElement => (
      <SkypeBlockRenderer {...props} />
    ),
    fieldUtil: {
      hasValue: (e, i) => e.skype?.value?.length > 0,
      value: (e) => e.skype?.value,
      clearValue: (e) => delete e.skype,
      fieldType: () => FieldBlockTypes.ListItem
    }
  };
  return registry;
};

export default Build();
