import _ from "lodash";
import FieldsRegistry from "src/components/FieldsRegistry";
import { Profile, ProfileData, ProfileField, ProfileFieldCategory } from "src/services/apiService/response-models";
import { PreviewerField } from "..";

export default function convertProfileToPreviewerFields(fieldCategories: ProfileFieldCategory[], profile: Profile): PreviewerField[] {
  const preFields: PreviewerField[] = [];

  if (!profile || !profile.data)
    return preFields;

  const data = profile.data;

  const fields = _.flatten(fieldCategories.map(o => o.fields));

  const hash = _.groupBy(fields, (o => o.name));

  profile.fields?.map(f => {
    const fieldRef = _.first(hash[f.name]);
    if (fieldRef && fieldHasValue(f, data)) {

      if (fieldRef.multiple == true) {
        //if mulitple
        const values = FieldsRegistry[fieldRef.name]?.fieldUtil.value(data) as any[];
        values.forEach((v, i) => {
          preFields.push({
            preId: fieldRef.name + '_' + i,
            index: i,
            field: fieldRef,
            isEdit: false,
          });
        })
      }
      else {
        preFields.push({
          preId: fieldRef.name,
          field: fieldRef,
          isEdit: false
        });
      }
    }
  });

  return preFields;
}
const fieldHasValue = (e: ProfileField, data: ProfileData): boolean => {
  return FieldsRegistry[e.name]?.fieldUtil.hasValue(data)
};