export const getScroll = (): number[] => {
  if (window.scrollY != undefined) {
    return [scrollX, scrollY];
  } else {
    var sx, sy, d = document,
      r = d.documentElement,
      b = d.body;
    sx = r.scrollLeft || b.scrollLeft || 0;
    sy = r.scrollTop || b.scrollTop || 0;
    return [sx, sy];
  }
}