export default function readQueryString(url?: string) {

    let pairs:string[] = [];

    if (url) {
        const i = url.indexOf('?');
        if (i) {
            pairs = url.substr(i+1).split('&');
        }
    } else {
        pairs = location.search.slice(1).split('&');
    }

    var result = {};
    pairs.filter(o=> o.trim().length).forEach(function (pair) {
        const parts = pair.split('=');
        result[parts[0]] = decodeURIComponent(parts[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
}

