import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import cdnService from 'src/services/cdnService';
import blobToBase64 from 'src/utility/blobToBase64';
import emptyImgUrl from 'src/utility/emptyImgUrl';

export default function CdnImage(props: {
  src: string;
  alt?: string;
  width?: string | number;
  height?: string | number;
  className?: string;
  sx?: SxProps<Theme>;
}) {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const blob = cdnService.blobHash[props.src];
    if (blob) {
      const temp = url;
      blobToBase64(blob).then((o) => {
        setUrl(o);
      });
      setUrl('');
    } else {
      setUrl(cdnService.toImageUrl(props.src));
    }
  }, [props.src]);

  return (
    <img
      src={!url ? emptyImgUrl : url}
      className={props.className}
      alt={props.alt || ''}
      width={props.width}
      height={props.height}
    />
  );
}
