import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import ReactPlayer from 'react-player/youtube';
import { TitledFieldValue } from 'src/services/apiService/response-models';
import { FieldRenderBlockProp } from '../..';
import getMultiFieldValue from '../../_utils/getMultiFieldValue';

export default function MediaPlayerBlock(props: FieldRenderBlockProp) {
  const theme = useTheme();

  const value = getMultiFieldValue<TitledFieldValue<string>>(
    props,
    props.data.mediaList
  );

  return (
    <Card
      style={{ width: '100%' }}
      sx={{
        backgroundColor: 'inherit',
        border: 'solid 1px #ccc',
        overflow: 'hidden'
      }}
    >
      {(value?.title || value?.subTitle) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            color:
              props.theme === 'light'
                ? `${theme.colors.alpha.black[100]}`
                : `${theme.colors.alpha.trueWhite[100]}`
          }}
        >
          <CardContent sx={{ flex: '1 0 auto', pb: '5px!important' }}>
            {value?.title && (
              <Typography component="div" variant="h5">
                {value?.title}
              </Typography>
            )}
            {value?.subTitle && (
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{
                  color:
                    props.theme === 'light'
                      ? `${theme.colors.alpha.black[100]}`
                      : `${theme.colors.alpha.trueWhite[100]}`
                }}
              >
                {value?.subTitle}
              </Typography>
            )}
          </CardContent>
        </Box>
      )}
      {/*<video controls width="250">*/}
      {/*    <source src="/static/sample_960x540.webm" type={"video/webm"}/>*/}
      {/*</video>*/}
      <Box sx={{ width: '100%' }}>
        {!value?.value ? (
          <Paper
            elevation={3}
            sx={{
              height: '200px',
              backgroundColor: '#d1d1d1',
              margin: 'auto',
              textAlign: 'center'
            }}
          >
            <img style={{ margin: '10%' }} src="/static/images/paper.png" />
          </Paper>
        ) : (
          <ReactPlayer
            height={'200px'}
            width={'100%'}
            url={value?.value || ''}
            controls
          />
        )}
      </Box>
    </Card>
  );
}
