import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  alpha,
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  styled,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import Activities from 'src/CRM/components/Activities';
import cdnService from 'src/services/cdnService';
import { getReviewById } from 'src/services/query-client';
import Label from 'src/ui-bloom/components/Label';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';
import { parseApiError } from 'src/utility/parseToApiErrorMessage';
import ErrorPanel from '../../../components/error-panel';
import {
  AddressTypes,
  Contact,
  Review
} from '../../../services/apiService/response-models';
import PageHeader from './page-header';

function ReviewCRM() {
  const { id: reviewId } = useParams();
  const navigate = useNavigate();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: { data: review } = {},
    isLoading,
    isError,
    error
  } = useQuery(['reviews', reviewId], (k) => getReviewById(reviewId));

  if (isError) {
    return (
      <ErrorPanel
        title="Critical error"
        message={parseApiError(error, 'Error on load')}
      />
    );
  }

  if (isLoading) {
    return <LinearProgress sx={{ mt: 2 }} />;
  }

  return (
    <>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Grid container>
        <Grid item xs={12} md={3}>
          {review?.contact ? (
            <ReviewContactSummary contact={review?.contact} />
          ) : (
            <ReviewSummary review={review} />
          )}
        </Grid>
        <Grid item xs={12} md={9}>
          <Activities
            entityType="review"
            entityId={review.id + ''}
            entityName={
              review.contact?.fullName ??
              review.contactFirstName + ' ' + review.contactLastName
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        transform: translateY(0px);
        transition: ${theme.transitions.create(['color', 'transform'])};

        .MuiSvgIcon-root {
            transform: scale(1);
            transition: ${theme.transitions.create(['transform'])};
        }

        &:hover {
            transform: translateY(-2px);
    
            .MuiSvgIcon-root {
                transform: scale(1.3);
            }
        }
  `
);

const getAddressDesc = (e: Contact) => {
  if (!e.addresses) return '';

  let address = e.addresses.find((o) => o.addressType == AddressTypes.Primary);
  if (!address && e.addresses.length) address = e.addresses[0];

  if (address) {
    // return only defined property
    const { addressLine, addressLine2, city, state, country, zip } = address;
    const addressArr = [addressLine, addressLine2, city, state, country, zip];
    const addressStr = addressArr.filter((o) => o).join(', ');
    if (addressStr) return addressStr;
  }

  return '';
};

function ReviewContactSummary({ contact }: { contact: Contact }) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const onGoback = () => {
    navigate('/app/review/list');
  };

  const onEdit = () => {
    navigate('/app/contact/edit/' + contact.id);
  };
  return (
    <>
      <Card
        sx={{
          position: 'relative',
          p: 3,
          pt: 0,
          mx: 1
        }}
      >
        <Box display="flex" justifyContent="space-between" my={2}>
          <Tooltip title={'Go back'}>
            <Button
              onClick={onGoback}
              variant="outlined"
              startIcon={<ArrowBackTwoToneIcon />}
              size="small"
            >
              {t('reviews')}
            </Button>
          </Tooltip>
          {/* <Tooltip title={'Edit review'}>
            <IconButton
              sx={{
                p: 1
              }}
              size="small"
              color="primary"
              onClick={onEdit}
            >
              <EditTwoToneIcon fontSize="small" />
            </IconButton>
          </Tooltip> */}
        </Box>
        <Box display="flex" alignItems="center">
          <Avatar
            variant="rounded"
            sx={{
              width: 95,
              height: 95
            }}
            src={
              cdnService.toImageUrl(contact.imageUrl) ||
              '/static/images/avatars/3.jpg'
            }
          />
          <Box
            sx={{
              width: '100%'
            }}
            ml={1.5}
          >
            <Link
              href={'/app/contact/edit/' + contact.id}
              color="text.primary"
              underline="none"
              sx={{
                transition: `${theme.transitions.create(['color'])}`,
                fontSize: `${theme.typography.pxToRem(17)}`,

                '&:hover': {
                  color: `${theme.colors.primary.main}`
                }
              }}
              variant="h4"
            >
              {contact.fullName}
            </Link>

            <Typography gutterBottom variant="subtitle2"></Typography>
            <Typography gutterBottom variant="subtitle2"></Typography>
            <Box
              display="flex"
              alignItems="center"
              flex={1}
              sx={{
                width: '100%'
              }}
            >
              <Typography
                sx={{
                  pl: 1
                }}
                fontWeight="bold"
                variant="body1"
                textAlign="right"
              >
                <Label color={'info'}>{contact.groupTags?.join(',')}</Label>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Typography variant="subtitle2">{contact.description}</Typography>
        <Box sx={{ my: 2 }}>
          <Card sx={{ my: 2 }}>
            <CardHeader title={t('Tags')} />
            <Divider />
            {contact.groupTags?.map((tag) => (
              <Label key={tag} color={'info'}>
                {tag}
              </Label>
            ))}
          </Card>
        </Box>
        <Box my={2}>
          <Box
            sx={{
              textAlign: 'center'
            }}
          >
            {contact.facebook && (
              <SocialButton link={contact.facebook} icon="fb" />
            )}
            {contact.twitter && (
              <SocialButton link={contact.facebook} icon="tw" />
            )}
            {contact.whatsApp && (
              <SocialButton link={contact.facebook} icon="wa" />
            )}
            {contact.linkedIn && (
              <SocialButton link={contact.facebook} icon="li" />
            )}
          </Box>
        </Box>
        <Card
          elevation={0}
          sx={{
            my: 2,
            background: `${alpha(theme.colors.alpha.black[100], 0.05)}`
          }}
        >
          <List dense>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5'
                }}
                primary={t('Email') + ':'}
              />
              <Typography variant="subtitle1">
                {contact.email || '-'}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5'
                }}
                primary={t('Contact') + ':'}
              />
              <Typography variant="subtitle1">
                {contact.contactNumber || '-'}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5'
                }}
                sx={{ width: '154px' }}
                primary={t('Address') + ':'}
              />
              <Box>
                <Typography variant="subtitle1">
                  {getAddressDesc(contact) || t('-')}
                </Typography>
              </Box>
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5'
                }}
                primary={t('Created on') + ':'}
              />
              <Typography variant="subtitle1">
                {new Date(contact.createdOn).toLocaleDateString()}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5'
                }}
                primary={t('Source') + ':'}
              />
              <Typography variant="subtitle1">
                {contact.source?.code}
              </Typography>
            </ListItem>
          </List>
        </Card>
      </Card>
    </>
  );
}

function ReviewSummary({ review }: { review: Review }) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const onGoback = () => {
    navigate('/app/review/list');
  };

  const onEdit = () => {
    navigate('/app/review/edit/' + review.id);
  };
  return (
    <>
      <Card
        sx={{
          position: 'relative',
          p: 3,
          pt: 0,
          mx: 1
        }}
      >
        <Box display="flex" justifyContent="space-between" my={2}>
          <Tooltip title={'Go back'}>
            <Button
              onClick={onGoback}
              variant="outlined"
              startIcon={<ArrowBackTwoToneIcon />}
              size="small"
            >
              {t('reviews')}
            </Button>
          </Tooltip>
          <Tooltip title={'Edit review'}>
            <IconButton
              sx={{
                p: 1
              }}
              size="small"
              color="primary"
              onClick={onEdit}
            >
              <EditTwoToneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box display="flex" alignItems="center">
          <Avatar
            variant="rounded"
            sx={{
              width: 95,
              height: 95
            }}
            src={'/static/images/avatars/3.jpg'}
          />
          <Box
            sx={{
              width: '100%'
            }}
            ml={1.5}
          >
            <Link
              href={'/app/review/edit/' + review.id}
              color="text.primary"
              underline="none"
              sx={{
                transition: `${theme.transitions.create(['color'])}`,
                fontSize: `${theme.typography.pxToRem(17)}`,

                '&:hover': {
                  color: `${theme.colors.primary.main}`
                }
              }}
              variant="h4"
            >
              {`${review.contactFirstName} ${review.contactLastName}`}
            </Link>

            <Typography gutterBottom variant="subtitle2"></Typography>
            <Typography gutterBottom variant="subtitle2"></Typography>
            <Box
              display="flex"
              alignItems="center"
              flex={1}
              sx={{
                width: '100%'
              }}
            >
              <Typography
                sx={{
                  pl: 1
                }}
                fontWeight="bold"
                variant="body1"
                textAlign="right"
              >
                <Label color={'info'}>{review.tags?.join(',')}</Label>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Typography variant="subtitle2">{review.remark}</Typography>
        <Box sx={{ my: 2 }}>
          <Card sx={{ my: 2 }}>
            <CardHeader title={t('Tags')} />
            <Divider />
            {review.tags?.map((tag) => (
              <Label key={tag} color={'info'}>
                {tag}
              </Label>
            ))}
          </Card>
        </Box>
        <Box my={2}></Box>
        <Card
          elevation={0}
          sx={{
            my: 2,
            background: `${alpha(theme.colors.alpha.black[100], 0.05)}`
          }}
        >
          <List dense>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5'
                }}
                primary={t('Email') + ':'}
              />
              <Typography variant="subtitle1">
                {review.contactEmail || '-'}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5'
                }}
                primary={t('Contact') + ':'}
              />
              <Typography variant="subtitle1">
                {review.contactNumber || '-'}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5'
                }}
                sx={{ width: '154px' }}
                primary={t('Address') + ':'}
              />
              <Box>
                <Typography variant="subtitle1">
                  {review?.contactAddress || t('-')}
                </Typography>
              </Box>
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5'
                }}
                primary={t('Created on') + ':'}
              />
              <Typography variant="subtitle1">
                {new Date(review.createdOn).toLocaleDateString()}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5'
                }}
                primary={t('Rating') + ':'}
              />
              <Typography variant="subtitle1">{review?.rating}</Typography>
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5'
                }}
                primary={t('Source') + ':'}
              />
              <Typography variant="subtitle1">
                {review?.profile?.data?.fullName}
              </Typography>
            </ListItem>
          </List>
        </Card>
        <Card
          elevation={0}
          sx={{
            my: 2,
            background: `${alpha(theme.colors.alpha.black[100], 0.05)}`
          }}
          title="Faq"
        >
          <List dense>
            {review.questions?.map((q, i) => (
              <>
                <ListItem key={q.question}>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'h5'
                    }}
                    primary={'Q' + (i + 1) + ': ' + q.question}
                  />
                </ListItem>

                <Divider />
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'h5'
                    }}
                    primary={'Ans'}
                  />

                  <Typography variant="subtitle1">{q.answer}</Typography>
                </ListItem>
              </>
            ))}
          </List>
        </Card>
      </Card>
    </>
  );
}

function SocialButton(props: {
  link?: string;
  icon: 'fb' | 'tw' | 'wa' | 'li';
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <Tooltip arrow placement="top" title={'click to open'}>
      <IconButtonWrapper
        onClick={() => window.open(props.link, '_blank')}
        sx={{
          boxShadow: `${theme.colors.shadows.primary}`,
          background: `${theme.colors.primary.main}`,
          color: `${theme.palette.getContrastText(theme.colors.primary.main)}`,

          '&:hover': {
            background: `${theme.colors.primary.main}`,
            color: `${theme.palette.getContrastText(theme.colors.primary.main)}`
          },
          borderRadius: 50,
          width: theme.spacing(4),
          height: theme.spacing(4),
          mx: 1
        }}
        color="primary"
        size="large"
      >
        {props.icon == 'fb' && <FacebookIcon fontSize="small" />}
        {props.icon == 'tw' && <TwitterIcon fontSize="small" />}
        {props.icon == 'li' && <LinkedInIcon fontSize="small" />}
        {props.icon == 'wa' && <WhatsAppIcon fontSize="small" />}
      </IconButtonWrapper>
    </Tooltip>
  );
}

export default ReviewCRM;
