import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { Field, FieldProps } from 'formik';
import FeatureImage from '.';

const FeatureImageField = (props: { name: string; title?: string }) => (
  <Field name={props.name}>
    {({
      field,
      form: { isSubmitting, setFieldValue, setFieldTouched, setFieldError },
      meta
    }: FieldProps) => (
      <FormControl>
        {props.title && <FormLabel>{props.title}</FormLabel>}
        <FeatureImage
          onChange={(e) => {
            setFieldValue(field.name, e);
            setFieldTouched(field.name, true);
          }}
          value={field.value}
        />
        {meta.touched && meta.error && (
          <FormHelperText error={true}>{meta.error}</FormHelperText>
        )}
      </FormControl>
    )}
  </Field>
);

export default FeatureImageField;
