import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
  Zoom
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePassword } from '../../../../services/apiService';
import { User } from '../../../../services/apiService/response-models';
import { RootState } from '../../../../store';
import { parseToApiErrorMessage } from '../../../../utility/parseToApiErrorMessage';

const roles = [
  { label: 'Administrator', roleName: 'admin' },
  { label: 'Member', roleName: 'member' }
];

function ResetPwdDialog(props: {
  user: User;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [open, setOpen] = useState(props.isOpen);
  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo>({
    checked: false,
    email: ''
  });
  const currentUser = useSelector((state: RootState) => state.app.user);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const handleSubmit = async () => {
    if (additionalInfo.checked && !additionalInfo.email.length) {
      enqueueSnackbar(t('Please enter additional email'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        TransitionComponent: Zoom
      });
      return;
    }
    try {
      setIsSaveInProgress(true);
      await generatePassword(props.user.userName, additionalInfo.email);
      setIsSaveInProgress(false);

      enqueueSnackbar(t('Email sent successfully'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        TransitionComponent: Zoom
      });
      props.onClose();
    } catch (ex) {
      setIsSaveInProgress(false);
      console.log(ex);
      const msg = parseToApiErrorMessage(ex, `Failed to reset password`);
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            pl: 3
          }}
        >
          <Typography variant="h3" gutterBottom>
            {props.user.displayName}
          </Typography>
        </DialogTitle>
        <Container maxWidth="sm">
          <Card
            sx={{
              mt: 0,
              p: 1,
              mb: 2
            }}
          >
            <Box>
              <Typography
                variant="h2"
                sx={{
                  mb: 1
                }}
              >
                {t('Recover Password')}
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 3
                }}
              >
                {t(
                  'Enter the email used for registration to reset your password.'
                )}
              </Typography>
            </Box>
            <Box textAlign="center">
              <Alert severity="info">
                {t("New password will be sent on user's registered email")}
              </Alert>
              <Typography
                variant="h4"
                color="text.primary"
                fontWeight="normal"
                sx={{
                  mb: 1
                }}
              >
                {props.user.userName}
              </Typography>
            </Box>

            {currentUser.context.isAdmin && (
              <AddtionalEmail
                onChange={(e) => {
                  setAdditionalInfo(e);
                }}
              />
            )}

            <DialogActions
              sx={{
                mt: 3
              }}
            >
              <Button onClick={handleClose} size="large" variant="outlined">
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => handleSubmit()}
                type="button"
                fullWidth
                size="large"
                startIcon={
                  isSaveInProgress ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSaveInProgress}
                variant="contained"
              >
                {'Send new password'}
              </Button>
            </DialogActions>
          </Card>
        </Container>
      </Dialog>
    </>
  );
}

function AddtionalEmail(props: { onChange: (info: AdditionalInfo) => void }) {
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState('');

  return (
    <Paper sx={{ border: 'solid 1px #ccc', p: 2, mt: 3 }}>
      <Box
        alignItems="center"
        display={{ md: 'flex' }}
        justifyContent="space-between"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              name="terms"
              color="primary"
              onChange={(e, flag) => {
                setChecked(flag);
                props.onChange({ checked: flag, email });
              }}
            />
          }
          label={
            <>
              <Typography variant="body2">
                {'Also send new password mail to:'}
              </Typography>
            </>
          }
        />
      </Box>

      <TextField
        fullWidth
        label={'Additional email address'}
        margin="normal"
        name="email"
        sx={{ display: !checked ? 'none' : 'block' }}
        onChange={(e) => {
          setEmail(e.target.value.trim());
          props.onChange({ checked, email });
        }}
        type="email"
        value={email}
        variant="outlined"
      />
    </Paper>
  );
}

interface AdditionalInfo {
  checked: boolean;
  email: string;
}

export default ResetPwdDialog;
