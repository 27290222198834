export default function toFullname(
  firstName?: string,
  middleName?: string,
  lastName?: string
): string {
  if (middleName) {
    return `${firstName || ''} ${middleName || ''} ${lastName || ''}`;
  }
  return `${firstName || ''} ${lastName || ''}`;
}
