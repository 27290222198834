import { CodeItem, TeamMember } from 'src/services/apiService/response-models';

export default function membersToItems(members: TeamMember[]) {
  const items: CodeItem[] = [];
  members.forEach((p) => {
    items.push({
      code: p.id,
      value: `${p?.fullName || p.firstName + ' ' + p.lastName} (${p.email})`
    });
  });
  return items;
}
