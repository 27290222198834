import { ChangeEvent, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Grid,
  Tab,
  Tabs,
  Typography,
  Divider,
  Card,
  Box,
  Button,
  useTheme,
  Avatar,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ActivitiesTab from './activities-tab';
import NotesTab from './notes-tab';
import TasksTab from './tasks-tab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
      padding: 0 ;
      position: relative;
      bottom: -1px;

      .MuiTabs-root {
        height: 44px;
        min-height: 44px;
      }

      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          min-height: 4px;
          height: 4px;
          box-shadow: none;
          bottom: -4px;
          background: none;
          border: 0;

          &:after {
            position: absolute;
            left: 50%;
            width: 28px;
            content: ' ';
            margin-left: -14px;
            background: ${theme.colors.primary.main};
            border-radius: inherit;
            height: 100%;
          }
      }

      .MuiTab-root {
          &.MuiButtonBase-root {
              height: 44px;
              min-height: 44px;
              background: ${theme.colors.alpha.white[50]};
              border: 1px solid ${theme.colors.alpha.black[10]};
              border-bottom: 0;
              position: relative;
              margin-right: ${theme.spacing(1)};
              font-size: ${theme.typography.pxToRem(14)};
              color: ${theme.colors.alpha.black[80]};
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;

              .MuiTouchRipple-root {
                opacity: .1;
              }

              &:after {
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                bottom: 0;
                height: 1px;
                content: '';
                background: ${theme.colors.alpha.black[10]};
              }

              &:hover {
                color: ${theme.colors.alpha.black[100]};
              }
          }

          &.Mui-selected {
              color: ${theme.colors.alpha.black[100]};
              background: ${theme.colors.alpha.white[100]};
              border-bottom-color: ${theme.colors.alpha.white[100]};

              &:after {
                height: 0;
              }
          }
      }
  `
);

function TabsContainer() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const [currentTab, setCurrentTab] = useState<string>('activities');

  const tabs = [
    { value: 'activities', label: t('Activities') },
    { value: 'notes', label: t('Notes') },
    { value: 'tasks', label: t('Tasks') }
  ];

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  return (
    <>
      <TabsContainerWrapper>
        <Tabs
          onChange={handleTabsChange}
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </TabsContainerWrapper>
      <Card variant="outlined">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={0}
          >
            {currentTab === 'activities' && (
              <>
                <Grid item xs={12}>
                  <ActivitiesTab />
                </Grid>
              </>
            )}
            {currentTab === 'notes' && (
              <Grid item xs={12}>
                <NotesTab />
              </Grid>
            )}
            {currentTab === 'tasks' && (
              <Grid item xs={12}>
                <TasksTab />
              </Grid>
            )}
          </Grid>
        </LocalizationProvider>
      </Card>
    </>
  );
}

export default TabsContainer;
