import { Box, Button, Card, CardHeader, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FeatureImage from 'src/components/FeatureImage';
import { FieldRenderEditorProp } from '../..';

export default function CoverPhotoEditorRenderer(props: FieldRenderEditorProp) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const onImageChange = (e: string) => {
    if (!e) {
      props.onDelete();
    } else {
      props.onSave({ ...props.data, coverImage: e });
    }
  };

  return (
    <Card sx={{ px: 1, width: '100%' }}>
      <CardHeader title={props.field.displayName} />
      <FeatureImage
        value={props.data.coverImage || ''}
        onChange={onImageChange}
      />
      <Box my={1} textAlign={'center'}>
        <Button onClick={props.onCancel} size="small">
          Close
        </Button>
      </Box>
    </Card>
  );
}
