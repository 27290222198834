import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import QrCodeTwoToneIcon from '@mui/icons-material/QrCodeTwoTone';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  TextField,
  useTheme
} from '@mui/material';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import QRCodeStyling from 'qr-code-styling';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QrInfo } from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import blobToBase64 from 'src/utility/blobToBase64';
import isDataUrl from 'src/utility/isDataUrl';
import isValidUrl from 'src/utility/isValidUrl';
import textToEllipsis from 'src/utility/textToEllipsis';
import toDataURL from 'src/utility/toDataURL';
import QrEditorDialog from './QrEditorDialog';

export interface QrCodeCardProp {
  qrInfo?: QrInfo;
  placeholder?: string;
  label?: string;
  buildText: (value: string) => Promise<string>;
  validate?: (
    value: string
  ) => Promise<{ hasError: boolean; message?: string }>;
  onChange: (e: QrInfo) => void;
}

function QrCodeCard(props: QrCodeCardProp) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [qrDataImg, setQrDataImg] = useState('');
  const [qrInfo, setQrInfo] = useState<QrInfo>({ ...props.qrInfo });

  useEffect(() => {
    if (props.qrInfo) {
      setQrInfo(props.qrInfo);
      updateQrImage(props.qrInfo.text, props.qrInfo.color, props.qrInfo.logo);
    }
  }, [props.qrInfo]);

  const [qrCodeStylingRef, setQrCodeStylingRef] = useState(
    new QRCodeStyling({
      width: 300,
      height: 300,
      data: '',
      image: '',
      dotsOptions: {
        color: '#ec2727',
        type: 'square'
      },

      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 1
      }
    })
  );

  const debouncedOnChange = useCallback(
    debounce(async (e: QrInfo) => {
      let qrText = await props.buildText(e.code);
      const value = { ...e, text: qrText };
      setQrInfo(value);
      props.onChange(value);
      updateQrImage(qrText, e.color, e.logo).then(() => {});
    }, 500),
    []
  );
  const updateQrImage = async (
    qrText: string,
    color: string,
    logo?: string
  ) => {
    //update  QR image

    if (logo && !isDataUrl(logo)) {
      const url = cdnService.toImageUrl(logo);
      logo = await toDataURL(url, '');
    }
    qrCodeStylingRef.update({
      data: qrText || ' ',
      image: logo ? logo : '',
      dotsOptions: {
        color: color || '#000'
      }
    });

    const qrData = await qrCodeStylingRef.getRawData();
    const imgData = await blobToBase64(qrData);
    setQrDataImg(imgData);
  };

  const onCustomizeClick = () => {
    setIsOpen(true);
  };

  const onRightClick = async () => {
    const txt = await props.buildText(qrInfo.code);
    if (isValidUrl(txt)) window.open(txt, '_blank');
    else alert(txt);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Paper sx={{ p: 1, width: theme.spacing(34) }} elevation={3}>
            <Avatar
              sx={{
                mx: 'auto',
                mb: 0,
                width: theme.spacing(30),
                height: theme.spacing(30)
              }}
              variant="rounded"
              alt="Qr Code"
              src={qrInfo?.code ? qrDataImg : null}
              onContextMenu={() => onRightClick()}
            >
              <BrokenImageIcon />
            </Avatar>
          </Paper>
        </Box>
      </Grid>
      {/*<Typography align="center" variant="h4" gutterBottom>*/}
      {/*    Craig Donin*/}
      {/*</Typography>*/}
      <Grid item xs={12}>
        <FormControl sx={{ p: 5, pb: 1, width: '100%', pt: 1 }}>
          <FormLabel title={qrInfo.text}>
            {(props.label || 'Viewer url') + ': ' + textToEllipsis(qrInfo.text)}
          </FormLabel>
          <TextField
            // label="Profile url"
            placeholder={props.placeholder || 'Your qr code here...'}
            id="Label-size-small"
            value={qrInfo.code ?? ''}
            onChange={(e) => {
              const val = e.target.value;
              // debouncedUniqueProfileCheck(val);
              setQrInfo({ ...qrInfo, code: val });
              debouncedOnChange({ ...qrInfo, code: val });
              //if (!val) {
              //    setValueError('Qr text is required');
              //}
              //else if (!validateProfileText(val)) {
              //    setValueError(
              //        'Qr text can only contain letters, numbers, dashes("-"), underscores ("_") or periods (".").'
              //    );
              //} else {
              //    debouncedUniqueProfileCheck(val);
              //}
            }}
            fullWidth
            inputProps={{ maxLength: 100 }}
          />
        </FormControl>
      </Grid>
      {/*<Typography align="center" variant="subtitle2" gutterBottom>*/}
      {/*    {t('kicard.co/app/price-catalog/123')}*/}
      {/*</Typography>*/}
      <Grid item xs={12}>
        <Box
          sx={{ mt: 1 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            onClick={() => {
              qrCodeStylingRef.download({
                name: qrInfo.text,
                extension: 'png'
              });
            }}
            size="small"
            variant="contained"
            startIcon={<FileDownloadTwoToneIcon />}
          >
            Download
          </Button>
          <Button
            onClick={onCustomizeClick}
            size="small"
            sx={{ ml: 2 }}
            variant="contained"
            startIcon={<QrCodeTwoToneIcon />}
          >
            Customize QR
          </Button>
          {/*<Tooltip arrow placement="top" title={t('Customize QR')}>*/}
          {/*    <IconButton*/}
          {/*        color="primary"*/}
          {/*        sx={{*/}
          {/*            mx: 0.5*/}
          {/*        }}*/}
          {/*        onClick={onCustomizeClick}*/}
          {/*    >*/}
          {/*        <QrCodeTwoToneIcon />*/}
          {/*    </IconButton>*/}
          {/*</Tooltip>*/}
          {isOpen && (
            <QrEditorDialog
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              title={'Customize QR'}
              qrConfig={qrInfo}
              onSave={(e) => {
                setIsOpen(false);
                const newQr = { ...qrInfo, color: e.color, logo: e.logo };
                setQrInfo(newQr);
                updateQrImage(newQr.text, newQr.color, newQr.logo);
                props.onChange(newQr);
              }}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default QrCodeCard;
