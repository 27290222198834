import { alpha, styled } from '@mui/material';

export const FieldWrapper = styled('div')(
  ({ theme }) => `
    width:'100%';
    margin-top:${theme.spacing(1)};
    margin-bottom:${theme.spacing(1)};
 position: relative;

 .MuiActionButtons {
            background: ${alpha(theme.colors.alpha.white[100], 0.95)};
            border-radius: ${theme.general.borderRadius};
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
            top: 50%;
            margin-top: -${theme.spacing(3.5)};
            position: absolute;
            z-index:10;
            right: ${theme.spacing(1.5)};
            padding: ${theme.spacing(0.5, 1)};
            transition: ${theme.transitions.create(['visibility', 'opacity'])};
            .MuiIconButton-root {
                border-radius: 100px;
                width: ${theme.spacing(5)};
                height: ${theme.spacing(5)};
                margin: ${theme.spacing(0.5)};
            }
      }

      &:hover {
          background: ${alpha(theme.colors.secondary.main, 0.15)};

          .MuiActionButtons {
            visibility: visible;
            opacity: 1;
      }
    
`
);
