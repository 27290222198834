import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cloneProfileById } from 'src/services/apiService';
import { ProfileTypes } from 'src/services/apiService/response-models';
import { parseApiError } from 'src/utility/parseToApiErrorMessage';
import { ProfileCloneHelper } from 'src/utility/profileCloneHelper';

export function ProfileCloneDialog(props: {
  title: string;
  profileId: string;
  profileTyepe: ProfileTypes;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [label, setLabel] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    setOpen(Boolean(props.profileId?.length));
    setType(ProfileTypes.Business ? 'company' : 'member');
  }, [props.profileId]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleConfirm = async () => {
    try {
      if (isInProgress) return;

      setIsInProgress(true);
      const res = await cloneProfileById(
        props.profileId,
        props.profileTyepe,
        label
      );
      handleClose();

      const key = Date.now() + '';
      ProfileCloneHelper.hash[key] = res.data;
      navigate(
        '/app/' +
          type +
          '/profile-editor?cid=' +
          key +
          '&ret=' +
          window.location.pathname
      );
    } catch (ex) {
      const msg = parseApiError(ex, `Failed to clone Profile`);
      enqueueSnackbar(msg, { variant: 'error' });
    }
    setIsInProgress(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Clone - {props.title}</DialogTitle>
        <Divider />
        <DialogContent>
          <FormControl sx={{ my: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Profile Type</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={type}
              label="Profile Type"
              onChange={handleChange}
            >
              <MenuItem value={'company'}>Company Profile</MenuItem>
              <MenuItem value={'member'}>Member Profile</MenuItem>
            </Select>
            <FormHelperText>
              Select profile type you want to clone to
            </FormHelperText>
          </FormControl>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="label"
            label="Profile label (optional)"
            type="text"
            fullWidth
            variant="standard"
            value={label}
            helperText={
              'Optionally, you can enter a label for your new profile.'
            }
            onChange={(e) => setLabel(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} disabled={isInProgress} type="button">
            Clone
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
