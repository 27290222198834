import NoteAddTwoToneIcon from '@mui/icons-material/NoteAddTwoTone';
import { Box, Button, Drawer, Grid, useTheme } from '@mui/material';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Note } from 'src/CRM/models/note';
import { RootState } from '../../../../store';
import {
  closeDrawerPanel,
  openDrawerPanel
} from '../../../../store/slices/app';
import ActivitiesContext from '../activities-context';
import NoteEditor from '../note-editor';
import NotesFilter from '../note-filter';
import NotesList from '../notes-list';

function NotesTab() {
  const { addNote } = useContext(ActivitiesContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isDrawerOpen } = useSelector((state: RootState) => state.app);
  const noteToEdit = useSelector((state: RootState) => state.app.noteToEdit);
  const closeDrawer = (): void => {
    dispatch(closeDrawerPanel());
  };
  const onNoteSave = (note: Note, isEdit: boolean): void => {
    dispatch(closeDrawerPanel());
    console.log(note);
    addNote(note, isEdit);
  };

  const handleAddClick = (): void => {
    dispatch(openDrawerPanel());
  };

  return (
    <Box p={1}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          Filter by: <NotesFilter />
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          <Button
            sx={{
              mt: { xs: 2, md: 0 }
            }}
            onClick={handleAddClick}
            variant="contained"
            color="primary"
            startIcon={<NoteAddTwoToneIcon fontSize="small" />}
          >
            {'Add note'}
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ pt: 2 }}>
          <NotesList />
        </Grid>
      </Grid>
      <Drawer
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'left' : 'right'}
        onClose={closeDrawer}
        open={isDrawerOpen}
        elevation={9}
      >
        {isDrawerOpen && (
          <NoteEditor
            note={noteToEdit}
            onCancel={closeDrawer}
            onSave={onNoteSave}
          />
        )}
      </Drawer>
    </Box>
  );
}

export default NotesTab;
