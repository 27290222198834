import { Box, Drawer, Grid, IconButton, styled, useTheme } from '@mui/material';
import { useState } from 'react';
import Scrollbar from 'src/ui-bloom/components/Scrollbar';
import PageHeader from './PageHeader';

import Sidebar from './Sidebar';

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Formik } from 'formik';
import { t } from 'i18next';
import { useQuery } from 'react-query';
import { QKEY, getRefData } from 'src/services/query-client';
import * as Yup from 'yup';
import AdditionalInfo from './AdditionalInfo';
import GeneralSection from './GeneralSection';

const DrawerWrapper = styled(Drawer)(
  ({ theme }) => `
    width: 400px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 400px;
        height: calc(100% - ${theme.header.height});
        position: absolute;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.alpha.white[10]};
    }
`
);

const DrawerWrapperMobile = styled(Drawer)(
  ({ theme }) => `
    width: 360px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 360px;
        z-index: 3;
        background: ${theme.colors.alpha.white[30]};
  }
`
);

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

function ItemEditor() {
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = useState(false);

  const {
    data: { data: refData } = {},
    isError,
    error,
    isLoading: isRefDataLoading
  } = useQuery(QKEY.RefData, getRefData, {});

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const tagOptions = [];
  const sidebarContent = (
    <Scrollbar>
      <Sidebar
        categories={
          refData?.equipmentCategories || [
            {
              name: 'Cardio Machines',
              id: 'Cardio',
              subCategories: [
                {
                  name: 'Cross Trainers',
                  id: 'CT'
                },
                {
                  name: 'Recline Bikes',
                  id: 'RB'
                },
                {
                  name: 'Treadmills',
                  id: 'TM'
                }
              ]
            },
            {
              name: 'Free Weights',
              id: 'Weights',
              subCategories: [
                {
                  name: 'Dumbbells',
                  id: 'DB'
                },
                {
                  name: 'Fixed Barbells',
                  id: 'FB'
                }
              ]
            },
            {
              name: 'Gym Strength Equipment',
              id: 'Gym Strength Equipment',
              subCategories: [
                {
                  name: 'Gym Benches',
                  id: 'GB'
                },
                {
                  name: 'Gym Racks',
                  id: 'GR'
                },
                {
                  name: 'Outdoor Fitness',
                  id: 'OF'
                }
              ]
            }
          ]
        }
      />
    </Scrollbar>
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().max(250).required(t('The field is required')),
          lastName: Yup.string().max(250).required(t('The field is required')),
          email: Yup.string()
            .max(250)
            .email('Not a proper email')
            .test('Unique Email', 'Email already in use', async (value) => {
              // if (!value || isEdit) return true;
              // const result = await checkAccountExistsByEmail(value);
              return true;
              //!result.data;
            }),
          altEmail: Yup.string().max(250).email('Not a proper email'),

          countryDialCodeWork: Yup.string(),
          countryDialCodePersonal: Yup.string(),
          linkedIn: Yup.string().max(250),
          twitter: Yup.string().max(250),
          facebook: Yup.string().max(250),
          whatsApp: Yup.string().max(250)
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          // const updatedModel: EquipmentDto = {
          //   ..._values,
          // };

          //  save(updatedModel);

          // cdnService.uploadBlobToCloud(updatedModel.imageUrl);

          setStatus({ success: true });
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3} display="flex">
              <MainContentWrapper>
                <Grid
                  sx={{
                    px: 4
                  }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid item xs={12}>
                    <Box
                      mt={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <PageHeader />
                      <Box
                        component="span"
                        sx={{
                          display: { lg: 'none', xs: 'inline-block' }
                        }}
                      >
                        <IconButtonToggle
                          sx={{
                            ml: 2
                          }}
                          color="primary"
                          onClick={handleDrawerToggle}
                          size="small"
                        >
                          <MenuTwoToneIcon />
                        </IconButtonToggle>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <GeneralSection tagOptions={tagOptions} />
                  </Grid>
                  <Grid item xs={12}>
                    <AdditionalInfo />
                  </Grid>
                </Grid>
              </MainContentWrapper>
              <Box
                component="span"
                sx={{
                  display: { lg: 'none', xs: 'inline-block' }
                }}
              >
                <DrawerWrapperMobile
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                >
                  {sidebarContent}
                </DrawerWrapperMobile>
              </Box>
              <Box
                component="span"
                sx={{
                  display: { xs: 'none', lg: 'inline-block' }
                }}
              >
                <DrawerWrapper
                  variant="permanent"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open
                >
                  {sidebarContent}
                </DrawerWrapper>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}

export default ItemEditor;
