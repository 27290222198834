import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  Link,
  Paper,
  Skeleton,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProfileField,
  ProfileFieldCategory
} from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import { Icon } from '../../../components/Icon';
import ProfileEditorContext from '../profile-editor-context';

const PaperWrapper = styled(Paper)(
  ({ theme }) => `
  &:hover {
transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
      }
 `
);

const applySearchFilter = (
  fields: ProfileFieldCategory[],
  searchKeyword: string
): ProfileFieldCategory[] => {
  searchKeyword = searchKeyword.toLowerCase().trim();

  if (!searchKeyword) return fields;

  const newFields = cloneDeep(fields);

  newFields.forEach((o) => {
    o.fields = o.fields.filter((f) =>
      f.displayName.toLowerCase().includes(searchKeyword)
    );
  });
  return newFields.filter((o) => o.fields.length);
};

export default function ProfileFieldLayout(props: {}) {
  const { t }: { t: any } = useTranslation();
  const { isLoading, fieldInEdit, setFieldInEdit, fieldCategories } =
    useContext(ProfileEditorContext);
  const [fields, setFields] = useState<ProfileField[]>([]);

  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const handleFieldClick = (field: ProfileField) => {
    if (fieldInEdit?.name != field.name) {
      setFieldInEdit(field);
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setSearchKeyword(event.target.value);
  };

  const getIcon = (field: ProfileField) => {
    if (field.icon?.includes('.')) {
      return cdnService.toCdnUrl(field.icon, 'icons');
    }
    return null;
  };

  const filteredFieldCategories = applySearchFilter(
    fieldCategories,
    searchKeyword
  );

  if (isLoading) return <Loading />;

  return (
    <>
      <Box display="flex" mb={1}>
        <Box>
          <Typography variant="h3" component="h3" gutterBottom>
            {t('Customize your profile')}
          </Typography>
          <Typography variant="subtitle2">
            {t('Add, Edit field blocks to complete your profile')}
          </Typography>
        </Box>
      </Box>
      <Box display="flex">
        <TextField
          sx={{
            mb: 2
          }}
          size="small"
          fullWidth
          onChange={handleSearchChange}
          value={searchKeyword}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            )
          }}
          placeholder={t('Search fields...')}
        />
      </Box>

      {filteredFieldCategories.map((category) => (
        <Card key={category.name} sx={{ marginTop: 2 }}>
          <CardHeader title={t(category.displayName)} />
          <Divider />
          <CardContent
            sx={{
              background: '#f9fafb'
            }}
          >
            <Box p={1}>
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
              >
                {category.fields.map((field) => (
                  <Grid key={field.name} item>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        handleFieldClick(field);
                      }}
                      component="button"
                      href="#"
                      underline="none"
                    >
                      <Box
                        p={1}
                        display="flex"
                        justifyContent="left"
                        alignItems="center"
                      >
                        <PaperWrapper elevation={3} sx={{ width: 120 }}>
                          <Avatar
                            sx={{ marginLeft: '30%', marginTop: 1 }}
                            src={getIcon(field)}
                          >
                            {getIcon(field) == null && (
                              <Icon name={field.icon} />
                            )}
                          </Avatar>
                          <Typography
                            textAlign="center"
                            margin={1}
                            gutterBottom
                            variant="subtitle2"
                          >
                            {field.displayName}
                          </Typography>
                        </PaperWrapper>
                      </Box>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </CardContent>
        </Card>
      ))}
    </>
  );
}

function Loading() {
  return (
    <>
      {[1, 2, 3, 4, 5].map((o) => (
        <Card key={o} sx={{ marginTop: 2 }}>
          <CardHeader
            title={
              <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
            }
          />
          <Divider />
          <CardContent
            sx={{
              background: '#f9fafb'
            }}
          >
            <Box p={1}>
              <Grid container spacing={3}>
                {[1, 2, 3, 4, 5].map((e) => (
                  <Grid key={e} item xs>
                    <Link href="#" underline="none">
                      <Box
                        p={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Paper elevation={3} sx={{ width: 100 }}>
                          <Skeleton
                            variant="rectangular"
                            width={'100%'}
                            height={100}
                          />
                        </Paper>
                      </Box>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </CardContent>
        </Card>
      ))}
    </>
  );
}
