import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import Text from 'src/ui-bloom/components/Text';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import ContentCopyTwoTone from '@mui/icons-material/ContentCopyTwoTone';
import MoreHorizTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import QrCode2TwoToneIcon from '@mui/icons-material/QrCode2TwoTone';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  BusinessAppInstance,
  Profile
} from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import { ApiUrls } from 'src/services/endpoints';
import Label from 'src/ui-bloom/components/Label';

const CardActions = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(1)};
    top: ${theme.spacing(1)};
    z-index: 7;
  `
);

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(13)};
    font-weight: bold;
    text-transform: uppercase;
    border-radius: ${theme.general.borderRadiusSm};
    padding: ${theme.spacing(0.9, 1.5, 0.7)};
    line-height: 1;
  `
);

const CardWrapper = styled(Box)(
  ({ theme }) => `
      background: ${alpha(theme.colors.primary.main, 0.05)};
  `
);

const CardActionAreaWrapper = styled(CardActionArea)(
  ({ theme }) => `
        text-align: center;
        background: ${alpha(theme.colors.primary.main, 0.03)};

        .MuiTouchRipple-root {
          opacity: .2;
        }
  
        .MuiCardActionArea-focusHighlight {
          background: ${theme.colors.primary.main};
        }
  
        &:hover {
          .MuiCardActionArea-focusHighlight {
            opacity: .05;
          }
        }
  `
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        transform: translateY(0px);
        transition: ${theme.transitions.create(['color', 'transform'])};

        .MuiSvgIcon-root {
            transform: scale(1);
            transition: ${theme.transitions.create(['transform'])};
        }

        &:hover {
            transform: translateY(-2px);
    
            .MuiSvgIcon-root {
                transform: scale(1.3);
            }
        }
  `
);
const AvatarAddWrapper = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
`
);
function ProfileBlock({
  profile,
  onDelete,
  onClone,
  onAddApp,
  appInstances
}: {
  profile: Profile;
  appInstances: BusinessAppInstance[];
  onDelete: (id: string) => void;
  onClone: (id: string) => void;
  onAddApp: () => void;
}) {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const onEdit = () => {
    navigate('/app/company/profile-editor/' + profile.id);
  };
  const onQrView = () => {
    let url = ApiUrls.viewerAppUrl + '/' + profile.id;
    if (profile.qrInfo?.text) {
      url = profile.qrInfo?.text;
    } else if (profile.qrInfo?.code) {
      url = ApiUrls.viewerAppUrl + '/' + profile.qrInfo?.code;
    }
    window.open(url, '_blank');
  };

  const getImgUrl = () => {
    if (profile.data.logoImage)
      return cdnService.toImageUrl(profile.data.logoImage);
    if (!profile.data.businessName) return '/static/images/no-image.png';
  };
  return (
    <Card variant="outlined">
      <CardWrapper
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          pt: 3,
          px: 3,
          pb: 7
        }}
      >
        <CardActions>
          <IconButton
            onClick={() => onDelete(profile.id)}
            size="small"
            color="error"
          >
            <MoreHorizTwoToneIcon />
          </IconButton>
        </CardActions>
        <Avatar
          onClick={onEdit}
          src={getImgUrl()}
          sx={{
            '.MuiAvatar-img': {
              objectFit: 'fill',
              height: '100%'
            },
            width: `${theme.spacing(14)}`,
            height: `${theme.spacing(14)}`,
            borderRadius: '10%',
            mb: 1.5,
            cursor: 'pointer',
            border: `${theme.colors.alpha.white[100]} solid 4px`,
            objectFit: 'fill',
            boxShadow: `0 2rem 8rem 0 ${alpha(
              theme.colors.alpha.black[100],
              0.05
            )}, 
                            0 0.6rem 1.6rem ${alpha(
                              theme.colors.alpha.black[100],
                              0.15
                            )}, 
                            0 0.2rem 0.2rem ${alpha(
                              theme.colors.alpha.black[100],
                              0.1
                            )}`
          }}
        >
          {(profile.data?.businessName || ' ')[0].toUpperCase()}
        </Avatar>
        <Link
          onClick={onEdit}
          href="#"
          color="text.primary"
          underline="none"
          sx={{
            transition: `${theme.transitions.create(['color'])}`,
            fontSize: `${theme.typography.pxToRem(17)}`,

            '&:hover': {
              color: `${theme.colors.primary.main}`
            }
          }}
          variant="h4"
        >
          {profile.data.businessName}
        </Link>
        <Box mt={1} mb={1.5}>
          <LabelWrapper
            sx={{
              background: `${theme.colors.info.main}`,
              color: `${theme.palette.getContrastText(theme.colors.info.dark)}`
            }}
          >
            {t('Business')}
          </LabelWrapper>
        </Box>
        {profile.data.headline && (
          <Typography variant="subtitle2">
            {t(profile.data.headline)}.
          </Typography>
        )}
      </CardWrapper>
      <Box p={3}>
        <Box
          sx={{
            textAlign: 'center',
            mt: `-${theme.spacing(7)}`,
            mb: 3
          }}
        >
          <Tooltip arrow placement="top" title="QR profile view">
            <IconButtonWrapper
              onClick={onQrView}
              sx={{
                boxShadow: `${theme.colors.shadows.primary}`,
                background: `${theme.colors.primary.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.primary.main
                )}`,

                '&:hover': {
                  background: `${theme.colors.primary.main}`,
                  color: `${theme.palette.getContrastText(
                    theme.colors.primary.main
                  )}`
                },
                borderRadius: 50,
                width: theme.spacing(8),
                height: theme.spacing(8)
              }}
              color="primary"
              size="large"
            >
              <QrCode2TwoToneIcon fontSize="small" />
            </IconButtonWrapper>
          </Tooltip>
          <Tooltip arrow placement="top" title="Copy profile">
            <IconButtonWrapper
              onClick={() => onClone(profile.id)}
              sx={{
                boxShadow: `${theme.colors.shadows.primary}`,
                background: `${theme.colors.primary.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.primary.main
                )}`,

                '&:hover': {
                  background: `${theme.colors.primary.main}`,
                  color: `${theme.palette.getContrastText(
                    theme.colors.primary.main
                  )}`
                },
                borderRadius: 50,
                mx: 1.5,
                width: theme.spacing(8),
                height: theme.spacing(8)
              }}
              color="primary"
              size="large"
            >
              <ContentCopyTwoTone fontSize="small" />
            </IconButtonWrapper>
          </Tooltip>
          <Tooltip arrow placement="top" title="Edit profile">
            <IconButtonWrapper
              onClick={onEdit}
              sx={{
                boxShadow: `${theme.colors.shadows.primary}`,
                background: `${theme.colors.primary.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.primary.main
                )}`,

                '&:hover': {
                  background: `${theme.colors.primary.main}`,
                  color: `${theme.palette.getContrastText(
                    theme.colors.primary.main
                  )}`
                },
                borderRadius: 50,
                width: theme.spacing(8),
                height: theme.spacing(8)
              }}
              color="primary"
              size="large"
            >
              <EditTwoToneIcon fontSize="small" />
            </IconButtonWrapper>
          </Tooltip>
        </Box>
        <Stack
          sx={{
            my: 2,
            textAlign: 'center'
          }}
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={2}
        >
          <Box>
            <Typography gutterBottom variant="subtitle2">
              {t('Views')}
            </Typography>
            <Typography variant="h4">-</Typography>
          </Box>
          <Box>
            <Typography gutterBottom variant="subtitle2">
              {t('Reviews')}
            </Typography>
            <Typography variant="h4">{profile.reviewCount}</Typography>
          </Box>
          <Box>
            <Typography gutterBottom variant="subtitle2">
              {t('Leads')}
            </Typography>
            <Typography variant="h4">{profile.leadCount}</Typography>
          </Box>
        </Stack>
        <Divider />
        <Box
          mt={2}
          py={0}
          sx={{
            textAlign: 'center'
          }}
        >
          <Label color="secondary">Business Apps</Label>
        </Box>

        <Box p={2}>
          <Grid container spacing={4}>
            {appInstances.map((appIns) => (
              <Grid key={appIns.id} item xs={12} sm={6}>
                <Card variant="outlined">
                  <CardActionAreaWrapper
                    sx={{
                      p: 2
                    }}
                  >
                    <Text color="warning">
                      <Avatar
                        sx={{
                          mb: 1,
                          height: 25,
                          width: 25
                        }}
                        src={cdnService.toImageUrl(appIns.businessApp.logo)}
                      />
                    </Text>
                    <Typography variant="h4">{t('Review App')}</Typography>
                    <Typography variant="subtitle2" noWrap>
                      {t('Business reviews')}
                    </Typography>
                  </CardActionAreaWrapper>
                </Card>
              </Grid>
            ))}

            <Grid item xs={12} sm={6}>
              <Card variant="outlined">
                <Tooltip title="Click to a new app" placement="top">
                  <CardActionAreaWrapper
                    onClick={onAddApp}
                    sx={{
                      p: 2,
                      textAlign: 'center',
                      justifyContent: 'center',
                      display: 'flex'
                    }}
                  >
                    <AvatarAddWrapper>
                      <AddTwoToneIcon fontSize="large" />
                    </AvatarAddWrapper>
                  </CardActionAreaWrapper>
                </Tooltip>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
}

export default ProfileBlock;
