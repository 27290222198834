import { Card, CardHeader, Divider, Grid, useTheme } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField as FmTextFiled } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import FieldEditorFooter from 'src/contents/profile-editor/FieldEditorFooter';
import { TitledFieldValue } from 'src/services/apiService/response-models';
import * as Yup from 'yup';
import { FieldRenderEditorProp } from '../..';
import getMultiFieldValue from '../../_utils/getMultiFieldValue';

export default function EmailEditorRenderer(props: FieldRenderEditorProp) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  // useEffect(){

  // }
  const value = getMultiFieldValue<TitledFieldValue<string>>(
    props,
    props.data.emails
  );

  return (
    <>
      <Card>
        <CardHeader title={t(props.field.displayName)} />
        <Divider />
        <Formik
          initialValues={{
            title: value?.title,
            email: value?.value
          }}
          validateOnBlur={false}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(250),
            email: Yup.string()
              .max(250)
              .required(t('The name field is required'))
          })}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            const emails = [...(props.data.emails || [])];
            emails[props.valueIndex] = {
              title: _values.title,
              value: _values.email
            };
            props.onSave({ ...props.data, emails: emails });
          }}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid sx={{ padding: theme.spacing(2) }} container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name={'email'}
                    label="Email"
                    component={FmTextFiled}
                    placeholder={t('Enter here...')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name={'title'}
                    component={FmTextFiled}
                    label="Title"
                    placeholder={t('Enter here...')}
                    // inputRef={(input) => {
                    //   input?.focus();
                    // }}
                  />
                </Grid>
              </Grid>
              <FieldEditorFooter
                onCancel={props.onCancel}
                onDelete={() => props.onDelete()}
              />
            </form>
          )}
        </Formik>
      </Card>
    </>
  );
}
