import {
  Avatar,
  Icon,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material';
import cdnService from 'src/services/cdnService';
import { FieldRenderBlockProp } from '..';

export default function ListAvatarBlock({
  title,
  value,
  e
}: {
  title?: string;
  value?: string;
  e: FieldRenderBlockProp;
}) {
  const theme = useTheme();

  return (
    <>
      <ListItemAvatar
        sx={{
          mr: 2,
          display: 'flex',
          alignItems: 'center',
          minWidth: 0
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            background: `${theme.colors.alpha.white[10]}`,
            color: `${theme.colors.gradients.pink2}`,
            width: 64,
            height: 64
          }}
        >
          <Icon>
            <img
              src={cdnService.toCdnUrl(e.field.icon, 'icons')}
              height={25}
              width={25}
            />
          </Icon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{
          color:
            e.theme === 'light'
              ? `${theme.colors.alpha.black[70]}`
              : `${theme.colors.alpha.trueWhite[70]}`,
          wordWrap: 'break-word'
        }}
        primary={
          <Typography gutterBottom variant="h4">
            {value}
          </Typography>
        }
        secondary={
          <Typography
            sx={{
              color:
                e.theme === 'light'
                  ? `${theme.colors.alpha.black[70]}`
                  : `${theme.colors.alpha.trueWhite[70]}`
            }}
            variant="subtitle2"
          >
            {title}
          </Typography>
        }
      />
    </>
  );
}
