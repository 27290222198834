import axios, { AxiosResponse } from 'axios';
import { QueryClient } from 'react-query';
import { LeadDto } from '../apiService/request-models/dto';
import {
  BusinessApp,
  BusinessAppInstance,
  Contact,
  Lead,
  NfcCodeMap,
  Profile,
  ProfileFieldCategory,
  RefData,
  Review,
  TeamMember,
  User
} from '../apiService/response-models';
import {
  BusinessAppInstanceDto,
  ContactDto,
  ProfileDto,
  TeamMemberDto
} from '../dto';
import { ApiUrls } from '../endpoints';

export const QKEY = {
  Profiles: 'profiles',
  Contacts: 'contacts',
  Leads: 'leads',
  Reviews: 'reviews',
  Members: 'members',
  Users: 'users',
  Teams: 'teams',
  RefData: 'ref-data',
  MyNFCCard: 'my-nfc-cards'
};

export const appQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false // default: true
    }
  }
});

export const getBusinessApps = (): Promise<AxiosResponse<BusinessApp[]>> => {
  const dataUrl = ApiUrls.base + '/api/v1/business-app';
  return axios.get(dataUrl);
};

export const getAppInstances = (): Promise<
  AxiosResponse<BusinessAppInstance[]>
> => {
  const dataUrl = ApiUrls.base + '/api/v1/business-app/instances';
  return axios.get(dataUrl);
};

export const getAppIstanceById = (
  instanceId: string
): Promise<AxiosResponse<BusinessAppInstance>> => {
  const dataUrl = ApiUrls.base + '/api/v1/business-app/instance/' + instanceId;
  return axios.get(dataUrl);
};
export const deleteAppIstanceById = (
  instanceId: string
): Promise<AxiosResponse<BusinessAppInstance>> => {
  const dataUrl = ApiUrls.base + '/api/v1/business-app/instance/' + instanceId;
  return axios.delete(dataUrl);
};

export const getIndividualProfiles = (): Promise<AxiosResponse<Profile[]>> => {
  const dataUrl = ApiUrls.base + '/api/v1/profile';
  return axios.get(dataUrl);
};

export const getMembers = (): Promise<AxiosResponse<TeamMember[]>> => {
  const dataUrl = ApiUrls.base + '/api/v1/team-member/all';
  return axios.get(dataUrl);
};

export const getBusinessProfiles = (): Promise<AxiosResponse<Profile[]>> => {
  const dataUrl = ApiUrls.base + '/api/v1/profile/business-profiles';
  return axios.get(dataUrl);
};

export const getAllProfilesList = (): Promise<AxiosResponse<Profile[]>> => {
  const dataUrl = ApiUrls.base + '/api/v1/profile/all';
  return axios.get(dataUrl);
};

export const getfieldCategorySchema = (): Promise<
  AxiosResponse<{ categories: ProfileFieldCategory[] }>
> => {
  const dataUrl = ApiUrls.base + '/api/v1/profile/field/schema';
  return axios.get(dataUrl);
};

export const getProfileById = (id: string): Promise<AxiosResponse<Profile>> => {
  const dataUrl = ApiUrls.base + '/api/v1/profile/' + id;
  return axios.get(dataUrl);
};

export const getProfileByNfcCode = (
  nfcCode: string
): Promise<AxiosResponse<Profile>> => {
  const dataUrl =
    ApiUrls.base +
    '/api/v1/profile/nfc/look-up?nfc-code=' +
    encodeURIComponent(nfcCode);
  return axios.get(dataUrl);
};

export const saveProfile = (
  profile: ProfileDto,
  id?: string
): Promise<AxiosResponse<Profile>> => {
  const dataUrl = ApiUrls.base + '/api/v1/profile';
  if (id && id.trim().length > 0) return axios.put(dataUrl + `/${id}`, profile);
  else return axios.post(dataUrl, profile);
};

export const saveAppInstance = (
  app: BusinessAppInstanceDto,
  id?: string
): Promise<AxiosResponse<BusinessAppInstance>> => {
  const dataUrl = ApiUrls.base + '/api/v1/business-app/instance';
  if (id && id.trim().length > 0) return axios.put(dataUrl + `/${id}`, app);
  else return axios.post(dataUrl, app);
};

export const saveProfileNfcMap = (
  nfcCode: string,
  profileId: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.base + '/api/v1/profile/nfc-code-map';
  return axios.post(dataUrl, {
    nfcCode,
    profileId
  });
};

//---------------------

export const getContacts = (): Promise<AxiosResponse<Contact[]>> => {
  const dataUrl = ApiUrls.base + '/api/v1/contact/all';
  return axios.get(dataUrl);
};

export const getRefData = (): Promise<AxiosResponse<RefData>> => {
  const dataUrl = ApiUrls.base + '/ref-data';
  return axios.get(dataUrl);
};

export const getContactById = (id: string): Promise<AxiosResponse<Contact>> => {
  const dataUrl = ApiUrls.base + '/api/v1/contact/' + id;
  return axios.get(dataUrl);
};

export const saveContact = (
  contact: ContactDto,
  id?: string
): Promise<AxiosResponse<Contact>> => {
  const dataUrl = ApiUrls.base + '/api/v1/contact';
  if (id && id.trim().length > 0) return axios.put(dataUrl + `/${id}`, contact);
  else return axios.post(dataUrl, contact);
};

export const getTeamMembers = (): Promise<AxiosResponse<TeamMember[]>> => {
  const dataUrl = ApiUrls.base + '/api/v1/team-member/all';
  return axios.get(dataUrl);
};

export const getTeamMemberById = (
  id: string
): Promise<AxiosResponse<TeamMember>> => {
  const dataUrl = ApiUrls.base + '/api/v1/team-member/' + id;
  return axios.get(dataUrl);
};

export const getTeamMemberUsers = (
  id: string
): Promise<AxiosResponse<User[]>> => {
  const dataUrl = ApiUrls.base + '/api/v1/team-member/' + id + '/users';
  return axios.get(dataUrl);
};

export const saveLead = (
  lead: LeadDto,
  id?: string
): Promise<AxiosResponse<Lead>> => {
  const dataUrl = ApiUrls.base + '/api/v1/lead';
  if (id && id.trim().length > 0) return axios.put(dataUrl + `/${id}`, lead);
  else return axios.post(dataUrl, lead);
};

export const getLeads = (): Promise<AxiosResponse<Lead[]>> => {
  const dataUrl = ApiUrls.base + '/api/v1/lead';
  return axios.get(dataUrl);
};

export const getLeadById = (id: string): Promise<AxiosResponse<Lead>> => {
  const dataUrl = ApiUrls.base + '/api/v1/lead/' + id;
  return axios.get(dataUrl);
};

export const deleteLeadById = (id: string): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.base + '/api/v1/lead/' + id;
  return axios.delete(dataUrl);
};

export const getReviews = (): Promise<AxiosResponse<Review[]>> => {
  const dataUrl = ApiUrls.base + '/api/v1/review';
  return axios.get(dataUrl);
};

export const getReviewById = (id: string): Promise<AxiosResponse<Review>> => {
  const dataUrl = ApiUrls.base + '/api/v1/review/' + id;
  return axios.get(dataUrl);
};

export const deletReviewById = (
  id: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.base + '/api/v1/review/' + id;
  return axios.delete(dataUrl);
};

export const saveTeamMember = (
  lead: TeamMemberDto,
  id?: string
): Promise<AxiosResponse<TeamMember>> => {
  const dataUrl = ApiUrls.base + '/api/v1/team-member';
  if (id && id.trim().length > 0) return axios.put(dataUrl + `/${id}`, lead);
  else return axios.post(dataUrl, lead);
};

export const sendEmailVerificationCode = (
  email: string
): Promise<AxiosResponse> => {
  const dataUrl =
    ApiUrls.membershipEndpoint + '/sendVerificationCodeMail?email=' + email;
  return axios.get(dataUrl);
};

export const verifyEmail = (
  email: string,
  verificationCode: string
): Promise<
  AxiosResponse<{ isVerified: boolean; isVarificationCodeExpired: boolean }>
> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/verifyEmail';
  return axios.post(dataUrl, {
    email: email,
    verificationCode: verificationCode
  });
};

export const nfcCodeReset = (
  nfcCode: string
): Promise<AxiosResponse<{ nfcCode: string }>> => {
  const dataUrl = ApiUrls.base + '/api/v1/profile/nfc-code-reset';
  return axios.post(dataUrl, { nfcCode });
};

export const getMyNfcCards = (): Promise<AxiosResponse<NfcCodeMap[]>> => {
  const dataUrl = ApiUrls.base + '/api/v1/profile/my-nfc-cards';
  return axios.get(dataUrl);
};
