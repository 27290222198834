import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { useLocation } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { BusinessApp } from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import { getBusinessApps } from 'src/services/query-client';
import { parseApiError } from 'src/utility/parseToApiErrorMessage';
import ErrorPanel from '../error-panel';

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

const AvatarWrapper = styled(Box)(
  ({ theme }) => `
      .MuiAvatar-root {
        height: auto;
        width: ${theme.spacing(16)};
        transition: ${theme.transitions.create(['opacity'])};

        &:hover {
          opacity: .8;
        }
      }
`
);
function AppSelectorContent(props: {
  onAdd: (e: BusinessApp) => void;
  onClose: () => void;
}) {
  const theme = useTheme();
  const { t }: { t: any } = useTranslation();

  const {
    data: { data: apps = [] } = {},
    isError,
    error,
    isLoading,
    refetch
  } = useQuery('apps', getBusinessApps);

  return (
    <>
      <Grid
        sx={{
          px: 1
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
      >
        <Grid display="flex" alignItems="center" item xs={12}>
          <PageHeader handleClose={props.onClose} />
        </Grid>

        <Grid item xs={12} md={12}>
          {isLoading && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {isLoading && isError && (
            <Box sx={{ width: '100%' }}>
              <ErrorPanel
                title="Failed to load"
                message={parseApiError(error, '')}
              />
            </Box>
          )}
          {!isLoading && (
            <Grid container spacing={2}>
              {apps.map((app) => (
                <Grid key={app.name} item xs={3} md={3}>
                  <Card sx={{ height: '100%' }}>
                    <AvatarWrapper
                      p={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Avatar
                        src={cdnService.toCdnUrl(app.image, 'icons')}
                        variant="rounded"
                      />
                    </AvatarWrapper>
                    <Box textAlign="center" px={1}>
                      <Typography
                        variant="h3"
                        sx={{
                          mb: 1
                        }}
                      >
                        {app.displayName}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        {app.description}
                      </Typography>
                    </Box>
                    <Box
                      pb={3}
                      pt={1}
                      display="flex"
                      justifyContent="center"
                      alignItems=""
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => props.onAdd(app)}
                      >
                        {t('Add')}
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

function PageHeader(props: { handleClose: () => void }) {
  const { t }: { t: any } = useTranslation();
  const location = useLocation();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" component="h3" gutterBottom>
              {t('Add App')}
            </Typography>
            <Typography variant="subtitle2">
              {t('Select an app from business apps')}
            </Typography>
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
export default AppSelectorContent;
