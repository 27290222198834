import { useTheme } from '@mui/material';
import { FieldRenderBlockProp } from '../..';
import ListAvatarBlock from '../../_utils/list-avatar-block';

export default function TelegramBlockRenderer(props: FieldRenderBlockProp) {
  const theme = useTheme();

  if (!props.data?.telegram) return <></>;

  return (
    <ListAvatarBlock
      title={props.data.telegram?.title}
      value={props.data.telegram?.value}
      e={props}
    />
  );
}
