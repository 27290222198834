import React, { ChangeEvent, useState } from 'react';

import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import DeleteConfirm from 'src/components/delete-confirm';
import { CustomFieldsCard } from './CustomFieldsCard';

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
  `
);

interface CustomField {
  id: string;
  name: string;
  value: string;
  group?: string;
}

function AdditionalInfo() {
  const { t }: { t: any } = useTranslation();

  const [currentTab, setCurrentTab] = useState<string>('custom-fields');
  const [customField, setCustomField] = useState<CustomField>({
    id: '',
    name: '',
    value: ''
  });
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [fieldError, setFieldError] = useState<string>('');
  const isFieldEdit = customField.id.length > 0;

  const [features, setFeatures] = useState<CustomField[]>([]);
  const [feature, setFeature] = useState<CustomField>({
    id: '',
    name: '',
    value: ''
  });
  const [featureError, setFeatureError] = useState<string>('');
  const isFeatureEdit = feature.id.length > 0;

  const tabs = [
    { value: 'custom-fields', label: t('Custom fields') },
    { value: 'features', label: t('Features') }
  ];

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  const onCustomFieldSave = () => {
    setFieldError('');
    if (!customField.name.trim().length || !customField.value.trim().length) {
      setFieldError('Field name & value both are required');
      return;
    }

    const arr = _.clone(customFields);

    if (isFieldEdit) {
      const index = arr.findIndex((o) => o.id == customField.id);
      if (index != -1) {
        arr[index] = { ...customField };
      }
    } else {
      arr.push({ ...customField, id: Date.now() + '' });
    }

    setCustomField({
      id: '',
      name: '',
      value: ''
    });
    setCustomFields(arr);
  };

  const onFieldDelete = (e: string) => {
    const arr = _.clone(customFields);
    setCustomFields(arr.filter((o) => o.id != e));
  };
  const onFieldEdit = (e: string) => {
    setFieldError('');
    const match = customFields.find((o) => o.id == e);
    if (match) {
      setCustomField({ ...match });
    }
  };
  const onFeatureSave = () => {
    setFeatureError('');
    if (!feature.name.trim().length) {
      setFeatureError('Feature heading is required');
      return;
    }

    const arr = _.clone(features);

    if (isFeatureEdit) {
      const index = arr.findIndex((o) => o.id == feature.id);
      if (index != -1) {
        arr[index] = { ...feature };
      }
    } else {
      arr.push({ ...feature, id: Date.now() + '' });
    }

    setFeature({
      id: '',
      name: '',
      value: ''
    });
    setFeatures(arr);
  };
  const onFeatureDelete = (e: string) => {
    const arr = _.clone(features);
    setFeatures(arr.filter((o) => o.id != e));
  };
  const onFeatureEdit = (e: string) => {
    setFeatureError('');
    const match = features.find((o) => o.id == e);
    if (match) {
      setFeature({ ...match });
    }
  };

  return (
    <Card>
      <CardHeader title={t('Additional Informations')} />
      <Divider />
      <TabsContainerWrapper>
        <Tabs
          onChange={handleTabsChange}
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </TabsContainerWrapper>
      <Divider />
      <Box p={3}>
        {currentTab === 'custom-fields' && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="code"
                value={customField.group || ''}
                variant="outlined"
                label={t('Group name (optional)')}
                placeholder={t('Enter here ...')}
                onChange={(e) =>
                  setCustomField({ ...customField, group: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                value={customField.name || ''}
                name="fieldName"
                variant="outlined"
                label={t('Field name')}
                placeholder={t('Enter here ...')}
                onChange={(e) =>
                  setCustomField({ ...customField, name: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                value={customField.value || ''}
                name="value"
                variant="outlined"
                label={t('Value')}
                placeholder={t('Enter value here ...')}
                onChange={(e) =>
                  setCustomField({ ...customField, value: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button onClick={onCustomFieldSave} variant="outlined">
                {t(isFieldEdit ? 'Update field' : 'Add field')}
              </Button>
              {isFieldEdit && (
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => {
                    setCustomField({
                      id: '',
                      name: '',
                      value: '',
                      group: ''
                    });
                  }}
                  variant="outlined"
                >
                  {t('Cancel')}
                </Button>
              )}
              <FormHelperText error={true}>{fieldError}</FormHelperText>
              {/* <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="tax_class">{t('Tax Class')}</InputLabel>
                <Select
                  native
                  label={t('Tax Class')}
                  inputProps={{
                    name: 'tax_status'
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={1}>{t('Standard')}</option>
                </Select>
              </FormControl> */}
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomFieldsCard
                fields={customFields}
                onDelete={onFieldDelete}
                onEdit={onFieldEdit}
              />
            </Grid>
          </Grid>
        )}
        {currentTab === 'features' && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                name="sku"
                value={feature.name || ''}
                variant="outlined"
                label={t('Heading')}
                placeholder={t('Feature heading here ...')}
                onChange={(e) =>
                  setFeature({ ...feature, name: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="sku"
                value={feature.value || ''}
                variant="outlined"
                label={t('Sub heading')}
                placeholder={t('Feature sub-heading here ...')}
                onChange={(e) =>
                  setFeature({ ...feature, value: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button onClick={onFeatureSave} size="small">
                {isFeatureEdit ? 'Update' : 'Add'}
              </Button>
              {isFeatureEdit && (
                <Button
                  onClick={() => setFeature({ id: '', name: '', value: '' })}
                  size="small"
                >
                  Cancel
                </Button>
              )}
            </Grid>
            {featureError && (
              <FormHelperText sx={{ ml: 4 }} error={true}>
                {featureError}
              </FormHelperText>
            )}
            {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label={t('Sold individually')}
              />
              <Typography variant="h6" color="text.secondary">
                {t(
                  'Enable this to only allow one of this item to be bought in a single order'
                )}
              </Typography>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <FeatureList
                features={features}
                onDelete={onFeatureDelete}
                onEdit={onFeatureEdit}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Card>
  );
}

export default AdditionalInfo;

const ListItemWrapper = styled(ListItem)(
  ({ theme }) => `
      position: relative;
      transition: ${theme.transitions.create(['background'])};

      .MuiActionButtons {
            background: ${alpha(theme.colors.alpha.white[100], 0.95)};
            border-radius: ${theme.general.borderRadius};
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
            top: 50%;
            margin-top: -${theme.spacing(3.5)};
            position: absolute;
            right: ${theme.spacing(1.5)};
            padding: ${theme.spacing(0.5, 1)};
            transition: ${theme.transitions.create(['visibility', 'opacity'])};

            .MuiIconButton-root {
                border-radius: 100px;
                width: ${theme.spacing(5)};
                height: ${theme.spacing(5)};
                margin: ${theme.spacing(0.5)};
            }
      }

      &:hover {
          background: ${alpha(theme.colors.secondary.main, 0.15)};

          .MuiActionButtons {
            visibility: visible;
            opacity: 1;
      }   
      }
  `
);
function FeatureList(props: {
  features: CustomField[];
  onDelete: (e: string) => void;
  onEdit: (e: string) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [idToDelete, setIdToDelete] = useState('');
  return (
    <Card variant="outlined">
      <CardHeader
        sx={{
          p: 2,
          background: `${theme.colors.alpha.black[5]}`
        }}
        disableTypography
        title={
          <Typography
            variant="h4"
            sx={{
              fontSize: `${theme.typography.pxToRem(16)}`
            }}
          >
            {t('Item features')}
          </Typography>
        }
        // action={
        //   <Button
        //     variant="contained"
        //     size="small"
        //     endIcon={<LabelOutlinedIcon />}
        //     color="primary"
        //   >
        //     {t('Export')}
        //   </Button>
        // }
      />
      <Divider />
      <List disablePadding>
        {props.features.length == 0 && (
          <Typography sx={{ m: 2 }}>No feature</Typography>
        )}
        {props.features.map((o) => (
          <React.Fragment key={o.name}>
            <ListItemWrapper
              sx={{
                p: 2
              }}
            >
              <Box className="MuiActionButtons">
                <Tooltip
                  arrow
                  placement="top"
                  title={t('Purchase a single license')}
                >
                  <IconButton
                    onClick={() => props.onEdit(o.id)}
                    color="primary"
                  >
                    <EditTwoToneIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  arrow
                  placement="top"
                  title={t('Remove existing license')}
                >
                  <IconButton onClick={() => setIdToDelete(o.id)} color="error">
                    <CloseTwoToneIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <ListItemText
                primary={
                  <Typography gutterBottom variant="h4">
                    {o.name}
                  </Typography>
                }
                secondary={
                  <Typography variant="subtitle2">{o.value}</Typography>
                }
              />
            </ListItemWrapper>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <DeleteConfirm
        open={idToDelete.length > 0}
        message="Are you sure, you want to delete?"
        onClose={() => setIdToDelete('')}
        onDeleteConfirm={() => {
          setIdToDelete('');
          props.onDelete(idToDelete);
        }}
      />
    </Card>
  );
}
