import {
  Box,
  Typography,
  Container,
  Divider,
  styled,
  Button
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Logo from 'src/ui-bloom/components/LogoSign';

import { useTranslation } from 'react-i18next';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function NotFound(props: { title?: string; subTitle?: string }) {
  const { t }: { t: any } = useTranslation();

  const handleLogout = async (): Promise<void> => {
    try {
      //await oAuth.logout();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <MainContent>
        <Container
          maxWidth="md"
          sx={{
            p: 10
          }}
        >
          <Logo />
          <Box textAlign="center">
            <Container maxWidth="xs">
              <Typography
                variant="h2"
                sx={{
                  mt: 4,
                  mb: 2
                }}
              >
                {t(
                  'The page you are looking for is no longer here, or never existed.'
                )}
              </Typography>
              <Typography
                variant="h3"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 4
                }}
              >
                {t(
                  'If your entered correct url, you can always start over from the home page or sign-in again'
                )}
              </Typography>
            </Container>
            <img
              alt="Maintenance"
              height={250}
              src="/static/images/maintenance.svg"
            />
            <br />
            <Button
              onClick={handleLogout}
              variant="contained"
              sx={{
                m: 2,
                mb: 0
              }}
            >
              {t('Logout')}
            </Button>
          </Box>
          <Divider
            sx={{
              my: 4
            }}
          />
        </Container>
      </MainContent>
    </>
  );
}

export default NotFound;
