import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  styled,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Scrollbar from 'src/ui-bloom/components/Scrollbar';
import PageHeader from './PageHeader';

import Sidebar from './Sidebar';

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { TeamMember } from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import { TeamMemberDto } from 'src/services/dto';
import {
  QKEY,
  getRefData,
  getTeamMemberById,
  getTeamMemberUsers,
  saveTeamMember
} from 'src/services/query-client';
import { RootState } from 'src/store';
import extractDialCode from 'src/utility/extractDialCode';
import extractNumber from 'src/utility/extractNumber';
import * as Yup from 'yup';
import {
  checkMemberExistsByEmail,
  getTeams
} from '../../../services/apiService';
import useRefMounted from '../../../ui-bloom/hooks/useRefMounted';
import {
  parseApiError,
  parseToApiErrorMessage
} from '../../../utility/parseToApiErrorMessage';
import ContactInfo from './ContactInfo';
import GroupInfo from './GroupInfo';
import PersonalDetails from './PersonalDetails';

const DrawerWrapper = styled(Drawer)(
  ({ theme }) => `
    width: 400px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 400px;
        height: calc(100% - ${theme.header.height});
        position: absolute;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.alpha.white[10]};
    }
`
);

const DrawerWrapperMobile = styled(Drawer)(
  ({ theme }) => `
    width: 360px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 360px;
        z-index: 3;
        background: ${theme.colors.alpha.white[30]};
  }
`
);

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

function CreateEmployee() {
  const theme = useTheme();
  const isMountedRef = useRefMounted();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id: memberId } = useParams();

  const isEdit = Boolean(memberId && memberId.length > 0);

  const { user } = useSelector((state: RootState) => state.app);

  //Queries
  const {
    data: { data: refData } = {},
    isError,
    error,
    isLoading: isRefDataLoading
  } = useQuery(QKEY.RefData, getRefData, {});

  const { data: { data: teams } = {} } = useQuery(QKEY.Teams, getTeams, {});

  const {
    data: { data: model } = {},
    isLoading,
    isError: isErrorOnGet,
    error: errorOnGet,
    refetch: refetchById
  } = useQuery([QKEY.Members, memberId], (k) => getTeamMemberById(memberId), {
    enabled: isEdit,
    onSuccess: (e) => {}
  });

  const { data: { data: memberUsers = [] } = {}, refetch: refetchByUsers } =
    useQuery([QKEY.Users, memberId], (k) => getTeamMemberUsers(memberId), {
      enabled: isEdit,
      onSuccess: (e) => {}
    });

  const {
    mutate: save,
    isError: isSaveError,
    error: errorOnSave,
    isLoading: isSaving
  } = useMutation((e: TeamMemberDto) => saveTeamMember(e, memberId), {
    onSuccess: (o) => onSaveSuccess(o.data)
  });

  if (isSaveError) {
    const msg = parseApiError(
      errorOnSave,
      `Failed to ${!isEdit ? 'create' : 'update'} member`
    );
    enqueueSnackbar(t(msg), { variant: 'error' });
  }

  if (isErrorOnGet) {
    const msg = parseApiError(errorOnGet, `Failed to get member details`);
    enqueueSnackbar(t(msg), { variant: 'error' });
    navigate('/app/team/list');
  }
  const onSaveSuccess = async (updatedModel: TeamMember) => {
    enqueueSnackbar(
      t(`Member was ${!isEdit ? 'created' : 'updated'} successfully`),
      { variant: 'success' }
    );
    if (!isEdit) navigate('/app/team/employee/edit/' + updatedModel.id);
    else {
      refetchById();
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sidebarContent = (
    <Scrollbar>
      <Sidebar />
    </Scrollbar>
  );
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const urlRegExp =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

  const isValidZipRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

  // const address = model?.addresses.find((o) => (o.isPrimary = true));

  // const selectedTeamIds = (model?.teams || []).map((team) => team.id);

  const memberLogin = memberUsers?.find((o) => o.userName == model?.email);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: model?.firstName || '',
          pronouns: model?.pronouns || '',
          middleName: model?.middleName || '',
          lastName: model?.lastName || '',
          imageUrl: model?.imageUrl,
          email: model?.email || '',
          altEmail: model?.altEmail || '',
          contactNumber: extractNumber(model?.contactNumber) || '',
          altContactNumber: extractNumber(model?.altContactNumber) || '',
          contactNumberDialCode: extractDialCode(model?.contactNumber) || '1',
          altContactNumberDialCode:
            extractDialCode(model?.altContactNumber) || '1',
          // dateOfBirth: model?.dateOfBirth || null,
          dateOfJoining: model?.dateOfJoining || null,
          departments: model?.departments || [],
          jobTitle: model?.jobTitle,
          teams: (model?.teams || []).map((o) => o.id),
          createAuser: true,
          address: {
            addressLine: model?.address?.addressLine || '',
            addressLine2: model?.address?.addressLine2 || '',
            countryCode: model?.address?.countryCode || '',
            stateCode: model?.address?.stateCode || '',
            zip: model?.address?.zip || ''
          },
          linkedIn: model?.linkedIn || '',
          facebook: model?.facebook || '',
          twitter: model?.twitter || '',
          whatsApp: model?.whatsApp || '',
          userRoles: (memberLogin?.roles || user.user.roles).map(
            (role) => role.roleName
          )
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().max(250).required(t('The field is required')),
          lastName: Yup.string().max(250).required(t('The field is required')),
          email: Yup.string()
            .max(250)
            .required(t('The email field is required'))
            .email('Not a proper email')
            .test('Unique Email', 'Email already in use', async (value) => {
              if (!value || isEdit) return true;
              const result = await checkMemberExistsByEmail(value);
              return !result.data;
            }),
          alternateEmail: Yup.string().max(250).email('Not a proper email'),
          contactNumber: Yup.string().matches(
            phoneRegExp,
            'Phone number is not valid'
          ),

          altContactNumber: Yup.string().matches(
            phoneRegExp,
            'Phone number is not valid'
          ),
          linkedIn: Yup.string().max(250),
          facebook: Yup.string().max(250),
          twitter: Yup.string().max(250),
          whatsApp: Yup.string().max(250),
          zipcode: Yup.string()
            .matches(isValidZipRegExp, 'Enter correct zipcode!')
            .max(50),
          userRoles: Yup.array().when('createAuser', {
            is: (o) => o && !isEdit,
            then: Yup.array()
              .min(1, 'Choose at least 1 role')
              .required(t('Choose at least 1 role'))
          })
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            const updatedModel: TeamMemberDto = {
              ..._values
            };

            if (_values.contactNumber) {
              const dialCode = _values.contactNumberDialCode
                ? '+' + _values.contactNumberDialCode + ' '
                : '';
              updatedModel.contactNumber = dialCode + _values.contactNumber;
            }
            if (_values.altContactNumber) {
              const dialCode = _values.altContactNumberDialCode
                ? '+' + _values.altContactNumberDialCode + ' '
                : '';
              updatedModel.altContactNumber =
                dialCode + _values.altContactNumber;
            }

            cdnService.uploadBlobToCloud(updatedModel.imageUrl);

            save(updatedModel);
            setStatus({ success: true });
            setSubmitting(false);
          } catch (err) {
            console.error(err);
            const msg = parseToApiErrorMessage(err, `Failed to save employee`);
            enqueueSnackbar(t(msg), { variant: 'error' });
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3} display="flex">
              <MainContentWrapper>
                <Grid
                  sx={{
                    px: 4
                  }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid item xs={12}>
                    <Box
                      mt={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <PageHeader isEdit={isEdit} />
                      <Box
                        component="span"
                        sx={{
                          display: { lg: 'none', xs: 'inline-block' }
                        }}
                      >
                        <IconButtonToggle
                          sx={{
                            ml: 2
                          }}
                          color="primary"
                          onClick={handleDrawerToggle}
                          size="small"
                        >
                          <MenuTwoToneIcon />
                        </IconButtonToggle>
                      </Box>
                    </Box>
                  </Grid>
                  {isLoading && (
                    <Grid item xs={12}>
                      <LinearProgress />
                      loading...
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <PersonalDetails accountRefData={refData} />
                  </Grid>
                  <Grid item xs={12}>
                    <GroupInfo
                      isEdit={isEdit}
                      teamsList={teams}
                      accountRefData={refData}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ContactInfo isEdit={isEdit} />
                  </Grid>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        p: 1
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Button
                            type="button"
                            component={RouterLink}
                            variant="outlined"
                            to={`/${location.pathname.split('/')[1]}/team/list`}
                          >
                            Cancel
                          </Button>
                          <Button
                            sx={{ ml: 2 }}
                            type="submit"
                            variant="contained"
                            startIcon={
                              isSubmitting || isSaving ? (
                                <CircularProgress size="1rem" />
                              ) : (
                                <SaveIcon />
                              )
                            }
                            disabled={isLoading || isSubmitting || isSaving}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </MainContentWrapper>
              <Box
                component="span"
                sx={{
                  display: { lg: 'none', xs: 'inline-block' }
                }}
              >
                <DrawerWrapperMobile
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                >
                  {sidebarContent}
                </DrawerWrapperMobile>
              </Box>
              <Box
                component="span"
                sx={{
                  display: { xs: 'none', lg: 'inline-block' }
                }}
              >
                <DrawerWrapper
                  variant="permanent"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open
                >
                  {sidebarContent}
                </DrawerWrapper>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}

export default CreateEmployee;
