import { useEffect, useRef, useState } from 'react';

import Footer from 'src/ui-bloom/components/Footer';
import PageHeader from './PageHeader';

import { Grid } from '@mui/material';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';

import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import ErrorPanel from 'src/components/error-panel';
import { QKEY, getReviews } from 'src/services/query-client';
import Results from './Results';

function ReviewList() {
  const { enqueueSnackbar } = useSnackbar();
  const [isExportClicked, setIsExportClicked] = useState(false);
  const [teamDataRefreshTriggerId, setTeamDataRefreshTriggerId] = useState('');
  const refLink = useRef(null);

  const {
    data: { data: reviews = [] } = {},
    isError,
    error,
    isLoading,
    refetch
  } = useQuery(QKEY.Reviews, getReviews);

  const onRefreshData = () => {
    setTeamDataRefreshTriggerId(Date.now() + '');
  };

  const handleExportReviews = () => {
    refLink.current?.link?.click();
  };

  useEffect(() => {}, []);

  return (
    <>
      <PageTitleWrapper>
        <PageHeader refreshData={onRefreshData} />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          {isError && (
            <ErrorPanel
              title="failed to load data"
              message={JSON.stringify(error)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Results
            reviews={reviews}
            isLoading={isLoading}
            onDeleteSuccess={() => refetch()}
          />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default ReviewList;
