import { useTheme } from '@mui/material';
import { FieldRenderBlockProp } from '../..';
import ListAvatarBlock from '../../_utils/list-avatar-block';

export default function WhatsAppBlockRenderer(props: FieldRenderBlockProp) {
  const theme = useTheme();

  if (!props.data?.whatsApp) return <></>;

  return (
    <ListAvatarBlock
      title={props.data.whatsApp?.title}
      value={props.data.whatsApp?.value}
      e={props}
    />
  );
}
