import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { useField } from 'formik';
import FeatureImage from 'src/components/FeatureImage';

function Sidebar() {
  const [field, meta, helpers] = useField('imageUrl');

  const onImageChange = (e: string) => {
    helpers.setValue(e);
  };

  return (
    <Box sx={{ m: 1 }}>
      <Card sx={{ width: '100%' }}>
        <CardHeader title={'Profile image'} />
        <CardContent sx={{ pl: '20%' }}>
          <FeatureImage value={field.value || ''} onChange={onImageChange} />
        </CardContent>
      </Card>
    </Box>
  );
}

export default Sidebar;
