import { CardHeader, Grid, Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import ReactPlayer from 'react-player/youtube';

import FieldEditorFooter from 'src/contents/profile-editor/FieldEditorFooter';
import { TitledFieldValue } from 'src/services/apiService/response-models';
import { FieldRenderEditorProp } from '../..';
import getMultiFieldValue from '../../_utils/getMultiFieldValue';

export default function MediaPlayerEditor(props: FieldRenderEditorProp) {
  const theme = useTheme();

  const fvalue = getMultiFieldValue<TitledFieldValue<string>>(
    props,
    props.data.mediaList
  );

  const [value, setValue] = React.useState<TitledFieldValue<string>>(
    fvalue || { value: '' }
  );

  const onSave = () => {
    if (value.value) {
      const mediaList = [...(props.data.mediaList || [])];
      mediaList[props.valueIndex] = {
        title: value.title,
        value: value.value,
        subTitle: value.subTitle
      };
      props.onSave({ ...props.data, mediaList: mediaList });
    }
  };

  const url = value?.value || '';

  return (
    <Card>
      <CardHeader title={props.field.displayName} />
      <Box m={1} display="flex" flexDirection={'column'}>
        <form noValidate style={{ width: '100%' }}>
          <Grid sx={{ padding: 0 }} container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              {!url && (
                <Paper
                  elevation={3}
                  sx={{
                    height: '200px',
                    backgroundColor: '#d1d1d1',
                    margin: 'auto',
                    textAlign: 'center'
                  }}
                >
                  <img
                    style={{ margin: '10%' }}
                    src="/static/images/paper.png"
                  />
                </Paper>
              )}
              {url && (
                <ReactPlayer
                  height={'200px'}
                  width={'100%'}
                  url={url}
                  controls
                />
              )}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus={true}
                id={'url'}
                required={true}
                name={'url'}
                label={'Url'}
                fullWidth
                value={url}
                // error={!!validate(field, values[field.name])}
                // helperText={validate(field, values[field.name])}
                onChange={(e) => {
                  setValue({ ...value, value: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus={false}
                id={'title'}
                required={false}
                name={'title'}
                label={'Title'}
                fullWidth
                value={value?.title || ''}
                // error={!!validate(field, values[field.name])}
                // helperText={validate(field, values[field.name])}
                onChange={(e) => {
                  setValue({ ...value, title: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus={false}
                id={'subTitle'}
                required={false}
                name={'subTitle'}
                label={'Subtitle'}
                fullWidth
                value={value?.subTitle || ''}
                onChange={(e) => {
                  setValue({ ...value, subTitle: e.target.value });
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
      <FieldEditorFooter
        onOk={onSave}
        onCancel={props.onCancel}
        onDelete={() => props.onDelete()}
      />
    </Card>
  );
}
