import { Box, Grid } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ErrorPanel from 'src/components/error-panel';
import { ApiUrls } from '../../services/endpoints';
import PageHeader from './PageHeader';
import MyProfiles from './myProfiles';

function MapNfcCode() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const nfcCode = searchParams.get('nfccode');
  const retUrl = searchParams.get('returl');

  const buildRetUrl = () => {
    return ApiUrls.accountEndpoint + '/' + nfcCode;
  };

  const onGoBack = () => {
    if (retUrl && retUrl.trim().length) {
      window.location.href = retUrl;
    } else {
      navigate('/app/dashboard');
    }
  };

  return (
    <Box sx={{}}>
      <PageHeader nfcCode={nfcCode} retUrl={retUrl} onGoBack={onGoBack} />
      <Grid
        sx={{
          px: 4,
          py: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item display={{ xs: 'none', md: 'block' }} md={3} xs={12}></Grid>
        {!nfcCode && (
          <ErrorPanel
            title="NFC code missing"
            message="This page requires NFC code in url."
          />
        )}
        {nfcCode && (
          <Grid item md={6} xs={12}>
            <MyProfiles
              nfcCode={nfcCode}
              returnUrl={retUrl}
              onGoBack={onGoBack}
            />
          </Grid>
        )}
        <Grid item display={{ xs: 'none', md: 'block' }} md={3} xs={12}></Grid>
      </Grid>
    </Box>
  );
}

export default MapNfcCode;
