import {
  Avatar,
  Box,
  Button,
  Dialog,
  Grid,
  LinearProgress,
  Slide,
  styled,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { forwardRef, ReactElement, Ref, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppSelectorDialog from 'src/components/app-selector-dialog';
import DeleteConfirm from 'src/components/delete-confirm';
import ErrorPanel from 'src/components/error-panel';
import {
  BusinessApp,
  BusinessAppInstance,
  CodeItem
} from 'src/services/apiService/response-models';
import {
  deleteAppIstanceById,
  getAppInstances
} from 'src/services/query-client';
import { RootState } from 'src/store';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';
import { parseApiError } from 'src/utility/parseToApiErrorMessage';
import CardGridBlock from './CardGridBlock';
import PageHeader from './PageHeader';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);
const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);
const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface ItemFilter {
  appName: string;
}

const applyFilters = (items: BusinessAppInstance[], filter: ItemFilter) => {
  if (!filter.appName || filter.appName == 'all') return items;

  return items.filter((o) => o.businessApp.name == filter.appName);
};

function BusinessAppList() {
  const navigate = useNavigate();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useSelector((state: RootState) => state.app);
  const [isAppSelectorOpen, setIsAppSelectorOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');
  const [tabs, setTabs] = useState<CodeItem[]>([]);
  const [filters, setFilters] = useState<ItemFilter>({
    appName: ''
  });
  const {
    data: { data: apps = [] } = {},
    isError,
    error,
    isLoading,
    refetch
  } = useQuery('my-apps', getAppInstances, {
    onSuccess(data) {
      const apps = _.unionBy(
        data.data.map((o) => ({
          code: o.businessApp.name,
          value: o.businessApp.displayName
        })),
        (o) => o.code
      );

      if (apps.length) {
        setTabs([
          {
            code: 'all',
            value: t('All apps')
          },
          ...apps
        ]);
      }
    }
  });

  if (isLoading) return <LinearProgress />;

  if (isError)
    return (
      <ErrorPanel title="Critical error" message={parseApiError(error, '')} />
    );

  if (apps.length) {
  }

  const handleTabsChange = (_event: SyntheticEvent, tabsValue: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      appName: tabsValue
    }));
  };

  const onAppAdded = async (app: BusinessApp) => {
    console.log(app.name);
    setIsAppSelectorOpen(false);
    if (app) {
      navigate(
        '/app/business-apps/' +
          app.name.toLowerCase() +
          '?ret=' +
          location.pathname
      );
    }
    try {
    } catch (ex) {}
  };

  const filteredApps = applyFilters(apps, filters);
  const onAppDeleteConfirm = async () => {
    try {
      await deleteAppIstanceById(idToDelete);
      refetch();
    } catch (ex) {
      console.log(ex);
      enqueueSnackbar(t('Failed to delete app.'), { variant: 'error' });
    }
  };
  return (
    <>
      <PageTitleWrapper>
        <PageHeader
          onAdd={() => {
            setIsAppSelectorOpen(true);
          }}
        />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        {tabs.length > 0 && (
          <>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent={{ xs: 'center', sm: 'space-between' }}
                pb={3}
              >
                <TabsWrapper
                  onChange={handleTabsChange}
                  scrollButtons="auto"
                  textColor="secondary"
                  value={filters.appName || 'all'}
                  variant="scrollable"
                >
                  {tabs.map((tab) => (
                    <Tab key={tab.code} value={tab.code} label={tab.value} />
                  ))}
                </TabsWrapper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <CardGridBlock
                isLoading={isLoading}
                items={filteredApps}
                onClick={() => {}}
                onDeleteClick={(id: string) => setIdToDelete(id)}
              />
            </Grid>
            {filteredApps.length == 0 && (
              <Grid item xs={12}>
                <Typography
                  sx={{
                    py: 10
                  }}
                  variant="h3"
                  fontWeight="normal"
                  color="text.secondary"
                  align="center"
                >
                  {t('No app to display.')}
                </Typography>
              </Grid>
            )}
          </>
        )}

        {apps.length == 0 && (
          <Grid item xs={12}>
            <Typography
              sx={{
                py: 10
              }}
              variant="h3"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {t("No app to display. Please click 'New App' to add one.")}
            </Typography>
          </Grid>
        )}
      </Grid>
      <DeleteConfirm
        open={idToDelete.length > 0}
        onClose={() => setIdToDelete('')}
        onDeleteConfirm={onAppDeleteConfirm}
        message="Delete App?"
      />
      {isAppSelectorOpen && (
        <AppSelectorDialog
          isOpen={isAppSelectorOpen}
          onClose={() => setIsAppSelectorOpen(false)}
          onSave={onAppAdded}
        />
      )}
    </>
  );
}

export default BusinessAppList;
