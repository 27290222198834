import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { Dictionary } from '@reduxjs/toolkit';

class CDNService {
  private cdnUrl = process.env.REACT_APP_CDN_ENDPOINT || '';

  private sasUrl = process.env.REACT_APP_CDN_SAS_URL || '';

  private tenantCode = 'kieazy-app-v2';

  public readonly imageContainerName = 'images';

  public FILE_UPLOADS_CONTAINER_NAME = 'file-uploads';

  public DEFAULT_UPLOADS_CONTAINER_NAME = 'file-uploads';
  //constructor(tenantCode: string, containerName:string) {
  //    this.sasUrl = process.env.REACT_APP_BLOB_SAS_URL || '';
  //    this.tenantCode = tenantCode;
  //    this.containerName = containerName;
  //}

  public getContainerClient = (containerName: string): ContainerClient => {
    const blobServiceClient = new BlobServiceClient(this.sasUrl);
    const containerClient = blobServiceClient.getContainerClient(
      this.tenantCode + '/' + containerName
    );
    return containerClient;
  };

  public toCdnUrl(fileName: string, containerName?: string) {
    if (containerName)
      return (
        this.cdnUrl + this.tenantCode + '/' + containerName + '/' + fileName
      );
    else return this.cdnUrl + this.tenantCode + '/' + fileName;
  }

  public toImageUrl(fileName: string) {
    if (!fileName) return null;

    if (fileName.startsWith(this.cdnUrl)) return fileName;

    const containerName = 'images';
    return this.cdnUrl + this.tenantCode + '/' + containerName + '/' + fileName;
  }

  public noImageUrl() {
    return this.cdnUrl + '_sys/no-image.jpg';
  }

  public blobHash: Dictionary<Blob> = {};

  public async uploadBlobToCloud(hashKey: string) {
    if (hashKey) {
      const blob = this.blobHash[hashKey];
      if (blob) {
        await this.uploadFileToCloud(hashKey, blob);
        delete this.blobHash[hashKey];
      }
    }
  }

  public async deleteBlob(hashKey: string) {
    delete this.blobHash[hashKey];
    await this.deleteFileFromCloud(hashKey);
  }

  public toNfcUrl(nfcCode: string) {
    return 'https://kicard.co/nfc/' + nfcCode;
  }

  public async uploadFileToCloud(fileName: string, blob: any): Promise<string> {
    const blobName = fileName;

    const containerClient = this.getContainerClient(this.imageContainerName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    await blockBlobClient.uploadData(blob, {
      onProgress: (o) => {
        //    total +=   o.loadedBytes;
      }
    });

    return blobName;
  }

  public async deleteFileFromCloud(fileName: string): Promise<boolean> {
    const containerClient = this.getContainerClient(this.imageContainerName);
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);
    const res = await blockBlobClient.deleteIfExists();

    return res.succeeded;
  }
}
const cdnService = new CDNService();
export default cdnService;
