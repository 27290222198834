import { useTheme } from '@mui/material';
import CdnImage from 'src/components/CdnImage';
import { FieldRenderBlockProp } from '../..';

export default function CoverPhotoBlock(props: FieldRenderBlockProp) {
  const theme = useTheme();

  if (!props.field) return <></>;

  return (
    <CdnImage
      className="cardMediaBg "
      height="222px"
      width={'100%'}
      sx={{
        borderRadius: 'inherit',
        position: 'relative',
        zIndex: 1
      }}
      src={props.data.coverImage}
      alt="..."
    />
  );
}
