import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import BaseLayout from 'src/ui-bloom/layouts/BaseLayout';
import NotFound from '../contents/not-found';
import baseRoutes from './base';

import AccountDeletion from '../pages/account-deletion';
import BoxedSidebarLayout from '../ui-bloom/layouts/BoxedSidebarLayout';

import BusinessAppList from 'src/contents/business-apps';
import ExternalAppEditor from 'src/contents/business-apps/external-app';
import KiFormAppEditor from 'src/contents/business-apps/ki-form-app';
import LeadAppEditor from 'src/contents/business-apps/lead-app';
import ListingAppEditor from 'src/contents/business-apps/listing-app';
import ItemEditor from 'src/contents/business-apps/listing-app/item-editor';
import ReviewAppEditor from 'src/contents/business-apps/review-app';
import ScanToCallAppEditor from 'src/contents/business-apps/scan-to-call-app';
import CompanyProfileList from 'src/contents/company-profiles';
import ContactCRM from 'src/contents/contact/contact-crm';
import CreateContact from 'src/contents/contact/create-contact';
import ContactList from 'src/contents/contact/list';
import DashboardMain from 'src/contents/dashboard';
import CreateLead from 'src/contents/lead/create-lead';
import LeadCRM from 'src/contents/lead/lead-crm';
import LeadList from 'src/contents/lead/list';
import MemberProfileList from 'src/contents/member-profiles';
import MyCardList from 'src/contents/my-cards';
import ProfileEditor from 'src/contents/profile-editor';
import ReviewList from 'src/contents/review/list';
import ReviewCRM from 'src/contents/review/review-crm';
import ApplicationSetting from 'src/contents/settings';
import CreateEmployee from 'src/contents/team/create-employee';
import TeamList from 'src/contents/team/list';
import UserList from 'src/contents/users/list';
import MapNfcCode from 'src/pages/map-nfc-code';
import SignUpLogout from 'src/pages/sign-up/signup-logout';

export const router: RouteObject[] = [
  {
    path: '',
    element: <Navigate to="app" />
  },
  {
    path: 'app',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: 'app/sign-up',
    element: <SignUpLogout />
  },

  // Extended Sidebar Layout

  {
    path: 'app',
    element: <BoxedSidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" />
      },

      {
        path: 'dashboard',
        children: [
          {
            path: '',
            element: <DashboardMain />
          }
        ]
      },
      {
        path: 'company-profiles',
        element: <CompanyProfileList />
      },
      {
        path: 'member-profiles',
        element: <MemberProfileList />
      },
      {
        path: 'company/profile-editor',
        element: <ProfileEditor />
      },
      {
        path: 'member/profile-editor',
        element: <ProfileEditor />
      },
      {
        path: 'company/profile-editor/:id',
        element: <ProfileEditor />
      },
      {
        path: 'member/profile-editor/:id',
        element: <ProfileEditor />
      },
      {
        path: 'business-apps',
        element: <BusinessAppList />
      },
      {
        path: 'business-apps/review-app/',
        element: <ReviewAppEditor />
      },
      {
        path: 'business-apps/listing-app/',
        element: <ListingAppEditor />
      },
      {
        path: 'business-apps/listing-app/item/new',
        element: <ItemEditor />
      },
      {
        path: 'business-apps/listing-app/item/edit/:id',
        element: <ItemEditor />
      },
      {
        path: 'business-apps/review-app/:id',
        element: <ReviewAppEditor />
      },
      {
        path: 'business-apps/lead-app/',
        element: <LeadAppEditor />
      },
      {
        path: 'business-apps/lead-app/:id',
        element: <LeadAppEditor />
      },
      {
        path: 'business-apps/ki-form-app/',
        element: <KiFormAppEditor />
      },
      {
        path: 'business-apps/ki-form-app/:id',
        element: <KiFormAppEditor />
      },
      {
        path: 'business-apps/external-app/',
        element: <ExternalAppEditor />
      },
      {
        path: 'business-apps/external-app/:id',
        element: <ExternalAppEditor />
      },
      {
        path: 'business-apps/scan-to-call-app/',
        element: <ScanToCallAppEditor />
      },
      {
        path: 'business-apps/scan-to-call-app/:id',
        element: <ScanToCallAppEditor />
      },
      {
        path: 'member/my-cards',
        element: <MyCardList />
      },
      {
        path: 'contact/list',
        element: <ContactList />
      },
      {
        path: 'contact/new',
        element: <CreateContact />
      },
      {
        path: 'contact/edit/:id',
        element: <CreateContact />
      },
      {
        path: 'contact/crm/:id',
        element: <ContactCRM />
      },
      {
        path: 'lead/list',
        element: <LeadList />
      },
      {
        path: 'lead/new',
        element: <CreateLead />
      },
      {
        path: 'lead/edit/:id',
        element: <CreateLead />
      },
      {
        path: 'lead/crm/:id',
        element: <LeadCRM />
      },
      {
        path: 'review/list',
        element: <ReviewList />
      },
      {
        path: 'review/crm/:id',
        element: <ReviewCRM />
      },
      {
        path: 'team/list',
        element: <TeamList />
      },
      {
        path: 'team/employee/new',
        element: <CreateEmployee />
      },
      {
        path: 'team/employee/edit/:id',
        element: <CreateEmployee />
      },
      {
        path: 'admin/user/list',
        element: <UserList />
      },
      {
        path: 'setting',
        element: <ApplicationSetting />
      },
      {
        path: 'not-found',
        element: <NotFound />
      },
      {
        path: '*',
        element: <Navigate to="not-found" />
      }
    ]
  },
  {
    path: 'app/map-nfc-code',
    element: <MapNfcCode />
  },
  {
    path: 'app/delete-account',
    element: <AccountDeletion />
  }
];

export const routerManager: RouteObject[] = [
  {
    path: '',
    element: <Navigate to="app" />
  },
  {
    path: 'app',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: 'app/sign-up',
    element: <SignUpLogout />
  },

  // Extended Sidebar Layout

  {
    path: 'app',
    element: <BoxedSidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" />
      },

      {
        path: 'dashboard',
        children: [
          {
            path: '',
            element: <DashboardMain />
          }
        ]
      },
      {
        path: 'member-profiles',
        element: <MemberProfileList />
      },
      {
        path: 'company/profile-editor',
        element: <ProfileEditor />
      },
      {
        path: 'member/profile-editor',
        element: <ProfileEditor />
      },
      {
        path: 'company/profile-editor/:id',
        element: <ProfileEditor />
      },
      {
        path: 'member/profile-editor/:id',
        element: <ProfileEditor />
      },
      {
        path: 'business-apps',
        element: <BusinessAppList />
      },
      {
        path: 'business-apps/review-app/',
        element: <ReviewAppEditor />
      },
      {
        path: 'business-apps/listing-app/',
        element: <ListingAppEditor />
      },
      {
        path: 'business-apps/listing-app/item/new',
        element: <ItemEditor />
      },
      {
        path: 'business-apps/listing-app/item/edit/:id',
        element: <ItemEditor />
      },
      {
        path: 'business-apps/review-app/:id',
        element: <ReviewAppEditor />
      },
      {
        path: 'business-apps/lead-app/',
        element: <LeadAppEditor />
      },
      {
        path: 'business-apps/lead-app/:id',
        element: <LeadAppEditor />
      },
      {
        path: 'business-apps/ki-form-app/',
        element: <KiFormAppEditor />
      },
      {
        path: 'business-apps/ki-form-app/:id',
        element: <KiFormAppEditor />
      },
      {
        path: 'business-apps/external-app/',
        element: <ExternalAppEditor />
      },
      {
        path: 'business-apps/external-app/:id',
        element: <ExternalAppEditor />
      },
      {
        path: 'business-apps/scan-to-call-app/',
        element: <ScanToCallAppEditor />
      },
      {
        path: 'business-apps/scan-to-call-app/:id',
        element: <ScanToCallAppEditor />
      },
      {
        path: 'member/my-cards',
        element: <MyCardList />
      },
      {
        path: 'contact/list',
        element: <ContactList />
      },
      {
        path: 'contact/new',
        element: <CreateContact />
      },
      {
        path: 'contact/edit/:id',
        element: <CreateContact />
      },
      {
        path: 'contact/crm/:id',
        element: <ContactCRM />
      },
      {
        path: 'lead/list',
        element: <LeadList />
      },
      {
        path: 'lead/new',
        element: <CreateLead />
      },
      {
        path: 'lead/edit/:id',
        element: <CreateLead />
      },
      {
        path: 'lead/crm/:id',
        element: <LeadCRM />
      },
      {
        path: 'review/list',
        element: <ReviewList />
      },
      {
        path: 'review/crm/:id',
        element: <ReviewCRM />
      },
      {
        path: 'team/list',
        element: <TeamList />
      },
      {
        path: 'team/employee/new',
        element: <CreateEmployee />
      },
      {
        path: 'team/employee/edit/:id',
        element: <CreateEmployee />
      },
      {
        path: 'admin/user/list',
        element: <UserList />
      },
      {
        path: 'setting',
        element: <ApplicationSetting />
      },
      {
        path: 'not-found',
        element: <NotFound />
      },
      {
        path: '*',
        element: <Navigate to="not-found" />
      }
    ]
  },
  {
    path: 'app/map-nfc-code',
    element: <MapNfcCode />
  },
  {
    path: 'app/delete-account',
    element: <AccountDeletion />
  }
];

//For Individual AccountType
export const routerIndAcc: RouteObject[] = [
  {
    path: '',
    element: <Navigate to="app" />
  },
  {
    path: 'app',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: 'app/sign-up',
    element: <SignUpLogout />
  },

  // Extended Sidebar Layout

  {
    path: 'app',
    element: <BoxedSidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" />
      },

      {
        path: 'dashboard',
        children: [
          {
            path: '',
            element: <DashboardMain />
          }
        ]
      },
      {
        path: 'member-profiles',
        element: <MemberProfileList />
      },
      {
        path: 'member/profile-editor',
        element: <ProfileEditor />
      },
      {
        path: 'member/profile-editor/:id',
        element: <ProfileEditor />
      },
      {
        path: 'business-apps',
        element: <BusinessAppList />
      },
      {
        path: 'business-apps/review-app/',
        element: <ReviewAppEditor />
      },
      {
        path: 'business-apps/review-app/:id',
        element: <ReviewAppEditor />
      },
      {
        path: 'business-apps/lead-app/',
        element: <LeadAppEditor />
      },
      {
        path: 'business-apps/lead-app/:id',
        element: <LeadAppEditor />
      },
      {
        path: 'business-apps/ki-form-app/',
        element: <KiFormAppEditor />
      },
      {
        path: 'business-apps/ki-form-app/:id',
        element: <KiFormAppEditor />
      },
      {
        path: 'business-apps/external-app/',
        element: <ExternalAppEditor />
      },
      {
        path: 'business-apps/external-app/:id',
        element: <ExternalAppEditor />
      },
      {
        path: 'business-apps/scan-to-call-app/',
        element: <ScanToCallAppEditor />
      },
      {
        path: 'business-apps/scan-to-call-app/:id',
        element: <ScanToCallAppEditor />
      },
      {
        path: 'member/my-cards',
        element: <MyCardList />
      },
      {
        path: 'contact/list',
        element: <ContactList />
      },
      {
        path: 'contact/new',
        element: <CreateContact />
      },
      {
        path: 'contact/edit/:id',
        element: <CreateContact />
      },
      {
        path: 'contact/crm/:id',
        element: <ContactCRM />
      },
      {
        path: 'lead/list',
        element: <LeadList />
      },
      {
        path: 'lead/new',
        element: <CreateLead />
      },
      {
        path: 'lead/edit/:id',
        element: <CreateLead />
      },
      {
        path: 'lead/crm/:id',
        element: <ContactCRM />
      },
      {
        path: 'review/list',
        element: <ReviewList />
      },
      {
        path: 'not-found',
        element: <NotFound />
      },
      {
        path: '*',
        element: <Navigate to="not-found" />
      }
    ]
  },
  {
    path: 'app/map-nfc-code',
    element: <MapNfcCode />
  },
  {
    path: 'app/delete-account',
    element: <AccountDeletion />
  }
];
