export default function parseReqType(e: string): { name: string, value: string } {

  const res = {
    name: e,
    value: ''
  };

  if (e) {
    const i = e.indexOf('-');
    if (i != -1) {
      res.name = e.substring(0, i);
      res.value = e.substring(i + 1);
    }
  }

  return res;
}