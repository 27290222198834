import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  Button,
  styled,
  ButtonGroup,
  Popper,
  Grow
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import React from 'react';

const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);

function PageHeader(props: { isEdit: boolean }) {
  const { t }: { t: any } = useTranslation();
  const location = useLocation();

  return (
    <RootWrapper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h3" component="h3" gutterBottom>
                {props.isEdit ? 'Edit Member' : t('New Member')}
              </Typography>
              <Typography variant="subtitle2">
                {props.isEdit
                  ? t('Fill in the fields below to edit member')
                  : t('Fill in the fields below to create a new member')}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Button
            sx={{
              mt: { xs: 2, sm: 0 }
            }}
            component={RouterLink}
            startIcon={<ArrowBackTwoToneIcon />}
            to={`/${location.pathname.split('/')[1]}/team/list`}
            variant="contained"
          >
            {t('Go back to all members')}
          </Button>
        </Grid>
      </Grid>
    </RootWrapper>
  );
}

export default PageHeader;
