const extractNumber=(contactNumber?:string)=>{
    if(!contactNumber)
    return contactNumber;

    let cnumber = contactNumber;
    if(contactNumber.trim().startsWith('+')){
      const index=contactNumber.trim().indexOf(' ');
      if(index!=-1){
        cnumber = contactNumber.trim().substring(index).trim();
      }
    }
    return cnumber;
  }

  export default extractNumber;