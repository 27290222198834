import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Drawer,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  styled,
  useTheme
} from '@mui/material';
import { Field, FieldProps, Formik } from 'formik';
import { TextField as FmTextFiled } from 'formik-mui';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import QrCodeCard from 'src/components/QrCodeCard';
import { BusinessAppInstanceDto } from 'src/services/dto';
import { ApiUrls } from 'src/services/endpoints';
import {
  appQueryClient,
  getAllProfilesList,
  getAppIstanceById,
  saveAppInstance
} from 'src/services/query-client';
import { RootState } from 'src/store';
import readQueryString from 'src/utility/readQueryString';
import * as Yup from 'yup';
import {
  BusinessAppInstance,
  BusinessAppNames,
  CodeItem,
  QrInfo
} from '../../../services/apiService/response-models';
import { parseApiError } from '../../../utility/parseToApiErrorMessage';
import PageHeader from './PageHeader';

const DrawerWrapper = styled(Drawer)(
  ({ theme }) => `
    width: 400px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 400px;
        height: calc(100% - ${theme.header.height});
        position: absolute;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.alpha.white[10]};
    }
`
);

const DrawerWrapperMobile = styled(Drawer)(
  ({ theme }) => `
    width: 360px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 360px;
        z-index: 3;
        background: ${theme.colors.alpha.white[30]};
  }
`
);

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

const leadTemplates: CodeItem[] = [
  {
    code: 'default',
    value: 'Default'
  }
];

export default function LeadAppEditor() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id } = useParams();

  const [qrInfo, setQrInfo] = useState<QrInfo | null>(null);
  const [assignToId, setAssignToId] = useState<string>('');
  const [notifyToIds, setNotifyToIds] = useState<string[]>();

  const [isEdit, setIsEdit] = useState(Boolean(id && id.length));

  const { user } = useSelector((state: RootState) => state.app);

  const {
    data: { data: model } = {},
    isLoading,
    refetch: refetchById
  } = useQuery(['app-instance', id], (k) => getAppIstanceById(id), {
    enabled: isEdit,
    onSuccess: (e) => {
      setQrInfo(e.data.qrInfo);
    }
  });

  const { data: { data: profiles = [] } = {}, refetch } = useQuery(
    'profiles',
    getAllProfilesList
  );

  const {
    mutate: save,
    isError: isSaveError,
    error: errorOnSave,
    isLoading: isSaving
  } = useMutation((e: BusinessAppInstanceDto) => saveAppInstance(e, id), {
    onSuccess: (o) => onSaveSuccess(o.data)
  });

  //----Queries

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (isSaveError) {
    const msg = parseApiError(
      errorOnSave,
      `Failed to ${!isEdit ? 'created' : 'updated'} Contact`
    );
    enqueueSnackbar(t(msg), { variant: 'error' });
  }

  const onSaveSuccess = async (updatedModel: BusinessAppInstance) => {
    enqueueSnackbar(
      t(`App was ${!isEdit ? 'created' : 'updated'} successfully`),
      { variant: 'success' }
    );
    if (!isEdit) navigate('/app/business-apps/lead-app/' + updatedModel.id);
    else {
      appQueryClient.setQueryData(['app-instances', id], updatedModel);
      refetchById();
    }
  };

  const handleBack = (): void => {
    return navigate(readQueryString()?.ret || `/app/business-apps`);
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  if (isSaveError) {
    closeSnackbar();
    enqueueSnackbar(t(parseApiError(errorOnSave, 'Failed to save')), {
      variant: 'error'
    });
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          label: model?.label || '',
          template: model?.leadApp?.template || 'default',
          title: model?.leadApp?.title || '',
          subTitle: model?.leadApp?.subTitle || '',
          profileId: model?.profileId || ''
        }}
        validationSchema={Yup.object().shape({
          label: Yup.string().max(250),
          title: Yup.string().max(250),
          subTitle: Yup.string().max(250)
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          if (!qrInfo?.code) {
            enqueueSnackbar(t('Please provide QR code'), { variant: 'error' });
            setStatus({ success: false });
            setSubmitting(false);
            return;
          }
          const updatedModel: BusinessAppInstanceDto = {
            ..._values,
            qrInfo: qrInfo,
            label: _values.label,
            appName: BusinessAppNames.LeadApp,
            profileId: _values.profileId,
            leadApp: {
              leadName: _values.label,
              template: _values.template,
              title: _values.title,
              subTitle: _values.subTitle,
              assignToId: assignToId,
              notifyToIds: notifyToIds
            }
          };

          save(updatedModel);
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3} display="flex">
              <MainContentWrapper>
                <Grid
                  sx={{
                    px: 4
                  }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid item xs={12}>
                    <Box
                      mt={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <PageHeader
                        isEdit={isEdit}
                        id={id}
                        onBack={handleBack}
                        isSaving={isSaving}
                      />
                    </Box>
                  </Grid>

                  {isLoading && (
                    <Grid item xs={12}>
                      <LinearProgress />
                      loading...
                    </Grid>
                  )}
                  <Grid container mt={2}>
                    <Grid item xs={12} md={3}>
                      <QrCodeCard
                        label="Lead link:"
                        qrInfo={model?.qrInfo}
                        placeholder=""
                        onChange={(e) => {
                          setQrInfo({ ...e });
                        }}
                        buildText={async (o) => {
                          return ApiUrls.viewerAppUrl + '/lead/' + o;
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Card sx={{ p: 2 }}>
                            <Field
                              fullWidth
                              name="label"
                              component={FmTextFiled}
                              label="Lead name for your reference"
                              placeholder={t('enter a name')}
                            />
                            <FormHelperText>
                              (Optional) Enter a name
                            </FormHelperText>
                          </Card>
                        </Grid>
                        <Grid item xs={12}>
                          <Card>
                            <Grid
                              sx={{ padding: theme.spacing(2) }}
                              container
                              spacing={3}
                            >
                              <Grid item md={6} xs={12}>
                                <FormControl variant="filled" fullWidth>
                                  <InputLabel id="profile-select">
                                    Associate to a profile
                                  </InputLabel>
                                  <Field name="profileId">
                                    {({ field, form, meta }: FieldProps) => (
                                      <Select
                                        labelId="profile-select"
                                        value={field.value}
                                        onChange={(_event) => {
                                          form.setFieldValue(
                                            field.name,
                                            _event.target.value
                                          );
                                        }}
                                      >
                                        {profiles.map((o) => (
                                          <MenuItem key={o.id} value={o.id}>
                                            {(o.data?.businessName ||
                                              o.data?.fullName) +
                                              `(${
                                                o.data.businessName
                                                  ? 'Company'
                                                  : 'Indiviudal'
                                              })`}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    )}
                                  </Field>
                                  {/*{touched.stateCode && (errors.stateCode ? true : false) &&*/}
                                  {/*    <FormHelperText error={touched.stateCode && (errors.stateCode ? true : false)}>{errors.stateCode}</FormHelperText >}*/}
                                </FormControl>
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <FormControl variant="filled" fullWidth>
                                  <InputLabel id="template-select">
                                    Lead page template
                                  </InputLabel>
                                  <Field name="template">
                                    {({ field, form, meta }: FieldProps) => (
                                      <Select
                                        labelId="template-select"
                                        value={field.value}
                                        onChange={(_event) => {
                                          form.setFieldValue(
                                            field.name,
                                            _event.target.value
                                          );
                                        }}
                                      >
                                        {leadTemplates.map((o) => (
                                          <MenuItem key={o.code} value={o.code}>
                                            {o.value}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    )}
                                  </Field>
                                  {/*{touched.stateCode && (errors.stateCode ? true : false) &&*/}
                                  {/*    <FormHelperText error={touched.stateCode && (errors.stateCode ? true : false)}>{errors.stateCode}</FormHelperText >}*/}
                                </FormControl>
                              </Grid>

                              <Grid item md={6} xs={12}>
                                <Field
                                  fullWidth
                                  name="title"
                                  component={FmTextFiled}
                                  label="Title"
                                  placeholder={t('Enter here...')}
                                />
                                <FormHelperText>
                                  Title to be desplayed on lead page
                                </FormHelperText>
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <Field
                                  fullWidth
                                  name="subTitle"
                                  component={FmTextFiled}
                                  label="Subtitle"
                                  placeholder={t('Enter here...')}
                                />
                                <FormHelperText>
                                  Subtitle to be desplayed on lead page
                                </FormHelperText>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                        <Grid item xs={12}>
                          <Card
                            sx={{
                              p: 1
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Button
                                  type="button"
                                  variant="outlined"
                                  onClick={handleBack}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  sx={{ ml: 2 }}
                                  type="submit"
                                  variant="contained"
                                  startIcon={
                                    isSubmitting || isSaving ? (
                                      <CircularProgress size="1rem" />
                                    ) : (
                                      <SaveIcon />
                                    )
                                  }
                                  disabled={
                                    isLoading || isSubmitting || isSaving
                                  }
                                >
                                  Save
                                </Button>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}></Grid>
                  </Grid>
                </Grid>
              </MainContentWrapper>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}
