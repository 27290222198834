import {
  Avatar,
  Box,
  Card,
  Divider,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';

import CardMembershipTwoToneIcon from '@mui/icons-material/CardMembershipTwoTone';
import ContentCopyTwoTone from '@mui/icons-material/ContentCopyTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import QrCode2TwoToneIcon from '@mui/icons-material/QrCode2TwoTone';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Profile } from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import { ApiUrls } from 'src/services/endpoints';
import Label from 'src/ui-bloom/components/Label';

function ProfileBlock({
  profile,
  onDelete,
  onClone,
  onLinkedCard
}: {
  profile: Profile;
  onDelete: (id: string) => void;
  onClone: (id: string) => void;
  onLinkedCard: (id: string) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const onEdit = () => {
    navigate('/app/member/profile-editor/' + profile.id);
  };
  const onQrView = () => {
    let url = ApiUrls.viewerAppUrl + '/' + profile.id;
    if (profile.qrInfo?.text) {
      url = profile.qrInfo?.text;
    } else if (profile.qrInfo?.code) {
      url = ApiUrls.viewerAppUrl + '/' + profile.qrInfo?.code;
    }
    window.open(url, '_blank');
  };

  return (
    <Card
      sx={{
        p: 2.5
      }}
      variant="outlined"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Avatar
            onClick={onEdit}
            sx={{
              '.MuiAvatar-img': {
                objectFit: 'fill'
                // height: '100%'
              },
              width: 50,
              height: 50,
              cursor: 'pointer',
              boxShadow: `0 .113rem .5rem ${theme.colors.alpha.black[10]}, 0 .126rem .225rem ${theme.colors.alpha.black[30]}`
            }}
            src={
              profile.data.profileImage
                ? cdnService.toImageUrl(profile.data.profileImage)
                : ''
            }
          >
            {(profile.data.fullName || ' ')[0].toUpperCase()}
          </Avatar>
          <Box ml={1.5}>
            <Link
              onClick={onEdit}
              color="text.primary"
              underline="none"
              sx={{
                transition: `${theme.transitions.create(['color'])}`,
                cursor: 'pointer',
                '&:hover': {
                  color: `${theme.colors.primary.main}`
                }
              }}
              variant="h5"
            >
              {profile.data.fullName}
            </Link>{' '}
            {profile.isActive && <Label color="primary">Active</Label>}
            {profile.cards && profile.cards.length > 0 && (
              <Label color="success">
                {profile.cards[0].cardType} card linked
              </Label>
            )}
            <Typography variant="subtitle2">
              {(profile.data.jobTitle || '') +
                ' ' +
                (profile.data.department || '')}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip arrow placement="top" title={t('Delete profile')}>
            <IconButton onClick={() => onDelete(profile.id)} color="error">
              <DeleteIcon
                sx={{
                  fontSize: `${theme.typography.pxToRem(18)}`
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Divider
        sx={{
          mt: 2.5
        }}
      />
      <Box display="flex" justifyContent="center" py={2}>
        <Tooltip arrow placement="top" title={t('QR profile view')}>
          <IconButton onClick={onQrView} color="success">
            <QrCode2TwoToneIcon
              sx={{
                fontSize: `${theme.typography.pxToRem(18)}`
              }}
            />
          </IconButton>
        </Tooltip>
        {profile.cards && profile.cards.length > 0 && (
          <Tooltip arrow placement="top" title={t('Linked to card')}>
            <IconButton onClick={() => onLinkedCard(profile.id)} color="info">
              <CardMembershipTwoToneIcon
                sx={{
                  fontSize: `${theme.typography.pxToRem(18)}`
                }}
              />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip
          onClick={() => onClone(profile.id)}
          arrow
          placement="top"
          title={t('Copy profile')}
        >
          <IconButton color="success">
            <ContentCopyTwoTone
              sx={{
                fontSize: `${theme.typography.pxToRem(18)}`
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip arrow placement="top" title={t('Edit profile')}>
          <IconButton onClick={onEdit} color="success">
            <EditTwoToneIcon
              sx={{
                fontSize: `${theme.typography.pxToRem(18)}`
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Divider />
      <Stack
        sx={{
          mt: 2.5,
          textAlign: 'center'
        }}
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={2}
      >
        <Box>
          <Typography gutterBottom variant="subtitle2">
            {t('Views')}
          </Typography>
          <Typography variant="h4">-</Typography>
        </Box>
        <Box>
          <Typography gutterBottom variant="subtitle2">
            {t('Reviews')}
          </Typography>
          <Typography variant="h4">{profile.reviewCount}</Typography>
        </Box>

        <Box>
          <Typography gutterBottom variant="subtitle2">
            {t('Leads')}
          </Typography>
          <Typography variant="h4">{profile.leadCount}</Typography>
        </Box>
      </Stack>
    </Card>
  );
}

export default ProfileBlock;
