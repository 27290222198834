import { Profile, ProfileData, ProfileTypes } from "src/services/apiService/response-models";

export class ProfileValueReader {
  readonly p?: Profile;

  readonly data?: ProfileData;

  constructor(_p?: Profile) {
    this.p = _p;
    this.data = _p?.data;
  }

  public readName(): string {

    if (!this.p)
      return '';

    const isBusiness = this.p.type == ProfileTypes.Business ? true : false;
    return (isBusiness ? this.p.data?.businessName : this.p.data?.fullName) || '';
  }

  public readLogo(): string {

    if (!this.p)
      return '';

    const isBusiness = this.p.type == ProfileTypes.Business ? true : false;
    return (isBusiness ? this.p.data?.logoImage : this.p.data?.profileImage) || '';
  }
}