import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAuth from 'src/ui-bloom/hooks/useAuth';

import { ArrowDropDownCircleOutlined } from '@mui/icons-material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  styled
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import ContactsUploadDialog from '../contacts-upload-dialog';

const Input = styled('input')({
  display: 'none'
});

const AvatarWrapper = styled(Box)(
  ({ theme }) => `

    position: relative;

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(6)};
    height: ${theme.spacing(6)};
    bottom: -${theme.spacing(2)};
    right: -${theme.spacing(2)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);

function PageHeader(props: {
  refreshData: () => void;
  handleExportContacts: () => void;
  onUploadSuccess: (ids: string[]) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const navigate = useNavigate();

  const onMenuClick = (name: string) => {
    if (name == 'newContact') {
      navigate('/app/contact/new');
    } else if (name == 'uploadContacts') {
      setOpen(true);
    } else if (name == 'exportContacts') {
      props.handleExportContacts();
    }
  };

  const onSave = () => {
    // props.refreshData();
    props.onUploadSuccess([]);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {t('Contacts')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'Listing all contacts. Add, edit & manage details from this page'
            )}
          </Typography>
        </Grid>
        <Grid item>
          <SplitButton onClick={onMenuClick} />
        </Grid>
      </Grid>
      <ContactsUploadDialog
        isOpen={open}
        onClose={() => setOpen(false)}
        onSave={() => onSave()}
      />
    </>
  );
}

function SplitButton(props: { onClick: (name: string) => void }) {
  const options = ['New Contact', 'Upload Contacts', 'Export'];
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = () => {
    props.onClick('newContact');
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
    option: string
  ) => {
    setSelectedIndex(index);
    let clickedItem = '';
    if (option == 'Upload Contacts') {
      clickedItem = 'uploadContacts';
    } else if (option == 'New Contact') {
      clickedItem = 'newContact';
    } else if (option == 'Export') {
      clickedItem = 'exportContacts';
    }
    props.onClick(clickedItem);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          startIcon={<AddTwoToneIcon fontSize="small" />}
          onClick={handleClick}
        >
          {'Add contact'}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownCircleOutlined />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) =>
                        handleMenuItemClick(event, index, option)
                      }
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

export default PageHeader;
