import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import {
  UserAccountEntity,
  UserRoles
} from 'src/services/apiService/response-models';

export interface SettingTab {
  readonly name: string;
  readonly text: string;
  readonly role?: UserRoles;
  readonly icon: React.ReactNode;
}

const SettingTabs: SettingTab[] = [
  {
    name: 'general',
    text: 'General',
    icon: <SettingsIcon />
  },
  // {
  //     name: 'nfcMap',
  //     text: 'NFC Code Mapping',
  //     icon: <CreditCardIcon />
  // },
  {
    name: 'mysubscription',
    text: 'Subscription',
    icon: <CurrencyExchangeTwoToneIcon />
  }
];

const getSettingTabs = (user: UserAccountEntity) => {
  const roleNames = (user.user.roles || []).map((o) => o.roleName);

  return SettingTabs.filter((o) => !o.role || roleNames.includes(o.role));
};
export default getSettingTabs;
