import { Box, Icon, IconButton, Tooltip, alpha, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldWrapper } from 'src/components/FieldWrapper';
import { PreviewerField } from 'src/contents/profile-editor/ProfilePreviewer';
import {
  ProfileData,
  ProfileFieldNames
} from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';

export default function BtnGroupFieldsBlock({
  profileTheme,
  preFields,
  data,
  onEditClick,
  fieldInEdit
}: {
  preFields: PreviewerField[];
  data: ProfileData;
  profileTheme: string;
  onEditClick: (e: React.MouseEvent<HTMLElement>, f: PreviewerField) => void;
  fieldInEdit: PreviewerField | null;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  // const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [fields, setFields] = useState<PreviewerField[]>([]);

  useEffect(() => {
    const arr: PreviewerField[] = [];

    const btnFieldNames = [
      ProfileFieldNames.LinkedIn,
      ProfileFieldNames.Facebook,
      ProfileFieldNames.Twitter,
      ProfileFieldNames.Instagram,
      ProfileFieldNames.Snapchat,
      ProfileFieldNames.YouTube,
      ProfileFieldNames.Twitch
    ];

    btnFieldNames.forEach((fname) => {
      const match = preFields.find((o) => o.field.name == fname);
      if (match) {
        arr.push(match);
      }
    });
    const matched = arr.find((o) => o.field.name == fieldInEdit?.field.name);
    if (
      !matched &&
      fieldInEdit &&
      btnFieldNames.find((o) => o == fieldInEdit.field.name)
    ) {
      arr.push(fieldInEdit);
    }

    setFields(arr);
  }, [preFields, fieldInEdit]);

  const isActive = (e: PreviewerField) => {
    return fieldInEdit?.preId === e.preId;
  };

  if (!fields.length) return <></>;

  return (
    <Box display="flex" justifyContent="center">
      {fields.map((field) => (
        <FieldWrapper
          key={field.preId}
          className={
            'field-wrapper ' +
            field.preId +
            (isActive(field) ? ' active-field' : '')
          }
        >
          {' '}
          <Tooltip arrow placement="top" title={t(field.field.displayName)}>
            <IconButton
              onClick={(e) => onEditClick(e, field)}
              sx={{
                width: 60,
                height: 60,
                color: `${theme.colors.alpha.trueWhite[70]}`,
                background: `${theme.colors.alpha.trueWhite[10]}`,
                transition: `${theme.transitions.create(['all'])}`,
                '&:hover': {
                  color: `${theme.colors.alpha.trueWhite[100]}`,
                  background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`
                },
                borderRadius: 50,
                marginRight: '4px'
              }}
              size="large"
            >
              <Icon>
                <img
                  src={cdnService.toCdnUrl(field.field.icon, 'icons')}
                  height={25}
                  width={25}
                />
              </Icon>
            </IconButton>
          </Tooltip>
        </FieldWrapper>
      ))}
    </Box>
  );
}
