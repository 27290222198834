export default async function toDataURL(imgUrl: string, defValue?: string) {
  return new Promise<string>((res) => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = imgUrl;
    image.onload = function () {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      canvas.remove();
      res(dataURL);
    };
    image.onerror = function () {
      res(defValue ?? imgUrl);
    }
  });
}
