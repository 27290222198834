import { useTheme } from '@mui/material';
import { TitledFieldValue } from 'src/services/apiService/response-models';
import { FieldRenderBlockProp } from '../..';
import getMultiFieldValue from '../../_utils/getMultiFieldValue';
import ListAvatarBlock from '../../_utils/list-avatar-block';

export default function EmailBlockRenderer(props: FieldRenderBlockProp) {
  const theme = useTheme();

  const value = getMultiFieldValue<TitledFieldValue<string>>(
    props,
    props.data.emails
  );

  return (
    <ListAvatarBlock title={value?.title} value={value?.value} e={props} />
  );
}
