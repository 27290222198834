import AppsTwoToneIcon from '@mui/icons-material/AppsTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import {
  Avatar,
  Box,
  Card,
  IconButton,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BusinessAppInstance,
  BusinessAppNames
} from 'src/services/apiService/response-models';
import getThemeColor from '../../utility/getThemeColor';
import DescText from '../desc-text';

const BgComposed = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: ${theme.transitions.create(['opacity'])};
    z-index: 6;
    opacity: 1;
   
  `
);

const CardWrapper = styled(Card)(
  ({ theme }) => `

    background: ${theme.colors.gradients.blue1};
    color:  ${theme.colors.alpha.trueWhite[100]};
    border-radius: ${theme.general.borderRadiusXl};
    padding: ${theme.spacing(1)};
    box-shadow: none;

    .MuiCardHeader-root {
      .MuiCardHeader-title {
          color: ${theme.colors.alpha.trueWhite[100]};;
          font-weight: normal;
          padding-bottom: ${theme.spacing(0.5)};
      }
    }
    
    .MuiCardHeader-subheader {
        color:  ${theme.colors.alpha.trueWhite[100]};;
    }

      .MuiActionButtons {
            background: ${alpha(theme.colors.alpha.white[100], 0.95)};
            border-radius: ${theme.general.borderRadius};
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
            top: 15%;
            margin-top: -${theme.spacing(3.5)};
            position: absolute;
            right: ${theme.spacing(1.5)};
            padding: ${theme.spacing(0.5, 1)};
            transition: ${theme.transitions.create(['visibility', 'opacity'])};
            z-index:100;
            .MuiIconButton-root {
                border-radius: 100px;
                width: ${theme.spacing(5)};
                height: ${theme.spacing(5)};
                margin: ${theme.spacing(0.5)};
            }
      }

      &:hover {
          .MuiActionButtons {
            visibility: visible;
            opacity: 1;
      }   
      }
  `
);

const CardActions = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};
    z-index: 7;
  `
);

const Label = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.success.main};
    color: ${theme.palette.success.contrastText};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(10)};
    font-weight: bold;
    line-height: 23px;
    height: 22px;
    padding: ${theme.spacing(0, 1.2)};
    border-radius: 50px;
  `
);

const DefaultAppThumb = (props: {
  item: BusinessAppInstance;
  showDelete?: boolean;
  actionLabel?: string;
  onDeleteClick?: (item: BusinessAppInstance) => void;
  onSelect?: (item: BusinessAppInstance) => void;
}) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const tpTiltle = props.onSelect ? 'Click to view/edit' : '';

  const blockInfo = convertAppToBlockInfo(props.item);

  return (
    <CardWrapperThemed appName={props.item.businessApp.name}>
      {props.showDelete && (
        <Box className="MuiActionButtons">
          <Tooltip arrow placement="top" title={t('Delete profile')}>
            <IconButton
              onClick={() => props.onDeleteClick(props.item)}
              color="error"
            >
              <CloseTwoToneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <BgComposed
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className="MuiBgComposed"
        sx={{ cursor: props.onSelect ? 'pointer' : 'inherit' }}
        onClick={() => (props.onSelect ? props.onSelect(props.item) : null)}
      >
        <Tooltip title={tpTiltle} placement="top">
          <Avatar
            sx={{
              width: 62,
              height: 62,
              mb: 1,
              border: `${theme.colors.alpha.trueWhite[100]} solid 3px`
            }}
            src={blockInfo.image}
            variant={'rounded'}
          >
            <AppsTwoToneIcon />
          </Avatar>
        </Tooltip>
        <Tooltip title={tpTiltle} placement="top">
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: `${theme.colors.alpha.trueWhite[100]}`
            }}
          >
            {blockInfo.title}
          </Typography>
        </Tooltip>
        <Tooltip title={tpTiltle} placement="top">
          <Typography
            variant="subtitle2"
            sx={{
              mb: 1,
              color: `${theme.colors.alpha.trueWhite[70]}`
            }}
          >
            <DescText text={blockInfo.subTitle} maxLen={70} />
          </Typography>
        </Tooltip>
        {/*        
          <Tooltip title={tpTiltle || 'Qr code'} placement="top">
            <Box display="flex" alignItems="center" justifyContent="center">
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  background: `${theme.colors.alpha.black[100]}`,
                  color: `${theme.colors.alpha.white[100]}`
                }}
              >
                <QrCode2TwoToneIcon fontSize="small" />
              </Avatar>

              <span style={{ marginLeft: 5 }}> {profile.qrName}</span>
            </Box>
          </Tooltip> */}

        {/* <Button
          sx={{
            px: 2.5,
            borderRadius: 10,
            transform: 'scale(1)',
            transition: `${theme.transitions.create(['all'])}`,
            boxShadow: `${theme.colors.shadows.info}`,

            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: `${theme.colors.shadows.info}`
            },
            '&:active': {
              boxShadow: 'none'
            }
          }}
          variant="contained"
          startIcon={<AccountBoxTwoToneIcon />}
          color="info"
          onClick={() => props.onClick(props.item)}
        >
          {t(props.actionLabel || 'View card')}
        </Button> */}
      </BgComposed>

      <CardActions
        sx={{
          bottom: 'auto',
          top: `${theme.spacing(2)}`,
          right: 'auto',
          left: `${theme.spacing(2)}`
        }}
      >
        <Label
          sx={{
            background: `${theme.palette.info.main}`,
            color: `${theme.palette.info.contrastText}`
          }}
        >
          {t(props.item.businessApp.displayName)}
        </Label>
      </CardActions>
    </CardWrapperThemed>
  );
};

interface BlockItemInfo {
  image: string;
  tag: string;
  title: string;
  subTitle: string;
}

const convertAppToBlockInfo = (app: BusinessAppInstance): BlockItemInfo => {
  const result: BlockItemInfo = {
    image: '',
    tag: app.businessApp.displayName,
    title: app.label || app.businessApp.displayName,
    subTitle: app.qrInfo?.code || '[No QR]'
  };

  return result;
};
const CardWrapperThemed: FC<{ appName?: string }> = ({ children, appName }) => {
  const theme = useTheme();
  const colorAppMap = {};

  if (appName == BusinessAppNames.ReviewApp) {
    return <CardWrapperTurquoise>{children}</CardWrapperTurquoise>;
  }
  if (appName == BusinessAppNames.LeadApp) {
    return <CardWrapperGreen>{children}</CardWrapperGreen>;
  }
  if (appName == BusinessAppNames.ExternalApp) {
    return <CardWrapperBlue>{children}</CardWrapperBlue>;
  }
  if (appName == BusinessAppNames.ScanToCallApp) {
    return <CardWrapperPink>{children}</CardWrapperPink>;
  }
  if (appName == BusinessAppNames.KiFormApp) {
    return <CardWrapperGrey>{children}</CardWrapperGrey>;
  }
  return <CardWrapperPink>{children}</CardWrapperPink>;
};

const CardWrapperBlue = ({ children }) => {
  const theme = useTheme();
  return (
    <CardWrapper
      sx={{
        height: '260px',
        textAlign: 'center',
        position: 'relative',
        transition: `${theme.transitions.create(['box-shadow', 'transform'])}`,
        background: getThemeColor(theme, 'blue'),

        transform: 'translateY(0px)',

        '&:hover': {
          transform: `translateY(-${theme.spacing(0.5)})`,
          boxShadow: `0 2rem 8rem 0 ${alpha(
            theme.colors.alpha.black[100],
            0.05
          )}, 
                        0 0.6rem 1.6rem ${alpha(
                          theme.colors.alpha.black[100],
                          0.15
                        )}, 
                        0 0.2rem 0.2rem ${alpha(
                          theme.colors.alpha.black[100],
                          0.1
                        )}`,

          '& .MuiBgComposed': {
            opacity: 1
          }
        }
      }}
    >
      {children}
    </CardWrapper>
  );
};

const CardWrapperPink = ({ children }) => {
  const theme = useTheme();
  return (
    <CardWrapper
      sx={{
        height: '260px',
        textAlign: 'center',
        position: 'relative',
        transition: `${theme.transitions.create(['box-shadow', 'transform'])}`,
        // background: getThemeColor(theme, card.theme),
        background:
          'linear-gradient(135deg, rgb(247, 97, 161) 0%, rgb(140, 27, 171) 100%)',
        transform: 'translateY(0px)',

        '&:hover': {
          transform: `translateY(-${theme.spacing(0.5)})`,
          boxShadow: `0 2rem 8rem 0 ${alpha(
            theme.colors.alpha.black[100],
            0.05
          )}, 
              0 0.6rem 1.6rem ${alpha(theme.colors.alpha.black[100], 0.15)}, 
              0 0.2rem 0.2rem ${alpha(theme.colors.alpha.black[100], 0.1)}`,

          '& .MuiBgComposed': {
            opacity: 1
          }
        }
      }}
    >
      {children}
    </CardWrapper>
  );
};

const CardWrapperGrey = ({ children }) => {
  const theme = useTheme();
  return (
    <CardWrapper
      sx={{
        height: '260px',
        textAlign: 'center',
        position: 'relative',
        transition: `${theme.transitions.create(['box-shadow', 'transform'])}`,
        // background: getThemeColor(theme, card.theme),
        background: 'linear-gradient(to right, #868f96 0%, #596164 100%)',
        transform: 'translateY(0px)',

        '&:hover': {
          transform: `translateY(-${theme.spacing(0.5)})`,
          boxShadow: `0 2rem 8rem 0 ${alpha(
            theme.colors.alpha.black[100],
            0.05
          )}, 
              0 0.6rem 1.6rem ${alpha(theme.colors.alpha.black[100], 0.15)}, 
              0 0.2rem 0.2rem ${alpha(theme.colors.alpha.black[100], 0.1)}`,

          '& .MuiBgComposed': {
            opacity: 1
          }
        }
      }}
    >
      {children}
    </CardWrapper>
  );
};
const CardWrapperGreen = ({ children }) => {
  const theme = useTheme();
  return (
    <CardWrapper
      sx={{
        height: '260px',
        textAlign: 'center',
        position: 'relative',
        transition: `${theme.transitions.create(['box-shadow', 'transform'])}`,
        // background: getThemeColor(theme, card.theme),
        background:
          ' linear-gradient(114.3deg, rgb(19, 126, 57) 0.2%, rgb(8, 65, 91) 68.5%)',
        transform: 'translateY(0px)',

        '&:hover': {
          transform: `translateY(-${theme.spacing(0.5)})`,
          boxShadow: `0 2rem 8rem 0 ${alpha(
            theme.colors.alpha.black[100],
            0.05
          )}, 
              0 0.6rem 1.6rem ${alpha(theme.colors.alpha.black[100], 0.15)}, 
              0 0.2rem 0.2rem ${alpha(theme.colors.alpha.black[100], 0.1)}`,

          '& .MuiBgComposed': {
            opacity: 1
          }
        }
      }}
    >
      {children}
    </CardWrapper>
  );
};

const CardWrapperTurquoise = ({ children }) => {
  const theme = useTheme();
  return (
    <CardWrapper
      sx={{
        height: '260px',
        textAlign: 'center',
        position: 'relative',
        transition: `${theme.transitions.create(['box-shadow', 'transform'])}`,
        // background: getThemeColor(theme, card.theme),
        background:
          'radial-gradient(993px at 0.5% 98.5%, rgb(0, 239, 247) 0%, rgb(0, 122, 147) 46.7%, rgb(0, 29, 72) 100.2%)',
        transform: 'translateY(0px)',

        '&:hover': {
          transform: `translateY(-${theme.spacing(0.5)})`,
          boxShadow: `0 2rem 8rem 0 ${alpha(
            theme.colors.alpha.black[100],
            0.05
          )}, 
              0 0.6rem 1.6rem ${alpha(theme.colors.alpha.black[100], 0.15)}, 
              0 0.2rem 0.2rem ${alpha(theme.colors.alpha.black[100], 0.1)}`,

          '& .MuiBgComposed': {
            opacity: 1
          }
        }
      }}
    >
      {children}
    </CardWrapper>
  );
};

const CardWrapperPurple = ({ children }) => {
  const theme = useTheme();
  return (
    <CardWrapper
      sx={{
        height: '260px',
        textAlign: 'center',
        position: 'relative',
        transition: `${theme.transitions.create(['box-shadow', 'transform'])}`,
        // background: getThemeColor(theme, card.theme),
        background: 'linear-gradient(45deg,#2F1C53, #A46DFF, #F6D1FD) ',
        transform: 'translateY(0px)',

        '&:hover': {
          transform: `translateY(-${theme.spacing(0.5)})`,
          boxShadow: `0 2rem 8rem 0 ${alpha(
            theme.colors.alpha.black[100],
            0.05
          )}, 
              0 0.6rem 1.6rem ${alpha(theme.colors.alpha.black[100], 0.15)}, 
              0 0.2rem 0.2rem ${alpha(theme.colors.alpha.black[100], 0.1)}`,

          '& .MuiBgComposed': {
            opacity: 1
          }
        }
      }}
    >
      {children}
    </CardWrapper>
  );
};
export default DefaultAppThumb;
