import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  alpha,
  lighten,
  styled,
  useTheme
} from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ProfileTypes } from 'src/services/apiService/response-models';
import readQueryString from 'src/utility/readQueryString';

const AvatarPageTitle = styled(Avatar)(
  ({ theme }) => `
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      color: ${theme.colors.primary.main};
      margin-right: ${theme.spacing(2)};
      background: ${
        theme.palette.mode === 'dark'
          ? theme.colors.alpha.trueWhite[10]
          : theme.colors.alpha.white[50]
      };
      box-shadow: ${
        theme.palette.mode === 'dark'
          ? '0 1px 0 ' +
            alpha(lighten(theme.colors.primary.main, 0.8), 0.2) +
            ', 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)'
          : '0px 2px 4px -3px ' +
            alpha(theme.colors.alpha.black[100], 0.4) +
            ', 0px 5px 16px -4px ' +
            alpha(theme.colors.alpha.black[100], 0.2)
      };
`
);

const periods = [
  {
    value: 'today',
    text: 'Today'
  },
  {
    value: 'yesterday',
    text: 'Yesterday'
  },
  {
    value: 'last_month',
    text: 'Last month'
  },
  {
    value: 'last_year',
    text: 'Last year'
  }
];

function PageHeader(props: {
  profileType: ProfileTypes;
  isSaving: boolean;
  onSave: () => void;
}) {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const [openPeriod, setOpenMenuPeriod] = useState<boolean>(false);
  const [period, setPeriod] = useState<string>(periods[3].text);
  const actionRef1 = useRef<any>(null);

  const isBusiness = props.profileType == ProfileTypes.Business;

  const handleBack = (): void => {
    const retUrl = readQueryString()?.ret;

    return navigate(
      retUrl || `/app/${isBusiness ? 'company' : 'member'}-profiles/`
    );
  };

  return (
    <Box
      display="flex"
      alignItems={{ xs: 'stretch', md: 'center' }}
      flexDirection={{ xs: 'column', md: 'row' }}
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        <Tooltip arrow placement="top" title={t('Go back')}>
          <IconButton
            onClick={handleBack}
            color="primary"
            sx={{
              p: 1,
              mr: 1
            }}
          >
            <ArrowBackTwoToneIcon />
          </IconButton>
        </Tooltip>
        <AvatarPageTitle variant="rounded">
          <AdminPanelSettingsTwoToneIcon fontSize="large" />
        </AvatarPageTitle>
        <Box>
          <Typography variant="h3" component="h3" gutterBottom>
            {t(isBusiness ? 'Company Profile' : 'Member Profile')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'Your ' +
                (isBusiness ? 'profile' : 'profile') +
                " is looking great but don't stop here. Let's connect people to all your content."
            )}
          </Typography>
        </Box>
      </Box>

      <Card
        sx={{
          mt: { xs: 3, lg: 0 }
        }}
      >
        <Stack
          direction="row"
          divider={
            <Divider
              sx={{
                background: `${theme.colors.alpha.black[10]}`
              }}
              orientation="vertical"
              flexItem
            />
          }
          justifyContent="space-around"
          alignItems="center"
          spacing={0}
        >
          <Box px={3} py={2} textAlign="center">
            {/*<Button*/}
            {/*    variant="outlined"*/}
            {/*    ref={actionRef1}*/}
            {/*    onClick={() => setOpenMenuPeriod(true)}*/}
            {/*    sx={{*/}
            {/*        mr: 1*/}
            {/*    }}*/}
            {/*    endIcon={<KeyboardArrowDownTwoToneIcon fontSize="small" />}*/}
            {/*>*/}
            {/*    {period}*/}
            {/*</Button>*/}
            <Menu
              disableScrollLock
              anchorEl={actionRef1.current}
              onClose={() => setOpenMenuPeriod(false)}
              open={openPeriod}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              {periods.map((_period) => (
                <MenuItem
                  key={_period.value}
                  onClick={() => {
                    setPeriod(_period.text);
                    setOpenMenuPeriod(false);
                  }}
                >
                  {_period.text}
                </MenuItem>
              ))}
            </Menu>
            {props.isSaving == true ? (
              <Button
                variant="contained"
                disabled
                startIcon={<SaveTwoToneIcon />}
              >
                {t('Saving...')}
              </Button>
            ) : (
              <Button
                onClick={props.onSave}
                variant="contained"
                startIcon={<SaveTwoToneIcon />}
              >
                {t('Save Profile')}
              </Button>
            )}
          </Box>
        </Stack>
      </Card>
    </Box>
  );
}

export default PageHeader;
