import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import SaveIcon from '@mui/icons-material/Save';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  styled,
  Typography
} from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QrInfo } from 'src/services/apiService/response-models';

const Input = styled('input')({
  display: 'none'
});

const AvatarWrapper = styled(Card)(
  ({ theme }) => `
  position: relative;
  overflow: visible;
  display: inline-block;
 

  .MuiAvatar-root {
    width: ${theme.spacing(10)};
    height: ${theme.spacing(10)};
  }
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
  position: absolute;
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  bottom: -${theme.spacing(1)};
  right: -${theme.spacing(1)};

  .MuiIconButton-root {
    border-radius: 100%;
    background: ${theme.colors.primary.main};
    color: ${theme.palette.primary.contrastText};
    box-shadow: ${theme.colors.shadows.primary};
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    padding: 0;

    &:hover {
      background: ${theme.colors.primary.dark};
    }
  }
`
);

const ButtonRemoveWrapper = styled(Box)(
  ({ theme }) => `
  position: absolute;
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  bottom: -${theme.spacing(1)};
  right: -${theme.spacing(1)};

  .MuiIconButton-root {
    border-radius: 100%;
    background: ${theme.colors.error.main};
    color: ${theme.palette.error.contrastText};
    box-shadow: ${theme.colors.shadows.error};
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    padding: 0;

    &:hover {
      background: ${theme.colors.error.dark};
    }
  }
`
);

function QrEditorDialog(props: {
  isOpen: boolean;
  qrConfig: QrInfo;
  title?: string;
  onClose: () => void;
  onSave: (e: QrInfo) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [color, setColor] = useState(props.qrConfig?.color || '#000000');
  const [logo, setLogo] = useState(props.qrConfig?.logo || '');

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    if (props.qrConfig) {
      setColor(props.qrConfig.color || '#000000');
      setLogo(props.qrConfig.logo || '');
    }
  }, [props.qrConfig]);

  const handleFileInput = (e) => {
    // handle validations
    const file = e.target.files[0];

    const maxMB = 1;

    var sizeInMB = +(file.size / (1024 * 1024)).toFixed(2);

    if (sizeInMB > maxMB) {
      enqueueSnackbar(t(`File size cannot exceed more than ${maxMB}MB`), {
        variant: 'error'
      });
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result as string;
      setLogo(base64String);
      console.log(base64String);
      // Logs wL2dvYWwgbW9yZ...
    };
    reader.readAsDataURL(file);
  };

  const removeLogo = () => {
    setLogo('');
  };

  const handleCreateClose = () => {
    props.onClose();
  };

  return (
    <Dialog open={true} onClose={handleCreateClose}>
      <DialogTitle
        sx={{
          p: 3,
          pb: 1
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t(props.title || 'Customize QR')}
        </Typography>
      </DialogTitle>

      <Formik
        initialValues={{
          color: '#000',
          logo: ''
        }}
        onSubmit={async (_values, { resetForm, setStatus, setSubmitting }) => {
          try {
            setIsSaveInProgress(true);
            await props.onSave({ ...props.qrConfig, color, logo });
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setSubmitting(false);
          }
          setIsSaveInProgress(false);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent
              dividers
              sx={{
                p: 3,
                pt: 1
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel>Color</FormLabel>
                      </FormControl>
                      <div>
                        <input
                          type="color"
                          id="qrColor"
                          name="qrColor"
                          value={color}
                          onChange={(e) => setColor(e.target.value)}
                        />
                        <label style={{ marginLeft: '5px' }} htmlFor="qrColor">
                          {color}
                        </label>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel>QR Logo</FormLabel>
                        <AvatarWrapper>
                          <Avatar
                            variant="rounded"
                            alt={'Qr Logo'}
                            src={
                              logo.length ? logo : '/static/images/no-image.png'
                            }
                          />
                          {logo.length > 0 && (
                            <ButtonRemoveWrapper>
                              <IconButton
                                onClick={removeLogo}
                                component="span"
                                color="error"
                              >
                                <DeleteTwoTone />
                              </IconButton>
                            </ButtonRemoveWrapper>
                          )}
                          {logo.length == 0 && (
                            <ButtonUploadWrapper>
                              <Input
                                accept="image/*"
                                id="icon-button-file"
                                name="icon-button-file"
                                type="file"
                                value=""
                                onChange={handleFileInput}
                              />
                              <label htmlFor="icon-button-file">
                                <IconButton component="span" color="primary">
                                  <UploadTwoToneIcon />
                                </IconButton>
                              </label>
                            </ButtonUploadWrapper>
                          )}
                        </AvatarWrapper>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                p: 3
              }}
            >
              <Button
                color="secondary"
                onClick={handleCreateClose}
                size="small"
              >
                {t('Cancel')}
              </Button>
              <Button
                type="submit"
                size="small"
                startIcon={
                  isSubmitting || isSaveInProgress ? (
                    <CircularProgress size="1rem" />
                  ) : (
                    <SaveIcon />
                  )
                }
                disabled={isSubmitting || isSaveInProgress}
                variant="contained"
              >
                {t('Save')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

export default QrEditorDialog;
