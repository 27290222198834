import { Autocomplete, TextField, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Contact } from 'src/services/apiService/response-models';
import { getContacts } from 'src/services/query-client';

function ContactSelector(props: {
  onChange: (values: Contact) => void;
  selectedId?: string;
  disabled?: boolean;
  placeholder?: string;
  helperText?: string;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [item, setItem] = useState<Contact | null>(null);

  const {
    data: { data: items = [] } = {},
    isError,
    error,
    isLoading
  } = useQuery('contacts', getContacts);

  useEffect(() => {
    const match = items.find((o) => props.selectedId == o.id);
    setItem(match);
  }, [props.selectedId]);

  return (
    <Autocomplete
      id="contact-select-comp"
      fullWidth
      multiple={false}
      limitTags={1}
      disabled={props.disabled}
      value={item}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      options={items.filter((o) => !(item?.id == o.id))}
      // @ts-ignore
      getOptionLabel={(option: Contact) => option?.fullName || ''}
      onChange={(event: any, newValue: Contact | null) => {
        setItem(newValue);
        props.onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: ''
          }}
          fullWidth
          variant="outlined"
          // label={t('Member(s)')}
          placeholder={t(props.placeholder || 'Select contact...')}
          helperText={props.helperText}
        />
      )}
    />
  );
}

export default ContactSelector;
