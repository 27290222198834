import { Grid, LinearProgress, Typography, Zoom } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import AppSelectorDialog from 'src/components/app-selector-dialog';
import DeleteConfirm from 'src/components/delete-confirm';
import { ProfileCloneDialog } from 'src/components/profile-clone-dialog';
import { deleteProfileById } from 'src/services/apiService';
import {
  BusinessApp,
  ProfileTypes
} from 'src/services/apiService/response-models';
import {
  getAppInstances,
  getBusinessProfiles
} from 'src/services/query-client';
import Footer from 'src/ui-bloom/components/Footer';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';
import { parseApiError } from 'src/utility/parseToApiErrorMessage';
import PageHeader from './page-header';
import ProfileBlock from './profile-block';

export default function CompanyProfileList() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [idToDelete, setIdToDelete] = useState('');
  const [idToClone, setIdToClone] = useState('');
  const [isAppSelectorOpen, setIsAppSelectorOpen] = useState(false);

  const {
    data: { data: profiles = [] } = {},
    isError,
    error,
    isLoading,
    refetch
  } = useQuery('business-profiles', getBusinessProfiles);

  const { data: { data: appInstances = [] } = {} } = useQuery(
    'my-apps',
    getAppInstances
  );

  const handleDeleteConfirm = async () => {
    try {
      await deleteProfileById(idToDelete);
      refetch();
      enqueueSnackbar('Profile has been deleted', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    } catch (ex) {
      const msg = parseApiError(ex, `Failed to delete Profile`);
      enqueueSnackbar(msg, { variant: 'error' });
    }
    setIdToDelete('');
  };

  const onAppAdded = async (app: BusinessApp) => {
    setIsAppSelectorOpen(false);
    if (app) {
      navigate(
        '/app/business-apps/' +
          app.name.toLowerCase() +
          '?ret=' +
          location.pathname
      );
    }
    try {
    } catch (ex) {}
  };

  if (isLoading) return <LinearProgress />;

  return (
    <>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="left"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          {!isLoading && !isError && profiles.length == 0 && (
            <Typography
              sx={{
                py: 10
              }}
              variant="h3"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {'No profiles to display'}
            </Typography>
          )}
        </Grid>
        {profiles.map((o) => (
          <Grid key={o.id} item md={6} xs={12}>
            <ProfileBlock
              profile={o}
              onDelete={(id) => setIdToDelete(id)}
              onClone={(id) => setIdToClone(id)}
              onAddApp={() => setIsAppSelectorOpen(true)}
              appInstances={appInstances.filter((ai) => ai.profileId == o.id)}
            />
          </Grid>
        ))}
      </Grid>
      <DeleteConfirm
        open={idToDelete.length ? true : false}
        message="Are you sure, want delete profile?"
        confirmText="Yes"
        cancelText="No"
        onClose={() => setIdToDelete('')}
        onDeleteConfirm={handleDeleteConfirm}
      />
      <ProfileCloneDialog
        profileId={idToClone}
        profileTyepe={ProfileTypes.Business}
        title={profiles.find((o) => o.id == idToClone)?.data?.businessName}
        onClose={() => setIdToClone('')}
        onConfirm={() => setIdToClone('')}
      />
      {isAppSelectorOpen && (
        <AppSelectorDialog
          profileType={ProfileTypes.Business}
          isOpen={isAppSelectorOpen}
          onClose={() => setIsAppSelectorOpen(false)}
          onSave={onAppAdded}
        />
      )}
      <Footer />
    </>
  );
}
