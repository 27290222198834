import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  SelectChangeEvent
} from '@mui/material';
import { Dictionary } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MyCardList from 'src/contents/my-cards';
import {
  Card,
  Profile,
  ProfileTypes
} from 'src/services/apiService/response-models';

export function NfcLinkedCardDialog(props: {
  linkedCards: Card[];
  profileId: string;
  onClose: () => void;
  onChange: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [label, setLabel] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    setOpen(Boolean(props.profileId?.length));
    setType(ProfileTypes.Business ? 'company' : 'member');
  }, [props.profileId]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Linked Card</DialogTitle>
        <Divider />
        <DialogContent>
          <MyCardList
            onResetSuccess={() => {
              props.onChange();
              handleClose();
            }}
            cards={props.linkedCards}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export class ProfileCloneHelper {
  static hash: Dictionary<Profile> = {};
}
