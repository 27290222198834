import { Box, CardHeader, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Note } from 'src/CRM/models/note';
import { RootState } from 'src/store';
import Text from 'src/ui-bloom/components/Text';
import ActionMenuButton from './action-menu-button';

function NoteBlock(props: { note: Note; hideActions?: boolean }) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const { user } = useSelector((state: RootState) => state.app);

  return (
    <Box>
      <CardHeader
        sx={{
          px: 0,
          pt: 0
        }}
        action={<ActionMenuButton note={props.note} />}
        title={t('Note')}
        titleTypographyProps={{
          variant: 'h5',
          color: 'textPrimary'
        }}
      />
      <Box>{props.note.description}</Box>
      <Box
        sx={{
          mt: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <Typography variant="subtitle2" gutterBottom>
          {t('Created on')}:{' '}
          <Text color="black">
            {props.note.createdOn
              ? new Date(props.note.createdOn).toLocaleString()
              : ''}
          </Text>
        </Typography>
      </Box>
    </Box>
  );
}

export default NoteBlock;
