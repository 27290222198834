import {
  Box,
  Grid,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import QrCodeCard from 'src/components/QrCodeCard';
import QrFromNfcMap from 'src/components/QrFromNfcMap';
import { ConnectedApps } from 'src/components/profile-connected-apps';
import {
  Profile,
  ProfileField,
  ProfileTypes,
  QrInfo
} from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import { ProfileDto } from 'src/services/dto';
import { ApiUrls } from 'src/services/endpoints';
import {
  appQueryClient,
  getAppInstances,
  getProfileById,
  getfieldCategorySchema,
  saveProfile
} from 'src/services/query-client';
import { RootState } from 'src/store';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';
import dataUrlToBlob from 'src/utility/dataUrlToBlob';
import getExtFromDataUrl from 'src/utility/getExtFromDataUrl';
import { parseApiError } from 'src/utility/parseToApiErrorMessage';
import { ProfileCloneHelper } from 'src/utility/profileCloneHelper';
import readQueryString from 'src/utility/readQueryString';
import usePrompt from 'src/utility/usePrompt';
import PageHeader from './PageHeader';
import FieldPropertyBox from './ProfileFieldLayout';
import ProfilePreviewer from './ProfilePreviewer';
import convertProfileToPreviewerFields from './ProfilePreviewer/utils/convertProfileToPreviewerFields';
import ProfileEditorContext from './profile-editor-context';
import filterProfileFields from './utils/filterProfileFields';
import mapModelToDto from './utils/mapModelToDto';

export default function ProfileEditor() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const navigate = useNavigate();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state: RootState) => state.app.user);
  const profileType = window.location.pathname?.includes('app/company/')
    ? ProfileTypes.Business
    : ProfileTypes.Individual;

  const isBusiness = profileType == ProfileTypes.Business;

  const { id: profileId } = useParams();

  const [isEdit] = useState(Boolean(profileId && profileId.length));

  const [fieldInEdit, setFieldInEdit] = useState<ProfileField | null>(null);
  const [modifiedProfile, setModifiedProfile] = useState<Profile | null>(null);
  const [modifyLogs, setModifyLogs] = useState<string[]>([]);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [clonedProfile, setClonedProfile] = useState<Profile | null>(null);

  //Queries
  const {
    data: { data: { categories: fieldCategories = [] } = {} } = {},
    error,
    isLoading: isFieldsLoading
  } = useQuery('field-schema', getfieldCategorySchema);

  const {
    data: { data: model } = {},
    isLoading,
    isError,
    refetch: refetchById
  } = useQuery(
    [(isBusiness ? 'business-' : '') + 'profiles', profileId],
    (k) => getProfileById(profileId),
    {
      enabled: isEdit,
      onSuccess: (o) => {
        setModifiedProfile(_.cloneDeep(o.data));
      }
    }
  );

  const {
    mutate: save,
    isError: isSaveError,
    error: errorOnSave,
    isLoading: isSaving
  } = useMutation((e: ProfileDto) => saveProfile(e, profileId), {
    onSuccess: (o) => onSaveSuccess(o.data)
  });

  const { data: { data: appInstances = [] } = {}, refetch } = useQuery(
    'my-apps',
    getAppInstances
  );
  //----Queries

  useEffect(() => {
    const cloneId = readQueryString().cid;
    if (!isEdit && ProfileCloneHelper.hash[cloneId]) {
      const cloneFrom = ProfileCloneHelper.hash[cloneId];
      setClonedProfile({ ...cloneFrom });
      delete ProfileCloneHelper.hash[cloneId];
      setModifiedProfile({ ...cloneFrom });
      setModifyLogs([...modifyLogs, 'Profile cloned']);
    }
  }, []);
  usePrompt(
    'Are you sure you want to leave, changes you made will be lost?',
    Boolean(modifyLogs.length)
  );

  const handleBack = (): void => {
    const retUrl = readQueryString()?.ret;

    return navigate(
      retUrl || `/app/${isBusiness ? 'company' : 'member'}-profiles/`
    );
  };

  const linkedNfcMap = false;

  if (isFieldsLoading || isLoading) return <LinearProgress sx={{ mt: 2 }} />;

  if (isError) {
    handleBack();
  }

  const hasPendingChanges = (): boolean => {
    if (!isEdit) return true;

    return modifyLogs.length ? true : false;
  };

  const onSaveClick = async () => {
    //validate
    if (isSaving || isImageUploading) return;

    if (!hasPendingChanges()) {
      enqueueSnackbar(t('No changes to save'), { variant: 'warning' });
      return;
    }

    if (!Boolean(modifiedProfile?.fields?.length)) {
      enqueueSnackbar(t('At least one profile field is required'), {
        variant: 'error'
      });
      return;
    }

    if (!Boolean(modifiedProfile?.qrInfo?.code)) {
      enqueueSnackbar(t('Profile qr code is required.'), {
        variant: 'error'
      });
      return;
    }
    const profileToSave = mapModelToDto(modifiedProfile);
    if (!isEdit) {
      profileToSave.memberId = user.teamMemberId;
      profileToSave.type = profileType;
    }

    try {
      setIsImageUploading(true);

      const logoEx = getExtFromDataUrl(profileToSave.qrInfo.logo);
      if (logoEx) {
        //it is dataImage url
        const uniqueName = 'qr_logo_' + Date.now() + logoEx;
        const logoBlob = await dataUrlToBlob(profileToSave.qrInfo.logo);
        await cdnService.uploadFileToCloud(uniqueName, logoBlob);
        profileToSave.qrInfo.logo = uniqueName;
      }

      cdnService.uploadBlobToCloud(profileToSave.data.logoImage);
      cdnService.uploadBlobToCloud(profileToSave.data.profileImage);
      cdnService.uploadBlobToCloud(profileToSave.data.coverImage);

      if (profileToSave.data.imageList) {
        profileToSave.data.imageList.forEach((e) => {
          e.imageList.forEach((o) => {
            cdnService.uploadBlobToCloud(o.url);
          });
        });
      }
    } catch (ex) {
      console.log(ex);
      enqueueSnackbar(t(`Failed to upload all images`), { variant: 'warning' });
    } finally {
      setIsImageUploading(false);
    }

    save(profileToSave);
    // if (!!profileNameError) {
    //   enqueueSnackbar(t('Invalid profile name!!'), { variant: 'error' });
    //   return;
    // }

    // if (!hasPendingChanges()) {
    //   enqueueSnackbar(t('No changes to save'), { variant: 'warning' });
    //   return;
    // }
  };

  if (isSaveError) {
    const msg = parseApiError(
      errorOnSave,
      `Failed to ${!isEdit ? 'created' : 'updated'} Profile`
    );
    enqueueSnackbar(t(msg), { variant: 'error' });
  }

  const onSaveSuccess = async (updatedModel: Profile) => {
    setModifyLogs([]);
    enqueueSnackbar(
      t(`Profile was ${!isEdit ? 'created' : 'updated'} successfully`),
      { variant: 'success' }
    );
    setModifyLogs([]);
    if (!isEdit)
      navigate(
        '/app/' +
          (isBusiness ? 'company' : 'member') +
          '/profile-editor/' +
          updatedModel.id
      );
    else {
      appQueryClient.setQueryData(
        [(isBusiness ? 'business-' : '') + 'profiles', profileId],
        updatedModel
      );
      refetchById();
    }
  };

  const onQrChange = (e: QrInfo) => {
    setModifiedProfile((o) => ({ ...o, qrInfo: { ...e } }));
    setModifyLogs((o) => {
      return [...o, 'Qr info updated'];
    });
  };

  let originalProfile: Profile = null;
  if (isEdit && model) {
    originalProfile = model;
  } else if (clonedProfile) {
    originalProfile = clonedProfile;
  }

  return (
    <ProfileEditorContext.Provider
      value={{
        fieldCategories: filterProfileFields(fieldCategories, profileType),
        fieldInEdit: fieldInEdit,
        profileType: ProfileTypes.Business,
        profileTheme: originalProfile?.theme,
        isLoading: false,
        setFieldInEdit: (e) => setFieldInEdit(e),
        changeTheme: (e) => {}
      }}
    >
      {' '}
      <PageTitleWrapper>
        <PageHeader
          profileType={profileType}
          onSave={onSaveClick}
          isSaving={isSaving || isImageUploading}
        />
      </PageTitleWrapper>
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item md={4} xs={12}>
          <Stack spacing={2} sx={{ width: '100%' }}>
            {!linkedNfcMap ? (
              <QrCodeCard
                onChange={onQrChange}
                qrInfo={originalProfile?.qrInfo}
                label="Profile url"
                buildText={async (value) => {
                  return ApiUrls.viewerAppUrl + '/' + value;
                }}
              />
            ) : (
              <QrFromNfcMap map={null} />
            )}
            <ProfilePreviewer
              profileData={originalProfile?.data}
              previeverFields={convertProfileToPreviewerFields(
                fieldCategories,
                originalProfile
              )}
              profileTheme={originalProfile?.theme}
              onThemeChange={(e) => {
                setModifiedProfile({
                  ...modifiedProfile,
                  theme: e
                });
                setModifyLogs([...modifyLogs, 'Profile theme modifield']);
              }}
              onFieldInEditChange={(e) => {
                setFieldInEdit(e);
              }}
              fieldInEdit={fieldInEdit}
              onDataModfied={(f, d, t) => {
                const p = modifiedProfile || { ...originalProfile };
                p.fields = [...f];
                p.data = { ...d };
                p.theme = t;
                setModifiedProfile({
                  ...p
                });
                setModifyLogs([...modifyLogs, 'Profile fields modifield']);
              }}
            />
          </Stack>
        </Grid>
        <Grid item md={8} xs={12}>
          <Stack spacing={2}>
            <div>
              {isEdit && (
                <>
                  <Box display="flex" mb={1} alignItems="center">
                    <Typography variant="h3" component="h3" gutterBottom>
                      {t('Business Apps')}
                    </Typography>
                    <Typography sx={{ ml: 1 }} variant="subtitle2">
                      {t('Connected apps')}
                    </Typography>
                  </Box>
                  <Box>
                    <ConnectedApps
                      appInstances={appInstances.filter(
                        (o) => o.profileId == profileId
                      )}
                    />
                  </Box>
                </>
              )}
            </div>
            <FieldPropertyBox />
          </Stack>
        </Grid>
      </Grid>
    </ProfileEditorContext.Provider>
  );
}
