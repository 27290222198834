import { MenuItems } from 'src/ui-bloom/layouts/BoxedSidebarLayout/Sidebar/SidebarMenu/items';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ContactsTwoToneIcon from '@mui/icons-material/ContactsTwoTone';
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone';
import PortraitTwoToneIcon from '@mui/icons-material/PortraitTwoTone';
import RateReviewIcon from '@mui/icons-material/RateReview';
import StorefrontIcon from '@mui/icons-material/Storefront';
import {
  AccountTypes,
  UserAccountEntity
} from 'src/services/apiService/response-models';

const menuItems: MenuItems[] = [
  // {
  //   heading: 'Dashboards',
  //   items: [
  //     {
  //       name: 'Main',
  //       icon: StorefrontTwoToneIcon,
  //       link: '/app/dashboard'
  //     }
  //   ]
  // },
  {
    heading: 'PROFILES',
    items: [
      {
        name: 'Company Profiles',
        icon: StorefrontIcon,
        link: '/app/company-profiles'
      },
      {
        name: 'Member Profiles',
        icon: PortraitTwoToneIcon,
        link: '/app/member-profiles'
      },
      // {
      //   name: 'My Cards',
      //   icon: CardMembershipTwoToneIcon,
      //   link: '/app/member/my-cards'
      // },
      {
        name: 'Business Apps',
        icon: AppRegistrationIcon,
        link: '/app/business-apps'
      }
    ]
  },
  {
    heading: 'MANAGEMENT',
    items: [
      {
        name: 'Contact',
        icon: ContactsTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/contact/list'
          },
          {
            name: 'New',
            icon: AddTwoToneIcon,
            link: '/app/contact/new'
          }
          // {
          //   name: 'Upload',
          //   icon: UploadTwoToneIcon,
          //   link: '/app/contact/upload'
          // }
        ]
      },
      {
        name: 'Lead',
        icon: GroupAddTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/lead/list'
          }
        ]
      },
      {
        name: 'Reviews',
        icon: GroupAddTwoToneIcon,
        link: '',
        items: [
          {
            name: 'Reviews',
            icon: RateReviewIcon,
            link: '/app/review/list'
          }
        ]
      }
    ]
  },
  {
    heading: 'ADMINISTRATION',
    items: [
      {
        name: 'Team',
        icon: GroupsTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/team/list'
          },
          {
            name: 'New Member',
            icon: AddTwoToneIcon,
            link: '/app/team/employee/new'
          }
        ]
      },
      {
        name: 'Users',
        icon: PeopleOutlineTwoToneIcon,
        link: '/app/admin/user/list'
      }
      // {
      //   name: 'Setting',
      //   icon: SettingsTwoToneIcon,
      //   link: '/app/setting'
      // }
    ]
  }
];

const menuItemsManagers: MenuItems[] = [
  // {
  //   heading: 'Dashboards',
  //   items: [
  //     {
  //       name: 'Main',
  //       icon: StorefrontTwoToneIcon,
  //       link: '/app/dashboard'
  //     }
  //   ]
  // },
  {
    heading: 'PROFILES',
    items: [
      {
        name: 'Member Profiles',
        icon: PortraitTwoToneIcon,
        link: '/app/member-profiles'
      },
      {
        name: 'Business Apps',
        icon: AppRegistrationIcon,
        link: '/app/business-apps'
      }
    ]
  },
  {
    heading: 'MANAGEMENT',
    items: [
      {
        name: 'Contact',
        icon: ContactsTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/contact/list'
          },
          {
            name: 'New',
            icon: AddTwoToneIcon,
            link: '/app/contact/new'
          }
          // {
          //   name: 'Upload',
          //   icon: UploadTwoToneIcon,
          //   link: '/app/contact/upload'
          // }
        ]
      },
      {
        name: 'Lead',
        icon: GroupAddTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/lead/list'
          }
        ]
      },
      {
        name: 'Reviews',
        icon: GroupAddTwoToneIcon,
        link: '',
        items: [
          {
            name: 'Reviews',
            icon: RateReviewIcon,
            link: '/app/review/list'
          }
        ]
      }
    ]
  },
  {
    heading: 'ADMINISTRATION',
    items: [
      {
        name: 'Team',
        icon: GroupsTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/team/list'
          },
          {
            name: 'New Member',
            icon: AddTwoToneIcon,
            link: '/app/team/employee/new'
          }
        ]
      },
      {
        name: 'Users',
        icon: PeopleOutlineTwoToneIcon,
        link: '/app/admin/user/list'
      }
      // {
      //   name: 'Setting',
      //   icon: SettingsTwoToneIcon,
      //   link: '/app/setting'
      // }
    ]
  }
];

const menuItemsForIndAccount: MenuItems[] = [
  {
    heading: 'PROFILES',
    items: [
      {
        name: 'Member Profiles',
        icon: PortraitTwoToneIcon,
        link: '/app/member-profiles'
      },
      {
        name: 'Business Apps',
        icon: AppRegistrationIcon,
        link: '/app/business-apps'
      }
    ]
  },
  {
    heading: 'MANAGEMENT',
    items: [
      {
        name: 'Contact',
        icon: ContactsTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/contact/list'
          },
          {
            name: 'New',
            icon: AddTwoToneIcon,
            link: '/app/contact/new'
          }
        ]
      },
      // {
      //   name: 'Lead',
      //   icon: GroupAddTwoToneIcon,
      //   link: '',
      //   items: [
      //     {
      //       name: 'List',
      //       icon: ListAltTwoToneIcon,
      //       link: '/app/lead/list'
      //     }
      //   ]
      // },
      {
        name: 'Reviews',
        icon: GroupAddTwoToneIcon,
        link: '',
        items: [
          {
            name: 'Reviews',
            icon: RateReviewIcon,
            link: '/app/review/list'
          }
          // {
          //   name: 'New',
          //   icon: AddTwoToneIcon,
          //   link: '/app/review/new'
          // }
        ]
      }
    ]
  }
];
export default function getMenuItems(e: UserAccountEntity) {
  if (!e) return [];
  const roles = e.user.roles.map((u) => u.roleName);
  const managerMenuItems = menuItemsManagers.filter(
    (m) => m.heading !== 'ADMINISTRATION'
  );
  if (e.clientAccount.accountType == AccountTypes.Business)
    return roles.includes('admin') ? menuItems : managerMenuItems;
  // ;

  return menuItemsForIndAccount;
}
