import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import ChangePassword from './ChangePassword';

function GeneralContent() {
  const { t }: { t: any } = useTranslation();
  const user = useSelector((state: RootState) => state.app.user);

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <ChangePassword />
      </Grid>
      <Grid item xs={12} md={6}></Grid>
    </Grid>
  );
}

export default GeneralContent;
