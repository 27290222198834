import { Box, Grid, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);

function PageHeader(props: {
  isEdit: boolean;
  id: string;
  onBack: () => void;
  isSaving: boolean;
}) {
  const { t }: { t: any } = useTranslation();
  const location = useLocation();

  return (
    <RootWrapper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h3" component="h3" gutterBottom>
                {props.isEdit ? 'Edit Lead-App' : t('New Lead-App')}
              </Typography>
              <Typography variant="subtitle2">
                Fill in the fields below to configure lead app
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* <Grid item>
          <Button type="button" variant="outlined" onClick={props.onBack}>
            Cancel
          </Button>
          <Button
            sx={{ ml: 2 }}
            type="submit"
            variant="contained"
            startIcon={
              props.isSaving ? <CircularProgress size="1rem" /> : <SaveIcon />
            }
            disabled={props.isSaving}
          >
            Save
          </Button>
        </Grid> */}
      </Grid>
    </RootWrapper>
  );
}

export default PageHeader;
