import SaveIcon from '@mui/icons-material/Save';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TeamDto } from 'src/services/dto';
import * as Yup from 'yup';
import {
  checkTeamExistsByName,
  getTeamById,
  saveTeam
} from '../../../services/apiService';
import { Team } from '../../../services/apiService/response-models';
import { RootState, useSelector } from '../../../store';
import { parseToApiErrorMessage } from '../../../utility/parseToApiErrorMessage';

function CreateEditTeamDialog(props: {
  teamId?: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(props.isOpen);
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.app);

  const [model, setModel] = useState<Team | null>(null);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const getById = async (teamId: string) => {
    try {
      const response = await getTeamById(teamId);
      setIsEdit(true);
      setModel(response.data);
    } catch (ex) {
      console.error(ex);
      const msg = ex.message ? ex.message : `Failed to load team by id`;
      enqueueSnackbar(t(msg), { variant: 'error' });
      navigate('/app/team/list');
    }
  };

  useEffect(() => {
    if (props.teamId && props.teamId?.length > 0) {
      setIsLoading(true);
      getById(props.teamId).then(() => {
        setIsLoading(false);
      });
    } else {
      setModel(null);
      setIsEdit(false);
    }
  }, [props.teamId]);

  const onSave = async (updatedModel: TeamDto) => {
    let isSuccess = false;
    try {
      setIsSaveInProgress(true);
      await saveTeam(updatedModel);
      enqueueSnackbar(
        t(`Team was ${!isEdit ? 'created' : 'updated'} successfully`),
        { variant: 'success' }
      );
      isSuccess = true;
      navigate('/app/team/list');
      setIsSaveInProgress(false);
      handleCreateClose();
      props.onSave();
    } catch (ex) {
      setIsSaveInProgress(false);
      console.log(ex);
      const msg = parseToApiErrorMessage(
        ex,
        `Failed to ${!isEdit ? 'created' : 'updated'} Team`
      );
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
    return isSuccess;
  };

  const handleCreateClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <Dialog open={open} onClose={handleCreateClose}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <DialogTitle
            sx={{
              p: 3
            }}
          >
            <Typography variant="h4" gutterBottom>
              {isEdit ? t(`Edit ${model.name}`) : t('Add new team')}
            </Typography>
            <Typography variant="subtitle2">
              {isEdit
                ? t('Edit the fields below to update team')
                : t('Fill in the fields below to create and add a new team')}
            </Typography>
          </DialogTitle>

          <Formik
            initialValues={{
              name: model?.name || '',
              description: model?.description || ''
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .max(255)
                .required(t('The name field is required'))
                .test(
                  'Unique-TeamName',
                  'Team name already in use',
                  async (value) => {
                    return !(await checkTeamExistsByName(value)).data;
                  }
                ),
              description: Yup.string().max(255)
            })}
            onSubmit={async (
              _values,
              { resetForm, setStatus, setSubmitting }
            ) => {
              try {
                var isSuccess = await onSave({ ...model, ..._values });
                if (isSuccess) {
                  resetForm();
                  setStatus({ success: true });
                  setSubmitting(false);
                }
              } catch (err) {
                console.error(err);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent
                  dividers
                  sx={{
                    p: 3
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            label={t('Team name')}
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            multiline
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            fullWidth
                            helperText={
                              touched.description && errors.description
                            }
                            label={t('Description')}
                            rows={3}
                            name="description"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  sx={{
                    p: 3
                  }}
                >
                  <Button color="secondary" onClick={handleCreateClose}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    type="submit"
                    startIcon={
                      isSubmitting || isSaveInProgress ? (
                        <CircularProgress size="1rem" />
                      ) : (
                        <SaveIcon />
                      )
                    }
                    disabled={isSubmitting || isSaveInProgress}
                    variant="contained"
                  >
                    {t('Save')}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </>
      )}
    </Dialog>
  );
}

export default CreateEditTeamDialog;
