import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BusinessApp,
  ProfileTypes
} from 'src/services/apiService/response-models';
import AppSelectorContent from './content';

export default function AppSelectorDialog(props: {
  isOpen: boolean;
  profileType?: ProfileTypes;
  onClose: () => void;
  onSave: (e: BusinessApp) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(props.isOpen);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const handleCreateClose = () => {
    setOpen(false);
    props.onClose();
  };

  const onAppAdd = (e) => {
    setOpen(false);
    props.onSave(e);
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleCreateClose}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <DialogContent sx={{}}>
            <AppSelectorContent onAdd={onAppAdd} onClose={handleCreateClose} />
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
