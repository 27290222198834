import EventNoteTwoToneIcon from '@mui/icons-material/EventNoteTwoTone';
import { Box, Button, Drawer, Grid, useTheme } from '@mui/material';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import {
  closeDrawerPanel,
  openDrawerPanel
} from '../../../../store/slices/app';
import { Task } from '../../../models/task';
import ActivitiesContext from '../activities-context';
import TaskEditor from '../task-editor';
import TasksFilter from '../task-filter';
import TasksList from '../tasks-list';

function TasksTab() {
  const context = useContext(ActivitiesContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isDrawerOpen } = useSelector((state: RootState) => state.app);
  const taskToEdit = useSelector((state: RootState) => state.app.taskToEdit);

  const closeDrawer = (): void => {
    dispatch(closeDrawerPanel());
    // props.onDrawerClose();
  };

  const onTaskSave = (task: Task, isEdit: boolean): void => {
    dispatch(closeDrawerPanel());
    console.log(task);
    context.addTask(task, isEdit);
  };

  const handleAddClick = (): void => {
    dispatch(openDrawerPanel());
  };

  return (
    <Box p={1}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          Filter by: <TasksFilter />
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          <Button
            sx={{
              mt: { xs: 2, md: 0 }
            }}
            onClick={handleAddClick}
            variant="contained"
            color="primary"
            startIcon={<EventNoteTwoToneIcon fontSize="small" />}
          >
            {'Add task'}
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ pt: 2 }}>
          <TasksList />
        </Grid>
      </Grid>
      <Drawer
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'left' : 'right'}
        onClose={closeDrawer}
        open={isDrawerOpen}
        elevation={9}
      >
        {isDrawerOpen && (
          <TaskEditor
            task={taskToEdit}
            onCancel={closeDrawer}
            onSave={onTaskSave}
          />
        )}
      </Drawer>
    </Box>
  );
}

export default TasksTab;

function ActivityContext(ActivityContext: any) {
  throw new Error('Function not implemented.');
}
