import { CodeItem } from "src/services/apiService/response-models";

enum Constants {
  DEFAULT_TEAM_NAME = 'Default team'
}

export enum MenuTemplate {
  Default = 'default',
  TemplateV2 = 'templateV2',
  TemplateV3 = 'templateV3',
  TemplateV4 = 'templateV4'
}

export enum MenuProvider {
  kiengage = 'kiengage',
  clover = 'clover'
}

export const priceTypes: CodeItem[] = [
  {
    code: 'Cash',
    value: 'Cash Price'
  },
  {
    code: 'Card',
    value: 'Card Price'
  }
];

export const USER_ROLES = [
  { label: 'Administrator', roleName: 'admin' },
  { label: 'Manager', roleName: 'manager' },
  { label: 'Member', roleName: 'member' }
];

export default Constants;
