import {
  ClickAwayListener,
  Dialog,
  Fade,
  Paper,
  Popper,
  Slide,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { ReactElement, Ref, forwardRef, useEffect, useState } from 'react';
import FieldsRegistry, {
  FieldRenderEditorProp
} from 'src/components/FieldsRegistry';
import {
  ProfileData,
  ProfileFieldNames
} from 'src/services/apiService/response-models';
import { PreviewerField } from '../ProfilePreviewer';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function FieldEditorPopper(props: {
  open: boolean;
  data: ProfileData;
  anchorEl: HTMLElement;
  onCancel: () => void;
  onDelete: () => void;
  fieldInEdit: PreviewerField;
  profileTheme: string;
  onSave: (e: ProfileData) => void;
}) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const isPicField = [
    ProfileFieldNames.ProfilePic,
    ProfileFieldNames.Logo,
    ProfileFieldNames.CoverPhoto
  ].includes(props.fieldInEdit.field.name as ProfileFieldNames);

  const [open, setOpen] = useState(props.open);
  const [anchorEl, setAnchorEl] = useState(props.anchorEl);

  useEffect(() => {
    setAnchorEl(props.anchorEl);
    setOpen(props.open);
  }, [props.open]);

  const calcWidth = () => {
    if (mobile) return '80%';

    return isPicField ? '220px' : '500px';
  };

  const handleEnterButton = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      //  onOk();
    }
  };

  const onSave = (e: ProfileData) => {
    setOpen(false);
    props.onSave(e);
  };

  const onCancel = () => {
    setOpen(false);
    props.onCancel();
  };

  const onDelete = () => {
    setOpen(false);
    props.onDelete();
  };

  const getValueIndex = () => {
    if (props.fieldInEdit.field.multiple == true) {
      return Number(props.fieldInEdit.preId.split('_')[1]);
    }
  };

  const renderFieldEditor = () => {
    const inputProp: FieldRenderEditorProp = {
      field: props.fieldInEdit.field,
      isReadOnly: false,
      data: props.data || {},
      theme: props.profileTheme,
      valueIndex: getValueIndex(),
      onCancel: onCancel,
      onDelete: onDelete,
      onSave: onSave
    };
    return FieldsRegistry[props.fieldInEdit.field.name]?.renderEditor(
      inputProp
    );
  };

  // return (
  //   <DialogWrapper
  //     open={open}
  //     maxWidth="sm"
  //     fullWidth
  //     TransitionComponent={Transition}
  //     keepMounted
  //     onClose={onCancel}
  //   >
  //     {renderFieldEditor()}
  //   </DialogWrapper>
  // );

  return (
    <Popper
      id="field-popper"
      sx={{ zIndex: 1000, width: calcWidth() }}
      open={open}
      anchorEl={anchorEl}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [isPicField ? -30 : 0, 50]
          }
        }
      ]}
      placement={!mobile ? 'right-start' : 'top-start'}
      transition
      onKeyDown={handleEnterButton}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper elevation={3}>
            <ClickAwayListener onClickAway={onCancel}>
              <>{renderFieldEditor()}</>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}
