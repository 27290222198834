import { AxiosError } from 'axios';

export const parseToApiErrorMessage = (
  ex: AxiosError,
  defaultMessage: string
): string => {

  let resultMsg = '';


  if (!ex)
    return resultMsg;


  if (typeof (ex) === 'string')
    resultMsg = ex + "";
  else if (ex.response && ex.response.data && (ex.response.data as any).message)
    resultMsg = (ex.response.data as any).message;
  else
    resultMsg = ex.message ? ex.message : defaultMessage;

  return resultMsg.replace('AxiosError: ', '');
};

export const parseApiError = (
  muteError: unknown,
  defaultMessage: string
): string => {
  console.log(muteError);

  const ex = muteError as any;

  let resultMsg = '';


  if (!muteError)
    return resultMsg;


  if (typeof (muteError) === 'string')
    resultMsg = muteError + "";
  else if (ex.response && ex.response.data && (ex.response.data as any).message)
    resultMsg = (ex.response.data as any).message;
  else
    resultMsg = ex.message ? ex.message : defaultMessage;

  return resultMsg.replace('AxiosError: ', '');
};

