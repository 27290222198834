import { CodeItem, Profile, ProfileTypes } from "src/services/apiService/response-models";
import { ProfileValueReader } from "./profileValueReader";

export default function profilesToItems(profiles: Profile[]) {
  const items: CodeItem[] = [];

  profiles.forEach((p) => {
    if (p.type == ProfileTypes.Individual) {
      let value = p.name;
      if (!value) {
        value = new ProfileValueReader(p).readName();
        if (!value) {
          value = p.qrInfo?.code || '[no name]';
        }
      }

      items.push({
        code: p.id,
        value
      });
    }
  });
  return items;
}
