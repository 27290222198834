import { Box, styled, useTheme } from '@mui/material';
import { FieldRenderBlockProp } from '../..';

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(13)};
    font-weight: bold;
    text-transform: uppercase;
    border-radius: ${theme.general.borderRadiusSm};
    padding: ${theme.spacing(0.9, 1.5, 0.7)};
    line-height: 1;
  `
);

export default function CompanyNameBlockRenderer(props: FieldRenderBlockProp) {
  const theme = useTheme();

  if (!props.data?.companyName) return <></>;

  return (
    <Box mt={1}>
      <LabelWrapper
        sx={{
          background: `${theme.colors.warning.main}`,
          color: `${theme.palette.getContrastText(theme.colors.warning.dark)}`
        }}
      >
        {props.data.companyName}
      </LabelWrapper>
    </Box>
  );
}
