import { Card, CardHeader, Divider, Grid, useTheme } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField as FmTextFiled } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import FieldEditorFooter from 'src/contents/profile-editor/FieldEditorFooter';
import * as Yup from 'yup';
import { FieldRenderEditorProp } from '../..';

export default function FacebookEditorRenderer(props: FieldRenderEditorProp) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Card>
        <CardHeader title={t(props.field.displayName)} />
        <Divider />
        <Formik
          initialValues={{
            facebook: props.data?.facebook?.value || ''
          }}
          validateOnBlur={false}
          validationSchema={Yup.object().shape({
            facebook: Yup.string().max(250).required(t('The field is required'))
          })}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            props.onSave({
              ...props.data,
              facebook: { title: '', value: _values.facebook }
            });
          }}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid sx={{ padding: theme.spacing(2) }} container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name={'facebook'}
                    component={FmTextFiled}
                    placeholder={t('Enter here...')}
                    inputRef={(input) => {
                      input?.focus();
                    }}
                  />
                </Grid>
              </Grid>
              <FieldEditorFooter
                onCancel={props.onCancel}
                onDelete={props.onDelete}
              />
            </form>
          )}
        </Formik>
      </Card>
    </>
  );
}
