import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import { useField } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddQuestionDialog from 'src/components/add-question';
import DeleteConfirm from 'src/components/delete-confirm';
import {
  CodeItem,
  ReviewQuestion
} from 'src/services/apiService/response-models';
import Scrollbar from 'src/ui-bloom/components/Scrollbar';

const ListItemWrapper = styled(ListItem)(
  ({ theme }) => `
      position: relative;
      transition: ${theme.transitions.create(['background'])};

      .MuiActionButtons {
            background: ${alpha(theme.colors.alpha.white[100], 0.95)};
            border-radius: ${theme.general.borderRadius};
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
            top: 50%;
            margin-top: -${theme.spacing(3.5)};
            position: absolute;
            right: ${theme.spacing(1.5)};
            padding: ${theme.spacing(0.5, 1)};
            transition: ${theme.transitions.create(['visibility', 'opacity'])};

            .MuiIconButton-root {
                border-radius: 100px;
                width: ${theme.spacing(5)};
                height: ${theme.spacing(5)};
                margin: ${theme.spacing(0.5)};
            }
      }

      &:hover {
          background: ${alpha(theme.colors.secondary.main, 0.15)};

          .MuiActionButtons {
            visibility: visible;
            opacity: 1;
      }   
      }
  `
);

const ListWrapper = styled(List)(
  () => `
    .MuiDivider-root:last-of-type {
        display: none;
    }
  `
);
const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
      .MuiInputBase-input {
          font-size: ${theme.typography.pxToRem(17)};
      }
`
);

const CardContentWrapper = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};
    border-radius: ${theme.general.borderRadius};
  `
);

export default function ReviewQuestionCard() {
  const theme = useTheme();
  const { t }: { t: any } = useTranslation();
  const [question, setQuestion] = useState<CodeItem>({ code: '', value: '' });

  const [idToDelete, setIdToDelete] = useState('');
  const [open, setOpen] = useState(false);
  const [field, meta, helpers] = useField('questions');

  const [questions, setQuestions] = useState<ReviewQuestion[]>(
    field.value || []
  );
  const [questionInEdit, setQuestionInEdit] = useState<ReviewQuestion | null>(
    null
  );

  const confirmDelete = () => {
    const values = [...questions.filter((o) => o.code != idToDelete)];
    setQuestions(values);
    helpers.setValue(values);
    setIdToDelete('');
  };

  const onEdit = (e: ReviewQuestion) => {
    setQuestionInEdit(e);
    setOpen(true);
  };

  return (
    <Card>
      <CardHeader
        title={t('Review questions')}
        // action={
        //   <FormControl variant="filled" fullWidth>
        //     <InputLabel id="address-state-select-filled-label">
        //       Import questions
        //     </InputLabel>
        //     <Select
        //       fullWidth
        //       sx={{ minWidth: 200 }}
        //       name="pronouns"
        //       labelId="pronouns-select"
        //       id="pronouns-select"
        //       value={''}
        //       onChange={(_event) => {
        //         //  helpersPronouns.setValue(_event.target.value);
        //       }}
        //     >
        //       <MenuItem value="">
        //         <em>Select...</em>
        //       </MenuItem>
        //     </Select>
        //   </FormControl>
        // }
      />
      <Divider />
      <Box
        px={3}
        sx={{
          display: 'flex',
          pt: { lg: 3 }
        }}
        pb={3}
      >
        <SearchInputWrapper
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            )
          }}
          onChange={(e) => {
            setQuestion({
              ...question,
              value: e.target.value
            });
          }}
          placeholder={t('Enter question to search')}
          fullWidth
          value={question.value}
        />
        <Button
          onClick={() => {
            setOpen(true);
          }}
          sx={{ ml: 1 }}
          size="small"
          variant="contained"
        >
          {'Add'}
        </Button>
        {/* <Tooltip placement="top" title="Cancel add">
          <Button
            onClick={() => {}}
            size="small"
            sx={{ ml: 1 }}
            variant="outlined"
          >
            X
          </Button>
        </Tooltip> */}
      </Box>
      <CardContentWrapper
        sx={{
          mx: 3,
          mb: 3,
          height: 344
        }}
      >
        <Scrollbar>
          <List disablePadding>
            {questions.map((item, index) => (
              <div key={item.code}>
                <ListItemWrapper
                  sx={{
                    alignItems: 'flex-start',
                    p: 3.5
                  }}
                >
                  <Box className="MuiActionButtons">
                    <Tooltip arrow placement="top" title={t('Edit')}>
                      <IconButton onClick={() => onEdit(item)} color="primary">
                        <EditTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip arrow placement="top" title={t('Delete')}>
                      <IconButton
                        onClick={() => setIdToDelete(item.code)}
                        color="error"
                      >
                        <CloseTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <ListItemText
                    primary={
                      <Typography lineHeight={1} gutterBottom variant="body1">
                        Q {index + 1}.&nbsp; {item.text}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          noWrap
                          variant="subtitle2"
                        >
                          Type:
                          <Box pl={0.5} component="span">
                            {t(item.type)}
                          </Box>
                        </Typography>
                      </>
                    }
                  />
                </ListItemWrapper>
                <Divider />
              </div>
            ))}
          </List>
        </Scrollbar>
      </CardContentWrapper>
      {open && (
        <AddQuestionDialog
          isOpen={open}
          onClose={() => {
            setOpen(false);
            setQuestionInEdit(null);
          }}
          question={questionInEdit}
          onSave={(e) => {
            if (!e.code) {
              e.code = questions.length + 1 + '';
              const newQs = [...questions, e];
              setQuestions(newQs);
              helpers.setValue(newQs);
            } else {
              const i = questions.findIndex((o) => o.code == e.code);
              if (i != -1) {
                const newQs = [...questions];
                newQs[i] = e;
                setQuestions(newQs);
                helpers.setValue(newQs);
              }
            }

            setOpen(false);
            setQuestionInEdit(null);
          }}
        />
      )}
      <DeleteConfirm
        open={Boolean(idToDelete.length)}
        onClose={() => setIdToDelete('')}
        onDeleteConfirm={confirmDelete}
        message="Are you sure, you want to delete"
      />
    </Card>
  );
}
