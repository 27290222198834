import CloseIcon from '@mui/icons-material/Close';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Zoom,
  styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useSnackbar } from 'notistack';
import {
  ChangeEvent,
  ReactElement,
  Ref,
  forwardRef,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { TeamMember } from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import { deleteEmployeeById } from '../../../../services/apiService';
import { parseToApiErrorMessage } from '../../../../utility/parseToApiErrorMessage';
import BulkActions from '../BulkActions';

interface Filters {
  role?: string;
}

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const applyFilters = (
  members: TeamMember[],
  query: string,
  filters: Filters
): TeamMember[] => {
  return members.filter((employee) => {
    let matches = true;

    if (query) {
      const properties = ['fullName'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (employee[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      // if (filters.role && employee.role !== filters.role) {
      //   matches = false;
      // }

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && employee[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
};

const applyPagination = (
  members: TeamMember[],
  page: number,
  limit: number
): TeamMember[] => {
  return members.slice(page * limit, page * limit + limit);
};

function MembersTab(props: { employees: TeamMember[]; isLoading: boolean }) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  const [query, setQuery] = useState<string>('');
  const [filters, setFilters] = useState<Filters>({
    role: null
  });

  const [employees, setEmployees] = useState<TeamMember[]>(props.employees);
  const [selectedItems, setSelectedmembers] = useState<string[]>([]);
  const filteredmembers = applyFilters(employees, query, filters);
  const paginatedmembers = applyPagination(filteredmembers, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomemembers =
    selectedItems.length > 0 && selectedItems.length < employees.length;
  const selectedAllmembers = selectedItems.length === employees.length;
  const [idsToDelete, setIdsToDelete] = useState([]);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
  };
  console.log(props.employees);
  console.log(employees);

  useEffect(() => {
    setIsLoading(props.isLoading);
    setEmployees(props.employees);
  }, [props.isLoading]);

  const handleSelectAllmembers = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedmembers(event.target.checked ? employees.map((o) => o.id) : []);
  };

  const handleSelectOneItem = (
    _event: ChangeEvent<HTMLInputElement>,
    employeeId: string
  ): void => {
    if (!selectedItems.includes(employeeId)) {
      setSelectedmembers((prevSelected) => [...prevSelected, employeeId]);
    } else {
      setSelectedmembers((prevSelected) =>
        prevSelected.filter((id) => id !== employeeId)
      );
    }
  };

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const onBulkDelete = () => {
    setIdsToDelete([...selectedItems]);
  };

  const handleDeleteClick = (id: string) => {
    setIdsToDelete([id]);
  };

  const closeConfirmDelete = () => {
    setIdsToDelete([]);
  };

  const handleDeleteCompleted = async () => {
    const ids = [...idsToDelete];
    setIdsToDelete([]);

    if (ids.length > 1) {
      const successIds: string[] = [];

      const deleteAll = async () => {
        for (let index = 0; index < ids.length; index++) {
          const id = ids[index];
          try {
            const success = await deleteEmployeeById(id);
            if (success) {
              successIds.push(id);
            }
          } catch (ex) {
            console.log(ex);
            const msg = parseToApiErrorMessage(ex, `Failed to delete member`);
            enqueueSnackbar(t(msg), { variant: 'error' });
          }
        }
      };
      await deleteAll();

      const items = [...employees].filter((o) => !successIds.includes(o.id));
      setEmployees(items);
      if (successIds.length) {
        enqueueSnackbar(t('Member(s) have been deleted'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      }
    } else {
      try {
        const id = ids[0];
        const success = await deleteEmployeeById(id);
        if (success) {
          const items = [...employees].filter((o) => o.id != id);
          setEmployees(items);
          enqueueSnackbar(t('Member has been deleted'), {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            },
            TransitionComponent: Zoom
          });
        }
      } catch (ex) {
        console.log(ex);
        const msg = parseToApiErrorMessage(ex, `Failed to delete Member`);
        enqueueSnackbar(t(msg), { variant: 'error' });
      }
    }
  };

  return (
    <>
      {props.isLoading && <LinearProgress />}
      <Card>
        <Box p={2}>
          {!selectedBulkActions && (
            <TextField
              sx={{
                m: 0
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
              onChange={handleQueryChange}
              placeholder={t('Search by name, email or department...')}
              value={query}
              size="small"
              fullWidth
              margin="normal"
              variant="outlined"
            />
          )}
          {selectedBulkActions && <BulkActions onDelete={onBulkDelete} />}
        </Box>

        <Divider />

        {paginatedmembers.length === 0 ? (
          <>
            <Typography
              sx={{
                py: 10
              }}
              variant="h3"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {props.isLoading && t('Loading members...')}
              {!props.isLoading &&
                t("We couldn't find any members matching your search criteria")}
            </Typography>
          </>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAllmembers}
                        indeterminate={selectedSomemembers}
                        onChange={handleSelectAllmembers}
                      />
                    </TableCell>
                    <TableCell>{t('Name')}</TableCell>
                    <TableCell>{t('Email')}</TableCell>
                    <TableCell align="center">{t('Department')}</TableCell>
                    <TableCell>{t('Job title')}</TableCell>
                    <TableCell>{t('Teams')}</TableCell>
                    <TableCell align="center">{t('Actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedmembers.map((employee) => {
                    const ismemberselected = selectedItems.includes(
                      employee.id
                    );
                    return (
                      <TableRow
                        hover
                        key={employee.id}
                        selected={ismemberselected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={ismemberselected}
                            onChange={(event) =>
                              handleSelectOneItem(event, employee.id)
                            }
                            value={ismemberselected}
                          />
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <Avatar
                              sx={{
                                mr: 1
                              }}
                              src={cdnService.toImageUrl(employee.imageUrl)}
                            />
                            <Box>
                              <Link
                                variant="h5"
                                component={RouterLink}
                                to={
                                  `/${
                                    location.pathname.split('/')[1]
                                  }/team/employee/edit/` + employee.id
                                }
                              >
                                {employee?.fullName}
                              </Link>
                              <Typography noWrap variant="subtitle2">
                                {employee?.createAuser ? 'Logged in user' : ''}
                                {(employee?.userRoles || []).map((role) =>
                                  role.toUpperCase()
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Typography>{employee.email}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography fontWeight="bold">
                            {(employee?.departments || []).join(',')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{employee?.jobTitle?.value}</Typography>
                        </TableCell>
                        <TableCell>
                          {employee.teams.map(({ name }) => name).join(',')}
                        </TableCell>

                        <TableCell align="center">
                          <Typography noWrap>
                            <Tooltip title={t('View')} arrow>
                              <IconButton
                                component={RouterLink}
                                to={
                                  `/${
                                    location.pathname.split('/')[1]
                                  }/team/employee/edit/` + employee.id
                                }
                                color="primary"
                              >
                                <LaunchTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t('Delete')} arrow>
                              <IconButton
                                onClick={() => handleDeleteClick(employee.id)}
                                color="primary"
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={filteredmembers.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[50, 100, 150]}
              />
            </Box>
          </>
        )}
      </Card>
      <DialogWrapper
        open={idsToDelete.length > 0}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 6
            }}
            variant="h3"
          >
            {t(
              'Are you sure you want to permanently delete ' +
                (idsToDelete.length > 1 ? 'selected members' : ' this members')
            )}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={closeConfirmDelete}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
}

export default MembersTab;
