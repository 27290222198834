import { Field, FieldProps, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
  Zoom,
  lighten,
  styled,
  useTheme
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import { Note } from 'src/CRM/models/note';
import { createNote, updateNote } from 'src/services/query-client/crm';
import { RootState } from 'src/store';
import ActivitiesContext from '../activities-context';
import AssociationPicker from '../association-picker';

const IconButtonError = styled(IconButton)(
  ({ theme }) => `
     background: ${theme.colors.error.lighter};
     color: ${theme.colors.error.main};

     &:hover {
      background: ${lighten(theme.colors.error.lighter, 0.4)};
     }
`
);

const CardActionsWrapper = styled(Card)(
  ({ theme }) => `
     background: ${theme.colors.alpha.black[5]};
     box-shadow: none;
     margin: 0 ${theme.spacing(3)};
`
);

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 300px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);

function NoteEditor(props: {
  note?: Note;
  onSave: (task: Note, isEdit: boolean) => void;
  onCancel: () => void;
}) {
  const { t }: { t: any } = useTranslation();
  const context = useContext(ActivitiesContext);
  const theme = useTheme();
  const { user } = useSelector((state: RootState) => state.app);
  const { enqueueSnackbar } = useSnackbar();
  const [isSaving, setIsSaving] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [model, setModel] = useState<Note>({
    description: '',
    descriptionRich: '',
    associations: [
      {
        id: context.entityId,
        type: context.entityType,
        name: context.entityName
      }
    ],
    createdOn: new Date(),
    createdBy: {
      // id: String(user.employeeIds[0]),
      // name: user.email
    },
    ...props.note
  });

  useEffect(() => {
    if (props.note && props.note?.id) {
      setIsEdit(true);
    }
  }, []);
  if (!model) return <Box m={5}> Please wait... </Box>;
  return (
    <Box sx={{ maxWidth: 700 }}>
      <Formik
        initialValues={{
          associations: model.associations || [],
          description: model.description || '',
          descriptionRich: model.descriptionRich || ''
        }}
        validationSchema={Yup.object().shape({
          description: Yup.string().required(t('field is required')).max(5000),
          descriptionRich: Yup.string()
            .required(t('field is required'))
            .max(5000),

          associations: Yup.array()
            .of(
              Yup.object().shape({
                type: Yup.string(),
                name: Yup.string(),
                id: Yup.string()
              })
            )
            .required('Task must be associcated to entity')
            .min(1, 'atleast one required')
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            const modelToSave = {
              ...model,
              ...values,
              source: user.context.appName
            };
            setModel(modelToSave);
            setSubmitting(true);
            let resNote = null;
            if (!isEdit) {
              const res = await createNote(modelToSave);
              resNote = res.data;
            } else {
              const res = await updateNote(modelToSave);
              resNote = res.data;
            }
            enqueueSnackbar(
              t(
                'The note has been successfully ' +
                  (!isEdit ? 'created' : 'updated')
              ),
              {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center'
                },
                TransitionComponent: Zoom
              }
            );
            props.onSave(resNote, isEdit);
          } catch (err) {
            console.error(err);
            setStatus({ success: false });

            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Box p={3} pb={1}>
              <Typography variant="h4">Note</Typography>
            </Box>
            <Divider />
            <Box px={3} py={1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl variant="filled" fullWidth>
                    <Field name="descriptionRich">
                      {({ field, form, meta }: FieldProps) => (
                        <EditorWrapper>
                          <ReactQuill
                            placeholder="Your note goes here..."
                            value={field.value}
                            onChange={(content, delta, source, editor) => {
                              const text = editor.getText();
                              const textRich = editor.getHTML();
                              form.setFieldValue(field.name, textRich);
                              form.setFieldValue('description', text);
                            }}
                          />
                        </EditorWrapper>
                      )}
                    </Field>
                    {touched['descriptionRich'] &&
                      Boolean(errors['descriptionRich']) && (
                        <FormHelperText
                          error={
                            touched['descriptionRich'] &&
                            Boolean(errors['descriptionRich'])
                          }
                        >
                          {errors['descriptionRich']}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Field name="associations">
                    {({ field, form, meta }: FieldProps) => (
                      <AssociationPicker
                        associations={model.associations}
                        onChange={(items) => {
                          form.setFieldValue(field.name, items);
                        }}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Box>
            <CardActionsWrapper
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    mr: 1
                  }}
                  color="secondary"
                  onClick={props.onCancel}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={isSubmitting}
                  color="primary"
                >
                  {t('Save note')}
                </Button>
              </Box>
            </CardActionsWrapper>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default NoteEditor;
