import LinkOffTwoToneIcon from '@mui/icons-material/LinkOffTwoTone';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
  Zoom,
  styled,
  useTheme
} from '@mui/material';
import { alpha, darken } from '@mui/material/styles';
import { Dictionary } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import DeleteConfirm from 'src/components/delete-confirm';
import ErrorPanel from 'src/components/error-panel';
import { Card as LinkedCard } from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import { QKEY, getMyNfcCards, nfcCodeReset } from 'src/services/query-client';
import { parseToApiErrorMessage } from 'src/utility/parseToApiErrorMessage';

const CardHover = styled(Card)(
  ({ theme }) => `
    transform: scale(1);
    transition: ${theme.transitions.create(['transform'])};

    &:hover {
        transform: scale(1.1);
    }
  `
);

const BgComposed = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: 100%;
    height: 145px;
    left: 0;
    top: 0;
    transition: ${theme.transitions.create(['opacity'])};
    background: ${darken(alpha(theme.colors.primary.main, 0.9), 0.8)};
    z-index: 6;
    opacity: 0;
    box-shadow: inset 0 0 2.3rem 0.5rem ${darken(
      theme.colors.primary.main,
      0.9
    )};
  `
);

const IconButtonWrapper2 = styled(IconButton)(
  ({ theme }) => `
      background-color: ${theme.colors.secondary.lighter};
      color:  ${theme.colors.primary.main};
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      border-radius: 100px;
      
      &:hover {
          background-color: ${alpha(theme.colors.secondary.light, 0.3)};
      }
`
);

export default function MyCardList(props: {
  cards?: LinkedCard[];
  onResetSuccess?: () => void;
}) {
  const navigate = useNavigate();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  // const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState('');
  const [nfcToUnlink, setNfcToUnlink] = useState('');
  const [maps, setMaps] = useState<LinkedCard[]>([]);
  const [qrImgs, setQrImgs] = useState<Dictionary<string>>({});
  // const [profileHash, setProfileHash] = useState<Dictionary<Profile>>({});
  const {
    data: { data: nfcCodeMap = [] } = {},
    isError,
    error,
    isLoading,
    refetch
  } = useQuery(QKEY.MyNFCCard, getMyNfcCards);
  console.log('nfcCodeMap', nfcCodeMap);
  const { resultProfileHash, resultMaps } = nfcCodeMap.reduce(
    (acc, o) => {
      acc.resultProfileHash[o.nfcCode] = o.profile;
      acc.resultMaps.push(...o.profile.cards);
      return acc;
    },
    { resultProfileHash: {}, resultMaps: [] }
  );

  // setProfileHash(resultProfileHash);
  // setMaps(resultMaps);

  useEffect(() => {
    setMaps(props.cards || resultMaps);
    // setProfileHash({
    //   'nfc test': { id: '1', name: 'pr', type: ProfileTypes.Individual }
    // });
    // (async () => {
    //   try {
    //     const res = await getNfcMaps();
    //     setMaps(res.data);

    //     const hash = {};
    //     res.data.forEach(
    //       (o) => (hash[o.nfcCode] = convertCardToProfile(o.card))
    //     );
    //     setProfileHash(hash);

    //     const imgHash = {};
    //     for (const map of res.data) {
    //       imgHash[map.nfcCode] = await QrImg(map);
    //     }
    //     setQrImgs(imgHash);

    //     setIsLoading(false);
    //   } catch (ex) {
    //     const msg = ex.message ? ex.message : `Failed to load cards data`;
    //     enqueueSnackbar(t(msg), { variant: 'error' });
    //     setIsLoading(false);
    //     setError(msg);
    //   }
    // })();
  }, [resultMaps]);

  const getBgImg = (e: LinkedCard): string => {
    let ctype = 'White';

    if (e.cardType) {
      if (e.cardType.trim().toLowerCase() == 'gold') ctype = 'Gold';
      else if (e.cardType.trim().toLowerCase() == 'black') ctype = 'Black';
      else if (e.cardType.trim().toLowerCase() == 'grey') ctype = 'Grey';
    }
    return ctype;
  };

  const onRightClick = async (e: LinkedCard) => {
    window.open(cdnService.toNfcUrl(e.nfcCode), '_blank');
  };

  const handleUnlinkConfirm = async () => {
    try {
      await nfcCodeReset(nfcToUnlink);
      setMaps([...maps.filter((o) => o.nfcCode != nfcToUnlink)]);
      setNfcToUnlink('');
      if (props.onResetSuccess) {
        props.onResetSuccess();
      }
      enqueueSnackbar(t('Card has been reset'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    } catch (ex) {
      setNfcToUnlink('');
      console.log(ex);
      const msg = parseToApiErrorMessage(ex, `Failed to unlink Card`);
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
  };

  const onUnlinkClick = (e: LinkedCard) => {
    if (!nfcToUnlink) {
      setNfcToUnlink(e.nfcCode);
    }
  };
  return (
    <>
      {/* <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper> */}
      <Grid
        sx={{
          px: 1
        }}
        container
        direction="row"
        spacing={2}
      >
        {!isLoading && isError && (
          <Grid item xs={12}>
            <ErrorPanel title="Critical Error" message={error as string} />
          </Grid>
        )}
        {isLoading &&
          [1, 2, 3, 4].map((o) => (
            <Grid key={o} item xs={12} md={3}>
              <Skeleton
                variant="rectangular"
                sx={{ height: 204, width: 325 }}
              ></Skeleton>
            </Grid>
          ))}
        {!isLoading &&
          maps.map((map) => (
            <Grid key={map.nfcCode} item xs={12} md={3}>
              <CardHover
                sx={{
                  display: 'flex',
                  px: 3,
                  py: 5,
                  height: 204,
                  width: 325,
                  background: `no-repeat url(${cdnService.toCdnUrl(
                    'static/' + getBgImg(map) + '-Front.png'
                  )})`,
                  backgroundSize: 'cover',
                  '&:hover': {
                    '& .MuiBgComposed': {
                      opacity: 1
                    }
                  }
                }}
              >
                <BgComposed
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  className="MuiBgComposed"
                >
                  <Tooltip
                    title={cdnService.toNfcUrl(map.nfcCode)}
                    placement="top"
                  >
                    <Avatar
                      sx={{
                        width: 62,
                        height: 62,
                        mb: 1,
                        border: `${theme.colors.alpha.trueWhite[100]} solid 3px`
                      }}
                      src={qrImgs[map.nfcCode]}
                      variant="square"
                      onContextMenu={() => onRightClick(map)}
                    />
                  </Tooltip>
                  <Tooltip title={map.nfcCode} placement="bottom">
                    <Button
                      sx={{
                        px: 2.5,
                        borderRadius: 10,
                        transform: 'scale(1)',
                        transition: `${theme.transitions.create(['all'])}`,
                        boxShadow: `${theme.colors.shadows.error}`,

                        '&:hover': {
                          transform: 'scale(1.05)',
                          boxShadow: `${theme.colors.shadows.error}`
                        },
                        '&:active': {
                          boxShadow: 'none'
                        }
                      }}
                      variant="contained"
                      color="error"
                      startIcon={<LinkOffTwoToneIcon />}
                      onClick={() => onUnlinkClick(map)}
                    >
                      {t('Unlink profile')}
                    </Button>
                  </Tooltip>
                </BgComposed>
                <AvatarGroup
                  sx={{
                    position: 'absolute',
                    bottom: theme.spacing(1),
                    left: theme.spacing(1),
                    justifyContent: 'center'
                  }}
                  max={6}
                >
                  <Tooltip
                    title={
                      'Linked profile: ' + resultProfileHash[map.nfcCode].name
                    }
                  >
                    <Link
                      to={
                        '/app/cards/personal/edit/' +
                        resultProfileHash[map.nfcCode].id +
                        '?ret=/app/my-cards'
                      }
                    >
                      <Avatar
                        sx={{
                          width: 44,
                          height: 44
                        }}
                        alt={resultProfileHash[map.nfcCode].name}
                        src={cdnService.toImageUrl(
                          resultProfileHash[map.nfcCode]?.data?.profileImage
                        )}
                      />
                    </Link>
                  </Tooltip>
                </AvatarGroup>
              </CardHover>
            </Grid>
          ))}
        {!isLoading && !isError && resultMaps.length == 0 && (
          <Grid item xs={12}>
            <Card
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 4.5,
                pt: 2
              }}
            >
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    pb: 1
                  }}
                >
                  {t('We found no card for you. No worries!!')}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    pb: 3
                  }}
                >
                  {t('Here is what you can do-')}
                </Typography>
                <Typography variant="subtitle2" sx={{}}>
                  {t('If you do not own any card-')}
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight="normal"
                  sx={{
                    pt: 1,
                    pb: 1
                  }}
                  color="primary"
                  component="p"
                >
                  {t(
                    'Visit Amazon.com to order your own KiEngage Digital Business Card'
                  )}
                </Typography>
                <Button
                  onClick={() =>
                    window.open(
                      'https://www.amazon.com/dp/B0CKGF1H71?ref=app-kiengage',
                      '_blank'
                    )
                  }
                  variant="contained"
                  color="secondary"
                >
                  {t('Order now')}
                </Button>
                <Typography
                  variant="subtitle2"
                  sx={{
                    pt: 3,
                    pb: 1
                  }}
                >
                  {t('If you own a card-')}
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight="normal"
                  sx={{
                    pb: 1
                  }}
                  color="primary"
                  component="p"
                >
                  {t(
                    'Please tap your card on your mobile and follow the steps on your mobile to link your card to your profile'
                  )}
                </Typography>
              </Box>
              <Box
                component="span"
                sx={{
                  display: { xs: 'none', md: 'inline-block' }
                }}
              >
                <img
                  src="https://kiengage.com/wp-content/uploads/2023/03/WhatsApp-Image-2023-03-13-at-22.33.58-1-768x374.jpeg"
                  alt="..."
                  width={305}
                  height={219}
                />
              </Box>
              <Box
                component="span"
                sx={{
                  display: { xs: 'none', sm: 'inline-block' }
                }}
              ></Box>
            </Card>
          </Grid>
        )}
      </Grid>
      <DeleteConfirm
        open={nfcToUnlink.length ? true : false}
        message="Are you want unlink profile & reset the card?"
        confirmText="Yes"
        cancelText="No"
        onClose={() => setNfcToUnlink('')}
        onDeleteConfirm={() => handleUnlinkConfirm()}
      />
    </>
  );
}
