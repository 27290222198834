import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { Box, Button, Grid, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);

function PageHeader(props: {
  isEdit: boolean;
  contactId: string;
  onBack: () => void;
}) {
  const { t }: { t: any } = useTranslation();
  const location = useLocation();

  return (
    <RootWrapper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h3" component="h3" gutterBottom>
                {props.isEdit ? 'Edit Lead' : t('New Lead')}
              </Typography>
              <Typography variant="subtitle2">
                {props.isEdit
                  ? t('Fill in the fields below to edit lead')
                  : t('Fill in the fields below to create a new lead')}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Button
            sx={{
              mt: { xs: 2, sm: 0 }
            }}
            startIcon={<ArrowBackTwoToneIcon />}
            onClick={props.onBack}
            variant="contained"
          >
            {t('Go back')}
          </Button>
        </Grid>
      </Grid>
    </RootWrapper>
  );
}

export default PageHeader;
