import { LinearProgress } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountTypes } from 'src/services/apiService/response-models';
import { RootState, useSelector } from 'src/store';

export default function DashboardMain() {
  const { user } = useSelector((state: RootState) => state.app);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      user.clientAccount.accountType == AccountTypes.Business &&
      user.user.roles.some((r) => r.roleName == 'admin')
    ) {
      navigate('/app/company-profiles');
    } else {
      navigate('/app/member-profiles');
    }
  }, []);

  return <LinearProgress sx={{ my: 2 }} />;
}
