import {
  Autocomplete,
  Box,
  Card,
  Grid,
  TextField,
  styled
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);

function GeneralSection(props: { tagOptions: string[] }) {
  const { t }: { t: any } = useTranslation();

  return (
    <Card
      sx={{
        p: 3
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="title"
            placeholder={t('Equipment name here...')}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <EditorWrapper>
            <ReactQuill />
          </EditorWrapper>
        </Grid>
        <Grid item xs={12}>
          <Field name="tags">
            {({
              field,
              form: {
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError
              },
              meta
            }: FieldProps) => (
              <Autocomplete
                sx={{
                  m: 0
                }}
                freeSolo
                multiple
                limitTags={10}
                value={field.value}
                autoSelect
                onChange={(event: any, newValues: string[] | null) => {
                  const vals = newValues.filter((o) => o.trim().length);
                  setFieldValue(field.name, vals);
                  setFieldTouched(field.name, true);
                }}
                options={props.tagOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={t('Enter or Select tags...')}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </Card>
  );
}

export default GeneralSection;
