import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  styled,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import Scrollbar from 'src/ui-bloom/components/Scrollbar';
import PageHeader from './PageHeader';

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import cdnService from 'src/services/cdnService';
import { ContactDto } from 'src/services/dto';
import {
  appQueryClient,
  getContactById,
  getRefData,
  saveContact
} from 'src/services/query-client';
import { RootState } from 'src/store';
import extractDialCode from 'src/utility/extractDialCode';
import extractNumber from 'src/utility/extractNumber';
import readQueryString from 'src/utility/readQueryString';
import { isValidZipRegExp, phoneRegExp } from 'src/utility/reg-exes';
import * as Yup from 'yup';
import {
  Address,
  AddressTypes,
  Contact
} from '../../../services/apiService/response-models';
import { parseApiError } from '../../../utility/parseToApiErrorMessage';
import ContactInfo from './ContactInfo';
import GroupInfo from './GroupInfo';
import PersonalDetails from './PersonalDetails';
import Sidebar from './Sidebar';

const DrawerWrapper = styled(Drawer)(
  ({ theme }) => `
    width: 400px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 400px;
        height: calc(100% - ${theme.header.height});
        position: absolute;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.alpha.white[10]};
    }
`
);

const DrawerWrapperMobile = styled(Drawer)(
  ({ theme }) => `
    width: 360px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 360px;
        z-index: 3;
        background: ${theme.colors.alpha.white[30]};
  }
`
);

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

function CreateContact() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id: contactId } = useParams();

  const [isSubmit, setIsSubmit] = useState(false);

  const [isEdit, setIsEdit] = useState(Boolean(contactId && contactId.length));

  const { user } = useSelector((state: RootState) => state.app);

  //Queries
  const {
    data: { data: accountRefData } = {},
    isError,
    error,
    isLoading: isRefDataLoading
  } = useQuery('ref-data', getRefData);

  const {
    data: { data: model } = {},
    isLoading,
    refetch: refetchById
  } = useQuery(['contacts', contactId], (k) => getContactById(contactId), {
    enabled: isEdit
  });

  const {
    mutate: save,
    isError: isSaveError,
    error: errorOnSave,
    isLoading: isSaving
  } = useMutation((e: ContactDto) => saveContact(e, contactId), {
    onSuccess: (o) => onSaveSuccess(o.data)
  });

  //----Queries

  useEffect(() => {}, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (isSaveError) {
    const msg = parseApiError(
      errorOnSave,
      `Failed to ${!isEdit ? 'created' : 'updated'} Contact`
    );
    enqueueSnackbar(t(msg), { variant: 'error' });
  }

  const onSaveSuccess = async (updatedModel: Contact) => {
    enqueueSnackbar(
      t(`Contact was ${!isEdit ? 'created' : 'updated'} successfully`),
      { variant: 'success' }
    );
    if (!isEdit) navigate('/app/contact/edit/' + updatedModel.id);
    else {
      appQueryClient.setQueryData(['contacts', contactId], updatedModel);
      refetchById();
    }
  };

  const onSaveClick = () => {
    setIsSubmit(true);
  };

  const handleBack = (): void => {
    return navigate(readQueryString()?.ret || `/app/contact/list`);
  };
  const sidebarContent = (
    <Scrollbar>
      <Sidebar isSubmit={isSubmit} />
    </Scrollbar>
  );
  const address = (model?.addresses || []).find(
    (o) => o.addressType == AddressTypes.Primary
  );

  if (isLoading || isRefDataLoading) {
    return <LinearProgress />;
  }
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: model?.firstName || '',
          pronouns: model?.pronouns || '',
          middleName: model?.middleName || '',
          lastName: model?.lastName || '',
          imageUrl: model?.imageUrl,
          email: model?.email || '',
          altEmail: model?.altEmail || '',
          contactType: model?.contactType || null,
          contactNumberWork: extractNumber(model?.altContactNumber) || '',
          countryDialCodeWork: extractDialCode(model?.altContactNumber) || '1',
          contactNumberPersonal: extractNumber(model?.contactNumber) || '',
          countryDialCodePersonal: extractDialCode(model?.contactNumber) || '1',
          descriptionRich: model?.descriptionRich || '',
          dateOfBirth: model?.dateOfBirth || null,
          groupTags: model?.groupTags || [],
          linkedIn: model?.linkedIn || '',
          twitter: model?.twitter || '',
          facebook: model?.facebook || '',
          whatsApp: model?.whatsApp || '',
          liCyStage: model?.liCyStage || null,
          address: {
            addressLine: address?.addressLine || '',
            addressLine2: address?.addressLine2 || '',
            countryCode: address?.countryCode || '',
            stateCode: address?.stateCode || '',
            zip: address?.zip || ''
          }
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().max(250).required(t('The field is required')),
          lastName: Yup.string().max(250).required(t('The field is required')),
          email: Yup.string()
            .max(250)
            .email('Not a proper email')
            .test('Unique Email', 'Email already in use', async (value) => {
              // if (!value || isEdit) return true;
              // const result = await checkAccountExistsByEmail(value);
              return true;
              //!result.data;
            }),
          altEmail: Yup.string().max(250).email('Not a proper email'),
          contactNumberWork: Yup.string().matches(
            phoneRegExp,
            'Phone number is not valid'
          ),
          contactNumberPersonal: Yup.string().matches(
            phoneRegExp,
            'Phone number is not valid'
          ),
          countryDialCodeWork: Yup.string(),
          countryDialCodePersonal: Yup.string(),
          linkedIn: Yup.string().max(250),
          twitter: Yup.string().max(250),
          facebook: Yup.string().max(250),
          whatsApp: Yup.string().max(250),
          zip: Yup.string().matches(isValidZipRegExp, 'Enter correct zip!')
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          const updatedModel: ContactDto = {
            ..._values,
            contactNumber: _values.contactNumberWork,
            altContactNumber: _values.contactNumberPersonal,
            source: {
              code: user.context.appName,
              value: location.origin
            }
          };

          ///addresses
          let addresses = model?.addresses ? [...model.addresses] : [];

          //remove primary
          addresses = addresses.filter(
            (o) => o.addressType != AddressTypes.Primary
          );

          const addr: Address = {
            ..._values.address,
            addressType: AddressTypes.Primary
          };

          updatedModel.addresses = [addr, ...addresses];

          save(updatedModel);

          cdnService.uploadBlobToCloud(updatedModel.imageUrl);

          setStatus({ success: true });
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3} display="flex">
              <MainContentWrapper>
                <Grid
                  sx={{
                    px: 4
                  }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid item xs={12}>
                    <Box
                      mt={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <PageHeader
                        isEdit={isEdit}
                        contactId={contactId}
                        onBack={handleBack}
                      />
                      <Box
                        component="span"
                        sx={{
                          display: { lg: 'none', xs: 'inline-block' }
                        }}
                      >
                        <IconButtonToggle
                          sx={{
                            ml: 2
                          }}
                          color="primary"
                          onClick={handleDrawerToggle}
                          size="small"
                        >
                          <MenuTwoToneIcon />
                        </IconButtonToggle>
                      </Box>
                    </Box>
                  </Grid>
                  {isLoading && (
                    <Grid item xs={12}>
                      <LinearProgress />
                      loading...
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <PersonalDetails accountRefData={accountRefData} />
                  </Grid>
                  <Grid item xs={12}>
                    <GroupInfo accountRefData={accountRefData} />
                  </Grid>
                  <Grid item xs={12}>
                    <ContactInfo isEdit={isEdit} />
                  </Grid>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        p: 1
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Button
                            type="button"
                            component={RouterLink}
                            variant="outlined"
                            to={`/${
                              location.pathname.split('/')[1]
                            }/contact/list`}
                          >
                            Cancel
                          </Button>
                          <Button
                            sx={{ ml: 2 }}
                            type="submit"
                            onClick={() => onSaveClick()}
                            variant="contained"
                            startIcon={
                              isSubmitting || isSaving ? (
                                <CircularProgress size="1rem" />
                              ) : (
                                <SaveIcon />
                              )
                            }
                            disabled={isLoading || isSubmitting || isSaving}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </MainContentWrapper>
              <Box
                component="span"
                sx={{
                  display: { lg: 'none', xs: 'inline-block' }
                }}
              >
                <DrawerWrapperMobile
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                >
                  {sidebarContent}
                </DrawerWrapperMobile>
              </Box>
              <Box
                component="span"
                sx={{
                  display: { xs: 'none', lg: 'inline-block' }
                }}
              >
                <DrawerWrapper
                  variant="permanent"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open
                >
                  {sidebarContent}
                </DrawerWrapper>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}

export default CreateContact;
