import {
  Grid,
  MenuItem,
  ListItemText,
  useTheme,
  styled,
  MenuList,
  Box,
  Popover,
  Button,
  alpha,
  Card,
  Autocomplete,
  TextField
} from '@mui/material';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import { useTranslation } from 'react-i18next';
import { useContext, useRef, useState } from 'react';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { AssociatedWithInfo } from '../../models/task';
import ActivitiesContext from './activities-context';

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(10)};
    font-weight: bold;
    text-transform: uppercase;
    border-radius: ${theme.general.borderRadiusSm};
    padding: ${theme.spacing(0.5, 1)};
  `
);
const SearchIconWrapper = styled(SearchTwoToneIcon)(
  ({ theme }) => `
        color: ${theme.colors.alpha.black[30]}
`
);

const MenuListWrapper = styled(MenuList)(
  ({ theme }) => `
    & .MuiMenuItem-root {
        border-radius: ${theme.general.borderRadius};
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: ${theme.spacing(1.5, 1, 1.5, 2.5)};
        margin-bottom: 2px;
        position: relative;

        &::before {
            left: 0;
            width: 0;
            background: ${theme.colors.primary.main};
            content: ' ';
            top: 50%;
            margin-top: -10px;
            height: 0;
            transition: ${theme.transitions.create(['all'])};
            opacity: 0;
            position: absolute;
            border-top-right-radius: ${theme.general.borderRadius};
            border-bottom-right-radius: ${theme.general.borderRadius};
        }

        &.Mui-selected,
        &:hover,
        &.MuiButtonBase-root:active {
            color: ${theme.colors.primary.main};

            &::before {
                height: 100%;
                opacity: 1;
                width: 4px;
                margin: 0;
                top: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
      }
  `
);

export default function AssociationPicker(props: {
  associations?: AssociatedWithInfo[];
  onChange: (e: AssociatedWithInfo[]) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const ref = useRef<any>(null);
  const context = useContext(ActivitiesContext);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Button ref={ref} onClick={handleOpen} variant="text">
        Associated with 1 record
      </Button>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        elevation={9}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box
          sx={{
            p: 2,
            background: alpha(theme.colors.alpha.black[100], 0.06)
          }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <AssociationSelector
            entityType={context.entityType}
            entityId={context.entityId}
            entityName={context.entityName}
            associations={props.associations}
          />
        </Box>
      </Popover>
    </>
  );
}
function AssociationSelector(props: {
  entityType: string;
  entityId: string;
  entityName: string;
  associations?: AssociatedWithInfo[];
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const selectedTags = props.associations || [];

  const isContact = props.entityType == 'contact';
  const isLead = props.entityType == 'lead';
  const isReview = props.entityType == 'review';

  return (
    <div style={{ width: '600px', border: 'solid 2px #ccc' }}>
      <Grid container>
        <Grid item xs={3}>
          <MenuListWrapper
            disablePadding
            sx={{
              mb: 3,
              mr: 3
            }}
          >
            <MenuItem selected={isContact} disabled={!isContact}>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5'
                }}
                primary={t('Contacts')}
              />
              <ChevronRightTwoToneIcon
                sx={{
                  color: `${theme.colors.alpha.black[30]}`,
                  opacity: 0.8
                }}
              />
            </MenuItem>
            <MenuItem selected={isLead} disabled={!isLead}>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5'
                }}
                primary={t('Leads')}
              />
              <ChevronRightTwoToneIcon
                sx={{
                  color: `${theme.colors.alpha.black[30]}`,
                  opacity: 0.8
                }}
              />
            </MenuItem>
            <MenuItem selected={isReview} disabled={!isReview}>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5'
                }}
                primary={t('Reviews')}
              />
              <ChevronRightTwoToneIcon
                sx={{
                  color: `${theme.colors.alpha.black[30]}`,
                  opacity: 0.8
                }}
              />
            </MenuItem>
          </MenuListWrapper>
        </Grid>
        <Grid item xs={9} sx={{ padding: '5px', paddingTop: '10px' }}>
          <Autocomplete
            multiple
            readOnly
            fullWidth
            limitTags={1}
            options={selectedTags}
            // @ts-ignore
            getOptionLabel={(option) => option.name}
            defaultValue={selectedTags}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                label={t('Associated ' + props.entityType)}
                placeholder={t('Select ...')}
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
