import {
  Avatar,
  Button,
  Dialog,
  Grid,
  LinearProgress,
  Slide,
  styled,
  Tab,
  Tabs,
  Typography,
  Zoom
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { forwardRef, ReactElement, Ref, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';

import { TransitionProps } from '@mui/material/transitions';
import { useQuery } from 'react-query';
import DeleteConfirm from 'src/components/delete-confirm';
import { NfcLinkedCardDialog } from 'src/components/nfc-linked-card-dialong';
import { ProfileCloneDialog } from 'src/components/profile-clone-dialog';
import { deleteProfileById } from 'src/services/apiService';
import { ProfileTypes } from 'src/services/apiService/response-models';
import { getIndividualProfiles, QKEY } from 'src/services/query-client';
import { RootState, useSelector } from 'src/store';
import Footer from 'src/ui-bloom/components/Footer';
import { parseApiError } from 'src/utility/parseToApiErrorMessage';
import PageHeader from './page-header';
import ProfileBlock from './profile-block';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function MemberProfileList() {
  const navigate = useNavigate();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [idToDelete, setIdToDelete] = useState('');
  const [idToClone, setIdToClone] = useState('');
  const [linkedProfileId, setLinkedProfileId] = useState('');
  const { user } = useSelector((state: RootState) => state.app);
  const [tabValue, setTabValue] = useState('my-profiles');

  const {
    data: { data: profiles = [] } = {},
    isError,
    error,
    isLoading,
    refetch
  } = useQuery(QKEY.Profiles, getIndividualProfiles);

  const myProfiles = profiles.filter(
    (o) => o.teamMemberId === user.user.teamMemberId
  );
  const teamProfiles = profiles.filter(
    (o) => o.teamMemberId !== user.user.teamMemberId
  );

  const handleDeleteConfirm = async () => {
    try {
      await deleteProfileById(idToDelete);
      refetch();
      enqueueSnackbar('Profile has been deleted', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    } catch (ex) {
      const msg = parseApiError(ex, `Failed to delete Profile`);
      enqueueSnackbar(msg, { variant: 'error' });
    }
    setIdToDelete('');
  };
  if (isLoading) return <LinearProgress />;

  if (isError) return <div>error:{error}</div>;

  return (
    <>
      <PageTitleWrapper>
        <PageHeader
          isManager={
            user?.context.isAdmin ||
            user.context.isManager ||
            user.context.isSadmin
          }
        />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <TabsWrapper
            onChange={(e, v) => {
              setTabValue(v);
            }}
            scrollButtons="auto"
            textColor="secondary"
            value={tabValue}
            variant="scrollable"
          >
            <Tab value={'my-profiles'} label={'My Profiles'} />
            {teamProfiles.length != 0 && (
              <Tab value={'team-profiles'} label={'Team Profiles'} />
            )}
          </TabsWrapper>
        </Grid>
        {tabValue == 'my-profiles' && (
          <Grid item xs={12}>
            {!isLoading && myProfiles.length == 0 && (
              <Typography
                sx={{
                  py: 10
                }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t('No profiles to display')}
              </Typography>
            )}
            <Grid container direction="row" spacing={4}>
              {myProfiles.map((o) => (
                <Grid key={o.id} item md={4} xs={12}>
                  <ProfileBlock
                    onDelete={(id) => setIdToDelete(id)}
                    onClone={(id) => setIdToClone(id)}
                    onLinkedCard={(id) => setLinkedProfileId(id)}
                    profile={o}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {tabValue == 'team-profiles' && (
          <Grid item xs={12}>
            {!isLoading && teamProfiles.length == 0 && (
              <Typography
                sx={{
                  py: 10
                }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t('No profiles to display')}
              </Typography>
            )}
            {teamProfiles.map((o) => (
              <Grid key={o.id} item md={6} xs={12}>
                <ProfileBlock
                  onDelete={(id) => setIdToDelete(id)}
                  onClone={(id) => setIdToClone(id)}
                  onLinkedCard={(id) => setLinkedProfileId(id)}
                  profile={o}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      <DeleteConfirm
        open={idToDelete.length ? true : false}
        message="Are you sure, want delete profile?"
        confirmText="Yes"
        cancelText="No"
        onClose={() => setIdToDelete('')}
        onDeleteConfirm={handleDeleteConfirm}
      />
      <ProfileCloneDialog
        profileId={idToClone}
        profileTyepe={ProfileTypes.Individual}
        title={profiles.find((o) => o.id == idToClone)?.data?.businessName}
        onClose={() => setIdToClone('')}
        onConfirm={() => setIdToClone('')}
      />
      <NfcLinkedCardDialog
        linkedCards={profiles.find((o) => o.id == linkedProfileId)?.cards || []}
        profileId={linkedProfileId}
        onClose={() => setLinkedProfileId('')}
        onChange={() => refetch()}
      />
      <Footer />
    </>
  );
}

export default MemberProfileList;
