import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  IconButton,
  List,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { isEmptyArray } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { FieldWrapper } from 'src/components/FieldWrapper';
import FieldsRegistry, {
  FieldBlockTypes,
  FieldRenderBlockProp
} from 'src/components/FieldsRegistry';
import BtnGroupFieldsBlock from 'src/components/FieldsRegistry/FieldRenderers/BtnGroupFieldsBlock';
import CoverPhotoBlock from 'src/components/FieldsRegistry/FieldRenderers/CoverPhoto/block';
import FixedFieldsBlock from 'src/components/FieldsRegistry/FieldRenderers/FixedFieldsBlock';
import LogoBlockRenderer from 'src/components/FieldsRegistry/FieldRenderers/Logo/block';
import ProfilePicBlockRenderer from 'src/components/FieldsRegistry/FieldRenderers/ProfilePic/block';
import {
  ProfileData,
  ProfileField,
  ProfileFieldNames
} from 'src/services/apiService/response-models';
import { getScroll } from 'src/utility/getScroll';
import getThemeColor from 'src/utility/getThemeColor';
import FieldEditorPopper from '../FieldEditorPopper';
import {} from '../profile-editor-context';
import './style.css';
import { ListItemWrapper } from './styled-wrapper';
import getValueIndex from './utils/getValueIndex';

export const DEFAULT_THEME = 'Light';

export interface ProfilePreviewerProp {
  profileTheme?: string;
  previeverFields: PreviewerField[];
  profileData: ProfileData;
  fieldInEdit?: ProfileField;
  onFieldInEditChange: (e: ProfileField | null) => void;
  onThemeChange: (e: string) => void;
  onDataModfied: (
    fields: ProfileField[],
    data: ProfileData,
    theme: string
  ) => void;
}

export interface PreviewerField {
  preId: string;
  index?: number;
  order?: number;
  isEdit: boolean;
  field: ProfileField;
}

export default function ProfilePreviewer(props: ProfilePreviewerProp) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t }: { t: any } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [fieldInEdit, setFieldInEdit] = useState<PreviewerField | null>();

  const [profileTheme, setProfileTheme] = useState(
    props.profileTheme || DEFAULT_THEME
  );
  const [preFields, setPreFields] = useState<PreviewerField[]>(
    props.previeverFields
  );

  const [modifiedProfileData, setModifiedProfileData] = useState<ProfileData>(
    props.profileData || {}
  );
  const isPopperOpen = Boolean(anchorEl);

  useEffect(() => {
    if (isEmpty(modifiedProfileData)) {
      setModifiedProfileData(props.profileData);
    }
    if (isEmptyArray(preFields)) {
      setPreFields(props.previeverFields);
    }
  }, [props.profileData, props.previeverFields]);

  useEffect(() => {
    if (fieldInEdit) return;
    if (!props.fieldInEdit) {
      clearEditInField();
      return;
    }

    // //when another field is is focused while exiting field is still active
    // if (fieldInEdit && !fieldInEdit.isEdit) {
    //   setPreFields([...preFields.filter((o) => o.preId == fieldInEdit.preId)]);
    // }
    let preIdToSelect = props.fieldInEdit.name + '';
    const preFieldToSelect: PreviewerField = {
      field: props.fieldInEdit,
      isEdit: false,
      preId: preIdToSelect
    };

    if (props.fieldInEdit.multiple == true) {
      const count = preFields.filter(
        (o) => o.field.name == props.fieldInEdit.name
      ).length;
      const newIndex = count == 0 ? 0 : count;
      preIdToSelect = preIdToSelect + '_' + newIndex;
      preFieldToSelect.preId = preIdToSelect;
      preFieldToSelect.index = newIndex;
      setPreFields((pv) => [...pv, preFieldToSelect]);
      setFieldInEdit(preFieldToSelect);
    } else {
      if (preIdToSelect != fieldInEdit?.preId) {
        const match = preFields.find((o) => o.preId == preIdToSelect);
        if (match) {
          setFieldInEdit({ ...match, isEdit: true });
        } else {
          setPreFields([...preFields, preFieldToSelect]);
          setFieldInEdit(preFieldToSelect);
        }
      }
    }
    if (preIdToSelect == fieldInEdit?.preId) {
      clearEditInField();
    }
    showPopperEditor(preIdToSelect);
  }, [props.fieldInEdit]);

  const showPopperEditor = (preId: string) => {
    setTimeout(() => {
      const nodes = document.querySelectorAll('.field-wrapper.' + preId);
      if (nodes.length) {
        setAnchorEl(nodes[0] as HTMLElement);
        if (!mobile) {
          const [x, y] = getScroll();
          if (y > 300) {
            setTimeout(() => {
              const popper = document.getElementById('field-popper');
              popper?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 100);
          }
        }
      }
    }, 100);
  };

  const renderFieldBlock = (e: PreviewerField) => {
    const inputProp: FieldRenderBlockProp = {
      field: e.field,
      isReadOnly: false,
      data: modifiedProfileData || {},
      valueIndex: getValueIndex(e),
      theme: profileTheme
    };
    return FieldsRegistry[e.field.name]?.renderBlock(inputProp);
  };

  const isActive = (e: PreviewerField) => {
    if (!e) return false;
    return fieldInEdit?.preId === e.preId;
  };

  const changeTheme = (e: string) => {
    setProfileTheme(e);
    props.onThemeChange(e);
  };

  const clearEditInField = () => {
    setAnchorEl(null);
    setFieldInEdit(null);
  };
  const onEditClick = (
    e: React.MouseEvent<HTMLElement>,
    preField: PreviewerField
  ) => {
    if (fieldInEdit?.preId != preField.preId) {
      setAnchorEl(null);
      setFieldInEdit(null);
    }

    setTimeout(() => {
      if (fieldInEdit?.preId != preField.preId) {
        setFieldInEdit({ ...preField, isEdit: true });
      }
      setAnchorEl(
        (e.target as any).closest(
          '.field-wrapper.' + preField.preId
        ) as HTMLElement
      );
    });
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const preFieldsClone = [...preFields];

    const [removed] = preFieldsClone.splice(source.index, 1);
    preFieldsClone.splice(destination.index, 0, removed);

    // const resultList = reorderPrevierFields(
    //   preFields,
    //   source.index,
    //   destination.index
    // );
    // setListItemFields(resultList);

    // preFieldsClone.forEach((p) => {
    //   const match = resultList.find((o) => o.preId == p.preId);
    //   if (match) {
    //     p.order = match.order;
    //   }
    // });
    // setListItemFields(listItemFields);
    setPreFields(preFieldsClone);
    // props.onFieldsModified(preFieldsClone);
  };

  const onPopperDelete = () => {
    if (fieldInEdit) {
      const data = { ...modifiedProfileData };
      FieldsRegistry[fieldInEdit.field.name].fieldUtil.clearValue(
        data,
        fieldInEdit.index
      );
      const filtered = preFields.filter((o) => o.preId != fieldInEdit.preId);
      setModifiedProfileData(data);
      setPreFields([...filtered]);
      props.onDataModfied(
        filtered.map((o) => o.field),
        data,
        profileTheme
      );
      onPopperClose();
    }
  };

  const onPopperClose = () => {
    if (!fieldInEdit.isEdit) {
      const fitered = preFields.filter((o) => o.preId != fieldInEdit.preId);
      setPreFields([...fitered]);
    }

    setAnchorEl(null);
    setFieldInEdit(null);

    props.onFieldInEditChange(null);
  };

  const onProfileDataModified = (e: ProfileData) => {
    setModifiedProfileData(e);
    clearEditInField();
    props.onFieldInEditChange(null);
    props.onDataModfied(
      preFields.map((o) => o.field),
      e,
      profileTheme
    );
  };

  const listItemPreFields = useMemo(() => {
    const list = preFields.filter(
      (o) =>
        FieldsRegistry[o.field.name].fieldUtil.fieldType() ==
        FieldBlockTypes.ListItem
    );
    return list;
  }, [preFields]);

  const coverPhotoField = preFields.find(
    (o) => o.field.name == ProfileFieldNames.CoverPhoto
  );
  const profilePicField = preFields.find(
    (o) => o.field.name == ProfileFieldNames.ProfilePic
  );
  const logoField = preFields.find(
    (o) => o.field.name == ProfileFieldNames.Logo
  );
  const fieldWrapClass = ' field-wrapper ' + fieldInEdit?.preId + ' ';

  const renderTopMargin = () => {
    {
      /* top margin if coverphoto is not rendered */
    }
    if (profilePicField || logoField) {
      if (
        !preFields.find((o) => o.field.name === ProfileFieldNames.CoverPhoto)
      ) {
        return <div style={{ height: 80, width: '100%' }}></div>;
      }
    }
    return <></>;
  };

  return (
    <>
      {' '}
      <Stack spacing={1}>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <Tooltip title="Change Theme" placement="top">
            <ButtonGroup size="medium">
              <Button
                onClick={() => changeTheme('Light')}
                sx={{ background: '#EAEAEA' }}
              ></Button>
              <Button
                onClick={() => changeTheme('Dark')}
                sx={{ background: theme.colors.gradients.black2 }}
              >
                {' '}
              </Button>
              <span style={{ marginLeft: '5px' }}>{profileTheme}</span>
            </ButtonGroup>
          </Tooltip>
        </Box>
        <Card
          variant="outlined"
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: `${getThemeColor(theme, 'blue')}`,
            p: 3,
            textAlign: 'center'
          }}
        >
          {coverPhotoField && (
            <>
              <Box
                className={
                  fieldWrapClass + (isActive(coverPhotoField) ? ' ' : ' ')
                }
                sx={{ cursor: 'pointer', width: '100%' }}
                onClick={(e) => onEditClick(e, coverPhotoField)}
              >
                <CoverPhotoBlock
                  data={modifiedProfileData || {}}
                  field={coverPhotoField.field}
                />
              </Box>
            </>
          )}
          {profilePicField && (
            <>
              {renderTopMargin()}
              <Box
                className={fieldWrapClass}
                sx={{ cursor: 'pointer' }}
                onClick={(e) => onEditClick(e, profilePicField)}
              >
                <ProfilePicBlockRenderer
                  data={modifiedProfileData || {}}
                  field={profilePicField.field}
                />
              </Box>
            </>
          )}

          {logoField && (
            <>
              {renderTopMargin()}
              <Box
                className={fieldWrapClass}
                sx={{ cursor: 'pointer' }}
                onClick={(e) => onEditClick(e, logoField)}
              >
                <LogoBlockRenderer
                  data={modifiedProfileData || {}}
                  field={logoField.field}
                />
              </Box>
            </>
          )}
          <FixedFieldsBlock
            data={modifiedProfileData}
            preFields={preFields}
            profileTheme={profileTheme}
            fieldInEdit={fieldInEdit}
            onEditClick={onEditClick}
          />
          <BtnGroupFieldsBlock
            data={modifiedProfileData}
            preFields={preFields}
            profileTheme={profileTheme}
            fieldInEdit={fieldInEdit}
            onEditClick={onEditClick}
          />
          <List sx={{ width: '100%', marginTop: 2 }} disablePadding>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {listItemPreFields.map((prefield, index) => (
                      <Draggable
                        key={prefield.preId}
                        draggableId={prefield.preId}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <FieldWrapper
                              key={prefield.preId}
                              className={
                                'field-wrapper ' +
                                prefield.preId +
                                (isActive(prefield) ? ' active-field' : '')
                              }
                            >
                              <ListItemWrapper
                                sx={{
                                  minWidth: 0
                                }}
                              >
                                <Box className="MuiActionButtons">
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title={t(
                                      'Edit ' + prefield.field.displayName
                                    )}
                                  >
                                    <IconButton
                                      id={'edit_button_' + prefield.field.name}
                                      color="primary"
                                      onClick={(e) => onEditClick(e, prefield)}
                                    >
                                      <EditTwoToneIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                                {renderFieldBlock(prefield)}
                              </ListItemWrapper>

                              <Divider />
                            </FieldWrapper>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </List>
          {anchorEl && fieldInEdit && isPopperOpen && (
            <FieldEditorPopper
              open={isPopperOpen}
              fieldInEdit={fieldInEdit}
              profileTheme={profileTheme}
              anchorEl={anchorEl}
              data={modifiedProfileData}
              onCancel={onPopperClose}
              onDelete={onPopperDelete}
              onSave={onProfileDataModified}
            />
          )}
        </Card>
        <div style={{ height: 20 }}> </div>
      </Stack>
    </>
  );
}
