const extractDialCode=(contactNumber?:string)=>{
    if(!contactNumber)
    return '';

    let code = '';
    if(contactNumber.trim().startsWith('+')){
      const index=contactNumber.trim().indexOf(' ');
      if(index!=-1){
        code = contactNumber.trim().substring(1,index);
      }
    }
    return code;
  }

  export default extractDialCode;