import { TitledFieldValue } from "src/services/apiService/response-models";

export default function fieldValueAt(arr: TitledFieldValue<string>[], i?: number) {
  if (!arr || !arr.length)
    return null;

  return i && i < arr.length ? arr[i].value : arr;
}

export function fieldValueAt2(arr: unknown[], i?: number) {
  if (!arr || !arr.length)
    return null;

  if (typeof (i) != 'number')
    return arr;

  return i < arr.length ? arr[i] : null;
}