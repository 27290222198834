import { useState } from 'react';
import Footer from 'src/ui-bloom/components/Footer';
import PageHeader from './PageHeader';

import { Grid } from '@mui/material';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';
import useRefMounted from 'src/ui-bloom/hooks/useRefMounted';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { QKEY, getTeamMembers } from 'src/services/query-client';
import { getUsers } from '../../../services/apiService';
import Results from './Results';
import CreateUserDialog from './create-user-dialog';

function UserList() {
  const { t }: { t: any } = useTranslation();
  const isMountedRef = useRefMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [isDialogMounted, setIsDialogMounted] = useState(false);

  const {
    data: { data: users = [] } = {},
    isError,
    error,
    isLoading,
    refetch
  } = useQuery(QKEY.Users, getUsers);

  const { data: { data: members = [] } = {} } = useQuery(
    QKEY.Members,
    getTeamMembers
  );

  const handleCreateUserSuccess = async () => {
    // setIsLoading(true);
    try {
      const res = await getUsers();
      //  setUsers(res.data);
    } catch (ex) {
      console.log(ex);
    }
    //  setIsLoading(false);
  };

  const handleCreateUserOpen = () => {
    setIsDialogMounted(true);
    setOpen(true);
  };

  const handleCreateUserClose = () => {
    setOpen(false);
    setTimeout(() => setIsDialogMounted(false));
  };

  return (
    <>
      <PageTitleWrapper>
        <PageHeader onCreate={handleCreateUserOpen} />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Results isLoading={isLoading} users={users} />
        </Grid>
      </Grid>
      {isDialogMounted && (
        <CreateUserDialog
          members={members}
          isOpen={open}
          onClose={handleCreateUserClose}
          onSave={handleCreateUserSuccess}
        />
      )}
      <Footer />
    </>
  );
}

export default UserList;
