import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import {
  CodeItem,
  ReviewQuestion
} from 'src/services/apiService/response-models';
import DeleteConfirm from '../delete-confirm';

const answerTypes: CodeItem[] = [
  {
    code: 'text',
    value: 'Text'
  },
  {
    code: 'singleChoice',
    value: 'Single Choice'
  },
  {
    code: 'multiChoice',
    value: 'Multiple Choice'
  },
  {
    code: 'selectionList',
    value: 'Selection List'
  }
];

export default function AddQuestionDialog(props: {
  isOpen: boolean;
  question?: ReviewQuestion;
  onClose: () => void;
  onSave: (e: ReviewQuestion) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(props.isOpen);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit] = useState(props.question ? true : false);
  const [questionText, setQuestionText] = useState(props.question?.text || '');
  const [ansLabel, setAnsLabel] = useState(props.question?.label || '');
  const [selectedAnsType, setSelectedAnsType] = useState(
    props.question?.type || 'text'
  );
  const [errorMsg, setErrorMsg] = useState('');
  const [answerOptions, setAnswerOptions] = useState<CodeItem[]>(
    props.question?.options || []
  );

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const handleCreateClose = (
    ev: any,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason != 'backdropClick') {
      setOpen(false);
      props.onClose();
    }
  };

  const onSave = () => {
    let isValid = true;
    setErrorMsg('');
    if (!questionText.length) {
      setErrorMsg('Question text is required.');
      isValid = false;
    } else if (selectedAnsType != 'text' && answerOptions.length == 0) {
      setErrorMsg('Add at-least on answer option.');
      isValid = false;
    }
    if (isValid) {
      setOpen(false);
      props.onSave({
        text: questionText,
        type: selectedAnsType,
        code: props.question?.code || '',
        label: ansLabel,
        options: answerOptions
      });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleCreateClose}
      disableEscapeKeyDown
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <DialogContent sx={{}}>
            <Grid
              sx={{
                px: 1
              }}
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={2}
            >
              <Grid display="flex" alignItems="center" item xs={12}>
                <ContentHeader isEdit={isEdit} handleClose={props.onClose} />
              </Grid>
              <Grid display="flex" alignItems="center" item xs={12}>
                <Divider />
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    name="questionText"
                    variant="outlined"
                    label={t('Question text')}
                    placeholder={t('Enter question here ...')}
                    onChange={(e) => setQuestionText(e.target.value)}
                    value={questionText}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="answerType">
                      {t('Anwser type')}
                    </InputLabel>
                    <Select
                      label={t('answerType')}
                      inputProps={{
                        name: 'answerType'
                      }}
                      value={selectedAnsType}
                      onChange={(e) => {
                        setSelectedAnsType(e.target.value);
                      }}
                    >
                      {answerTypes.map((o) => (
                        <MenuItem key={o.code} value={o.code}>
                          {t(o.value)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="anwerLabel"
                    variant="outlined"
                    label={t('Answer Label')}
                    placeholder={t('Enter label here ...')}
                    value={ansLabel}
                    onChange={(e) => setAnsLabel(e.target.value)}
                  />
                </Grid>
                {selectedAnsType && selectedAnsType != 'text' && (
                  <Grid item xs={12} md={12}>
                    <AnswerOptionList
                      options={props.question?.options}
                      onChange={(e) => {
                        setAnswerOptions(e);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid display={'flex'} justifyContent={'end'} item xs={12}>
                {/* <Button variant="outlined">Cancel</Button> */}
                <Button onClick={onSave} variant="contained">
                  Save
                </Button>
              </Grid>
              {errorMsg.length > 0 && (
                <Grid display={'flex'} justifyContent={'end'} item xs={12}>
                  <FormHelperText error={true}>{errorMsg}</FormHelperText>
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
function ContentHeader(props: { isEdit: boolean; handleClose: () => void }) {
  const { t }: { t: any } = useTranslation();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" component="h3" gutterBottom>
              {t(props.isEdit ? 'Edit' : 'Add' + ' Question')}
            </Typography>
            <Typography variant="subtitle2">
              {props.isEdit
                ? 'Edit review question detail'
                : 'Add a new review question'}
            </Typography>
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

const ListItemWrapper = styled(ListItem)(
  ({ theme }) => `
      position: relative;
      transition: ${theme.transitions.create(['background'])};

      .MuiActionButtons {
            background: ${alpha(theme.colors.alpha.white[100], 0.95)};
            border-radius: ${theme.general.borderRadius};
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
            top: 50%;
            margin-top: -${theme.spacing(3.5)};
            position: absolute;
            right: ${theme.spacing(1.5)};
            padding: ${theme.spacing(0.5, 1)};
            transition: ${theme.transitions.create(['visibility', 'opacity'])};

            .MuiIconButton-root {
                border-radius: 100px;
                width: ${theme.spacing(5)};
                height: ${theme.spacing(5)};
                margin: ${theme.spacing(0.5)};
            }
      }

      &:hover {
          background: ${alpha(theme.colors.secondary.main, 0.15)};

          .MuiActionButtons {
            visibility: visible;
            opacity: 1;
      }   
      }
  `
);

function AnswerOptionList(props: {
  options?: CodeItem[];
  onChange: (e: CodeItem[]) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [ansOptions, setAnsOptions] = useState<CodeItem[]>(props.options || []);
  const [newOption, setNewOption] = useState('');
  const [optionToDelete, setOptionToDelete] = useState('');

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const optionsClone = [...ansOptions];

    const [removed] = optionsClone.splice(source.index, 1);
    optionsClone.splice(destination.index, 0, removed);

    setAnsOptions(optionsClone);
    props.onChange(optionsClone);
  };

  return (
    <Card variant="outlined">
      <CardHeader
        sx={{
          p: 1,
          background: `${theme.colors.alpha.black[5]}`
        }}
        disableTypography
        title={
          <Typography
            variant="h4"
            sx={{
              fontSize: `${theme.typography.pxToRem(16)}`
            }}
          >
            {t('Answer Options')}{' '}
            {ansOptions.length ? `(${ansOptions.length})` : ''}
          </Typography>
        }
        action={
          <TextField
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AddTwoToneIcon />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              setNewOption(e.target.value);
            }}
            placeholder={t('Enter option to add')}
            fullWidth
            value={newOption}
            onKeyDown={(e) => {
              if (e.key == 'Enter' && newOption.trim().length) {
                const newOptions = [
                  ...ansOptions,
                  { code: ansOptions.length + 1 + '', value: newOption }
                ];
                setAnsOptions(newOptions);
                setNewOption('');
                props.onChange(newOptions);
              }
            }}
          />
        }
      />
      <Divider />
      <List disablePadding>
        {ansOptions.length == 0 && (
          <ListItemWrapper
            sx={{
              alignItems: 'flex-start',
              p: 3.15
            }}
          >
            <ListItemText
              primary={
                <Typography lineHeight={1} gutterBottom variant="body1">
                  {'No option. Please add at-least one option.'}
                </Typography>
              }
            />
          </ListItemWrapper>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {ansOptions.map((o, index) => (
                  <Draggable key={o.code} draggableId={o.code} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ListItemWrapper
                          sx={{
                            alignItems: 'flex-start',
                            p: 3.15
                          }}
                        >
                          <Box className="MuiActionButtons">
                            <Tooltip
                              arrow
                              placement="top"
                              title={t('Remove option')}
                            >
                              <IconButton
                                onClick={() => setOptionToDelete(o.code)}
                                color="error"
                              >
                                <CloseTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <ListItemText
                            primary={
                              <Typography
                                lineHeight={1}
                                gutterBottom
                                variant="h4"
                              >
                                {o.value}
                              </Typography>
                            }
                          />
                        </ListItemWrapper>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </List>
      <DeleteConfirm
        open={Boolean(optionToDelete.length)}
        onClose={() => setOptionToDelete('')}
        onDeleteConfirm={(o) => {
          const newOptions = ansOptions.filter(
            (o) => o.code !== optionToDelete
          );
          setAnsOptions(newOptions);
          setOptionToDelete('');
          props.onChange(newOptions);
        }}
      />
    </Card>
  );
}
