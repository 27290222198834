import { Typography, useTheme } from '@mui/material';
import { FieldRenderBlockProp } from '../..';

export default function JobTitleBlockRenderer(props: FieldRenderBlockProp) {
  const theme = useTheme();

  if (!props.data?.jobTitle) return <></>;

  return (
    <Typography
      sx={{
        color:
          props.theme === 'light'
            ? `${theme.colors.alpha.black[100]}`
            : `${theme.colors.alpha.trueWhite[100]}`
      }}
      gutterBottom
      variant="subtitle2"
    >
      {props.data?.jobTitle}
    </Typography>
  );
}
