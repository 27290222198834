import {
  Avatar,
  Box,
  CircularProgress,
  Fab,
  Tooltip,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { BusinessAppInstance } from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export function ConnectedApps(props: { appInstances: BusinessAppInstance[] }) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const goToApp = (e: BusinessAppInstance) => {
    navigate(
      '/app/business-apps/' +
        e.businessApp.name +
        '/' +
        e.id +
        '?ret=' +
        encodeURIComponent(location.pathname)
    );
  };

  return (
    <Box
      px={0}
      pb={1}
      sx={{
        '.swiper-pagination-bullets': {
          bottom: '0 !important'
        }
      }}
    >
      {isLoading && (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && !error && !props.appInstances.length && (
        <Box sx={{ display: 'flex' }}>
          <Typography variant="subtitle2" gutterBottom>
            No connnected apps
          </Typography>
        </Box>
      )}
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        navigation={{
          nextEl: '.MuiSwipe-right',
          prevEl: '.MuiSwipe-left'
        }}
        breakpoints={{
          500: {
            slidesPerView: 5,
            spaceBetween: 12
          },
          768: {
            slidesPerView: 7,
            spaceBetween: 12
          },
          1200: {
            slidesPerView: 10,
            spaceBetween: 24
          }
        }}
        modules={[Navigation]}
      >
        {props.appInstances.map((item, i) => (
          <React.Fragment key={item.id}>
            <SwiperSlide key={item.id}>
              <Tooltip title={item.businessApp.displayName}>
                <Fab
                  onClick={() => goToApp(item)}
                  color="primary"
                  aria-label="add"
                >
                  <Avatar
                    sx={{
                      height: 45,
                      width: 45
                    }}
                    src={cdnService.toCdnUrl(item.businessApp.logo, 'icons')}
                  />
                </Fab>
              </Tooltip>
              {item.businessApp.displayName}
            </SwiperSlide>
          </React.Fragment>
        ))}
        {!isLoading && (
          <React.Fragment>
            {/*<SwiperSlide >*/}
            {/*    <Fab aria-label="add">*/}
            {/*        <AddIcon />*/}
            {/*    </Fab>*/}
            {/*</SwiperSlide>*/}
          </React.Fragment>
        )}
      </Swiper>
    </Box>
  );
}
