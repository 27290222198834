import { useState, SyntheticEvent } from 'react';
import {
  Box,
  IconButton,
  Tooltip,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  styled,
  useTheme,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import { SettingTab } from './setting-tabs';

const RootWrapper = styled(Box)(
  ({ theme }) => `
        @media (min-width: ${theme.breakpoints.values.md}px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
      }
`
);

function TopBarContent(props: { selectedTab: SettingTab, onSave: () => void }) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <>
      <RootWrapper>
        <Box display="flex" alignItems="center">
          <Avatar
            variant="rounded"
            sx={{
              width: 48,
              height: 48
            }}
                  >
                      {props.selectedTab?.icon}
                  </Avatar>
          <Box ml={1}>
                      <Typography variant="h4">{props.selectedTab?.text}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', lg: 'flex' }
          }}
              >
                  {/*<Button onClick={props.onSave} variant="contained" startIcon={<SaveTwoToneIcon />}>*/}
                  {/*    {t('Save Settings')}*/}
                  {/*</Button>*/}
        </Box>
      </RootWrapper>
    </>
  );
}

export default TopBarContent;
