import { createContext } from 'react';
import { ProfileField, ProfileFieldCategory, ProfileTypes } from 'src/services/apiService/response-models';

export interface ProfileEditorContextType {
  profileType: ProfileTypes;
  fieldCategories: ProfileFieldCategory[];
  isLoading?: boolean;
  fieldInEdit: ProfileField | null;
  profileTheme: string;
  changeTheme: (e: string) => void;
  setFieldInEdit: (e: ProfileField) => void,
  // fieldInEdit: (task: Task, isEdit: boolean) => Promise<void>;

}

const initialState: ProfileEditorContextType = {
  fieldCategories: [],
  fieldInEdit: null,
  setFieldInEdit: () => { },
  changeTheme: () => { },
  profileTheme: '',
  profileType: ProfileTypes.Business,
  isLoading: false
};

const ProfileEditorContext = createContext<ProfileEditorContextType>(initialState);

export default ProfileEditorContext;
