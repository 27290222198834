import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  CardActions,
  Grid,
  IconButton,
  Slide,
  Tooltip
} from '@mui/material';
import { useState } from 'react';
import Label from '../../../ui-bloom/components/Label';

export default function FieldEditorFooter(props: {
  onCancel: () => void;
  onDelete: () => void;
  onOk?: () => void;
}) {
  const [isDeleteRequested, setIsDeleteRequested] = useState(false);

  if (isDeleteRequested) {
    return (
      <Slide
        direction="right"
        in={isDeleteRequested}
        mountOnEnter
        unmountOnExit
      >
        <CardActions>
          <Label>Are you sure?</Label>
          <Button
            sx={{ ml: 1 }}
            size="small"
            onClick={props.onDelete}
            variant="outlined"
            color="error"
          >
            Delete
          </Button>
          <Tooltip title="Cancel delete">
            <IconButton
              onClick={() => {
                setIsDeleteRequested(false);
              }}
              sx={{ ml: 1 }}
              aria-label="delete"
            >
              <CancelPresentationTwoToneIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Slide>
    );
  }

  return (
    <CardActions>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <IconButton
            color="error"
            aria-label="delete"
            type="button"
            onClick={() => {
              setIsDeleteRequested(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Button
            size="small"
            type="button"
            onClick={(e) => {
              props.onCancel();
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            // disabled={!isFormValid}
            sx={{ ml: 2 }}
            size="small"
            onClick={props.onOk}
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </CardActions>
  );
}
