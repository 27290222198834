import { Profile } from "src/services/apiService/response-models";
import { ProfileDto } from "src/services/dto";

export default function mapModelToDto(e: Profile): ProfileDto {
  return {
    name: e.name,
    memberId: e.teamMemberId,
    type: e.type,
    description: e.description,
    theme: e.theme,
    fields: e.fields,
    data: e.data,
    qrInfo: e.qrInfo || {}
  };
}