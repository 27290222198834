import { Box, Typography, useTheme } from '@mui/material';
import { FieldRenderBlockProp } from '../..';

export default function FullNameBlockRenderer(props: FieldRenderBlockProp) {
  const theme = useTheme();

  if (!props.data?.fullName) return <></>;

  return (
    <Box justifyContent="center" alignItems="center">
      <Typography
        sx={{
          color:
            props.theme === 'light'
              ? `${theme.colors.alpha.black[100]}`
              : `${theme.colors.alpha.trueWhite[100]}`
        }}
        gutterBottom
        variant="h2"
      >
        {props.data?.fullName}
      </Typography>
    </Box>
  );
}
