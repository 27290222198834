import {
  Card,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  useTheme
} from '@mui/material';
import { Field, FieldProps, Formik } from 'formik';
import { TextField as FmTextFiled } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import Input from 'react-phone-number-input/input';
import FieldEditorFooter from 'src/contents/profile-editor/FieldEditorFooter';
import { TitledFieldValue } from 'src/services/apiService/response-models';
import * as Yup from 'yup';
import { FieldRenderEditorProp } from '../..';
import getMultiFieldValue from '../../_utils/getMultiFieldValue';

export default function ContactNumberEditorRenderer(
  props: FieldRenderEditorProp
) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  // useEffect(){

  // }
  const value = getMultiFieldValue<TitledFieldValue<string>>(
    props,
    props.data.contactNumbers
  );

  return (
    <>
      <Card>
        <CardHeader title={t(props.field.displayName)} />
        <Divider />
        <Formik
          initialValues={{
            title: value?.title,
            contactNumber: value?.value
          }}
          validateOnBlur={false}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(250),
            contactNumber: Yup.string()
              .max(20, t('Number is too long'))
              .required(t('The name field is required'))
          })}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            const contactNumbers = [...(props.data.contactNumbers || [])];
            contactNumbers[props.valueIndex] = {
              title: _values.title,
              value: _values.contactNumber
            };
            props.onSave({ ...props.data, contactNumbers: contactNumbers });
          }}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid sx={{ padding: theme.spacing(2) }} container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name={'contactNumber'}
                    label="Contact number"
                    placeholder={t('Enter here...')}
                  >
                    {({
                      field,
                      form: {
                        isSubmitting,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      },
                      meta
                    }: FieldProps) => (
                      <div>
                        <Input
                          placeholder="Enter phone number"
                          value={field.value}
                          onChange={(e) => {
                            setFieldValue(field.name, e?.toString() || '');
                            setFieldTouched(field.name, true);
                          }}
                          className="contactNumber"
                        />
                        {meta.touched && meta.error && (
                          <FormHelperText error={true}>
                            {meta.error}
                          </FormHelperText>
                        )}
                      </div>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name={'title'}
                    component={FmTextFiled}
                    label="Title"
                    placeholder={t('Enter here...')}
                    // inputRef={(input) => {
                    //   input?.focus();
                    // }}
                  />
                </Grid>
              </Grid>
              <FieldEditorFooter
                onCancel={props.onCancel}
                onDelete={() => props.onDelete()}
              />
            </form>
          )}
        </Formik>
      </Card>
    </>
  );
}
