import CloseIcon from '@mui/icons-material/Close';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import Settings from '@mui/icons-material/Settings';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Slide,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  Zoom,
  styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  ReactElement,
  Ref,
  SyntheticEvent,
  forwardRef,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Label from 'src/ui-bloom/components/Label';
import { USER_ROLES } from 'src/utility/constants';
import CircularProgressWithLabel from '../../../components/CircularProgressWithLabel';
import { deleteUserById } from '../../../services/apiService';
import { User } from '../../../services/apiService/response-models';
import { parseToApiErrorMessage } from '../../../utility/parseToApiErrorMessage';
import BulkActions from './BulkActions';
import ResetPwdDialog from './reset-pwd-dialog';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const CardWrapper = styled(Card)(
  ({ theme }) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);

interface ResultsProps {
  isLoading: boolean;
  users: User[];
}

interface Filters {
  role: string;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const getUserRoleLabel = (user: User): JSX.Element => {
  return (
    <Label color={'primary'}>
      {(user.roles || [])
        .map(
          (role) => USER_ROLES.find((o) => o.roleName == role.roleName).label
        )
        .join(', ')}
    </Label>
  );
  // if (user.isAdmin) return <Label color={'warning'}>Administrator</Label>;
  // else if (user.roles.find((rl) => rl.roleName === 'manager'))
  //   return <Label color={'primary'}>Manager</Label>;

  // return <Label color={'info'}>Member</Label>;
};

const applyFilters = (
  users: User[],
  query: string,
  filters: Filters
): User[] => {
  return users.filter((user) => {
    let matches = true;

    if (query) {
      const properties = ['email', 'fullName', 'userName'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (user[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (
        filters.role &&
        !user.roles.map((o) => o.roleName.toString()).includes(filters.role)
      ) {
        matches = false;
      }

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && !user.roles.map((o) => o.roleName).includes(value)) {
        matches = false;
      }
    });

    return matches;
  });
};

const applyPagination = (
  users: User[],
  page: number,
  limit: number
): User[] => {
  return users.slice(page * limit, page * limit + limit);
};

const Results: FC<ResultsProps> = (props) => {
  const [users, setUsers] = useState<User[]>(props.users);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteProgress, setDeleteProgress] = useState(-1);
  const location = useLocation();
  const [actionAnchorEl, setActionAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [userForAction, setUserForAction] = useState<User | null>(null);

  useEffect(() => {
    setUsers(props.users);
  }, [props.users]);

  const tabs = [
    {
      value: 'all',
      label: t('All users')
    },
    {
      value: 'admin',
      label: t('Administrators')
    },
    {
      value: 'manager',
      label: t('Managers')
    },
    {
      value: 'member',
      label: t('Members')
    }
  ];

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  const [query, setQuery] = useState<string>('');

  const [filters, setFilters] = useState<Filters>({
    role: null
  });
  const handleTabsChange = (_event: SyntheticEvent, tabsValue: unknown) => {
    let value = null;

    if (tabsValue !== 'all') {
      value = tabsValue;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      role: value
    }));

    setSelectedUsers([]);
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSelectAllUsers = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedUsers(event.target.checked ? users.map((user) => user.id) : []);
  };

  const handleSelectOneUser = (
    _event: ChangeEvent<HTMLInputElement>,
    userId: string
  ): void => {
    if (!selectedUsers.includes(userId)) {
      setSelectedUsers((prevSelected) => [...prevSelected, userId]);
    } else {
      setSelectedUsers((prevSelected) =>
        prevSelected.filter((id) => id !== userId)
      );
    }
  };

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredUsers = applyFilters(users, query, filters);
  const paginatedUsers = applyPagination(filteredUsers, page, limit);
  const selectedBulkActions = selectedUsers.length > 0;
  const selectedSomeUsers =
    selectedUsers.length > 0 && selectedUsers.length < users.length;
  const selectedAllUsers = selectedUsers.length === users.length;

  const [toggleView, setToggleView] = useState<string | null>('table_view');
  const [idsToDelete, setIdsToDelete] = useState([]);

  const handleViewOrientation = (
    _event: MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    setToggleView(newValue);
  };

  const onBulkDelete = () => {
    setIdsToDelete([...selectedUsers]);
  };

  const handleDeleteClick = (id: number) => {
    setIdsToDelete([id]);
  };

  const closeConfirmDelete = () => {
    setIdsToDelete([]);
  };

  const handleDeleteCompleted = async () => {
    const ids = [...idsToDelete];
    const successIds: string[] = [];

    for (let index = 0; index < ids.length; index++) {
      const id = ids[index];
      try {
        const success = await deleteUserById(id);
        if (success) {
          successIds.push(id);
        }
      } catch (ex) {
        console.log(ex);
        const msg = parseToApiErrorMessage(ex, `Failed to delete user`);
        enqueueSnackbar(t(msg), { variant: 'error' });
      }
    }

    if (successIds.length) {
      const items = [...users].filter((o) => !successIds.includes(o.id));
      setUsers(items);

      enqueueSnackbar(t('The user account(s) removed'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    }

    setIdsToDelete([]);
    setSelectedUsers([]);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >
        <TabsWrapper
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="secondary"
          value={filters.role || 'all'}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </TabsWrapper>
        <ToggleButtonGroup
          sx={{
            mt: { xs: 2, sm: 0 }
          }}
          value={toggleView}
          exclusive
          onChange={handleViewOrientation}
        >
          <ToggleButton disableRipple value="table_view">
            <TableRowsTwoToneIcon />
          </ToggleButton>
          <ToggleButton disableRipple value="grid_view">
            <GridViewTwoToneIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {props.isLoading && <LinearProgress />}
      {toggleView === 'table_view' && (
        <Card>
          <Box p={2}>
            {!selectedBulkActions && (
              <TextField
                sx={{
                  m: 0
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={t('Search by name, email or username...')}
                value={query}
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
              />
            )}
            {selectedBulkActions && <BulkActions onDelete={onBulkDelete} />}
          </Box>

          <Divider />

          {paginatedUsers.length === 0 ? (
            <>
              <Typography
                sx={{
                  py: 10
                }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t("We couldn't find any users matching your search criteria")}
              </Typography>
            </>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedAllUsers}
                          indeterminate={selectedSomeUsers}
                          onChange={handleSelectAllUsers}
                        />
                      </TableCell>
                      <TableCell>{t('Username')}</TableCell>
                      <TableCell>{t('Name')}</TableCell>
                      <TableCell>{t('Email')}</TableCell>
                      <TableCell>{t('Role')}</TableCell>
                      <TableCell align="center">{t('Actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedUsers.map((user) => {
                      const isUserSelected = selectedUsers.includes(user.id);
                      return (
                        <TableRow hover key={user.id} selected={isUserSelected}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isUserSelected}
                              onChange={(event) =>
                                handleSelectOneUser(event, user.id)
                              }
                              value={isUserSelected}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography variant="h5">
                              {user.userName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <Avatar
                                sx={{
                                  mr: 1
                                }}
                              />
                              <Box>
                                <Link
                                  variant="h5"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  {user.displayName}
                                </Link>
                                <Typography noWrap variant="subtitle2">
                                  {user.roles.map((o) => o.roleName).join(',')}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography>{user.displayName}</Typography>
                          </TableCell>
                          <TableCell>{getUserRoleLabel(user)}</TableCell>
                          <TableCell align="center">
                            <Typography noWrap>
                              {/* <Tooltip title={t('Actions')} arrow>
                                <IconButton
                                  color="primary"
                                  onClick={(
                                    event: React.MouseEvent<HTMLElement>
                                  ) => setActionAnchorEl(event.currentTarget)}
                                >
                                  <MoreVertTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip> */}
                              <IconButton
                                onClick={(
                                  event: React.MouseEvent<HTMLElement>
                                ) => {
                                  setUserForAction(user);
                                  setActionAnchorEl(event.currentTarget);
                                }}
                                color="primary"
                              >
                                <MoreVertTwoToneIcon />
                              </IconButton>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={filteredUsers.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[50, 100, 150]}
                />
              </Box>
            </>
          )}
        </Card>
      )}
      {toggleView === 'grid_view' && (
        <>
          <Card
            sx={{
              p: 2,
              mb: 3
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              {paginatedUsers.length !== 0 && (
                <>
                  <Box display="flex" alignItems="center">
                    <Tooltip
                      arrow
                      placement="top"
                      title={t('Select all users')}
                    >
                      <Checkbox
                        checked={selectedAllUsers}
                        indeterminate={selectedSomeUsers}
                        onChange={handleSelectAllUsers}
                      />
                    </Tooltip>
                  </Box>
                  {selectedBulkActions && (
                    <Box flex={1} pl={2}>
                      <BulkActions onDelete={onBulkDelete} />
                    </Box>
                  )}
                </>
              )}
              {!selectedBulkActions && (
                <TextField
                  sx={{
                    my: 0,
                    ml: paginatedUsers.length !== 0 ? 2 : 0
                  }}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleQueryChange}
                  placeholder={t('Search by name, email or username...')}
                  value={query}
                  size="small"
                  margin="normal"
                  variant="outlined"
                />
              )}
            </Box>
          </Card>
          {paginatedUsers.length === 0 ? (
            <Typography
              sx={{
                py: 10
              }}
              variant="h3"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {t("We couldn't find any users matching your search criteria")}
            </Typography>
          ) : (
            <>
              <Grid container spacing={3}>
                {paginatedUsers.map((user) => {
                  const isUserSelected = selectedUsers.includes(user.id);

                  return (
                    <Grid item xs={12} sm={6} md={4} key={user.id}>
                      <CardWrapper
                        className={clsx({
                          'Mui-selected': isUserSelected
                        })}
                      >
                        <Box
                          sx={{
                            position: 'relative',
                            zIndex: '2'
                          }}
                        >
                          <Box
                            px={2}
                            pt={2}
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="space-between"
                          >
                            {getUserRoleLabel(user)}
                            <IconButton
                              color="primary"
                              sx={{
                                p: 0.5
                              }}
                              onClick={(
                                event: React.MouseEvent<HTMLElement>
                              ) => {
                                setUserForAction(user);
                                setActionAnchorEl(event.currentTarget);
                              }}
                            >
                              <MoreVertTwoToneIcon />
                            </IconButton>
                          </Box>
                          <Box p={2} display="flex" alignItems="flex-start">
                            <Avatar
                              sx={{
                                width: 50,
                                height: 50,
                                mr: 2
                              }}
                            />
                            <Box>
                              <Box>
                                <Link
                                  variant="h5"
                                  component={RouterLink}
                                  to={`/${
                                    location.pathname.split('/')[1]
                                  }/management/users/single/${user.id}`}
                                >
                                  {user.displayName}
                                </Link>{' '}
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  ({user.userName})
                                </Typography>
                              </Box>
                              <Typography
                                sx={{
                                  pt: 0.3
                                }}
                                variant="subtitle2"
                              >
                                {user.displayName}
                              </Typography>
                              <Typography
                                sx={{
                                  pt: 1
                                }}
                                variant="h6"
                              >
                                {user.roles?.map((o) => o.roleName).join(',')}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box
                            pl={2}
                            py={1}
                            pr={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Checkbox
                              checked={isUserSelected}
                              onChange={(event) =>
                                handleSelectOneUser(event, user.id)
                              }
                              value={isUserSelected}
                            />
                          </Box>
                        </Box>
                      </CardWrapper>
                    </Grid>
                  );
                })}
              </Grid>
              <Card
                sx={{
                  p: 2,
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box>
                  <Typography component="span" variant="subtitle1">
                    {t('Showing')}
                  </Typography>{' '}
                  <b>{limit}</b> {t('of')} <b>{filteredUsers.length}</b>{' '}
                  <b>{t('users')}</b>
                </Box>
                <TablePagination
                  component="div"
                  count={filteredUsers.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  labelRowsPerPage=""
                  rowsPerPageOptions={[50, 100, 150]}
                />
              </Card>
            </>
          )}
        </>
      )}
      {!toggleView && (
        <Card
          sx={{
            textAlign: 'center',
            p: 3
          }}
        >
          <Typography
            align="center"
            variant="h4"
            fontWeight="normal"
            color="text.secondary"
            sx={{
              my: 5
            }}
            gutterBottom
          >
            {t(
              'Choose between table or grid views for displaying the users list.'
            )}
          </Typography>
        </Card>
      )}

      <DialogWrapper
        open={idsToDelete.length > 0}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        {deleteProgress != -1 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            p={5}
          >
            <AvatarError>
              <CircularProgressWithLabel value={deleteProgress} />
            </AvatarError>

            <Typography
              align="center"
              sx={{
                py: 4,
                px: 6
              }}
              variant="h3"
            >
              {t('Deleting users...')}?
            </Typography>

            <Box>
              <Button
                variant="text"
                size="large"
                sx={{
                  mx: 1
                }}
                disabled
              >
                {t('Cancel')}
              </Button>
              <ButtonError
                size="large"
                disabled
                sx={{
                  mx: 1,
                  px: 3
                }}
                variant="contained"
              >
                {t('Delete')}
              </ButtonError>
            </Box>
          </Box>
        )}
        {deleteProgress == -1 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            p={5}
          >
            <AvatarError>
              <CloseIcon />
            </AvatarError>

            <Typography
              align="center"
              sx={{
                py: 4,
                px: 6
              }}
              variant="h3"
            >
              {t(
                'Are you sure you want to permanently delete this user account'
              )}
              ?
            </Typography>

            <Box>
              <Button
                variant="text"
                size="large"
                sx={{
                  mx: 1
                }}
                onClick={closeConfirmDelete}
              >
                {t('Cancel')}
              </Button>
              <ButtonError
                onClick={handleDeleteCompleted}
                size="large"
                sx={{
                  mx: 1,
                  px: 3
                }}
                variant="contained"
              >
                {t('Delete')}
              </ButtonError>
            </Box>
          </Box>
        )}
      </DialogWrapper>
      <UserActionMenu
        user={userForAction}
        anchorEl={actionAnchorEl}
        onClose={() => {
          setUserForAction(null);
          setActionAnchorEl(null);
        }}
      />
    </>
  );
};

Results.propTypes = {
  users: PropTypes.array.isRequired
};

Results.defaultProps = {
  users: []
};

function UserActionMenu(props: {
  user: User | null;
  anchorEl: null | HTMLElement;
  onClose: () => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
    props.anchorEl
  );
  const [resetPwdOpen, setResetPwdOpen] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setAnchorEl(props.anchorEl);
  }, [props.anchorEl]);

  const handleClose = () => {
    setAnchorEl(null);
    props.onClose();
  };

  return (
    <>
      <Menu
        anchorEl={props.anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/*<MenuItem>*/}
        {/*    <Avatar /> Profile*/}
        {/*</MenuItem>*/}
        {/*<MenuItem>*/}
        {/*    <Avatar /> My account*/}
        {/*</MenuItem>*/}
        {/*<Divider />*/}
        {/*<MenuItem>*/}
        {/*    <ListItemIcon>*/}
        {/*        <PersonAdd fontSize="small" />*/}
        {/*    </ListItemIcon>*/}
        {/*    Add another account*/}
        {/*</MenuItem>*/}
        <MenuItem onClick={() => setResetPwdOpen(true)}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Reset Password
        </MenuItem>
        {/*<MenuItem>*/}
        {/*    <ListItemIcon>*/}
        {/*        <Logout fontSize="small" />*/}
        {/*    </ListItemIcon>*/}
        {/*    Logout*/}
        {/*</MenuItem>*/}
      </Menu>
      {props.user && (
        <ResetPwdDialog
          isOpen={resetPwdOpen}
          user={props.user}
          onClose={() => setResetPwdOpen(false)}
        />
      )}
    </>
  );
}
export default Results;
