import { Dictionary } from 'lodash';

export enum AllConstant {
  DEFAULT_TEAM_NAME = 'Default team'
}
export interface MetadataItem {
  displayName?: string;
  value: string | number | boolean;
}
export type Metadata = Dictionary<MetadataItem>;

export interface ClientAccount {
  id: string;
  name: string;
  email: string;
  contactNumber: string;
  accountType: AccountTypes;
  description?: string;
  status: string;
  managedBy: string;
  metadata?: Metadata;
  subscription?: Subscription;
  accountSetting?: AccountSetting;
  isActive: boolean;
  createdBy: string;
  createdOn: Date;
  updatedBy?: string;
  updatedOn?: Date;
}
export interface Subscription {
  name: string;
  description?: string;
  isActive: boolean;
  statusDesc?: string;
}

export interface AccountSetting {
  reviewReceiveMailToOwner?: boolean;
}
export enum AccountTypes {
  Business = 'Business',
  Individual = 'Individual'
}
export enum UserRoles {
  SAdmin = 'sadmin',
  Admin = 'admin',
  Manager = 'manager',
  Member = 'member'
}
export interface UserContext {
  userName: string;
  appName: string;
  clientAccountId: string;
  user: User;
  isApiClient: boolean;
  isManager: boolean;
  isAdmin: boolean;
  isSadmin: boolean;
}

export interface User {
  id: string;
  userName: string;
  displayName?: string;
  teamMemberId?: string;
  isActive: boolean;
  clientAcccountId: string;
  roles: { roleName: string }[];
}
export interface UserAccountEntity {
  context: UserContext;
  user: User;
  clientAccount: ClientAccount;
}
export interface LogInResult {
  token: string;
}

export enum ProfileTypes {
  Business = 'Business',
  Individual = 'Individual'
}
export enum CardTypes {
  Gold = 'Gold',
  Silver = 'Silver'
}
export enum BusinessAppNames {
  ReviewApp = 'review-app',
  LeadApp = 'lead-app',
  ExternalApp = 'external-app',
  ScanToCallApp = 'scan-to-call-app',
  KiFormApp = 'ki-form-app'
}
export interface ProfileFieldCategory {
  name: string;
  displayName: string;
  profileType: ProfileTypes;
  order?: number;
  fields: ProfileField[];
}

export enum ProfileFieldNames {
  FullName = 'FullName',
  JobTitle = 'JobTitle',
  Department = 'Department',
  CompanyName = 'CompanyName',
  Headline = 'Headline',
  Email = 'Email',
  ContactNumber = 'ContactNumber',
  Website = 'Website',
  Link = 'Link',
  Address = 'Address',
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  Facebook = 'Facebook',
  YouTube = 'YouTube',
  Snapchat = 'Snapchat',
  Twitch = 'Twitch',
  WhatsApp = 'WhatsApp',
  Signal = 'Signal',
  Discord = 'Discord',
  Skype = 'Skype',
  Telegram = 'Telegram',
  GitHub = 'GitHub',
  Calendly = 'Calendly',
  PayPal = 'PayPal',
  CashApp = 'CashApp',
  BusinessName = 'BusinessName',
  ProfilePic = 'ProfilePic',
  Logo = 'Logo',
  CoverPhoto = 'CoverPhoto',
  MediaPlayer = 'MediaPlayer',
  ImageSlider = 'ImageSlider',
  GoogleReview = 'GoogleReview',
  Text = 'Text'
}

export interface ProfileField {
  name: ProfileFieldNames;
  categoryName: string;
  categoryDisplayName: string;
  profileType: ProfileTypes;
  displayName: string;
  description?: string;
  icon?: string;
  order?: number;
  multiple?: boolean;
}

export interface Profile {
  id: string;
  categoryName?: string;
  name?: string;
  isActive?: boolean;
  description?: string;
  type: ProfileTypes;
  fields?: ProfileField[];
  cards?: Card[];
  qrInfo?: QrInfo;
  archievedQrs?: string[];
  theme?: string;
  isOnline?: boolean;
  cardAppItems?: CardAppItem[];
  isMappedToCard?: boolean;
  data?: ProfileData;
  teamMemberId?: string;
  reviewCount?: number;
  leadCount?: number;
}

export type ProfileData = {
  companyName?: string;
  businessName?: string;
  headline?: string;
  coverImage?: string;
  emails?: TitledFieldValue<string>[];
  contactNumbers?: TitledFieldValue<string>[];
  addresses?: TitledFieldValue<AddressFieldValue>[];
  links?: TitledFieldValue<string>[];
  websites?: TitledFieldValue<string>[];
  twitter?: TitledFieldValue<string>;
  facebook?: TitledFieldValue<string>;
  linkedIn?: TitledFieldValue<string>;
  instagram?: TitledFieldValue<string>;
  youtube?: TitledFieldValue<string>;
  snapChat?: TitledFieldValue<string>;
  twitch?: TitledFieldValue<string>;
  whatsApp?: TitledFieldValue<string>;
  signal?: TitledFieldValue<string>;
  discord?: TitledFieldValue<string>;
  skype?: TitledFieldValue<string>;
  telegram?: TitledFieldValue<string>;
  calendly?: TitledFieldValue<string>;
  googleReview?: TitledFieldValue<string>;
  imageList?: ImageFieldValue[];
  mediaList?: TitledFieldValue<string>[];
} & BusinessProfileData &
  IndividualProfileData;

export type TitledFieldValue<T> = {
  title?: string;
  subTitle?: string;
  icon?: string;
  value: T;
};

export type AddressFieldValue = {
  addressLine?: string;
  addressLine2?: string;
  city?: string;
  cityCode?: string;
  state?: string;
  stateCode?: string;
  country?: string;
  countryCode?: string;
  zip?: string;
};

export type ImageFieldValue = {
  title?: string;
  subTitle?: string;
  icon?: string;
  orientation?: string;
  imageList: {
    url: string;
    title?: string;
    subTitle?: string;
    audio?: string;
    orientation?: string;
  }[];
};

export interface Card {
  id: string;
  cardType: CardTypes;
  nfcCode: '';
}

export interface QrInfo {
  code?: string;
  text?: string;
  color?: string;
  logo?: string;
}

export type BusinessProfileData = {
  logoImage?: string;
};

export type IndividualProfileData = {
  fullName?: string;
  department?: string;
  jobTitle?: string;
  profileImage?: string;
};

export interface Contact {
  id?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  title?: string;
  fullName?: string;
  email?: string;
  contactType: CodeItem;
  altEmail?: string;
  contactNumber?: string;
  altContactNumber?: string;
  pronouns?: string;
  description?: string;
  descriptionRich?: string;
  imageUrl?: string;
  website?: string;
  dateOfBirth?: Date;
  groupTags?: string[];
  addresses?: Address[];
  linkedIn?: string;
  facebook?: string;
  twitter?: string;
  whatsApp?: string;
  externalUrl?: string;
  metadata?: Metadata;
  liCyStage?: string;
  archievedLiCyStages?: CodeItem[];
  source?: CodeItem;
  isDeleted?: boolean;
  createdBy: string;
  createdOn: Date;
  updatedBy?: string;
  updatedOn?: Date;
}

export interface CodeItem {
  code?: string;
  value?: string;
}

export interface RefData {
  jobTitles?: CodeItem[];
  departments?: CodeItem[];
  nameTitles?: CodeItem[];
  socialMediaProviders?: CodeItem[];
  contactGroups?: CodeItem[];
  contactTypes?: CodeItem[];
  leadLiCyStages?: CodeItem[];
  leadStatus?: CodeItem[];
  selfReviewStatus?: CodeItem[];
  accountDelReasons?: CodeItem[];
  reviewLiCyStages?: CodeItem[];
  equipmentCategories?: CategoryItem[];
  contactStatus?: CodeItem[];
}

export interface Department {
  id: string;
  name: string;
  description?: string;
  imageUrl?: string;
}
export interface TeamMember {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  fullName?: string;
  email: string;
  altEmail?: string;
  contactNumber?: string;
  altContactNumber?: string;
  pronouns?: string;
  imageUrl?: string;
  teams?: Team[];
  departments?: string[];
  jobTitle?: CodeItem;
  dateOfBirth?: Date;
  dateOfJoining?: Date;
  userRoles?: string[];
  createAuser?: boolean;
  linkedIn?: string;
  facebook?: string;
  twitter?: string;
  whatsApp?: string;
  address?: Address;
  metadata?: Metadata;
  createdBy: string;
  createdOn: Date;
  updatedBy?: string;
  updatedOn?: Date;
}

export interface Lead {
  id?: string;
  contact?: Contact;
  profile?: Profile;
  teamMember?: TeamMember;
  contactFirstName?: string;
  contactMiddleName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactNumber?: string;
  contactAddress?: string;
  teamMemberId?: string;
  tags?: string[];
  remark?: string;
  formData?: Metadata;
  metadata?: Metadata;
  liCyStage?: string;
  archievedLiCyStages?: string[];
  createdBy?: string;
  createdOn?: Date;
  updatedBy?: string;
  updatedOn?: Date;
}

export interface Review {
  id?: string;
  contact?: Contact;
  profile?: Profile;
  teamMember?: TeamMember;
  contactFirstName?: string;
  contactMiddleName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactNumber?: string;
  contactAddress?: string;
  teamMemberId?: string;
  tags?: string[];
  remark?: string;
  rating?: number;
  questions?: {
    question: string;
    answer: string;
  }[];
  formData?: Metadata;
  metadata?: Metadata;
  liCyStage?: string;
  archievedLiCyStages?: string[];
  createdBy?: string;
  createdOn?: Date;
  updatedBy?: string;
  updatedOn?: Date;
}

export interface BusinessApp {
  id: string;
  name: BusinessAppNames;
  displayName: string;
  viewerPath?: string;
  description?: string;
  image?: string;
  logo?: string;
  isActive: boolean;
  accountType: AccountTypes | 'all';
  clientAccountIds: string[];
  excludeClientAccountId: string[];
}
export interface BusinessAppInstance {
  id: string;
  name: BusinessAppNames;
  businessApp: BusinessApp;
  label?: string;
  description?: string;
  memeberIds: string[];
  profileId?: string;
  qrInfo?: QrInfo;
  createdOn?: Date;
  createdBy?: string;
  updatedOn?: Date;
  updatedBy?: string;
  reviewApp?: ReviewAppModel;
  leadApp?: LeadAppModel;
  kiFormApp?: KiFormAppModel;
}

export interface ReviewAppModel {
  template: string;
  coverImage?: string;
  logoImage?: string;
  title?: string;
  subTitle?: string;
  email?: string;
  contactNumber?: string;
  websiteLink?: string;
  reviewProviders?: string[];
  reviewQuestions: ReviewQuestion[];
}

export interface ReviewQuestion {
  code: string;
  type: string;
  text: string;
  label?: string;
  options: CodeItem[];
}

export interface LeadAppModel {
  template: string;
  coverImage?: string;
  logoImage?: string;
  title?: string;
  subTitle?: string;
}
export interface KiFormAppModel {
  theme?: string;
  template?: string;
  coverImage?: string;
}

export default interface PaginatedDataResult<T> {
  sortBy?: string;
  filterBy?: string;
  currentPage: number;
  pageSize: number;
  totalPage?: number;
  totalCount: number;
  data: T[];
}
//------------------------------------------------------------------------------------------------------------------------------

export enum EmailTypes {
  Personal = 'Personal',
  Work = 'Work',
  Alternate = 'Alternate'
}
export enum AddressTypes {
  None = 'None',
  Primary = 'Primary',
  Billing = 'Billing',
  Shipping = 'Shipping',
  Home = 'Home',
  Office = 'Office'
}

export interface Address {
  addressType: AddressTypes;
  addressLine?: string;
  addressLine2?: string;
  city?: string;
  cityCode?: string;
  state?: string;
  stateCode?: string;
  country?: string;
  countryCode?: string;
  zip?: string;
  geoLat?: string;
  geoLong?: string;
  isActive?: boolean;
  clientAccountId?: number;
}

//card instance
export interface DigitalCard {
  id: number;
  label: string;
  name?: string;
  isActiveCard?: boolean;
  description: string;
  cardType: CardTypes;
  parentId: number;
  fields: CardFieldValue[];
  qrCode?: string;
  theme?: string;
  nfc?: NfcInfo;
  isOnline: boolean;
  isActive: boolean;
  cardAppItems: CardAppItem[];
  nfcCodeMaps?: NfcCodeMap[];
}

export interface NfcInfo {
  code: string;
}

export interface CardFieldValue {
  id: string;
  fieldId: number;
  order: number;
  fieldName: string;
  formFieldValues: FormFieldValue[];
}

export interface FormFieldValue {
  name: string;
  value: string;
}

export interface KeyValueItem {
  key: string;
  value: string;
}
export interface DigitalCardField {
  id: number;
  cardType: CardTypes;
  categoryId: number;
  categoryName: string;
  name: string;
  displayName: string;
  description?: string;
  icon?: string;
  order?: number;
  formFields?: CardFormField[];
  multiple?: boolean;
  valueFieldName?: string;
  valueDescriptor?: string;
  titleDescriptor?: string;
}

export interface CardFormField {
  id: number;
  digitalCardFieldId: number;
  name: string;
  displayName: string;
  helpText?: string;
  icon?: string;
  isRequired?: boolean;
  props: string | any;
  options: string | any;
  validationKey?: string;
  defaultValue?: string;
  order?: number;
}

export interface CardFieldCategory {
  id: number;
  name: string;
  displayName: string;
  description: string;
  icon: string;
  order: number;
  isActive: boolean;
  cardType: CardTypes;
  digitalCardFields: DigitalCardField[];
}

export interface Team {
  id: string;
  name: string;
  description?: string;
  imageUrl?: string;
  isActive: boolean;
  createdBy: number;
  createdOn: Date;
  updatedBy?: number;
  updatedOn?: Date;
}

export interface NameItem {
  id?: string;
  name?: string;
}

export interface DigitalCardListItem {
  digitalCardId: number;
  label: string;
  description: string;
  cardType: CardTypes;
  parentId: number;
  parentName?: string;
  theme?: string;
  isActiveCard: boolean;
  isActive: boolean;
  fields?: CardFieldValue[];
}

export interface DigitalCardAppItem extends DigitalCardListItem {
  cardAppItems: CardAppItem[];
}

export interface CardApp {
  id: number;
  name: string;
  description: string;
  displayName: string;
  icon: string;
  url: string;
  qrCode: string;
  isActive: boolean;
  isExternal: boolean;
}

export interface CardAppItem {
  id: number;
  digitalCardId: number;
  cardName: string;
  cardAppId: number;
  appConfig: Dictionary<string>;
  cardApp?: CardApp;
  qrUrl?: string;
}

export interface ContactUploadItem {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  description: string;
  pronouns: string;
  website: string;
  department: string;
  jobTitle: string;
  dateOfBirth: '2022-12-29T17:05:13.626Z';
  addressLine: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  facebook: string;
  linkedIn: string;
  tweeter: string;
  instagram: string;
  youTube: string;
  snapchat: string;
  whatsApp: string;
  skype: string;
  telegram: string;
}

export enum CardAppNames {
  BusinessApp = 'BusinessCard',
  LeadApp = 'LeadApp',
  ReviewApp = 'ReviewApp',
  PhoneQRApp = 'PhoneQRApp'
}

export interface AccountSignUp {
  isIndividual: boolean;
  accountType: AccountTypes;
  businessName: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  cardType?: string;
  password: string;
  contactNumber: string;
  terms: boolean;
  nfcCode?: string;
  additionalInfo?: {
    title?: string;
    description: string;
    logo: string;
    dateStarted?: '2023-01-05T12:51:06.749Z';
    businessCategory?: string;
    pronouns?: string;
    fullName?: string;
    imageUrl: string;
    website: string;
    department?: string;
    jobTitle?: string;
    address?: {
      id: 0;
      name: string;
      isPrimary: true;
      addressType: string;
      addressLine: string;
      addressLine2: string;
      city: string;
      cityCode: string;
      state: string;
      stateCode: string;
      country: string;
      countryCode: string;
      zipCode: string;
      geoLoc: {
        id: 0;
        lat: string;
        lng: string;
      };
      isActive: true;
    };
    companyName?: string;
    companyUrl?: string;
  };
}

export interface NfcCodeMap {
  id: string;
  nfcCode: string;
  profile?: Profile;
  cardAppId?: string;
  isActive: boolean;
  remark?: string;
  groupName?: string;
  mapType?: string;
  mapTo?: string;
}

export interface LeadComment {
  id: string;
  leadId: string;
  comment: string;
  attachement: string;
  createdOn: Date;
  createdBy: string;
}

export interface ReviewComment {
  id: string;
  selfReviewId: string;
  comment: string;
  attachement: string;
  createdOn: Date;
  createdBy: string;
}

export type LeadStatusType =
  | 'Open'
  | 'Completed'
  | 'InProgress'
  | 'Lost'
  | 'Won';
export type ReviewStatusType = 'Open' | 'InProgress' | 'Close';

export interface NotificationSetting {
  leadEmailSetting?: LeadEmailSetting;
  reviewEmailSetting?: ReviewEmailSetting;
}

export interface LeadEmailSetting {
  leadCreateMailToOwner?: boolean;
  leadCreateMailToMemberIds?: number[];
  leadStatusChangeMailToOwner?: boolean;
  leadStatusChangeMailToMemberIds?: number[];
  leadNoStatusChangeReminderInDay?: number;
  leadNoStatusChangeMailToOwner?: boolean;
  leadNoStatusChangeMailToMemberIds?: number[];
}

export interface ReviewEmailSetting {
  reviewReceiveMailToOwner?: boolean;
  reviewReceiveMailToMemberIds?: number[];
  reviewStatusChangeMailToOwner?: boolean;
  reviewStatusChangeMailToMemberIds?: number[];
  reviewNoStatusChangeReminderInDay?: number;
  reviewNoStatusChangeMailToOwner?: boolean;
  reviewNoStatusChangeMailToMemberIds?: number[];
}

export interface PaymentIntentResult {
  clientSecret: string;
  productId: string;
  productName: string;
  productDesc: string;
  productPrice: number;
}

export interface AccountSignUpStatus {
  status: string;
  statusDesc: string;
}
export interface ActiveSubscription {
  id: string;
  clientAccountId: number;
  status: 'Active' | 'Inactive';
  subscription: {
    name: string;
    id: string;
  };
  permission: {
    ocrEnabled: boolean;
    maxIndividualCard: number;
    maxBusinesCard: number;
    maxContact: number;
    qrMobileWidget: boolean;
    notification: {
      leadEmailEnabled: boolean;
      reviewEmailEnabled: boolean;
    };
  };
}
export interface CategoryItem {
  id: string;
  name: string;
  subCategories?: CategoryItem[];
}
