import {
  Autocomplete,
  Card,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  useTheme
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import DropdownMultiSelect from 'src/components/dropdown-multi-select';
import {
  CodeItem,
  RefData,
  Team
} from 'src/services/apiService/response-models';
import { USER_ROLES } from 'src/utility/constants';

type roleType = {
  label: string;
  roleName: string;
};
function GroupInfo(props: {
  isEdit: boolean;
  accountRefData: RefData;
  teamsList: Team[];
}) {
  const teamsOption = props.teamsList?.map(({ id, name }) => ({
    code: id,
    value: name
  }));

  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const jobTitlesList = props.accountRefData?.jobTitles || [];
  const departmentsList = props.accountRefData?.departments || [];
  return (
    <Card>
      <CardHeader title={t("Member's Group Info")} />
      <Divider />

      <Grid sx={{ padding: theme.spacing(2) }} container spacing={3}>
        <Grid item xs={12} md={6}>
          <Field name="departments">
            {({
              field,
              form: {
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError
              },
              meta
            }: FieldProps) => (
              <Autocomplete
                sx={{
                  m: 0
                }}
                freeSolo
                selectOnFocus
                clearOnBlur
                multiple={true}
                limitTags={10}
                value={field.value}
                autoSelect
                onChange={(event: any, newValues: null | string[]) => {
                  if (newValues) {
                    const vals = newValues.map((val) => {
                      return (
                        departmentsList.find(
                          (x) =>
                            x.value.toLowerCase() == val.trim().toLowerCase()
                        )?.value || val
                      );
                    });
                    setFieldValue(field.name, vals);
                  } else {
                    setFieldValue(field.name, []);
                  }
                }}
                isOptionEqualToValue={(option: string, value: string) => {
                  return option === value;
                }}
                getOptionLabel={(option: string) => {
                  return (
                    departmentsList.find((x) => x.code == option)?.value ||
                    option ||
                    ''
                  );
                }}
                options={departmentsList.map((o) => o.value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={t('Enter or select departments...')}
                    label={t('Departments')}
                  />
                )}
              />
            )}
          </Field>
          {/* <Field name="departments">
            {({ field, form, meta }: FieldProps) => (
              <DropdownMultiSelect
                label="Department"
                name="departments"
                options={convertToCodeItems(props.accountRefData.departments)}
              />
            )}
          </Field> */}
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="jobTitle">
            {({
              field,
              form: {
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError
              },
              meta
            }: FieldProps) => (
              <Autocomplete
                sx={{
                  m: 0
                }}
                freeSolo
                selectOnFocus
                clearOnBlur
                value={field.value || null}
                autoSelect
                onChange={(event: any, newValue: string | CodeItem | null) => {
                  if (newValue) {
                    let match: CodeItem = null;
                    if (typeof newValue == 'string') {
                      match = jobTitlesList.find(
                        (x) =>
                          x.value.toLowerCase() == newValue.toLowerCase().trim()
                      );
                      if (!match) {
                        match = { code: newValue, value: newValue };
                      }
                    } else {
                      match = newValue;
                    }
                    setFieldValue(field.name, { ...match });
                  }
                  setFieldTouched(field.name, true);
                }}
                getOptionLabel={(option: any) => {
                  return (
                    option.value ||
                    jobTitlesList.find((x) => x.code == option.code)?.value ||
                    ''
                  );
                }}
                isOptionEqualToValue={(option: CodeItem, value: CodeItem) => {
                  return option.code === value.code;
                }}
                options={jobTitlesList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={t('Enter or select job title...')}
                    label={t('Job Title')}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <DropdownMultiSelect
            label="Team/Group"
            name="teams"
            options={teamsOption}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="userRoles">
            {({ field, form, meta }: FieldProps) => (
              <Autocomplete
                disablePortal
                multiple={false}
                getOptionLabel={(option: roleType) => option.label}
                isOptionEqualToValue={(option, value) =>
                  option.roleName == value.roleName
                }
                value={USER_ROLES.find((o) =>
                  field.value?.includes(o.roleName)
                )}
                options={USER_ROLES as roleType[]}
                onChange={(event, values: roleType) => {
                  form.setFieldValue(
                    field.name,
                    values ? [values.roleName] : []
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(
                      form.touched[field.name] && form.errors[field.name]
                    )}
                    helperText={
                      (form.touched[field.name] && form.errors[field.name]) ||
                      'This role will assigned to the user associated with this member.'
                    }
                    fullWidth
                    {...params}
                    label={t('User role')}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
        {
          <Grid item xs={12} md={6}>
            <Field name="createAuser">
              {({ field, form, meta }: FieldProps) => (
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Switch
                      color={'primary'}
                      defaultChecked
                      onBlur={form.handleBlur}
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.checked)
                      }
                      checked={field.value}
                    />
                  }
                  label={t('Create a log-in user')}
                />
              )}
            </Field>
          </Grid>
        }
      </Grid>
    </Card>
  );
}

export default GroupInfo;
