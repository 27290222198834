import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import { IconButton, ListItemIcon, ListItemText, Zoom } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Task } from 'src/CRM/models/task';
import DeleteConfirm from 'src/components/delete-confirm';
import { deleteTaskApi } from 'src/services/query-client/crm';
import { openDrawerPanel, setTaskToEdit } from 'src/store/slices/app';
import ActivitiesContext from '../activities-context';

export default function ActionMenuButton(props: { task?: Task }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const { deleteTask } = React.useContext(ActivitiesContext);

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();
  const { t }: { t: any } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    dispatch(setTaskToEdit({ task: props.task }));
    dispatch(openDrawerPanel());
    handleClose();
  };
  const handleDelete = () => {
    setIsDeleteConfirmOpen(true);
    handleClose();
  };

  const onDeleteConfirm = async () => {
    try {
      const res = await deleteTaskApi(props.task.id);
      deleteTask(props.task);
      enqueueSnackbar(t('The task has been successfully deleted'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        TransitionComponent: Zoom
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <DeleteConfirm
        open={isDeleteConfirmOpen}
        message="are you sure you want to delete this task?"
        onDeleteConfirm={onDeleteConfirm}
        onClose={() => setIsDeleteConfirmOpen(false)}
      />
      <IconButton size="small" color="primary" onClick={handleClick}>
        <MoreVertTwoToneIcon />
      </IconButton>
      {/*<Button*/}
      {/*    size='small'*/}
      {/*    color={'secondary'}*/}
      {/*    id="demo-customized-button"*/}
      {/*    aria-controls={open ? 'demo-customized-menu' : undefined}*/}
      {/*    aria-haspopup="true"*/}
      {/*    aria-expanded={open ? 'true' : undefined}*/}
      {/*    variant="contained"*/}
      {/*    disableElevation*/}
      {/*    onClick={handleClick}*/}
      {/*    endIcon={<KeyboardArrowDownIcon />}*/}
      {/*>*/}
      {/*    Options*/}
      {/*</Button>*/}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))'
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/*<MenuItem onClick={handleClose}>*/}
        {/*    <Avatar /> Profile*/}
        {/*</MenuItem>*/}
        {/*<MenuItem onClick={handleClose}>*/}
        {/*    <Avatar /> My account*/}
        {/*</MenuItem>*/}
        {/*<Divider />*/}
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
