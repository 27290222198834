import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  CircularProgressProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Typography,
  styled
} from '@mui/material';
import { useSnackbar } from 'notistack';
import * as Papa from 'papaparse';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SaveIcon from '@mui/icons-material/Save';
import { useDropzone } from 'react-dropzone';
import { VirtualizedTable } from 'src/components/virtualized-table';
import { uploadContacts } from 'src/services/apiService';
import { parseToApiErrorMessage } from '../../../utility/parseToApiErrorMessage';

const BoxUploadWrapper = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
    background: ${theme.colors.alpha.black[5]};
    border: 1px dashed ${theme.colors.alpha.black[30]};
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.colors.alpha.white[50]};
      border-color: ${theme.colors.primary.main};
    }
`
);
const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.success.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarDanger = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.error.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box mt={1} sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const ContactsUploadDialog = (props: {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}) => {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(props.isOpen);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [invalidRows, setInvalidRows] = useState([]);

  const [progress, setProgress] = useState(0);
  const [rows, setRows] = useState([]);
  const [uploadedFile, setUploadedFile] = useState();
  let progressTimerRef: any;

  const onDropAccepted = useCallback((acceptedFiles) => {
    setIsUploading(true);
    const file = acceptedFiles[0];

    if (file) {
      // Parse local CSV file
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          console.log('Finished:', results.data);
          const { invalidRows, validRows } = results.data.reduce(
            (result, accu) => {
              if (accu.email && validateEmail(accu.email)) {
                result.validRows = [
                  ...result.validRows,
                  {
                    ...accu,
                    fullName: `${accu.firstName} ${accu.middleName} ${accu.lastName}`
                  }
                ];
                return result;
              }
              result.invalidRows = [
                ...result.invalidRows,
                {
                  ...accu,
                  fullName: `${accu.firstName} ${accu.middleName} ${accu.lastName}`
                }
              ];
              return result;
            },
            { validRows: [], invalidRows: [] }
          );
          setInvalidRows(invalidRows);
          setRows([...invalidRows, ...validRows]);
        }
      });
      setIsUploaded(true);
      setUploadedFile(file);
    }
    console.log(file);
    setIsUploading(false);
  }, []);

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 1,
    maxSize: 5242880,
    accept: {
      'text/plain': ['.csv']
    },
    onDropAccepted,
    disabled: isUploading
  });

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const handleCreateClose = () => {
    setOpen(false);
    setRows([]);
    setIsUploaded(false);
    setInvalidRows([]);
    props.onClose();
  };

  const onSave = async (updatedModel) => {
    setIsSaveInProgress(true);
    try {
      const data = await uploadContacts(uploadedFile);
      console.log(data);
      props.onSave(); // refetching
      enqueueSnackbar(t(`Contacts uploaded successfully`), {
        variant: 'success'
      });
      setRows([]);
      setInvalidRows([]);
      setIsUploaded(false);
      setOpen(false);
      props.onClose();
    } catch (ex) {
      const msg = parseToApiErrorMessage(ex, `Failed to upload contacts`);
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
    setIsSaveInProgress(false);
  };

  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={handleCreateClose}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <DialogTitle
            sx={{
              p: 3
            }}
          >
            <Typography variant="h4" gutterBottom>
              {t('Upload contacts')}
            </Typography>
            <Typography variant="subtitle2">
              {t(
                'Download template file and fill the required field to upload.'
              )}
              <a target="_self" href="../../contactsTemplate.csv">
                click here
              </a>
            </Typography>
          </DialogTitle>

          <DialogContent
            dividers
            sx={{
              p: 3
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {rows.length === 0 && (
                      <BoxUploadWrapper {...getRootProps()}>
                        <input {...getInputProps()} />
                        {isDragAccept && (
                          <>
                            <AvatarSuccess variant="rounded">
                              <CheckTwoToneIcon />
                            </AvatarSuccess>
                            <Typography
                              sx={{
                                mt: 2
                              }}
                            >
                              {t('Drop the files to start uploading')}
                            </Typography>
                          </>
                        )}
                        {isDragReject && (
                          <>
                            <AvatarDanger variant="rounded">
                              <CloseTwoToneIcon />
                            </AvatarDanger>
                            <Typography
                              sx={{
                                mt: 2
                              }}
                            >
                              {t('You cannot upload these file types')}
                            </Typography>
                          </>
                        )}
                        {!isDragActive && (
                          <>
                            <AvatarWrapper variant="rounded">
                              <CloudUploadTwoToneIcon />
                            </AvatarWrapper>
                            <Typography
                              sx={{
                                mt: 2
                              }}
                            >
                              {isUploading
                                ? t('Uploading...')
                                : t('Drag & drop file here')}
                            </Typography>
                          </>
                        )}
                        {isUploading && (
                          <CircularProgressWithLabel value={progress} />
                        )}
                      </BoxUploadWrapper>
                    )}
                    {isUploaded && !isUploading && (
                      <>
                        <Divider />
                        <Box pt={1}>
                          <Alert
                            sx={{
                              py: 0
                            }}
                            severity="success"
                          >
                            {t('You have uploaded')} <b>{1}</b> {t('file')}!
                          </Alert>
                        </Box>
                      </>
                    )}
                    {invalidRows.length > 0 && (
                      <>
                        <Divider />
                        <Box pt={1}>
                          <Alert
                            sx={{
                              py: 0
                            }}
                            severity="error"
                          >
                            {t(
                              'You have uploaded invalid rows for contacts please check'
                            )}
                            !
                          </Alert>
                        </Box>
                      </>
                    )}
                  </Grid>

                  {!!rows.length && (
                    <Grid item xs={12}>
                      <Paper style={{ height: 400, width: '100%' }}>
                        <VirtualizedTable
                          rowCount={rows.length}
                          rowGetter={({ index }) => rows[index]}
                          columns={[
                            {
                              width: 120,
                              label: 'Title',
                              dataKey: 'title',
                              numeric: false
                            },
                            {
                              width: 250,
                              label: 'Contact Name',
                              dataKey: 'fullName'
                            },
                            {
                              width: 120,
                              label: 'Email',
                              dataKey: 'email',
                              numeric: false,
                              required: true
                            },
                            {
                              width: 120,
                              label: 'Tags',
                              dataKey: 'tags',
                              numeric: false
                            },
                            {
                              width: 120,
                              label: 'Address',
                              dataKey: 'addressLine',
                              numeric: false
                            },
                            {
                              width: 120,
                              label: 'Country',
                              dataKey: 'country',
                              numeric: false
                            },
                            {
                              width: 120,
                              label: 'State',
                              dataKey: 'state',
                              numeric: false
                            },
                            {
                              width: 120,
                              label: 'City',
                              dataKey: 'city',
                              numeric: false
                            },
                            {
                              width: 120,
                              label: 'Zipcode',
                              dataKey: 'zipCode',
                              numeric: false
                            },

                            {
                              width: 120,
                              label: 'Contact number',
                              dataKey: 'contactNumber',
                              numeric: true
                            }
                          ]}
                        />
                      </Paper>
                    </Grid>
                  )}
                  <Grid item xs={12}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              p: 3
            }}
          >
            <Button color="secondary" onClick={handleCreateClose}>
              {t('Cancel')}
            </Button>
            <Button
              type="submit"
              onClick={onSave}
              startIcon={
                isSaveInProgress ? (
                  <CircularProgress size="1rem" />
                ) : (
                  <SaveIcon />
                )
              }
              variant="contained"
              disabled={!uploadedFile || !rows.length || isSaveInProgress}
            >
              {t('Save')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ContactsUploadDialog;
