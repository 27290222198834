import { FieldRenderBlockProp } from "..";

export default function getMultiFieldValue<T>(e: FieldRenderBlockProp, values: T[]): T | null {
  if (e.field.multiple == true) {
    if (values && (e.valueIndex || 0) < values.length) {
      return values[e.valueIndex || 0];
    }
    return null;
  }
  return values as any;
}