import { Autocomplete, TextField, useTheme } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { TeamMember } from 'src/services/apiService/response-models';
import { getTeamMembers } from 'src/services/query-client';

function MembersSelector(props: {
  onChange: (values: TeamMember[]) => void;
  selectedIds?: string[];
  disabled?: boolean;
  placeholder?: string;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [selectedIds, setSelectedIds] = useState<string[]>(
    props.selectedIds || []
  );

  const {
    data: { data: allMembers = [] } = {},
    isError,
    error,
    isLoading
  } = useQuery('team-members', getTeamMembers);

  const allMemberIds = allMembers.map((o) => o.id);

  return (
    <Autocomplete
      id="member-select-comp2"
      fullWidth
      multiple={true}
      disabled={props.disabled}
      value={selectedIds}
      isOptionEqualToValue={(option, value) => option === value}
      options={_.difference(allMemberIds, selectedIds)}
      // @ts-ignore
      getOptionLabel={(option: string) => {
        const match = allMembers.find((o) => o.id == option);
        return match ? match.fullName : '';
      }}
      onChange={(event: any, newValues: any) => {
        setSelectedIds(newValues || []);
        if (newValues) {
          props.onChange(allMembers.filter((o) => newValues.includes(o.id)));
        } else {
          props.onChange([]);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: ''
          }}
          fullWidth
          variant="outlined"
          // label={t('Member(s)')}
          placeholder={t(props.placeholder || 'Select Member...')}
        />
      )}
    />
  );
}

export default MembersSelector;
