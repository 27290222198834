import React, { ChangeEvent, useCallback, useState } from 'react';

import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import DeleteConfirm from 'src/components/delete-confirm';
import { CategoryItem } from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import getExt from 'src/utility/getExt';

const DotLegend = styled('span')(
  ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.5)};
    height: ${theme.spacing(1.5)};
    display: inline-block;
    margin-right: ${theme.spacing(0.5)};
`
);

const BoxUploadWrapper = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
    background: ${theme.colors.alpha.black[5]};
    border: 1px dashed ${theme.colors.alpha.black[30]};
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.colors.alpha.white[50]};
      border-color: ${theme.colors.primary.main};
    }
`
);

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.success.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarDanger = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.error.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

interface ProductTags {
  key: number;
  label: string;
}

function Sidebar(props: { categories: CategoryItem[] }) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [imageNames, setImageNames] = useState<string[]>([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [deleteImageOpen, setDeleteImageOpen] = useState(false);
  const [deleteVideoOpen, setDeleteVideoOpen] = useState(false);

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    const names: string[] = [];
    for (const i in acceptedFiles) {
      const file = acceptedFiles[i];
      const uniqueName = Date.now() + '.' + getExt(file.name);
      names.push(uniqueName);
      cdnService.blobHash[uniqueName] = file;
    }
    setImageNames(names);
  }, []);

  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg'],
      'image/heic': ['.heic'],
      'image/heif': ['.heif']
    },
    onDropAccepted
  });

  const [checkedCats, setCheckedCats] = useState<string[]>([]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    const match = props.categories.find((o) => o.id == id);
    const subIds = (match?.subCategories || []).map((o) => o.id);

    const arr = checkedCats.filter((o) => o != id && !subIds.includes(o));

    if (event.target.checked) {
      arr.push(id);
      subIds.forEach((o) => arr.push(o));
    }
    setCheckedCats([...arr]);
  };

  const children = (items?: CategoryItem[]) => {
    return items ? (
      items.map((cat) => (
        <Box
          key={cat.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: 3
          }}
        >
          <FormControlLabel
            label={cat.name}
            control={
              <Checkbox
                checked={checkedCats.includes(cat.id)}
                onChange={(e) => handleChange(e, cat.id)}
              />
            }
          />
        </Box>
      ))
    ) : (
      <></>
    );
  };

  return (
    <Box>
      <Card
        sx={{
          m: 3
        }}
      >
        <CardHeader
          // action={
          //   <Button size="small" variant="outlined">
          //     {t('Add category')}
          //   </Button>
          // }
          title={t('Categories')}
        />
        <Divider />
        <Box
          p={2}
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {props.categories.map((cat) => (
            <React.Fragment key={cat.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedCats.includes(cat.id)}
                    //indeterminate={checked[0] !== checked[1]}
                    onChange={(e) => handleChange(e, cat.id)}
                  />
                }
                label={cat.name}
              />
              {children(cat.subCategories)}
            </React.Fragment>
          ))}
          {props.categories.length == 0 && (
            <Typography>No categories</Typography>
          )}
        </Box>
      </Card>
      <Card
        sx={{
          m: 3
        }}
      >
        <CardHeader
          title={t('Equipment Images')}
          action={
            <Tooltip title="Delete image(s)">
              <IconButton
                onClick={() => setDeleteImageOpen(true)}
                size="small"
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          }
        />
        <Divider />
        <Box p={2}>
          <BoxUploadWrapper {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragAccept && (
              <>
                <AvatarSuccess variant="rounded">
                  <CheckTwoToneIcon />
                </AvatarSuccess>
                <Typography
                  sx={{
                    mt: 2
                  }}
                >
                  {t('Drop the files to start uploading')}
                </Typography>
              </>
            )}
            {isDragReject && (
              <>
                <AvatarDanger variant="rounded">
                  <CloseTwoToneIcon />
                </AvatarDanger>
                <Typography
                  sx={{
                    mt: 2
                  }}
                >
                  {t('You cannot upload these file types')}
                </Typography>
              </>
            )}
            {!isDragActive && (
              <>
                <AvatarWrapper variant="rounded">
                  <CloudUploadTwoToneIcon />
                </AvatarWrapper>
                <Typography
                  sx={{
                    mt: 2
                  }}
                >
                  {t('Drag & drop files here')}
                </Typography>
              </>
            )}
          </BoxUploadWrapper>
        </Box>

        {imageNames.length > 0 && (
          <>
            <Divider />
            <Box p={2}>
              <Alert
                sx={{
                  py: 0
                }}
                severity="success"
              >
                <b>{imageNames.length}</b> images uploaded!
              </Alert>
            </Box>
          </>
        )}
      </Card>
      <Card
        sx={{
          m: 3,
          mb: 5
        }}
      >
        <CardHeader
          title={t('Equipment Video')}
          // action={
          //   <Tooltip title="Delete Video">
          //     <IconButton
          //       onClick={() => setDeleteVideoOpen(true)}
          //       size="small"
          //       color="error"
          //     >
          //       <DeleteIcon />
          //     </IconButton>
          //   </Tooltip>
          // }
        />
        <Divider />
        <Box p={1}>
          {/* <Typography m={1}>No video</Typography> */}
          {videoUrl ? (
            <video width="100%" height="250" controls>
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <BoxUploadWrapper>
              <Typography
                sx={{
                  my: 4
                }}
              >
                {t('No video')}
              </Typography>
            </BoxUploadWrapper>
          )}
        </Box>
        <Divider />
        <Box p={1}>
          <TextField
            fullWidth
            size="small"
            name="videoUrl"
            variant="outlined"
            placeholder={t('Enter Video link here ...')}
            onChange={(e) => setVideoUrl(e.target.value)}
          />
        </Box>
      </Card>
      <DeleteConfirm
        open={deleteImageOpen}
        message="Are you sure you want to remove all images?"
        onClose={() => setDeleteImageOpen(false)}
        onDeleteConfirm={() => {
          setDeleteImageOpen(false);
          setImageNames([]);
        }}
      />
      <DeleteConfirm
        open={deleteVideoOpen}
        message="Are you sure you want to remove the video?"
        onClose={() => setDeleteVideoOpen(false)}
        onDeleteConfirm={() => {
          setDeleteVideoOpen(false);
          setVideoUrl('');
        }}
      />
    </Box>
  );
}

export default Sidebar;
