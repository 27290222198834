import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteConfirm from 'src/components/delete-confirm';

interface CustomField {
  id: string;
  name: string;
  value: string;
  group?: string;
}

const ListItemWrapper = styled(ListItem)(
  ({ theme }) => `
      position: relative;
      transition: ${theme.transitions.create(['background'])};

      .MuiActionButtons {
            background: ${alpha(theme.colors.alpha.white[100], 0.95)};
            border-radius: ${theme.general.borderRadius};
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
            top: 50%;
            margin-top: -${theme.spacing(3.5)};
            position: absolute;
            right: ${theme.spacing(1.5)};
            padding: ${theme.spacing(0.5, 1)};
            transition: ${theme.transitions.create(['visibility', 'opacity'])};

            .MuiIconButton-root {
                border-radius: 100px;
                width: ${theme.spacing(5)};
                height: ${theme.spacing(5)};
                margin: ${theme.spacing(0.5)};
            }
      }

      &:hover {
          background: ${alpha(theme.colors.secondary.main, 0.15)};

          .MuiActionButtons {
            visibility: visible;
            opacity: 1;
      }   
      }
  `
);

export function CustomFieldsCard(props: {
  fields: CustomField[];
  onDelete: (e: string) => void;
  onEdit: (e: string) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [idToDelete, setIdToDelete] = useState('');

  return (
    <Card variant="outlined">
      <CardHeader
        sx={{
          p: 2,
          background: `${theme.colors.alpha.black[5]}`
        }}
        disableTypography
        title={
          <Typography
            variant="h4"
            sx={{
              fontSize: `${theme.typography.pxToRem(16)}`
            }}
          >
            {t(
              'Custom fields' +
                (props.fields.length > 0
                  ? ' (' + props.fields.length + ')'
                  : '')
            )}
          </Typography>
        }
        // action={
        //   <Button
        //     variant="contained"
        //     size="small"
        //     endIcon={<LabelOutlinedIcon />}
        //     color="primary"
        //   >
        //     {t('Export')}
        //   </Button>
        // }
      />
      <Divider />
      <List disablePadding>
        {props.fields.length == 0 && (
          <Typography m={2}>No custom field</Typography>
        )}
        {props.fields.map((o) => (
          <React.Fragment key={o.name}>
            <ListItemWrapper
              sx={{
                p: 2
              }}
            >
              <Box className="MuiActionButtons">
                <Tooltip
                  arrow
                  placement="top"
                  title={t('Purchase a single license')}
                >
                  <IconButton
                    onClick={() => props.onEdit(o.id)}
                    color="primary"
                  >
                    <EditTwoToneIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  arrow
                  placement="top"
                  title={t('Remove existing license')}
                >
                  <IconButton onClick={() => setIdToDelete(o.id)} color="error">
                    <CloseTwoToneIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <ListItemAvatar
                sx={{
                  mr: 2,
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 0
                }}
              >
                <Avatar
                  variant="rounded"
                  sx={{
                    background: `${theme.colors.alpha.black[10]}`,
                    color: `${theme.colors.primary.main}`,
                    width: 64,
                    height: 64
                  }}
                >
                  <LabelOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography gutterBottom variant="h4">
                    {o.name}
                  </Typography>
                }
                secondary={
                  <Typography variant="subtitle2">{o.value}</Typography>
                }
              />
            </ListItemWrapper>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <DeleteConfirm
        open={idToDelete.length > 0}
        message="Are you sure, you want to delete?"
        onClose={() => setIdToDelete('')}
        onDeleteConfirm={() => {
          setIdToDelete('');
          props.onDelete(idToDelete);
        }}
      />
    </Card>
  );
}
