import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import SaveIcon from '@mui/icons-material/Save';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  styled,
  useTheme
} from '@mui/material';
import { Field, FieldProps, Formik } from 'formik';
import { TextField as FmTextFiled } from 'formik-mui';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import QrCodeCard from 'src/components/QrCodeCard';
import MemberSelector from 'src/components/member-selector';
import MembersSelector from 'src/components/members-selector';
import { BusinessAppInstanceDto } from 'src/services/dto';
import { ApiUrls } from 'src/services/endpoints';
import {
  QKEY,
  appQueryClient,
  getAllProfilesList,
  getAppIstanceById,
  getRefData,
  saveAppInstance
} from 'src/services/query-client';
import { RootState } from 'src/store';
import Scrollbar from 'src/ui-bloom/components/Scrollbar';
import readQueryString from 'src/utility/readQueryString';
import * as Yup from 'yup';
import {
  BusinessAppInstance,
  BusinessAppNames,
  CodeItem,
  QrInfo
} from '../../../services/apiService/response-models';
import { parseApiError } from '../../../utility/parseToApiErrorMessage';
import PageHeader from './PageHeader';
import ReviewQuestionCard from './review-question-card';

const DrawerWrapper = styled(Drawer)(
  ({ theme }) => `
    width: 400px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 400px;
        height: calc(100% - ${theme.header.height});
        position: absolute;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.alpha.white[10]};
    }
`
);

const DrawerWrapperMobile = styled(Drawer)(
  ({ theme }) => `
    width: 360px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 360px;
        z-index: 3;
        background: ${theme.colors.alpha.white[30]};
  }
`
);

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

const allReviewProviders: CodeItem[] = [
  {
    code: 'google',
    value: 'Google'
  },
  {
    code: 'instagram',
    value: 'Instagram'
  },
  {
    code: 'facebook',
    value: 'Facebook'
  }
];

const reviewTemplates: CodeItem[] = [
  {
    code: 'default',
    value: 'Default'
  }
];

export default function ReviewAppEditor() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id } = useParams();

  const [qrInfo, setQrInfo] = useState<QrInfo | null>(null);
  const [assignToId, setAssignToId] = useState<string>('');
  const [notifyToIds, setNotifyToIds] = useState<string[]>();

  const [isEdit, setIsEdit] = useState(Boolean(id && id.length));

  const { user } = useSelector((state: RootState) => state.app);

  //Queries
  const {
    data: { data: accountRefData } = {},
    isError,
    error,
    isLoading: isRefDataLoading
  } = useQuery(QKEY.RefData, getRefData, {});

  const {
    data: { data: model } = {},
    isLoading,
    refetch: refetchById
  } = useQuery(['app-instance', id], (k) => getAppIstanceById(id), {
    enabled: isEdit,
    onSuccess: (e) => {
      setQrInfo(e.data.qrInfo);
    }
  });

  const { data: { data: profiles = [] } = {}, refetch } = useQuery(
    'profiles',
    getAllProfilesList
  );

  const {
    mutate: save,
    isError: isSaveError,
    error: errorOnSave,
    isLoading: isSaving
  } = useMutation((e: BusinessAppInstanceDto) => saveAppInstance(e, id), {
    onSuccess: (o) => onSaveSuccess(o.data)
  });

  //----Queries

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (isSaveError) {
    const msg = parseApiError(
      errorOnSave,
      `Failed to ${!isEdit ? 'created' : 'updated'} Contact`
    );
    enqueueSnackbar(t(msg), { variant: 'error' });
  }

  const onSaveSuccess = async (updatedModel: BusinessAppInstance) => {
    enqueueSnackbar(
      t(`App was ${!isEdit ? 'created' : 'updated'} successfully`),
      { variant: 'success' }
    );
    if (!isEdit) navigate('/app/business-apps/review-app/' + updatedModel.id);
    else {
      appQueryClient.setQueryData(['app-instances', id], updatedModel);
      refetchById();
    }
  };

  const handleBack = (): void => {
    return navigate(readQueryString()?.ret || `/app/business-apps`);
  };
  const sidebarContent = (
    <Scrollbar>
      <Sidebar />
    </Scrollbar>
  );

  if (isLoading || isRefDataLoading) {
    return <LinearProgress />;
  }

  const selectedProviders: CodeItem[] = (
    model?.reviewApp?.reviewProviders || []
  ).map((o) => ({ code: o, value: o }));

  if (isSaveError) {
    closeSnackbar();
    enqueueSnackbar(t(parseApiError(errorOnSave, 'Failed to save')), {
      variant: 'error'
    });
  }
  function Sidebar() {
    return (
      <Grid
        sx={{}}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <QrCodeCard
            label="Review link:"
            qrInfo={qrInfo}
            placeholder=""
            onChange={(e) => {
              setQrInfo({ ...e });
            }}
            buildText={async (o) => {
              return ApiUrls.viewerAppUrl + '/review/' + o;
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ m: 1 }}>
            <CardHeader title={t('CRM Setting')} />
            <Divider />
            <Stack spacing={1} sx={{ p: 2 }}>
              <InputLabel id="profile-select">Assign review to:</InputLabel>
              <FormControl variant="filled" fullWidth>
                <MemberSelector
                  onChange={(e) => {
                    setAssignToId(e?.id || '');
                  }}
                />

                <FormHelperText>
                  Selected member to assign the new review to
                </FormHelperText>
              </FormControl>
              <Divider />
              <InputLabel id="profile-select">Notify to:</InputLabel>
              <FormControl variant="filled" fullWidth>
                <MembersSelector
                  onChange={(e) => {
                    setNotifyToIds(e?.map((o) => o.id) || []);
                  }}
                />

                <FormHelperText>
                  Send notifications to selected member when a new review is
                  recieved
                </FormHelperText>
              </FormControl>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          label: model?.label || '',
          template: model?.reviewApp?.template || 'default',
          title: model?.reviewApp?.title || '',
          subTitle: model?.reviewApp?.subTitle || '',
          profileId: model?.profileId || '',
          questions: model?.reviewApp?.reviewQuestions || [],
          reviewProviders: selectedProviders
        }}
        validationSchema={Yup.object().shape({
          label: Yup.string().max(250),
          title: Yup.string().max(250),
          subTitle: Yup.string().max(250)
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          if (!qrInfo?.code) {
            enqueueSnackbar(t('Please provide QR code'), { variant: 'error' });
            setStatus({ success: false });
            setSubmitting(false);
            return;
          }
          const updatedModel: BusinessAppInstanceDto = {
            ..._values,
            qrInfo: qrInfo,
            label: _values.label,
            appName: BusinessAppNames.ReviewApp,
            profileId: _values.profileId,
            reviewApp: {
              template: _values.template,
              title: _values.title,
              subTitle: _values.subTitle,
              reviewProviders: _values.reviewProviders?.map((o) => o.code),
              reviewQuestions: _values.questions,
              assignToId: assignToId,
              notifyToIds: notifyToIds
            }
          };

          save(updatedModel);
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3} display="flex">
              <MainContentWrapper>
                <Grid
                  sx={{
                    px: 4
                  }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid item xs={12}>
                    <Box
                      mt={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <PageHeader
                        isEdit={isEdit}
                        id={id}
                        onBack={handleBack}
                        isSaving={isSaving}
                      />
                      <Box
                        component="span"
                        sx={{
                          display: { lg: 'none', xs: 'inline-block' }
                        }}
                      >
                        <IconButtonToggle
                          sx={{
                            ml: 2
                          }}
                          color="primary"
                          onClick={handleDrawerToggle}
                          size="small"
                        >
                          <MenuTwoToneIcon />
                        </IconButtonToggle>
                      </Box>
                    </Box>
                  </Grid>
                  {isLoading && (
                    <Grid item xs={12}>
                      <LinearProgress />
                      loading...
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Card sx={{ p: 2 }}>
                      <Field
                        fullWidth
                        name="label"
                        component={FmTextFiled}
                        label="Label for your reference"
                        placeholder={t(
                          'enter a label, e.g. Golf course review...'
                        )}
                      />
                      <FormHelperText>
                        (Optional) Enter a label, e.g. Golf course review
                      </FormHelperText>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader title={t('Review app configuration')} />
                      <Divider />
                      <Grid
                        sx={{ padding: theme.spacing(2) }}
                        container
                        spacing={3}
                      >
                        <Grid item md={6} xs={12}>
                          <FormControl variant="filled" fullWidth>
                            <InputLabel id="profile-select">
                              Associate to a profile
                            </InputLabel>
                            <Field name="profileId">
                              {({ field, form, meta }: FieldProps) => (
                                <Select
                                  labelId="profile-select"
                                  value={field.value}
                                  onChange={(_event) => {
                                    form.setFieldValue(
                                      field.name,
                                      _event.target.value
                                    );
                                  }}
                                >
                                  {profiles.map((o) => (
                                    <MenuItem key={o.id} value={o.id}>
                                      {(o.data?.businessName ||
                                        o.data?.fullName) +
                                        `(${
                                          o.data.businessName
                                            ? 'Company'
                                            : 'Indiviudal'
                                        })`}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            </Field>
                            {/*{touched.stateCode && (errors.stateCode ? true : false) &&*/}
                            {/*    <FormHelperText error={touched.stateCode && (errors.stateCode ? true : false)}>{errors.stateCode}</FormHelperText >}*/}
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <FormControl variant="filled" fullWidth>
                            <InputLabel id="template-select">
                              Review Template
                            </InputLabel>
                            <Field name="template">
                              {({ field, form, meta }: FieldProps) => (
                                <Select
                                  labelId="template-select"
                                  value={field.value}
                                  onChange={(_event) => {
                                    form.setFieldValue(
                                      field.name,
                                      _event.target.value
                                    );
                                  }}
                                >
                                  {reviewTemplates.map((o) => (
                                    <MenuItem key={o.code} value={o.code}>
                                      {o.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            </Field>
                            {/*{touched.stateCode && (errors.stateCode ? true : false) &&*/}
                            {/*    <FormHelperText error={touched.stateCode && (errors.stateCode ? true : false)}>{errors.stateCode}</FormHelperText >}*/}
                          </FormControl>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <Field
                            fullWidth
                            name="title"
                            component={FmTextFiled}
                            label="Title"
                            placeholder={t('Enter here...')}
                          />
                          <FormHelperText>
                            Title to be desplayed on review page
                          </FormHelperText>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            fullWidth
                            name="subTitle"
                            component={FmTextFiled}
                            label="Subtitle"
                            placeholder={t('Enter here...')}
                          />
                          <FormHelperText>
                            Subtitle to be desplayed on review page
                          </FormHelperText>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <InputLabel sx={{ mb: 1 }} id="provider-select-comp">
                            External Review Provider(s)
                          </InputLabel>
                          <Field name="reviewProviders">
                            {({ field, form, meta }: FieldProps) => (
                              <Autocomplete
                                id="provider-select-comp"
                                fullWidth
                                multiple={true}
                                value={field.value || []}
                                isOptionEqualToValue={(option, value) =>
                                  option.code === value?.code
                                }
                                options={allReviewProviders}
                                // @ts-ignore
                                getOptionLabel={(option: CodeItem) =>
                                  option.value
                                }
                                onChange={(event: any, newValues: any) => {
                                  form.setFieldValue(
                                    field.name,
                                    newValues || []
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: ''
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    // label={t('Member(s)')}
                                    placeholder={'Select provider(s)'}
                                  />
                                )}
                              />
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <ReviewQuestionCard />
                  </Grid>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        p: 1
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Button
                            type="button"
                            variant="outlined"
                            onClick={handleBack}
                          >
                            Cancel
                          </Button>
                          <Button
                            sx={{ ml: 2 }}
                            type="submit"
                            variant="contained"
                            startIcon={
                              isSubmitting || isSaving ? (
                                <CircularProgress size="1rem" />
                              ) : (
                                <SaveIcon />
                              )
                            }
                            disabled={isLoading || isSubmitting || isSaving}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </MainContentWrapper>
              <Box
                component="span"
                sx={{
                  display: { lg: 'none', xs: 'inline-block' }
                }}
              >
                <DrawerWrapperMobile
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                >
                  {sidebarContent}
                </DrawerWrapperMobile>
              </Box>
              <Box
                component="span"
                sx={{
                  display: { xs: 'none', lg: 'inline-block' }
                }}
              >
                <DrawerWrapper
                  variant="permanent"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open
                >
                  {sidebarContent}
                </DrawerWrapper>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}
