import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import {
  CardActionArea,
  CardHeader,
  CardMedia,
  Link,
  Paper
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { ImageFieldValue } from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import blobToBase64 from 'src/utility/blobToBase64';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FieldRenderBlockProp } from '../..';
import getMultiFieldValue from '../../_utils/getMultiFieldValue';

export default function ImageSliderBlock(props: FieldRenderBlockProp) {
  const theme = useTheme();
  const [urls, setUrls] = useState<string[]>([]);

  const value = getMultiFieldValue<ImageFieldValue>(
    props,
    props.data.imageList
  );

  useEffect(() => {
    (async () => {
      const _urls: string[] = [];

      const lst = value?.imageList || [];

      for (let i = 0; i < lst.length; i++) {
        const blob = cdnService.blobHash[lst[i].url];
        if (blob) {
          const str = await blobToBase64(blob);
          _urls.push(str);
        } else {
          _urls.push(cdnService.toImageUrl(lst[i].url));
        }
      }
      setUrls(_urls);
    })();
  }, [value]);

  return (
    <Card
      sx={{
        overflow: 'hidden',
        textAlign: 'left',
        border: 'solid 1px #ccc',
        width: '100%',
        backgroundColor: 'inherit'
      }}
    >
      {(value?.title || value?.subTitle) && (
        <CardHeader
          sx={{
            pb: 0,
            color:
              props.theme === 'light'
                ? `${theme.colors.alpha.black[100]}`
                : `${theme.colors.alpha.trueWhite[100]}`,
            '.MuiCardHeader-subheader': {
              color:
                props.theme === 'light'
                  ? `${theme.colors.alpha.black[100]}`
                  : `${theme.colors.alpha.trueWhite[100]}`
            }
          }}
          title={value?.title}
          subheader={value?.subTitle}
        />
      )}
      <Box
        px={1}
        pb={1}
        sx={{
          '.swiper-pagination-bullets': {
            bottom: '0 !important'
          }
        }}
      >
        {!value?.imageList?.length ? (
          <Paper
            elevation={0}
            sx={{
              height: '100%',
              backgroundColor: '#f5dede',
              margin: 1,
              textAlign: 'center'
            }}
          >
            <ViewCarouselIcon
              fontSize="large"
              color="secondary"
              style={{ margin: '20%' }}
            />
          </Paper>
        ) : (
          <Swiper
            spaceBetween={12}
            slidesPerView={1}
            loop
            navigation={{}}
            modules={[Navigation, Pagination, Autoplay]}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false
            }}
            pagination={{ dynamicBullets: true, clickable: true }}
          >
            {value?.imageList?.map((o, i) => (
              <SwiperSlide key={o.url}>
                <Card
                  sx={{
                    mt: 2,
                    textAlign: 'center',
                    transition: `${theme.transitions.create([
                      'box-shadow',
                      'transform'
                    ])}`,
                    transform: 'translateY(0px)',

                    '&:hover': {
                      transform: 'translateY(-10px)',
                      boxShadow: `0 2rem 8rem 0 ${alpha(
                        theme.colors.alpha.black[100],
                        0.1
                      )}, 
                              0 0.6rem 1.6rem ${alpha(
                                theme.colors.alpha.black[100],
                                0.2
                              )}, 
                              0 0.2rem 0.2rem ${alpha(
                                theme.colors.alpha.black[100],
                                0.15
                              )}`
                    }
                  }}
                >
                  <CardActionAreaWrapper>
                    <CardMedia
                      component="img"
                      height="230"
                      image={i < urls.length ? urls[i] : ''}
                      alt="..."
                    />
                  </CardActionAreaWrapper>
                </Card>
                {(o.title || o.subTitle) && (
                  <Box
                    sx={{
                      px: { md: 2, lg: 1.5, xl: 3 },
                      pt: 2,
                      textAlign: 'center'
                    }}
                  >
                    {o.title && (
                      <Link
                        lineHeight={1.5}
                        target="_blank"
                        href={'o.targetUrl'}
                        sx={{
                          transition: `${theme.transitions.create(['color'])}`,

                          '&:hover': {
                            color: `${theme.colors.primary.main}`
                          }
                        }}
                        color="text.primary"
                        variant="h3"
                        underline="none"
                      >
                        {o.title}
                      </Link>
                    )}
                    {o.subTitle && (
                      <Typography
                        variant="subtitle2"
                        sx={{
                          pb: 2
                        }}
                      >
                        {o.subTitle}
                      </Typography>
                    )}
                  </Box>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Box>
    </Card>
  );
}
const CardActionAreaWrapper = styled(CardActionArea)(
  () => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .MuiTouchRipple-root {
      opacity: .3;
  }

  &:hover {
      .MuiCardActionArea-focusHighlight {
          opacity: .05;
      }
  }
`
);
