import { Avatar, Box, styled, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import cdnService from 'src/services/cdnService';
import blobToBase64 from 'src/utility/blobToBase64';
import { FieldRenderBlockProp } from '../..';

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(13)};
    font-weight: bold;
    text-transform: uppercase;
    border-radius: ${theme.general.borderRadiusSm};
    padding: ${theme.spacing(0.9, 1.5, 0.7)};
    line-height: 1;
  `
);

export default function ProfilePicBlockRenderer(props: FieldRenderBlockProp) {
  const theme = useTheme();
  const [url, setUrl] = useState('');

  useEffect(() => {
    const blob = cdnService.blobHash[props.data.profileImage];
    if (blob) {
      blobToBase64(blob).then((o) => {
        setUrl(o);
      });
      setUrl('');
    } else {
      setUrl(cdnService.toImageUrl(props.data.profileImage));
    }
  }, [props.data]);

  return (
    <Avatar
      sx={{
        '.MuiAvatar-img': {
          objectFit: 'fill',
          height: '100%'
        },
        width: 100,
        height: 100,
        mb: 2,
        mx: 'auto',
        mt: `-${theme.spacing(10)}`,
        zIndex: 2,
        boxShadow: `0 .113rem .5rem ${theme.colors.alpha.black[10]}, 0 .126rem .225rem ${theme.colors.alpha.black[30]}`,
        border: `${theme.colors.alpha.white[100]} solid 3px`
      }}
      src={url}
    />
  );
}
