import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormHelperText,
  Grid,
  LinearProgress,
  styled,
  useTheme
} from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField as FmTextFiled } from 'formik-mui';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import QrCodeCard from 'src/components/QrCodeCard';
import { BusinessAppInstanceDto } from 'src/services/dto';
import { ApiUrls } from 'src/services/endpoints';
import {
  appQueryClient,
  getAllProfilesList,
  getAppIstanceById,
  saveAppInstance
} from 'src/services/query-client';
import { RootState } from 'src/store';
import readQueryString from 'src/utility/readQueryString';
import * as Yup from 'yup';
import {
  BusinessAppInstance,
  BusinessAppNames,
  QrInfo
} from '../../../services/apiService/response-models';
import { parseApiError } from '../../../utility/parseToApiErrorMessage';
import PageHeader from './PageHeader';

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
);

export default function ExternalAppEditor() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id } = useParams();

  const [qrInfo, setQrInfo] = useState<QrInfo | null>(null);
  const [assignToId, setAssignToId] = useState<string>('');
  const [notifyToIds, setNotifyToIds] = useState<string[]>();

  const [isEdit, setIsEdit] = useState(Boolean(id && id.length));

  const { user } = useSelector((state: RootState) => state.app);

  const {
    data: { data: model } = {},
    isLoading,
    refetch: refetchById
  } = useQuery(['app-instance', id], (k) => getAppIstanceById(id), {
    enabled: isEdit,
    onSuccess: (e) => {
      setQrInfo(e.data.qrInfo);
    }
  });

  const { data: { data: profiles = [] } = {}, refetch } = useQuery(
    'profiles',
    getAllProfilesList
  );

  const {
    mutate: save,
    isError: isSaveError,
    error: errorOnSave,
    isLoading: isSaving
  } = useMutation((e: BusinessAppInstanceDto) => saveAppInstance(e, id), {
    onSuccess: (o) => onSaveSuccess(o.data)
  });

  //----Queries

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (isSaveError) {
    const msg = parseApiError(
      errorOnSave,
      `Failed to ${!isEdit ? 'created' : 'updated'} Contact`
    );
    enqueueSnackbar(t(msg), { variant: 'error' });
  }

  const onSaveSuccess = async (updatedModel: BusinessAppInstance) => {
    enqueueSnackbar(
      t(`App was ${!isEdit ? 'created' : 'updated'} successfully`),
      { variant: 'success' }
    );
    if (!isEdit) navigate('/app/business-apps/external-app/' + updatedModel.id);
    else {
      appQueryClient.setQueryData(['app-instances', id], updatedModel);
      refetchById();
    }
  };

  const handleBack = (): void => {
    return navigate(readQueryString()?.ret || `/app/business-apps`);
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  if (isSaveError) {
    closeSnackbar();
    enqueueSnackbar(t(parseApiError(errorOnSave, 'Failed to save')), {
      variant: 'error'
    });
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          label: model?.label || ''
        }}
        validationSchema={Yup.object().shape({
          label: Yup.string().max(250),
          title: Yup.string().max(250),
          subTitle: Yup.string().max(250)
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          if (!qrInfo?.code) {
            enqueueSnackbar(t('Please provide QR code'), { variant: 'error' });
            setStatus({ success: false });
            setSubmitting(false);
            return;
          }
          const updatedModel: BusinessAppInstanceDto = {
            ..._values,
            qrInfo: qrInfo,
            label: _values.label,
            appName: BusinessAppNames.ExternalApp
          };

          save(updatedModel);
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3} display="flex">
              <MainContentWrapper>
                <Grid
                  sx={{
                    px: 4
                  }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid item xs={12}>
                    <Box
                      mt={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <PageHeader
                        isEdit={isEdit}
                        id={id}
                        onBack={handleBack}
                        isSaving={isSaving}
                      />
                    </Box>
                  </Grid>

                  {isLoading && (
                    <Grid item xs={12}>
                      <LinearProgress />
                      loading...
                    </Grid>
                  )}
                  <Grid container mt={2}>
                    <Grid item xs={12} md={3}>
                      <QrCodeCard
                        label="External link"
                        qrInfo={model?.qrInfo}
                        placeholder="Enter external url here"
                        onChange={(e) => {
                          setQrInfo({ ...e });
                        }}
                        buildText={async (o) => {
                          return (
                            ApiUrls.viewerAppUrl +
                            '/' +
                            BusinessAppNames.ExternalApp +
                            '?url=' +
                            o
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Card sx={{ p: 2 }}>
                            <Field
                              fullWidth
                              name="label"
                              component={FmTextFiled}
                              label="Enter name for your reference"
                            />
                            <FormHelperText>(Optional)</FormHelperText>
                          </Card>
                        </Grid>

                        <Grid item xs={12}>
                          <Card
                            sx={{
                              p: 1
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Button
                                  type="button"
                                  variant="outlined"
                                  onClick={handleBack}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  sx={{ ml: 2 }}
                                  type="submit"
                                  variant="contained"
                                  startIcon={
                                    isSubmitting || isSaving ? (
                                      <CircularProgress size="1rem" />
                                    ) : (
                                      <SaveIcon />
                                    )
                                  }
                                  disabled={
                                    isLoading || isSubmitting || isSaving
                                  }
                                >
                                  Save
                                </Button>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}></Grid>
                  </Grid>
                </Grid>
              </MainContentWrapper>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}
