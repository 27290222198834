import SaveIcon from '@mui/icons-material/Save';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Switch,
  styled,
  useTheme
} from '@mui/material';
import { Field, FieldProps, Formik } from 'formik';
import { TextField as FmTextFiled } from 'formik-mui';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import QrCodeCard from 'src/components/QrCodeCard';
import cdnService from 'src/services/cdnService';
import { BusinessAppInstanceDto } from 'src/services/dto';
import { ApiUrls } from 'src/services/endpoints';
import {
  appQueryClient,
  getAllProfilesList,
  getAppIstanceById,
  saveAppInstance
} from 'src/services/query-client';
import { RootState } from 'src/store';
import readQueryString from 'src/utility/readQueryString';
import * as Yup from 'yup';
import {
  BusinessAppInstance,
  BusinessAppNames,
  CodeItem,
  QrInfo
} from '../../../services/apiService/response-models';
import { parseApiError } from '../../../utility/parseToApiErrorMessage';
import PageHeader from './PageHeader';

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
);

const Input = styled('input')({
  display: 'none'
});

const CardCover = styled(Card)(
  ({ theme }) => `
    position: relative;
    padding:5px;
  
`
);

const CardCoverAction = styled(Box)(
  ({ theme }) => `
    right: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};
    text-align:'center';
`
);

const formTemplates: CodeItem[] = [
  {
    code: 'checkin',
    value: 'Check-In Form'
  },
  {
    code: 'pledge',
    value: 'Donor/Pledge Form'
  }
];

export default function KiFormAppEditor() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id } = useParams();

  const [qrInfo, setQrInfo] = useState<QrInfo | null>(null);
  const [coverImage, setCoverImage] = useState<Blob | null | string>(null);

  const [isEdit, setIsEdit] = useState(Boolean(id && id.length));

  const { user } = useSelector((state: RootState) => state.app);

  const {
    data: { data: model } = {},
    isLoading,
    refetch: refetchById
  } = useQuery(['app-instance', id], (k) => getAppIstanceById(id), {
    enabled: isEdit,
    onSuccess: (e) => {
      setQrInfo(e.data.qrInfo);
      setCoverImage(e.data.kiFormApp?.coverImage || null);
    }
  });

  const { data: { data: profiles = [] } = {}, refetch } = useQuery(
    'profiles',
    getAllProfilesList
  );

  const {
    mutate: save,
    isError: isSaveError,
    error: errorOnSave,
    isLoading: isSaving
  } = useMutation((e: BusinessAppInstanceDto) => saveAppInstance(e, id), {
    onSuccess: (o) => onSaveSuccess(o.data)
  });

  //----Queries

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (isSaveError) {
    const msg = parseApiError(
      errorOnSave,
      `Failed to ${!isEdit ? 'created' : 'updated'} Contact`
    );
    enqueueSnackbar(t(msg), { variant: 'error' });
  }

  const onSaveSuccess = async (updatedModel: BusinessAppInstance) => {
    enqueueSnackbar(
      t(`App was ${!isEdit ? 'created' : 'updated'} successfully`),
      { variant: 'success' }
    );
    if (!isEdit) navigate('/app/business-apps/ki-form-app/' + updatedModel.id);
    else {
      appQueryClient.setQueryData(['app-instances', id], updatedModel);
      refetchById();
    }
  };

  const handleBack = (): void => {
    return navigate(readQueryString()?.ret || `/app/business-apps`);
  };

  const onCoverImageChange = (e) => {
    if (!e.target.files.length) return;

    // handle validations
    const file: File = e.target.files[0];

    if (!file) return;

    const maxMB = 50;

    var sizeInMB = +(file.size / (1024 * 1024)).toFixed(2);

    if (sizeInMB > maxMB) {
      enqueueSnackbar(t(`File size cannot exceed more than ${maxMB}MB`), {
        variant: 'error'
      });
      return;
    }

    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const blob = new Blob([reader.result], { type: file.name });
        setCoverImage(blob);
      };
      reader.readAsArrayBuffer(file);
    } catch (ex) {
      console.log(ex);
      //setIsUploading(false);
    }
  };
  const getImageUrl = () => {
    if (!coverImage) return cdnService.noImageUrl();

    if (typeof coverImage == 'string') return cdnService.toImageUrl(coverImage);

    return URL.createObjectURL(coverImage as Blob);
  };
  if (isLoading) {
    return <LinearProgress />;
  }

  if (isSaveError) {
    closeSnackbar();
    enqueueSnackbar(t(parseApiError(errorOnSave, 'Failed to save')), {
      variant: 'error'
    });
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          label: model?.label || '',
          template: model?.kiFormApp?.template || formTemplates[0].code,

          darkMode: (model?.kiFormApp?.theme || 'dark') == 'dark'
        }}
        validationSchema={Yup.object().shape({
          label: Yup.string().max(250)
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          if (!qrInfo?.code) {
            enqueueSnackbar(t('Please provide QR code'), { variant: 'error' });
            setStatus({ success: false });
            setSubmitting(false);
            return;
          }
          if (!coverImage) {
            enqueueSnackbar(t('Please upload cover image'), {
              variant: 'error'
            });
            setStatus({ success: false });
            setSubmitting(false);
            return;
          }
          let imgName = '';
          if (typeof coverImage == 'object') {
            try {
              const blobName = await cdnService.uploadFileToCloud(
                coverImage.type,
                coverImage
              );
              imgName = coverImage.type;
            } catch (ex) {
              console.log(ex);
              enqueueSnackbar(t('Failed to upload cover image'), {
                variant: 'error'
              });
              return;
            }
          } else {
            imgName = coverImage;
          }

          const updatedModel: BusinessAppInstanceDto = {
            ..._values,
            qrInfo: qrInfo,
            label: _values.label,
            appName: BusinessAppNames.KiFormApp,
            kiFormApp: {
              template: _values.template,
              coverImage: imgName,
              theme: _values.darkMode ? 'dark' : 'light'
            }
          };

          save(updatedModel);
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3} display="flex">
              <MainContentWrapper>
                <Grid
                  sx={{
                    px: 4
                  }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid item xs={12}>
                    <Box
                      mt={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <PageHeader
                        isEdit={isEdit}
                        id={id}
                        onBack={handleBack}
                        isSaving={isSaving}
                      />
                    </Box>
                  </Grid>

                  {isLoading && (
                    <Grid item xs={12}>
                      <LinearProgress />
                      loading...
                    </Grid>
                  )}
                  <Grid container mt={2}>
                    <Grid item xs={12} md={3}>
                      <QrCodeCard
                        label="Link"
                        qrInfo={model?.qrInfo}
                        placeholder=""
                        onChange={(e) => {
                          setQrInfo({ ...e });
                        }}
                        buildText={async (o) => {
                          return (
                            ApiUrls.viewerAppUrl +
                            '/' +
                            BusinessAppNames.KiFormApp +
                            '/' +
                            o
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Card sx={{ p: 2 }}>
                            <Field
                              fullWidth
                              name="label"
                              component={FmTextFiled}
                              label="KiForm name for your reference"
                              placeholder={t('enter a name')}
                            />
                            <FormHelperText>
                              (Optional) Enter a name
                            </FormHelperText>
                          </Card>
                        </Grid>
                        <Grid item xs={12}>
                          <Card sx={{ mt: 2 }}>
                            <CardHeader title="Ki-Form configuration"></CardHeader>
                            <CardContent>
                              <Grid spacing={2} container>
                                <Grid item xs={12} md={6}>
                                  <FormControl fullWidth>
                                    <InputLabel id="template-select">
                                      Select form template
                                    </InputLabel>
                                    <Field name="template">
                                      {({ field, form, meta }: FieldProps) => (
                                        <Select
                                          sx={{ mt: 1 }}
                                          labelId="template-select"
                                          value={field.value}
                                          onChange={(_event) => {
                                            form.setFieldValue(
                                              field.name,
                                              _event.target.value
                                            );
                                          }}
                                        >
                                          {formTemplates.map((o) => (
                                            <MenuItem
                                              key={o.code}
                                              value={o.code}
                                            >
                                              {o.value}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      )}
                                    </Field>
                                  </FormControl>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  paddingLeft={{ md: 2 }}
                                >
                                  <Field name="darkMode">
                                    {({ field, form, meta }: FieldProps) => (
                                      <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                          <Switch
                                            color={'primary'}
                                            onChange={(e, checked) =>
                                              form.setFieldValue(
                                                field.name,
                                                checked
                                              )
                                            }
                                            checked={field.value}
                                          />
                                        }
                                        label={t('Dark mode?')}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  sx={{ textAlign: 'center' }}
                                >
                                  <CardCover>
                                    <img
                                      style={{
                                        objectFit: 'contain',
                                        height: 400,
                                        width: '100%'
                                      }}
                                      src={getImageUrl()}
                                    />
                                    <CardCoverAction>
                                      <Input
                                        accept="image/*"
                                        id="change-cover"
                                        multiple
                                        type="file"
                                        onChange={onCoverImageChange}
                                      />
                                      <label htmlFor="change-cover">
                                        <Button
                                          startIcon={<UploadTwoToneIcon />}
                                          variant="contained"
                                          component="span"
                                        >
                                          {t('Change cover')}
                                        </Button>
                                      </label>
                                    </CardCoverAction>
                                  </CardCover>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12}>
                          <Card
                            sx={{
                              p: 1
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Button
                                  type="button"
                                  variant="outlined"
                                  onClick={handleBack}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  sx={{ ml: 2 }}
                                  type="submit"
                                  variant="contained"
                                  startIcon={
                                    isSubmitting || isSaving ? (
                                      <CircularProgress size="1rem" />
                                    ) : (
                                      <SaveIcon />
                                    )
                                  }
                                  disabled={
                                    isLoading || isSubmitting || isSaving
                                  }
                                >
                                  Save
                                </Button>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}></Grid>
                  </Grid>
                </Grid>
              </MainContentWrapper>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}
