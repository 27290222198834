import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldWrapper } from 'src/components/FieldWrapper';
import { PreviewerField } from 'src/contents/profile-editor/ProfilePreviewer';
import {
  ProfileData,
  ProfileFieldNames
} from 'src/services/apiService/response-models';
import FieldsRegistry from '../..';

export default function FixedFieldsBlock({
  profileTheme,
  preFields,
  data,
  onEditClick,
  fieldInEdit
}: {
  preFields: PreviewerField[];
  data: ProfileData;
  profileTheme: string;
  onEditClick: (e: React.MouseEvent<HTMLElement>, f: PreviewerField) => void;
  fieldInEdit: PreviewerField | null;
}) {
  const [fields, setFields] = useState<PreviewerField[]>([]);

  useEffect(() => {
    const arr: PreviewerField[] = [];

    const fixedFieldNames = [
      ProfileFieldNames.FullName,
      ProfileFieldNames.JobTitle,
      ProfileFieldNames.Department,
      ProfileFieldNames.CompanyName,
      ProfileFieldNames.BusinessName,
      ProfileFieldNames.Headline
    ];

    fixedFieldNames.forEach((fname) => {
      const match = preFields.find((o) => o.field.name == fname);
      if (match) {
        arr.push(match);
      }
    });
    const matched = arr.find((o) => o.field.name == fieldInEdit?.field.name);
    if (
      !matched &&
      fieldInEdit &&
      fixedFieldNames.find((o) => o == fieldInEdit.field.name)
    ) {
      arr.push(fieldInEdit);
    }

    setFields(arr);
  }, [preFields, fieldInEdit]);

  const isActive = (e: PreviewerField) => {
    return fieldInEdit?.preId === e.preId;
  };

  if (!fields.length) return <></>;

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {fields.map((f) => (
        <Grid key={f.preId} item xs={12}>
          <FieldWrapper
            className={
              'field-wrapper ' + f.preId + (isActive(f) ? ' active-field' : '')
            }
          >
            {FieldsRegistry[f.field.name].renderBlock({
              field: f.field,
              data: data || {},
              theme: profileTheme
            })}
            <Box className="MuiActionButtons">
              <Tooltip
                arrow
                placement="top"
                title={'Edit ' + f.field.displayName}
              >
                <IconButton color="primary" onClick={(e) => onEditClick(e, f)}>
                  <EditTwoToneIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </FieldWrapper>
        </Grid>
      ))}
    </Grid>
  );
}
