import { useRef, useState } from 'react';

import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LeaderboardTwoTone } from '@mui/icons-material';
import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

function BulkActions(props: {
  onDelete: () => void;
  onContactConversion: () => void;
}) {
  const [onMenuOpen, menuOpen] = useState<boolean>(false);
  const moreRef = useRef<HTMLButtonElement | null>(null);
  const { t }: { t: any } = useTranslation();

  const openMenu = (): void => {
    menuOpen(true);
  };

  const closeMenu = (): void => {
    menuOpen(false);
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" color="text.secondary">
            {t('Bulk actions')}:
          </Typography>
          <IconButton
            color="primary"
            sx={{
              ml: 1,
              p: 1
            }}
          >
            <VerifiedUserTwoToneIcon />
          </IconButton>

          {/* <Tooltip arrow placement="top" title={t('')}>
            <ButtonError
              sx={{
                ml: 1
              }}
              startIcon={<DeleteTwoToneIcon />}
              variant="contained"
              onClick={props.onDelete}
            >
              {t('Delete')}
            </ButtonError>
          </Tooltip> */}
          <Tooltip arrow placement="top" title={t('Sent back to contact')}>
            <Button
              sx={{
                ml: 1
              }}
              startIcon={<LeaderboardTwoTone />}
              variant="contained"
              onClick={props.onContactConversion}
            >
              {t('Close Leads')}
            </Button>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
}

export default BulkActions;
